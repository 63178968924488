export default function FilterIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 51 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.2998 7.69961H16.5998C15.8998 7.69961 15.2998 7.09961 15.2998 6.39961C15.2998 5.69961 15.8998 5.09961 16.5998 5.09961H49.2998C49.9998 5.09961 50.5998 5.69961 50.5998 6.39961C50.5998 7.09961 49.9998 7.69961 49.2998 7.69961Z"
        fill="black"
      />
      <path
        d="M6.4 7.69961H1.3C0.6 7.69961 0 7.09961 0 6.39961C0 5.69961 0.6 5.09961 1.3 5.09961H6.4C7.1 5.09961 7.7 5.69961 7.7 6.39961C7.7 7.09961 7.2 7.69961 6.4 7.69961Z"
        fill="black"
      />
      <path
        d="M49.2999 20.0004H40.6999C39.9999 20.0004 39.3999 19.4004 39.3999 18.7004C39.3999 18.0004 39.9999 17.4004 40.6999 17.4004H49.2999C49.9999 17.4004 50.5999 18.0004 50.5999 18.7004C50.5999 19.4004 49.9999 20.0004 49.2999 20.0004Z"
        fill="black"
      />
      <path
        d="M30.5 20.0004H1.3C0.6 20.0004 0 19.4004 0 18.7004C0 18.0004 0.6 17.4004 1.3 17.4004H30.5C31.2 17.4004 31.8 18.0004 31.8 18.7004C31.8 19.4004 31.2 20.0004 30.5 20.0004Z"
        fill="black"
      />
      <path
        d="M49.2998 32.4008H16.5998C15.8998 32.4008 15.2998 31.8008 15.2998 31.1008C15.2998 30.4008 15.8998 29.8008 16.5998 29.8008H49.2998C49.9998 29.8008 50.5998 30.4008 50.5998 31.1008C50.5998 31.8008 49.9998 32.4008 49.2998 32.4008Z"
        fill="black"
      />
      <path
        d="M6.4 32.3992H1.3C0.6 32.3992 0 31.7992 0 31.0992C0 30.2992 0.6 29.6992 1.3 29.6992H6.4C7.1 29.6992 7.7 30.2992 7.7 30.9992C7.8 31.7992 7.2 32.3992 6.4 32.3992Z"
        fill="black"
      />
      <path
        d="M11.5001 12.8C8.0001 12.8 5.1001 9.9 5.1001 6.4C5.1001 2.9 8.0001 0 11.5001 0C15.0001 0 17.9001 2.9 17.9001 6.4C17.9001 9.9 15.1001 12.8 11.5001 12.8ZM11.5001 2.6C9.4001 2.6 7.7001 4.3 7.7001 6.4C7.7001 8.5 9.4001 10.2 11.5001 10.2C13.6001 10.2 15.3001 8.5 15.3001 6.4C15.3001 4.3 13.6001 2.6 11.5001 2.6Z"
        fill="black"
      />
      <path
        d="M35.6002 25.1008C32.1002 25.1008 29.2002 22.2008 29.2002 18.7008C29.2002 15.2008 32.1002 12.3008 35.6002 12.3008C39.1002 12.3008 42.0002 15.2008 42.0002 18.7008C42.0002 22.2008 39.2002 25.1008 35.6002 25.1008ZM35.6002 14.9008C33.5002 14.9008 31.8002 16.6008 31.8002 18.7008C31.8002 20.8008 33.5002 22.5008 35.6002 22.5008C37.7002 22.5008 39.4002 20.8008 39.4002 18.7008C39.4002 16.6008 37.7002 14.9008 35.6002 14.9008Z"
        fill="black"
      />
      <path
        d="M11.5001 37.4992C8.0001 37.4992 5.1001 34.5992 5.1001 31.0992C5.1001 27.4992 8.0001 24.6992 11.5001 24.6992C15.0001 24.6992 17.9001 27.5992 17.9001 31.0992C18.0001 34.5992 15.1001 37.4992 11.5001 37.4992ZM11.5001 27.2992C9.4001 27.2992 7.7001 28.9992 7.7001 31.0992C7.7001 33.1992 9.4001 34.8992 11.5001 34.8992C13.6001 34.8992 15.3001 33.1992 15.3001 31.0992C15.3001 28.9992 13.6001 27.2992 11.5001 27.2992Z"
        fill="black"
      />
    </svg>
  );
}
