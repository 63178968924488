import { CFormInput, CSpinner } from "@coreui/react";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { FormEvent } from "react";
import BoxWrapper from "../../../components/BoxWrapper";
import apiService from "../../../service/apiService";
import { general } from "../../../locales/general";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import RestrictedRoute from "../../auth/RestrictedRoute";

interface NewCityData {
  label: string;
  latitude: string;
  longitude: string;
}

interface Props {
  onClose: () => void;
  onAccept: () => void;
}

export default function NewCity({ onClose }: Props) {
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();

  // @desc create new user
  const createNewCityMutation = useMutation(async (data: NewCityData) => {
    return await apiService.MakePostRequest("cities", data, token);
  });

  // @desc form submit handler
  const createCityHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const label = formData.get("label") as string;
    const latitude = formData.get("lat") as string;
    const longitude = formData.get("long") as string;

    const data = {
      label,
      latitude,
      longitude
    };
    createNewCityMutation.mutate(data, {
      onSuccess(data: any) {
        toastSuccess(general.fr.message.cityCreated);
        queryClient.invalidateQueries("getAllCities");

        onClose();
      },
      onError(error: any) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      }
    });
  };

  return (
    <RestrictedRoute>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success mb-3">
              <h4>Ajouter une ville</h4>
            </div>
            <form className="row form " onSubmit={createCityHandler}>
              <div className="col-12 mb-3">
                <label htmlFor="label" className="d-block">
                  Ville
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Ville"
                  id="label"
                  name="label"
                  required
                />
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="long" className="d-block">
                  Longitude
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Longitude"
                  id="long"
                  name="long"
                  required
                />
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="lat" className="d-block">
                  Latitude
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Latitude"
                  id="lat"
                  name="lat"
                  required
                />
              </div>

              <section className="d-flex justify-content-start buttons gap-4 mt-3">
                <button
                  className="btn btn-danger text-white shadow-primary w-20 px-4 py-2"
                  type="button"
                  onClick={onClose}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-success shadow-secondary w-20 text-white"
                  type="submit"
                  disabled={createNewCityMutation.isLoading}
                >
                  {createNewCityMutation.isLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </RestrictedRoute>
  );
}
