import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../useCookie";
import { useToast } from "../useToast";
import { general } from "../../locales/general";
import { useMemo } from "react";

export const useCitiesQuery = () => {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();

  const { data, isLoading } = useQuery<City[]>(["getAllCities"], query => {
    return apiService.MakeGetRequest("cities");
  });
  const deleteCity = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`cities/${id}`, token);
  });

  const arrangedCities = useMemo(() => {
    const results = data?.sort(function(a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return results || [];
  }, [data]);

  return {
    Cities: arrangedCities,
    fetchingCities: isLoading,
    deleteCity(id: string) {
      deleteCity.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.cityDeleted);
          queryClient.invalidateQueries("getAllCities");
        }
      });
    },
    mutating: deleteCity.isLoading
  };
};
