// @ts-nocheck
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  categoryId: yup
    .string()
    .typeError("Ce champ est obligatoire")
    .required("Ce champ est obligatoire")
});

interface FormData {
  monthYear: Date;
}

interface IProps {
  categories: Category[];
  onClose: () => void;
  onSelect: (category: Category) => void;
}

export default function CategoryModel({
  categories,
  onSelect,
  onClose
}: IProps) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: FormData) => {
    onSelect(categories.find(category => category.id === data.categoryId));
  };

  return (
    <div className="">
      <section className="p-4">
        <div className="border-bottom border-success mb-3">
          <h4>Choisir une catégorie</h4>
        </div>
        <form className="row form " onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 mb-3">
            <label htmlFor="categoryId" className="d-block">
              Catégorie
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register("categoryId")}
            >
              {categories?.map(category => (
                <option value={category.id}>{category.label}</option>
              ))}
            </select>
            {errors.categoryId?.message && (
              <div
                className="text-red-500 text-opacity-50"
                style={{ color: "red" }}
              >
                {errors.categoryId.message}
              </div>
            )}
          </div>

          <section className="d-flex justify-content-start buttons gap-4 mt-3">
            <button
              className="btn btn-danger text-white shadow-primary w-20 px-4 py-2"
              type="button"
              onClick={onClose}
            >
              Annuler
            </button>
            <button
              className="btn btn-success shadow-secondary w-20 text-white"
              type="submit"
            >
              Enregistrer
            </button>
          </section>
        </form>
      </section>
    </div>
  );
}
