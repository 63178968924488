import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCookie } from "../useCookie";
import { useToast } from "../useToast";
import apiService from "../../service/apiService";
import { general } from "../../locales/general";
import { useEffect, useState } from "react";
import { toQuery } from "../../helpers/general";
import { useDebounce } from "../useDebounce";

export const useTranslationsQuery = (target: string) => {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  let debouncedQuery = useDebounce(searchQuery, 2000);

  useEffect(() => {
    setSearchQuery("");
    debouncedQuery = "";
  }, [target]);

  useEffect(() => {
    setCurrentPage(1);
  }, [target, debouncedQuery]);

  const getEndpoint = target?.includes("service-sub-category")
    ? `service-category-translations/sub-categories-translation/all`
    : `${target}-translations`;
  const deleteEndpoint = target?.includes("service-sub-category")
    ? `service-category-translations/sub-categories-translation`
    : `${target}-translations`;

  const queryDeps = [
    "getAllTranslations",
    currentPage,
    getEndpoint,
    debouncedQuery
  ];

  const { data, isLoading } = useQuery(queryDeps, query => {
    return apiService.MakeGetRequest(
      `${getEndpoint}${toQuery({
        page: currentPage,
        query: debouncedQuery
      })}`,
      token
    );
  });

  const deleteTranslation = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`${deleteEndpoint}/${id}`, token);
  });
  return {
    translations: data?.items,
    totalPages: data?.meta.totalPages,
    totalCount: data?.meta.totalItems,
    fetchingTranslations: isLoading,
    deleteTranslation(id: string) {
      deleteTranslation.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.partnerDeleted);
          queryClient.invalidateQueries("getAllTranslations");
        }
      });
    },
    mutating: deleteTranslation.isLoading,
    currentPage,
    searchHandler: (query: string) => setSearchQuery(query),
    setCurrentPage
  };
};
