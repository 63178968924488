import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export const useCookie = (name: string) => {
  const [cookies, setCookies, removeCookie] = useCookies([name]);
  const [id, setId] = useState("");

  // useEffect(() => {
  //   const decodedValue: any = jwtDecode(cookies[name]);
  //   setId(decodedValue.id);
  // }, [cookies, name]);
  return { token: cookies[name], setCookies, id, removeCookie };
};
