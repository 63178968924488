import { Controller } from "react-hook-form";
import FormInput from "../../components/FormInput";
import InfiniteScrollSelect from "../../components/InfiniteScrollSelect";
import RichTextEditor from "../../components/RichTextEditor";
import EventServicesSection from "./EventServiceSection";

interface Props {
  control: any;
  selectedEvent?: any;
  maxUsage: number;
  defaultValues?: PromoCode;
}
export default function CreatePromoCode(props: Props) {
  const { control, selectedEvent, maxUsage = 0, defaultValues } = props;

  return (
    <div className="row form">
      <div className="col-md-6 mt-4">
        <FormInput
          name="name"
          label="Name"
          control={control}
          defaultValue={defaultValues?.name}
        />
      </div>
      <div className="col-md-6 mt-4">
        <FormInput
          name="discount"
          type="number"
          min={0}
          label="Discount"
          control={control}
          defaultValue={defaultValues?.discount?.toString()}
        />
      </div>
      <div className="col-md-6 mt-4">
        <FormInput
          name="maxUses"
          type="number"
          min={0}
          label="Max usage"
          control={control}
          defaultValue={defaultValues?.maxUses}
        />
      </div>
      <div className="mt-4 col-md-6">
        <Controller
          name="event"
          control={control}
          defaultValue={defaultValues?.event}
          render={({ field, fieldState }) => (
            <div>
              <label htmlFor="events">Events</label>
              <InfiniteScrollSelect
                defaultValue={defaultValues?.event}
                key={"events"}
                isClearable
                getOptionLabel={(option: Events) => option?.name}
                getOptionValue={(option: Events) => option.id}
                isOptionSelected={(option: Place, selectedValue: Place[]) => {
                  const isSelected = option.name === field.value;
                  return isSelected;
                }}
                url={{
                  path: "events/ours"
                }}
                value={field.value}
                onChange={(event: Events) => {
                  field.onChange(event);
                }}
              />
              <ErrorMessage error={fieldState.error?.message} />
            </div>
          )}
        />
      </div>
      <EventServicesSection
        control={control}
        event={selectedEvent}
        maxUsage={maxUsage}
      />
      <div className="col-md-6 mt-4">
        <Controller
          name="isActive"
          control={control}
          defaultValue={defaultValues?.isActive}
          render={({ field, fieldState }) => (
            <div>
              <div className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  id="isActive"
                  defaultChecked={defaultValues?.isActive}
                  {...field}
                />
                <label htmlFor="isActive">isActive</label>
              </div>
              <ErrorMessage error={fieldState.error?.message} />
            </div>
          )}
        />
      </div>

      <div className="mt-4">
        <Controller
          name="description"
          control={control}
          defaultValue={defaultValues?.description as string}
          render={({ field, fieldState }) => (
            <div>
              <label htmlFor="description">Description</label>
              <RichTextEditor
                {...field}
                defaultValue={defaultValues?.description as string}
              />
              <ErrorMessage error={fieldState.error?.message} />
            </div>
          )}
        />
      </div>
    </div>
  );
}

export function ErrorMessage({ error }: { error?: string }) {
  if (!error) return null;
  return (
    <div className="text-red-500 text-opacity-50" style={{ color: "red" }}>
      {error}
    </div>
  );
}
