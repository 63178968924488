import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../useCookie";
import { general } from "../../locales/general";
import { useToast } from "../useToast";
import { useState } from "react";
import { useDebounce } from "../useDebounce";
import { toQuery } from "../../helpers/general";

export const useEventQuery = () => {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedQuery = useDebounce(searchQuery, 2000);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchEvents = async (query: string = "", page: number) => {
    const endpoint = `events/ours${toQuery({ q: query, page })}`;
    return apiService.MakeGetRequest(endpoint, token);
  };

  const queryKey = ["getAllEvents", debouncedQuery, currentPage];

  const { data, isLoading: fetchingEvents } = useQuery(queryKey, () =>
    fetchEvents(debouncedQuery, currentPage)
  );

  const deleteEvent = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`events/${id}`, token);
  });

  return {
    removeEvent(id: string) {
      deleteEvent.mutate(id, {
        onError(error) {
          console.log(error);
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.eventDeleted);
          queryClient.invalidateQueries("getAllEvents");
        }
      });
    },
    mutating: deleteEvent.isLoading,
    fetchingEvents,
    events: data?.items,
    totalEvents: data?.meta?.totalItems,
    totalPages: data?.meta?.totalPages,
    searchHandler: (query: string) => setSearchQuery(query),
    currentPage,
    setCurrentPage
  };
};
