import { CCol, CRow } from "@coreui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useMutation } from "react-query";
import { ReactSortable } from "react-sortablejs";
import { apiConfig } from "../../config/apiConfig";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import apiService from "../../service/apiService";
import { format as dateFnsFormat } from "date-fns";
import AdminRoute from "../auth/RestrictedRoute";
const eventPlaceholderImage = "/venez.jpeg";

interface MutationProps {
  eventsOrder: {
    eventId: string;
    order: number;
  }[];
}

export default function OrganizeEvents() {
  const { token } = useCookie("vToken");
  const [arrangedEvents, setArrangedEvents] = useState<Events[]>([]);
  const [ordered, setOrdered] = useState(false);
  const currentPageRef = useRef<null | number>(1);

  const endOfEventsListRef = useRef(null);

  window.onbeforeunload = function() {
    window.scrollTo(0, 0);
  };
  const fetchEvents = async (page: number) => {
    const endpoint = apiConfig.events.all({ page, limit: "10" });
    return apiService.MakeGetRequest(endpoint, token);
  };

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    "getEventsOrganize",
    ({ pageParam }) => {
      return fetchEvents(pageParam);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const { currentPage, totalPages } = lastPage?.meta;
        const nextPage =
          currentPage === totalPages ? undefined : currentPage + 1;

        currentPageRef.current = currentPage;

        return nextPage;
      },
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const handleObserver = useCallback(
    (entries: any) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    if (!isLoading) {
      const fetchedEvents = data?.pages[data?.pageParams?.length - 1]?.items;

      if (ordered) {
        setArrangedEvents(events => [
          ...events.map((offer, i) => ({ ...offer, order: i + 1 })),
          ...fetchedEvents
        ]);
      } else {
        setArrangedEvents(events => [...events, ...fetchedEvents]);
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (endOfEventsListRef.current)
      observer.observe(endOfEventsListRef.current);
  }, [handleObserver]);

  const { toastError, toastSuccess } = useToast();

  const eventsOrderMutation = useMutation((data: MutationProps) =>
    apiService.MakePutRequest(apiConfig.events.set_order(), data, token)
  );

  const savePositions = () => {
    const eventsOrder = arrangedEvents.map((event, index) => ({
      eventId: event.id,
      order: index + 1
    }));

    eventsOrderMutation.mutate(
      { eventsOrder: eventsOrder },
      {
        onError(e) {
          toastError("Quelque chose n'a pas fonctionné");
        },
        onSuccess() {
          toastSuccess("L'ordre des événements a été mis à jour");
        }
      }
    );
  };

  return (
    <AdminRoute>
      <CRow className="bg-white p-3 rounded-md mb-3 mx-auto align-items-center">
        <CCol className="col-12 col-sm-7 col-md-8">
          <p className="organize__offer__description">
            Déplacer un événement pour préciser son emplacement dans
            l'application
          </p>
        </CCol>
        <CCol className="col-12 col-sm-5 col-md-4 mt-3 mt-sm-0">
          <button
            onClick={savePositions}
            className="btn btn-danger text-white ms-auto d-block shadow-primary"
            disabled={!arrangedEvents?.length || eventsOrderMutation.isLoading}
          >
            Mettre à jour
          </button>
        </CCol>
      </CRow>
      {!isLoading ? (
        <>
          <ReactSortable
            list={arrangedEvents}
            setList={setArrangedEvents}
            className="organize__offers_wrapper"
            onChange={e => setOrdered(true)}
          >
            {arrangedEvents?.map((event, index) => {
              const eventPassed = new Date(event.date) < new Date();
              return (
                <div key={event?.id}>
                  <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-2">
                    {/* <p className="text-danger my-0 text-uppercase">
                    {offersDisplayType[offer?.type]}
                  </p> */}
                    <div className="d-flex">
                      <p className="text-danger my-0 text-uppercase me-3">
                        {dateFnsFormat(
                          new Date(event.date),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </p>
                      <div>
                        {eventPassed === true ? (
                          <div className="bg-danger text-white px-2 rounded-sm">
                            Passé
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <p
                      className="fw-bold text-danger p-1 border text-center my-0"
                      style={{
                        marginLeft: "auto",
                        width: "30px",
                        height: "30px"
                      }}
                    >
                      {index + 1}
                    </p>
                  </div>

                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={event.image || eventPlaceholderImage}
                      alt={event?.name}
                      width="45px"
                      height="45px"
                      style={{ objectFit: "cover" }}
                    />
                    <p className="my-0 fw-semibold">
                      {event?.name}
                      <br />
                      {event?.place !== null ? (
                        <div>
                          <span className="text-danger">Établissement: </span>
                          {event?.place.name}
                        </div>
                      ) : null}
                    </p>
                  </div>
                </div>
              );
            })}
          </ReactSortable>
          {isFetchingNextPage && <p>Chargement...</p>}
          <div ref={endOfEventsListRef} />
        </>
      ) : null}
    </AdminRoute>
  );
}
