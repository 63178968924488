import {
  CModal,
  CModalBody,
  CModalContent,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useState } from "react";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { colors } from "../../config/theme";
import EditIcon from "../../components/icons/EditIcon";
import PromptModal from "../../components/modals/PromptModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";

interface newTypeModalProps {
  open: boolean;
  onClose: () => void;
  isUpdate?: boolean;
  category?: string;
  id?: string;
}
interface createNewTypeProp {
  label: string;
}
const TypeModal = ({
  open,
  onClose,
  isUpdate = false,
  category = "",
  id = "",
}: newTypeModalProps) => {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const [categoryName, setCategoryName] = useState(category);
  const queryClient = useQueryClient();
  const createEventType = useMutation((data: createNewTypeProp) => {
    return apiService.MakePostRequest("events/types", data, token);
  });
  const updateEventType = useMutation((data: createNewTypeProp) => {
    return apiService.MakePutRequest(`events/types/${id}`, data, token);
  });

  const createNewType = () => {
    createEventType.mutate(
      { label: categoryName },
      {
        onError() {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          queryClient.invalidateQueries("getEventTypes");
          toastSuccess(general.fr.message.eventTypeCreated);
          onClose();
          setCategoryName("");
        },
      }
    );
  };
  const updateType = () => {
    updateEventType.mutate(
      { label: categoryName },
      {
        onError() {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          queryClient.invalidateQueries("getEventTypes");
          toastSuccess(general.fr.message.eventTypeUpdated);
          onClose();
          setCategoryName("");
        },
      }
    );
  };
  return (
    <CModal visible={open} onClose={onClose} alignment="center">
      <CModalBody>
        <h5 className="fs-5 mb-4 border-bottom pb-1">
          {isUpdate ? "Mise à jour de la catégorie" : "Ajouter une catégorie"}
        </h5>
        <div>
          <label htmlFor="name" className="text-sm">
            Nom
          </label>
          <input
            type="text"
            className="custom-input w-100 mt-1"
            name="label"
            placeholder="Nom de la catégorie"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>
        <button
          type="button"
          onClick={isUpdate ? updateType : createNewType}
          disabled={createEventType.isLoading || updateEventType.isLoading}
          className="d-flex align-items-center justify-content-center btn btn-danger shadow-primary d-block w-100 mt-3 p-2 fw-bold text-white"
        >
          {createEventType.isLoading || updateEventType.isLoading ? (
            <>
              <CSpinner size="sm" /> {general.fr.wait}
            </>
          ) : isUpdate ? (
            "Mise à jour"
          ) : (
            "Sauvegarde"
          )}
        </button>
      </CModalBody>
    </CModal>
  );
};

const TableContent = ({ type }: { type: EventType }) => {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const deleteEventType = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`events/types/${id}`, token);
  });

  const deleteType = (id: string) => {
    deleteEventType.mutate(id, {
      onError() {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        queryClient.invalidateQueries("getEventTypes");
        toastSuccess(general.fr.message.eventTypeDeleted);
        setShowModal(false);
      },
    });
  };
  return (
    <>
      <CTableRow key={type?.id}>
        <CTableDataCell>{type?.label}</CTableDataCell>
        <CTableDataCell>
          <div className="d-flex align-items-center gap-3">
            <button
              onClick={() => setShowEditModal(true)}
              className="outline-none border-0 bg-transparent"
            >
              <EditIcon width="18" height="18" fill={colors.secondary} />
            </button>
            <button
              onClick={() => setShowModal(true)}
              className="outline-none border-0 bg-transparent"
            >
              <DeleteIcon
                width="20"
                height="20"
                fill={colors.primary}
                className="ms-2"
              />
            </button>
          </div>
        </CTableDataCell>
      </CTableRow>
      <PromptModal
        onAccept={() => deleteType(type?.id)}
        onClose={() => setShowModal(false)}
        open={showModal}
      />
      <TypeModal
        onClose={() => setShowEditModal(false)}
        open={showEditModal}
        id={type?.id}
        category={type?.label}
        isUpdate
      />
    </>
  );
};

export default function TypesTable() {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { data: eventType } = useQuery(["getEventTypes"], () => {
    return apiService.MakeGetRequest("events/types");
  });

  return (
    <div>
      <TypeModal
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
      <button
        onClick={() => setShowCreateModal(true)}
        className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary ms-2 my-3"
      >
        + Créer
      </button>
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm w-75">
              Nom
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {eventType?.map((type: EventType) => (
            <TableContent key={type?.id} type={type} />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
