import React, { ReactNode } from "react";

export default function BoxWrapper({
  className = "",
  children
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <div
      className={`w-100 bg-white rounded-lg shadow-sm my-3 rounded-top ${className}`}
    >
      {children}
    </div>
  );
}
