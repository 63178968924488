import React, { useMemo, useReducer, useState, useEffect } from "react";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../../context/bulkDeleteReducer";
import BoxWrapper from "../../../components/BoxWrapper";
import { Link, useParams } from "react-router-dom";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { colors } from "../../../config/theme";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CSpinner } from "@coreui/react";
import RenderTable from "../../../components/RenderTable";
import { usePaginatedContent } from "../../../hooks/usePaginatedContent";
import { general } from "../../../locales/general";
import Pagination from "../../../components/Pagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../../service/apiService";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import PlaceServicesTable from "./PlaceServiceTable";
import { pluralize } from "../../../helpers/general";
import { useDebounce } from "usehooks-ts";

const PAGE_SIZE = 10;

export default function PlaceServices() {
  const params = useParams();
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const queryClient = useQueryClient();
  const [query, setQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  // const [searchQuery, setSearchQuery] = useState("");

  const searchQuery = useDebounce(query, 1000);

  const fetchPlaceServices = async (
    currentPage: number,
    searchQuery: string,
    token: string
  ) => {
    const endpoint = `places/${params?.placeId}/services?page=${currentPage}&limit=${PAGE_SIZE}&q=${searchQuery}`;
    return apiService.MakeGetRequest(endpoint, token);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery])

  const querykey = ["getPlaceServices", token, currentPage, searchQuery];

  const { data: Services, isLoading } = useQuery(
    querykey,
    query => fetchPlaceServices(currentPage, searchQuery, query.queryKey[1]),
    { keepPreviousData: true }
  );

  const deleteServices = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`Services/${id}`, token);
  });

  const paginatedContent = usePaginatedContent(
    currentPage,
    PAGE_SIZE,
    Services
  );

  const filteredServices = useMemo(() => {
    const Services = paginatedContent?.filter(
      (Service: any) =>
        Service?.label?.toLowerCase()?.includes(searchQuery) ||
        Service?.startingPrice?.toString()?.includes(searchQuery)
    );
    return Services;
  }, [paginatedContent, searchQuery]);

  const [ServicesToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    ServicesToBeDeleted.ids.forEach((id: string) => {
      if (!id) return;
      deleteServices.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.serviceDeleted);
          queryClient.invalidateQueries("getPlaceServices");
        }
      });
    });
  };
  const PlaceSVG = () => {
    return (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17 0H1V2H17V0ZM18 10V8L17 3H1L0 8V10H1V16H11V10H15V16H17V10H18ZM9 14H3V10H9V14Z" />
      </svg>
    );
  };

  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Services</h5>
      <BoxWrapper>
        <div className="d-flex flex-column flex-md-row  justify-content-between align-items-baseline before-table">
          <div className="d-flex align-items-center gap-2 p-3 delete-add ">
            <Link
              to={`/places/${params.placeId}/services/new`}
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
            >
              + Ajouter un service
            </Link>

            <button
              disabled={
                deleteServices.isLoading ||
                ServicesToBeDeleted?.ids.length === 0
              }
              onClick={handleBulkDelete}
              className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary text-pre"
            >
              {!deleteServices.isLoading ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>
          <div className="search-form">
            <div className=" input-field">
              <MagnifyingGlassIcon color="gray" width={20} />
              <input
                type="text"
                className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                placeholder="Rechercher"
                onChange={e => setQuery(e?.target?.value?.toLowerCase())}
              />
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3 "
            style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
          >
            <PlaceSVG />
            <p className="fs-5 fw-bold m-0 text-white text-pre">
              {" "}
              {Services?.length} {pluralize("Service", Services?.length)}
            </p>
          </div>
        </div>

        <RenderTable
          loading={isLoading}
          render={() =>
            filteredServices?.length > 0 ? (
              <PlaceServicesTable
                Services={filteredServices as Service[]}
                ServicesToBeDeleted={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={Math.ceil(Services?.length / PAGE_SIZE)}
        pageSize={PAGE_SIZE}
      />
    </section>
  );
}
