import React from "react";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <p className="text-light text-gradient-primary fw-bold fs-2 w-md-75 text-center">
        Quelque chose n'a pas fonctionné
      </p>
      <Link to={"/home"} className="btn btn-danger">
        Retourner
      </Link>
    </div>
  );
}
