import { CSpinner } from "@coreui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React, { FormEvent, useMemo, useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOutSideClick";
import { usePlaceZone } from "../../hooks/usePlaceZone";

interface Props {
  onZoneSelect: (value: string) => void;
}
export default function CustomSelectInput({ onZoneSelect }: Props) {
  const [isOpened, setIsOpened] = useState(false);
  const [zone, updateZone] = useState({
    selectedZone: "",
    newZoneName: "",
    addNew: false
  });
  const dropDownRef = useRef(null);

  const { createZone, creatingZone, getZones } = usePlaceZone();

  const handleZoneChange = (
    target: keyof typeof zone,
    value: string | boolean
  ) => {
    updateZone(zone => ({ ...zone, [target]: value }));
  };

  const createNewZoneHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createZone(
      {
        placeObjects: [],
        name: zone.newZoneName
      },
      () => {
        handleZoneChange("newZoneName", " ");
      }
    );
  };

  const zones = useMemo(() => {
    return getZones();
  }, [getZones]);

  useOnClickOutside(dropDownRef, () => setIsOpened(false));

  return (
    <div ref={dropDownRef} className="position-relative">
      <button
        type="button"
        className=" border w-full btn d-flex justify-content-between align-items-center p-1 "
        onClick={() => setIsOpened(o => !o)}
      >
        <p className="ps-2 my-0">{zone.selectedZone || "Select zone"}</p>
        {isOpened ? (
          <ChevronUpIcon width={15} />
        ) : (
          <ChevronDownIcon width={15} />
        )}
      </button>
      {isOpened && (
        <div
          className="border w-full rounded-sm position-absolute left-0 bg-white "
          style={{ top: "40px", zIndex: 20 }}
        >
          <div className="select-content">
            {zones?.map(zone => (
              <SelectItem
                key={zone.id}
                zoneId={zone.id}
                zoneName={zone.name}
                onSelect={(value: string, zoneId: string) => {
                  onZoneSelect(zoneId);
                  handleZoneChange("selectedZone", value);
                }}
                onHide={() => setIsOpened(false)}
              />
            ))}
          </div>
          <form
            onSubmit={createNewZoneHandler}
            className="bg-white border-top py-2 px-1 mt-1"
          >
            {zone.addNew && (
              <input
                type="text"
                className="custom-input mb-2 p-1"
                placeholder="Dinning Area"
                onChange={e => handleZoneChange("newZoneName", e.target.value)}
                defaultValue={zone.newZoneName}
                value={zone.newZoneName}
              />
            )}
            <button
              type={!zone.addNew ? "button" : "submit"}
              className="btn btn-danger w-full shadow-primary d-flex justify-content-center align-items-center"
              onClick={() =>
                !zone.addNew ? handleZoneChange("addNew", true) : {}
              }
              disabled={
                (zone.addNew && !zone.newZoneName.length) || creatingZone
              }
            >
              {creatingZone && <CSpinner size="sm" />}
              Add new zone
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

interface SelectItemProps {
  zoneId: string;
  zoneName: string;
  onSelect: (value: string, zoneId: string) => void;
  onHide: () => void;
}
function SelectItem({ zoneId, zoneName, onSelect, onHide }: SelectItemProps) {
  const [isEditMode, setIsEditMode] = useState(false);

  const { updateZone, updatingZone, deleteZone, deletingZone } = usePlaceZone();

  const updateHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const zoneName = formData.get("zoneName") as string;

    const data = {
      name: zoneName,
      zoneId
    };

    updateZone(data, () => {
      e.currentTarget.reset();
      setIsEditMode(false);
    });
  };

  const handleSelect = (value: string, zoneId: string) => {
    onSelect(value, zoneId);
    onHide();
  };
  return (
    <form
      onSubmit={updateHandler}
      className="pt-2 pb-0 px-1 w-full d-flex align-items-center justify-content-between cursor-pointer"
      onClick={() => !isEditMode && handleSelect(zoneName, zoneId)}
    >
      <input
        type="text"
        defaultValue={zoneName}
        className={`${
          isEditMode ? "border px-1" : "border-0"
        } outline-none w-75 cursor-pointer`}
        readOnly={!isEditMode}
        name="zoneName"
      />
      <div className="d-flex gap-1">
        {!isEditMode && (
          <button
            className="border-0 outline-none bg-transparent p-0"
            onClick={e => {
              e.stopPropagation();
              setIsEditMode(true);
            }}
            type="button"
          >
            <PencilIcon width={12} />
          </button>
        )}
        <button
          onClick={e => {
            e.stopPropagation();
            isEditMode
              ? setIsEditMode(false)
              : deleteZone(zoneId, () => {
                  onSelect("", "");
                });
          }}
          className="border-0 outline-none bg-transparent p-0"
          type="button"
        >
          {deletingZone ? <CSpinner size="sm" /> : <XMarkIcon width={15} />}
        </button>
        {isEditMode && (
          <button
            className="border-0 outline-none p-0 text-danger bg-transparent"
            style={{ fontSize: "12px" }}
            disabled={updatingZone}
            type="submit"
          >
            {updatingZone ? <CSpinner size="sm" /> : " Save"}
          </button>
        )}
      </div>
    </form>
  );
}
