import { IconTypes } from "./types";

export default function InfoIcon({ className = `` }: Partial<IconTypes>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM30 23C31.1046 23 32 23.8954 32 25V45C32 46.1046 31.1046 47 30 47C28.8954 47 28 46.1046 28 45V25C28 23.8954 28.8954 23 30 23ZM30 16C31.1046 16 32 16.8954 32 18C32 19.1046 31.1046 20 30 20C28.8954 20 28 19.1046 28 18C28 16.8954 28.8954 16 30 16Z"
      />
    </svg>
  );
}
