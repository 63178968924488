import {
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import DeleteIcon from "../../components/icons/DeleteIcon";
import PromptModal from "../../components/modals/PromptModal";
import { DELETE_ACTIONS } from "../../config/constants";
import { colors } from "../../config/theme";
import { useUserQuery } from "../../hooks/users/useUserQuery";
import { format } from "date-fns";
import { HeaderSort } from "../../components/TableHeaderSort";
import { formatDateString } from "../../helpers/general";

function TableBodyRowContent({
  user,
  deleteList
}: {
  user: User;
  deleteList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { removeUser } = useUserQuery();

  useEffect(() => {
    if (isChecked) {
      deleteList({ type: DELETE_ACTIONS.ADD, id: user.id });
    } else {
      deleteList({ type: DELETE_ACTIONS.REMOVE, id: user.id });
    }
  }, [deleteList, isChecked, user.id]);

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 text-sm">
        <div className="d-flex align-items-center gap-3">
          <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          />
          {formatDateString(user.createdAt)}
        </div>
      </CTableDataCell>
      <CTableDataCell className="px-3 text-sm">
        <div className="d-flex align-items-center gap-3">
          <img
            src={
              user.profilePicture ??
              `https://ui-avatars.com/api/?name=${user?.fullName}`
            }
            alt=""
            width={30}
            height={30}
            style={{ borderRadius: "100%" }}
          />

          <div>
            <p className="fw-bold m-0">{user?.fullName}</p>
            <p className="m-0">{user.email}</p>
          </div>
        </div>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">{user?.phone}</CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {user?.isVerifiedPhone ? "Oui" : "Non"}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {user?.birthday ? formatDateString(user?.birthday) : ""}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {user?.city?.label}
      </CTableDataCell>

      {/* <CTableDataCell className="p-3">{user.}</CTableDataCell> */}
      <CTableDataCell className="p-3 text-sm">
        <button
          onClick={() => setShowModal(true)}
          className="outline-none border-0 bg-transparent"
        >
          <DeleteIcon
            width="20"
            height="20"
            fill={colors.primary}
            className="ms-2"
          />
        </button>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeUser(user?.id)}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  users: User[];
  UsersToBeDeletedList: React.Dispatch<BulkDeleteActions>;
  sort: {
    sort: { field: string; order: string };
    setSort: ({ field, order }: { field: string; order: string }) => void;
    resetSort: () => void;
  };
}

export default function UsersTable({
  users,
  UsersToBeDeletedList,
  sort
}: TableProps) {
  const setSort = (field: string, order: string) => {
    sort.setSort({ field, order });
  };

  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm ">
              <div className="d-flex align-items-center justify-content-between">
                Date d&apos;inscription{" "}
                <HeaderSort
                  field="createdAt"
                  sort={sort.sort.order}
                  sortedField={sort.sort}
                  setSort={setSort}
                  clearSort={() => sort.resetSort()}
                  defaultField="createdAt"
                />
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Nom{" "}
                <HeaderSort
                  field="fullName"
                  sort={sort.sort.order}
                  sortedField={sort.sort}
                  setSort={setSort}
                  clearSort={() => sort.resetSort()}
                  defaultField="createdAt"
                />
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Téléphone{" "}
                <HeaderSort
                  field="phone"
                  sort={sort.sort.order}
                  sortedField={sort.sort}
                  setSort={setSort}
                  clearSort={() => sort.resetSort()}
                  defaultField="createdAt"
                />
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Téléphone Vérifié{" "}
                <HeaderSort
                  field="isVerifiedPhone"
                  sort={sort.sort.order}
                  sortedField={sort.sort}
                  setSort={setSort}
                  clearSort={() => sort.resetSort()}
                  defaultField="createdAt"
                />
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Date de naissance{" "}
                <HeaderSort
                  field="birthday"
                  sort={sort.sort.order}
                  sortedField={sort.sort}
                  setSort={setSort}
                  clearSort={() => sort.resetSort()}
                  defaultField="createdAt"
                />
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Ville{" "}
                <div style={{ height: '40px', width: '20px' }}></div>
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {users?.map(user => (
            <TableBodyRowContent
              key={user.id}
              user={user}
              deleteList={UsersToBeDeletedList}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
