import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";
import React, { useState } from "react";
import { colors } from "../../config/theme";
import PromptModal from "../../components/modals/PromptModal";
import { useAuth } from "../../hooks/useAuth";
import { formatDateString } from "../../helpers/general";
import CheckIcon from "../../components/icons/CheckIcon";
import { usePayoutsQuery } from "../../hooks/payouts/usePayoutsQuery";

function TableBodyRowContent({
    payout,
    isAdmin
    // deleteList
}: {
    payout: Payout;
    isAdmin: boolean;
    // deleteList: React.Dispatch<BulkDeleteActions>;
}) {
    const [isChecked, setIsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { processWithdrawalRequest } = usePayoutsQuery();

    // useEffect(() => {
    //     if (isChecked) {
    //         deleteList({ type: DELETE_ACTIONS.ADD, id: event?.id });
    //     } else {
    //         deleteList({ type: DELETE_ACTIONS.REMOVE, id: event?.id });
    //     }
    // }, [deleteList, isChecked, event?.id]);

    return (
        <CTableRow
            style={{
                borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
                width: "100%",
                position: "relative"
            }}
        >
            {isAdmin && <CTableDataCell className="p-3">
                <div className="text-sm">
                    {/* <CFormCheck
                        id="flexCheckDefault"
                        label=""
                        defaultChecked={isChecked}
                        onChange={e => setIsChecked(e.target.checked)}
                        color={colors.yellow}
                    /> */}
                    <img
                        src={payout.place.image}
                        alt=""
                        width={30}
                        height={30}
                        style={{ borderRadius: "100%" }}
                    />
                    <span className="mx-2">{payout.place.name}</span>
                </div>
            </CTableDataCell>}
            <CTableDataCell className="p-3 text-sm">
                {formatDateString(payout.requestedAt)}
            </CTableDataCell>
            <CTableDataCell className="p-3 text-sm font-bold">{payout.amount}DH</CTableDataCell>
            <CTableDataCell className="p-3 text-sm">
                {payout.isProcessed ? <span>Traitée</span> : <span>En cours</span>}
            </CTableDataCell>
            <CTableDataCell className="p-3 text-sm">
                {payout.processingDate ? formatDateString(payout.processingDate) : '-'}
            </CTableDataCell>

            <CTableDataCell>
                {!payout.isProcessed && isAdmin && <div className="d-flex align-items-center mt-2">
                    <button
                        onClick={() => setShowModal(true)}
                        className="outline-none border-0 bg-transparent"
                    >
                        <CheckIcon
                            width="20"
                            height="20"
                            fill={colors.primary}
                            className="ms-2"
                        />
                    </button>
                </div>}
            </CTableDataCell>
            <PromptModal
                message="Voulez-vous marquer la demande comme payée ?"
                onClose={() => setShowModal(false)}
                onAccept={() => {
                    processWithdrawalRequest(payout.id)
                    setShowModal(false)
                }}
                open={showModal}
            />
        </CTableRow>
    );
}
interface TableProps {
    payouts: Payout[];
    // eventsToBeDeletedList: React.Dispatch<BulkDeleteActions>;
}
export default function PayoutsTable({
    // eventsToBeDeletedList,
    payouts
}: TableProps) {
    const { isAdmin } = useAuth();
    return (
        <div className="mt-3 pt-2">
            <CTable responsive>
                <CTableHead>
                    <CTableRow>
                        {isAdmin && <CTableHeaderCell scope="col" className="text-sm">
                            Place
                        </CTableHeaderCell>}
                        <CTableHeaderCell scope="col" className="text-sm">
                            Date de la demande
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-sm">
                            Montant
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-sm">
                            Etat
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-sm">
                            Date du traitement
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col" className="text-sm">
                        </CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {payouts?.map(payout => (
                        <TableBodyRowContent
                            key={payout?.id}
                            payout={payout}
                            isAdmin={isAdmin}
                        // deleteList={eventsToBeDeletedList}
                        />
                    ))}
                </CTableBody>
            </CTable>
        </div>
    );
}
