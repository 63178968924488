import * as yup from "yup";

export const createPromoCodeSchema = yup.object({
  name: yup.string().required("Ce champ est obligatoire"),
  event: yup.object().required("Ce champ est obligatoire"),
  discount: yup
    .number()
    .positive()
    .required("Ce champ est obligatoire")
    .typeError("Ce champ est obligatoire"),
  maxUses: yup
    .number()
    .positive()
    .required("Ce champ est obligatoire")
    .typeError("Ce champ est obligatoire"),
  isActive: yup
    .boolean()
    .default(true)
    .required("Ce champ est obligatoire")
    .typeError("Ce champ est obligatoire"),
  description: yup.string().required("Ce champ est obligatoire"),
  items: yup.array(
    yup
      .object({
        serviceId: yup.string().required("Ce champ est obligatoire"),
        maxUses: yup
          .number()
          .required("Ce champ est obligatoire")
          .positive()
      })
      .required()
      .default([])
  )
});

export type createPromoDto = yup.InferType<typeof createPromoCodeSchema>;
