import {
    CFormCheck,
    CFormInput,
    CModal,
    CModalBody,
    CSpinner,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";
import React, { createRef, useEffect, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { useToast } from "../../../hooks/useToast";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import { DELETE_ACTIONS } from "../../../config/constants";
import { general } from "../../../locales/general";
import { colors } from "../../../config/theme";
import EditIcon from "../../../components/icons/EditIcon";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import PromptModal from "../../../components/modals/PromptModal";
import { Link } from "react-router-dom";

//   interface newEventTranslationModalProps {
//     open: boolean;
//     onClose: () => void;
//     name?: string;
//     link?: string;
//     logo?: File | string;
//     id?: string;
//   }
//   interface updateEventTranslationProp {
//     name: string;
//     link: string;
//     logo?: File;
//   }
//   const EventTranslationModal = ({
//     open,
//     onClose,
//     name = "",
//     link = "",
//     logo = "",
//     id = ""
//   }: newEventTranslationModalProps) => {
//     const { token } = useCookie("vToken");
//     const { toastError, toastSuccess } = useToast();
//     const [EventTranslationName, setEventTranslationName] = useState(name);
//     const [EventTranslationLink, setEventTranslationLink] = useState(link);
//     const pictureRef = createRef<HTMLInputElement>();

//     const queryClient = useQueryClient();

//     const updateEventTranslation = useMutation((data: updateEventTranslationProp) => {
//       return apiService.MakePutRequest(`partners/${id}`, data, token, true);
//     });

//     const updateEventTranslationHandler = () => {
//       updateEventTranslation.mutate(
//         {
//           name: EventTranslationName,
//           link: EventTranslationLink,
//           logo: pictureRef?.current?.files?.[0]! || " "
//         },
//         {
//           onError() {
//             toastError(general.fr.message.operationFailed);
//           },
//           onSuccess() {
//             queryClient.invalidateQueries("getEventTranslation");
//             toastSuccess(general.fr.message.partnerUpdated);
//             onClose();
//             setEventTranslationName("");
//             setEventTranslationLink("");
//           }
//         }
//       );
//     };
//     return (
//       <CModal visible={open} onClose={onClose} alignment="center">
//         <CModalBody>
//           <h5 className="fs-5 mb-4 border-bottom pb-1">
//             Mise à jour du partenaire
//           </h5>
//           <div className="mb-4">
//             <label htmlFor="name" className="text-sm">
//               Nom du partenaire
//             </label>
//             <input
//               type="text"
//               className="custom-input w-100 mt-1"
//               name="name"
//               eventholder="Nom du partenaire"
//               value={EventTranslationName}
//               onChange={e => setEventTranslationName(e.target.value)}
//             />
//           </div>
//           <div className="mb-4">
//             <label htmlFor="link" className="text-sm">
//               Site web du partenaire
//               <br />
//               <span className="text-sm text-danger fst-italic">
//                 {" "}
//                 (Exemple : https://www.exemple.com )
//               </span>
//             </label>
//             <input
//               type="url"
//               className="custom-input w-100 mt-1"
//               name="link"
//               eventholder="eventTranslation Website"
//               value={EventTranslationLink}
//               onChange={e => setEventTranslationLink(e.target.value)}
//             />
//           </div>
//           <div className="mb-4">
//             <label htmlFor="logo" className="text-sm">
//               Logo du partenaire
//             </label>
//             <CFormInput
//               type="file"
//               id="formFile"
//               className="mt-1"
//               ref={pictureRef}
//             />
//           </div>

//           <div className="w-fit my-5 m-auto">
//             <img
//               src={logo as string}
//               alt=""
//               className="w-full"
//               width={100}
//               height={100}
//             />
//           </div>

//           <button
//             type="button"
//             onClick={updateEventTranslationHandler}
//             disabled={updateEventTranslation.isLoading}
//             className="d-flex align-items-center justify-content-center btn btn-danger shadow-primary d-block w-100 mt-2 p-2 fw-bold text-white"
//           >
//             {updateEventTranslation.isLoading ? (
//               <>
//                 <CSpinner size="sm" /> {general.fr.wait}
//               </>
//             ) : (
//               "Enregistrer"
//             )}
//           </button>
//         </CModalBody>
//       </CModal>
//     );
//   };

function TableBodyRowContent({
    eventTranslation,
    deletedList
}: {
    eventTranslation: EventTranslation;
    deletedList: React.Dispatch<BulkDeleteActions>;
}) {
    const [isChecked, setIsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { toastSuccess, toastError } = useToast();
    const { token } = useCookie("vToken");
    const queryClient = useQueryClient();
    const [showEditModal, setShowEditModal] = useState(false);

    const deleteEventTranslation = useMutation((id: string) => {
        return apiService.MakeDeleteRequest(`event-translations/${id}`, token);
    });

    useEffect(() => {
        if (isChecked) {
            deletedList({ type: DELETE_ACTIONS.ADD, id: eventTranslation.id });
        } else {
            deletedList({ type: DELETE_ACTIONS.REMOVE, id: eventTranslation.id });
        }
    }, [deletedList, isChecked, eventTranslation.id]);

    const removeEventTranslation = () => {
        deleteEventTranslation.mutate(eventTranslation.id, {
            onError(error) {
                console.log(error);
                toastError(general.fr.message.operationFailed);
            },
            onSuccess() {
                toastSuccess(general.fr.message.partnerDeleted);
                queryClient.invalidateQueries("getAllTranslations");
            }
        });
    };

    return (
        <CTableRow
            style={{
                borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
                width: "100%",
                position: "relative"
            }}
        >
            <CTableDataCell className=" text-capitalize text-sm">
                <div className="d-flex align-items-center gap-3">
                    <CFormCheck
                        id="flexCheckDefault"
                        label=""
                        defaultChecked={isChecked}
                        onChange={e => setIsChecked(e.target.checked)}
                        color={colors.yellow}
                    />
                    <img
                        src={eventTranslation.event?.image}
                        alt=""
                        width={30}
                        height={30}
                        style={{ borderRadius: "100%" }}
                    />

                    <div>
                        <Link
                            to={`/events/${eventTranslation.event?.id}`}
                            className="fw-bold m-0 text-capitalize text-sm"
                        >
                            {eventTranslation.event?.name}
                        </Link>
                        <p className="m-0 text-sm">{eventTranslation.event?.address}</p>
                    </div>
                </div>


            </CTableDataCell>
            <CTableDataCell className="text-capitalize text-sm">
                <span className="ms-3">{eventTranslation?.name}</span>
            </CTableDataCell>
            <CTableDataCell className="text-capitalize text-sm">
                <span className="ms-3">{eventTranslation?.lang}</span>
            </CTableDataCell>

            <CTableDataCell>
                <Link to={`/event-translations/update/${eventTranslation.id}`}>
                    <EditIcon width="18" height="18" fill={colors.secondary} />
                </Link>{" "}
                <button
                    className="outline-none border-0 bg-transparent"
                    onClick={() => setShowModal(true)}
                >
                    {deleteEventTranslation.isLoading ? (
                        <CSpinner size="sm" />
                    ) : (
                        <DeleteIcon
                            width="20"
                            height="20"
                            fill={colors.primary}
                            className="ms-2"
                        />
                    )}
                </button>
            </CTableDataCell>
            <PromptModal
                open={showModal}
                onAccept={removeEventTranslation}
                onClose={() => setShowModal(false)}
            />
            {/* <EventTranslationModal
                onClose={() => setShowEditModal(false)}
                open={showEditModal}
                id={eventTranslation?.id}
                name={eventTranslation?.name}
                link={eventTranslation?.link}
                logo={eventTranslation?.logoImage}
            /> */}
        </CTableRow>
    );
}

interface TableProps {
    eventTranslations: EventTranslation[];
    eventTranslationsToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function EventTranslationsTable({
    eventTranslations,
    eventTranslationsToBeDeleted
}: TableProps) {
    return (
        <div className="mt-3">
            <CTable>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">Événement</CTableHeaderCell>
                        <CTableHeaderCell scope="col">
                            Traduction
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col">
                            Langue
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col"></CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {eventTranslations?.map(eventTranslation => (
                        <TableBodyRowContent
                            eventTranslation={eventTranslation}
                            key={eventTranslation?.id}
                            deletedList={eventTranslationsToBeDeleted}
                        />
                    ))}{" "}
                </CTableBody>
            </CTable>
        </div>
    );
}
