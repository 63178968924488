import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import React from "react";

interface Props {
  onChange: (value: string) => void;
  value?: string
}
export default function SearchForm({ onChange, value = "" }: Props) {
  return (
    <div className="search-form" style={{ width: "60%" }}>
      <div className="input-field">
        <MagnifyingGlassIcon color="gray" width={20} />
        <input
          type="text"
          className="w-100 border-0 outline-none rounded-sm p-1 text-gray fs-6"
          placeholder="Rechercher"
          value={value}
          onChange={e => onChange(e.target.value.toLowerCase())}
        />
      </div>
    </div>
  );
}
