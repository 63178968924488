import {
  CFormCheck,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import EditIcon from "../../../components/icons/EditIcon";
import PromptModal from "../../../components/modals/PromptModal";
import { DELETE_ACTIONS } from "../../../config/constants";
import { colors } from "../../../config/theme";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";

function TableBodyRowContent({
  serviceCategoryTranslation,
  deletedList
}: {
  serviceCategoryTranslation: ServiceSubCategoryTranslation;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showEditModal, setShowEditModal] = useState(false);

  const deleteServiceCategoryTranslation = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(
      `service-category-translations/sub-categories-translation/${id}`,
      token
    );
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({
        type: DELETE_ACTIONS.ADD,
        id: serviceCategoryTranslation.id
      });
    } else {
      deletedList({
        type: DELETE_ACTIONS.REMOVE,
        id: serviceCategoryTranslation.id
      });
    }
  }, [deletedList, isChecked, serviceCategoryTranslation.id]);

  const removeServiceCategoryTranslation = () => {
    deleteServiceCategoryTranslation.mutate(serviceCategoryTranslation.id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess("Entité supprimée");
        queryClient.invalidateQueries("getAllTranslations");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className=" text-capitalize text-sm">
        <div className="d-flex align-items-center gap-3">
          <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          />

          <div>{serviceCategoryTranslation?.serviceSubCategory?.label}</div>
        </div>
      </CTableDataCell>
      <CTableDataCell className="text-capitalize text-sm">
        <span className="ms-3">{serviceCategoryTranslation?.label}</span>
      </CTableDataCell>
      <CTableDataCell className="text-capitalize text-sm">
        <span className="ms-3">{serviceCategoryTranslation?.lang}</span>
      </CTableDataCell>

      <CTableDataCell>
        <Link
          to={`/service-sub-category-translations/update/${serviceCategoryTranslation.id}`}
        >
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </Link>{" "}
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
        >
          {deleteServiceCategoryTranslation.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={removeServiceCategoryTranslation}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  serviceCategoryTranslations: ServiceSubCategoryTranslation[];
  serviceCategoryTranslationsToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function ServiceSubCategoryTranslationsTable({
  serviceCategoryTranslations,
  serviceCategoryTranslationsToBeDeleted
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Catégorie</CTableHeaderCell>
            <CTableHeaderCell scope="col">Traduction</CTableHeaderCell>
            <CTableHeaderCell scope="col">Langue</CTableHeaderCell>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {serviceCategoryTranslations?.map(serviceCategoryTranslation => (
            <TableBodyRowContent
              serviceCategoryTranslation={serviceCategoryTranslation}
              key={serviceCategoryTranslation?.id}
              deletedList={serviceCategoryTranslationsToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
