import { CFormInput, CSpinner, CFormSelect } from "@coreui/react";
import { createRef, FormEvent, useEffect, useState } from "react";
import { useQueryClient, useMutation, useQuery } from "react-query";

import { useNavigate, useParams } from "react-router-dom";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import RichTextEditor from "../../../components/RichTextEditor";
import { OFFER_TYPES } from "../../Offers/NewOffer";

interface updateOfferProp {
  title: string;
  text: string;
  type: string;
  reductionPercent?: string;
  carouselImage?: File | string;
  picture?: File | string;
}
export default function PlaceUpdateOffer() {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const params = useParams();
  const queryClient = useQueryClient();
  const [OfferTitle, setOfferTitle] = useState("");
  const [OfferDescription, setOfferDescription] = useState("");
  const [OfferReduction, setOfferReduction] = useState("");
  const carouselImageRef = createRef<HTMLInputElement>();
  const pictureRef = createRef<HTMLInputElement>();
  const navigate = useNavigate();

  const { data } = useQuery(["getSingleOffer"], () => {
    return apiService.MakeGetRequest(`specials/all/${params?.id}`, token);
  });

  useEffect(() => {
    setOfferTitle(data?.title);
    setOfferDescription(data?.description);
    setOfferReduction(data?.reductionPercent);
  }, [data?.description, data?.reductionPercent, data?.title]);

  const updateOffer = useMutation((data: updateOfferProp) => {
    return apiService.MakePutRequest(
      `specials/${params.id}`,
      data,
      token,
      true
    );
  });

  const updateOfferHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const offerType = formData.get("offerType") as string;

    updateOffer.mutate(
      {
        title: OfferTitle!,
        text: OfferDescription!,
        reductionPercent: OfferReduction,
        picture: pictureRef?.current?.files?.[0]! || " ",
        carouselImage: carouselImageRef?.current?.files?.[0]! || " ",
        type: offerType
      },
      {
        onError() {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          queryClient.invalidateQueries("getOffer");
          toastSuccess(general.fr.message.offerUpdated);
          navigate(`/places/${data?.place.id}/offers`);
        }
      }
    );
  };
  return (
    <form className="row form mt-4" onSubmit={updateOfferHandler}>
      <h5 className="fs-5 mb-4 border-bottom pb-1">
        Mise à jour de l&apos;offre
      </h5>
      <div className="mb-4">
        <label htmlFor="title" className="text-sm">
          Titre de l&apos;offre
        </label>
        <input
          type="text"
          className="custom-input w-100 mt-1"
          name="title"
          placeholder="Titre de l'offre"
          defaultValue={data?.title}
          onChange={e => setOfferTitle(e.target.value)}
        />
      </div>
      <div className="col-md-6 mb-4">
        <label htmlFor="title" className="d-block">
          Select offer type <span className="text-md text-red">*</span>
        </label>
        <CFormSelect
          name="offerType"
          id="offerType"
          className="custom-input"
          required
          feedbackInvalid="Example invalid select feedback"
          defaultValue={data?.type}
        >
          <option
            value={OFFER_TYPES.SPECIAL}
            selected={data?.type === OFFER_TYPES.SPECIAL}
          >
            SPECIAL OFFER
          </option>
          <option
            value={OFFER_TYPES.EVENT}
            selected={data?.type === OFFER_TYPES.EVENT}
          >
            EVENT OFFER
          </option>
          <option value="TOUR_OFFER" selected={data?.type === OFFER_TYPES.TOUR}>
            TOUR OFFER
          </option>
        </CFormSelect>
      </div>

      <div className="mb-4 col-md-6">
        <label htmlFor="reductionPercent" className="text-sm">
          Remise
        </label>
        <input
          type="text"
          className="custom-input w-100 mt-1"
          name="reductionPercent"
          placeholder="Remise"
          defaultValue={data?.reductionPercent}
          onChange={e => setOfferReduction(e.target.value)}
        />
      </div>

      <div className="mb-4 col-md-6">
        <label htmlFor="text" className="text-sm">
          Offer Description
        </label>
        <RichTextEditor
          defaultValue={data?.text}
          onChange={data => setOfferDescription(data)}
        />
      </div>

      <div className="mb-4 col-md-6">
        <label htmlFor="picture" className="text-sm">
          image de l&apos;offre
        </label>
        {data?.image && (
          <div className="border border-success rounded-sm my-2 py-1 px-2 bg-success-shade w-fit">
            <a
              target="__blank"
              href={data?.image}
              className="text-black text-sm line-clamp-1 d-block"
            >
              Aperçu
            </a>
          </div>
        )}
        <CFormInput
          type="file"
          id="formFile"
          name="picture"
          className="mt-1"
          ref={pictureRef}
        />
      </div>

      <div className="mb-4 col-md-6">
        <label htmlFor="carouselImage" className="text-sm">
          Image du carrousel
        </label>
        {data?.carouselPicture && (
          <div className="border border-success rounded-sm my-2 py-1 px-2 bg-success-shade w-fit">
            <a
              target="__blank"
              href={data?.carouselPicture}
              className="text-black text-sm line-clamp-1 d-block"
            >
              Aperçu
            </a>
          </div>
        )}
        <CFormInput
          type="file"
          id="formFile"
          name="carouselImage"
          className="mt-1"
          ref={carouselImageRef}
        />
      </div>

      <button
        disabled={updateOffer.isLoading}
        className="d-flex align-items-center justify-content-center btn btn-danger shadow-primary d-block w-100 mt-2 p-2 fw-bold text-white"
      >
        {updateOffer.isLoading ? (
          <>
            <CSpinner size="sm" /> {general.fr.wait}
          </>
        ) : (
          "Enregistrer"
        )}
      </button>
    </form>
  );
}
