import {
  CFormCheck,
  CFormInput,
  CModal,
  CModalBody,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import EditIcon from "../../components/icons/EditIcon";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { ArrowTopRightOnSquareIcon, EyeIcon } from "@heroicons/react/24/solid";
import { useQueryClient, useMutation } from "react-query";
import { DELETE_ACTIONS } from "../../config/constants";
import { useCookie } from "../../hooks/useCookie";
import { general } from "../../locales/general";
import { useToast } from "../../hooks/useToast";

import apiService from "../../service/apiService";
import PromptModal from "../../components/modals/PromptModal";

interface newCityModalProps {
  open: boolean;
  onClose: () => void;
  city?: City;
  id?: string;
}
interface updateCityProp {
  label: string;
  longitude: string;
  latitude: string;
}
const CityModal = ({ open, onClose, city, id = "" }: newCityModalProps) => {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const [CityName, setCityName] = useState(city?.label);
  const [latitude, setLatitude] = useState(city?.latitude);
  const [longitude, setLongitude] = useState(city?.longitude);
  const queryClient = useQueryClient();

  const updateCity = useMutation((data: updateCityProp) => {
    return apiService.MakePutRequest(`cities/${id}`, data, token);
  });

  const updateType = () => {
    updateCity.mutate(
      { label: CityName!, latitude: latitude!, longitude: longitude! },
      {
        onError() {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          queryClient.invalidateQueries("getAllCities");
          toastSuccess(general.fr.message.cityUpdated);
          onClose();
        }
      }
    );
  };
  const inputInvalid =
    CityName == city?.label &&
    latitude == city?.latitude &&
    longitude == city?.longitude;

  return (
    <CModal visible={open} onClose={onClose} alignment="center">
      <CModalBody>
        <h5 className="fs-5 mb-4 border-bottom pb-1">Mettre à jour la ville</h5>
        <div className="mb-3">
          <label htmlFor="label">Ville</label>
          <input
            type="text"
            className="custom-input w-100 mt-1"
            name="label"
            placeholder="Ville"
            defaultValue={CityName}
            onChange={e => setCityName(e.target.value)}
          />
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="long" className="d-block">
            Longitude
          </label>
          <input
            type="text"
            className="custom-input"
            placeholder="Longitude"
            id="long"
            name="long"
            defaultValue={city?.longitude!}
            onChange={e => setLongitude(e.target.value)}
            required
          />
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="lat" className="d-block">
            Latitude
          </label>
          <input
            type="text"
            className="custom-input"
            placeholder="Latitude"
            id="lat"
            name="lat"
            defaultValue={city?.latitude!}
            onChange={e => setLatitude(e.target.value)}
            required
          />
        </div>

        <button
          type="button"
          onClick={updateType}
          disabled={updateCity.isLoading || inputInvalid}
          className="d-flex align-items-center justify-content-center btn btn-danger shadow-primary d-block w-100 mt-2 p-2 fw-bold text-white"
        >
          {updateCity.isLoading ? (
            <>
              <CSpinner size="sm" /> {general.fr.wait}
            </>
          ) : (
            "Enregistrer"
          )}
        </button>
      </CModalBody>
    </CModal>
  );
};

function TableBodyRowContent({
  City,
  deletedList
}: {
  City: City;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();

  const queryClient = useQueryClient();

  const deleteCity = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`cities/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: City.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: City.id });
    }
  }, [deletedList, isChecked, City.id]);

  const removeCity = () => {
    deleteCity.mutate(City.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.placeDeleted);
        queryClient.invalidateQueries("getAllCities");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 text-capitalize text-sm">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
          className="!ms-2"
        />
        <span className="ms-2"> {City.label}</span>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {City?.longitude}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {City?.latitude}
      </CTableDataCell>

      <CTableDataCell className="p-3">
        <button
          onClick={() => setShowEditModal(true)}
          className="outline-none border-0 bg-transparent"
        >
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </button>{" "}
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
        >
          {deleteCity.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={removeCity}
        onClose={() => setShowModal(false)}
      />

      <CityModal
        onClose={() => setShowEditModal(false)}
        open={showEditModal}
        id={City?.id}
        city={City}
      />
    </CTableRow>
  );
}

interface TableProps {
  Cities: City[];
  CitiesToBeDeletedList: React.Dispatch<BulkDeleteActions>;
}

export default function CitiesTable({
  Cities,
  CitiesToBeDeletedList
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="w-100">
              Nom de la ville
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="w-100">
              Longitude
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="w-100">
              Latitude
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="w-100"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {Cities?.map(City => (
            <TableBodyRowContent
              City={City}
              key={City?.id}
              deletedList={CitiesToBeDeletedList}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
