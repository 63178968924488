import React, { useEffect, useState } from "react";
import PlaceAdminHeader from "./PlaceAdminHeader";
import { Controller, useForm } from "react-hook-form";
import { CFormInput, CFormSelect } from "@coreui/react";
import { FormSelectAPI } from "../../../components/FormReactSelect";
import {
  PlaceAdminEditFormField,
  placeAdminEditSchema
} from "../../../rules/CreatePlaceValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import RichTextEditor from "../../../components/RichTextEditor";
import { useAuthContext } from "../../../context/auth";
import { apiConfig } from "../../../config/apiConfig";
import apiService from "../../../service/apiService";
import { useMutation } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";

const getDefaultValues = (place?: Place) => {
  if (!place) return {};
  const values = {
    name: place?.name,
    categoryId: place.category,
    cityId: place?.city,
    address: place?.address,
    googleMapUrl: place.googleMapUrl,
    hasParking: place?.hasParking.toString(),
    priceRangeMin: +place?.priceRange?.[0],
    priceRangeMax: +place?.priceRange?.[1],
    paymentMethods: place?.paymentMethods?.join(","),
    mainType: place?.mainType,
    secondaryType: place?.secondaryType,
    speciality: place?.speciality,
    chefName: place?.chefName,
    keywords: place?.keywords?.join(",") ?? "",
    phone: place?.phone,
    servicesSectionTitle: place?.servicesSectionTitle,
    email: place?.email,
    website: place?.website,
    facebookURL: place?.facebookURL,
    instagramURL: place?.instagramURL,
    companyAddress: place?.companyAddress,
    companyName: place?.companyName,
    IF: place?.IF,
    IC: place?.IC,
    rib: place?.rib,
    logo: place?.logoImage,
    picture: place?.image,
    hoursStep: place?.hoursStep
  };
  return values;
};

interface PlaceData {
  name: string;
  categoryId: string;
  cityId: number;
  picture: string | File;
  logo: string | File;
  hasParking: string;
  priceRange: string[];
  paymentMethods: string[];
  mainType: string;
  secondaryType: string;
  speciality: string;
  chefName: string;
  averagePrice: number;
  keywords: string[];
  phone: string;
  email: string;
  website: string;
  facebookURL: string;
  instagramURL: string;
  address: string;
  latitude: number;
  longitude: number;
  placeAdmin: string;
  servicesSectionTitle: string;
  videoIds?: string[];
}
export default function PlaceAdminHomeView() {
  const { user } = useAuthContext();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, touchedFields }
  } = useForm<PlaceAdminEditFormField>({
    resolver: yupResolver(placeAdminEditSchema),
    defaultValues: getDefaultValues(user?.adminForPlace)
  });

  const [description, setDescription] = useState("");
  const [descriptionChanged, setDescriptionChanged] = useState(false);

  useEffect(() => {
    setDescription(user?.adminForPlace?.description ?? "");
  }, [user?.adminForPlace]);

  const { token } = useCookie("vToken");

  const { toastError, toastSuccess } = useToast();

  const updatePlaceMutation = useMutation(async (placeData: PlaceData) => {
    return await apiService.MakePutRequest(
      apiConfig.places.get(user?.adminForPlace?.id ?? ""),
      placeData,
      token,
      true
    );
  });

  const onSubmit = async (data: any) => {
    try {
      const reformedData = {
        keywords: data?.keywords?.split(","),
        cityId: data?.cityId?.id,
        latitude: data?.cityId?.latitude,
        longitude: data?.cityId?.longitude,
        categoryId: data?.categoryId?.id,
        paymentMethods: data?.paymentMethods?.split(","),
        description
      };

      await updatePlaceMutation.mutateAsync({ ...data, ...reformedData });
      toastSuccess(general.fr.message.placeUpdated);
      window.location.reload();
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div>
      <PlaceAdminHeader />
      <div className="px-3">
        <form className="row form px-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-center justify-content-end gap-2 mb-3">
            <button
              type="submit"
              disabled={
                !Object.keys(touchedFields).length && !descriptionChanged
              }
              className="btn btn-danger text-white shadow-primary"
            >
              Sauvegarder les modifications
            </button>
          </div>
          <div className="bg-white rounded-sm p-4">
            <div className="row form">
              <div className="col-md-6  my-3 ">
                <label htmlFor="name" className="d-block">
                  Nom <span className="text-sm text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Nom de Place"
                  id="name"
                  {...register("name")}
                />
                {errors.name?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.name.message}
                  </div>
                )}
              </div>
              <div className="col-md-6  my-3 ">
                <label htmlFor="categoryId" className="d-block mb-2">
                  Catégories <span className="text-sm text-red">*</span>
                </label>
                <Controller
                  name="categoryId"
                  control={control}
                  render={({ field }) => {
                    return (
                      <InfiniteScrollSelect
                        id="categoryId"
                        isClearable={false}
                        error={errors?.categoryId?.message}
                        getOptionLabel={(option: Category) => option?.label}
                        getOptionValue={(option: Category) => option.id}
                        isOptionSelected={(
                          option: Category,
                          selectedValue: Category[]
                        ) => {
                          const isSelected =
                            option?.id === selectedValue?.[0]?.id;
                          return isSelected;
                        }}
                        url={{
                          path: "categories"
                        }}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.categoryId?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.categoryId.message}
                  </div>
                )}
              </div>
              <div className="col-md-6  my-3 ">
                <label htmlFor="cityId" className="d-block mb-2">
                  Ville <span className="text-sm text-red">*</span>
                </label>
                <Controller
                  name="cityId"
                  control={control}
                  render={({ field }) => {
                    return (
                      <InfiniteScrollSelect
                        id="cityId"
                        isClearable={false}
                        error={errors?.cityId?.message}
                        getOptionLabel={(option: City) => option?.label}
                        getOptionValue={(option: City) => option.id}
                        isOptionSelected={(
                          option: City,
                          selectedValue: City[]
                        ) => {
                          const isSelected =
                            option?.id === selectedValue?.[0]?.id;
                          return isSelected;
                        }}
                        url={{
                          path: "cities"
                        }}
                        formatOptions={(cities: City[]) =>
                          cities.sort((a, b) =>
                            a.label.localeCompare(b.label, undefined, {
                              sensitivity: "base",
                              usage: "sort"
                            })
                          )
                        }
                        {...field}
                      />
                    );
                  }}
                />
                {errors.cityId?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.cityId.message}
                  </div>
                )}
              </div>
              <div className="col-md-6  my-3 ">
                <label htmlFor="address" className="d-block ">
                  Address <span className="text-sm text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  id="address"
                  placeholder="address"
                  className="custom-input"
                  {...register("address")}
                />
                {errors.address?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.address.message}
                  </div>
                )}
              </div>
              <div className="col-md-6  my-3">
                <label htmlFor="googleMapUrl" className="d-block">
                  URL de la carte de Google
                </label>
                <CFormInput
                  type="url"
                  id="googleMapUrl"
                  placeholder="url"
                  className="custom-input"
                  {...register("googleMapUrl")}
                />
                {errors.googleMapUrl?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.googleMapUrl.message}
                  </div>
                )}
              </div>
              <div className="col-md-6  my-3 ">
                <label htmlFor="picture" className="d-block">
                  Grande image d'établissement
                  {/* <span className="text-sm text-red">*</span> */}
                </label>

                {/* <Controller
                  name="picture"
                  control={control}
                  render={({ field }) => ( */}
                <CFormInput
                  type="file"
                  id="picture"
                  className="custom-input"
                  required={false}
                  accept="image/png,image/jpeg,image/jpg"
                  {...register("picture")}
                />

                {/* <FileUploader
                    //   file={user?.adminForPlace?.image ?? undefined}
                    //   required={false}
                    //   accept="image/png,image/jpeg,image/jpg"
                    //   {...field}
                    // />
                //   )}
                // /> */}

                {errors.picture?.message ? (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.picture.message}
                  </div>
                ) : (
                  <span className="text-xs text-danger">
                    (préférée 1425&times;520)
                  </span>
                )}
              </div>
              <div className="col-md-6  my-3 ">
                <label htmlFor="logo" className="d-block mb-1">
                  Petite image d'établissement{" "}
                  {/* <span className="text-sm text-red">*</span> */}
                </label>
                <CFormInput
                  type="file"
                  id="logo"
                  className="custom-input"
                  required={false}
                  accept="image/png,image/jpeg,image/jpg"
                  {...register("logo")}
                />
                {/* <CFormInput
                  type="file"
                  id="logo"
                  className="custom-input"
                  required={false}
                  accept="image/png,image/jpeg,image/jpg"
                  {...register("logo")}
                /> */}
                {errors.logo?.message ? (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.logo.message}
                  </div>
                ) : (
                  <span className="text-xs text-danger">
                    (préférée 150&times;150)
                  </span>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="hasParking" className="d-block">
                  Parking <span className="text-sm text-red">*</span>
                </label>
                <CFormSelect id="hasParking" {...register("hasParking")}>
                  <option selected value="">
                    Choisissez une option
                  </option>
                  <option value="true">Oui</option>
                  <option value="false">Non</option>
                </CFormSelect>
                {errors.hasParking?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.hasParking.message}
                  </div>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="priceRange" className="d-block">
                  Gamme de prix
                </label>
                <div className="d-flex align-items-center gap-2">
                  <CFormInput
                    type="number"
                    className="custom-input"
                    placeholder="0.00"
                    id="priceRangeMin"
                    {...register("priceRangeMin")}
                  />
                  -
                  <CFormInput
                    type="number"
                    className="custom-input"
                    placeholder="0.00"
                    id="priceRangeMax"
                    {...register("priceRangeMax")}
                  />
                </div>
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="paymentMethods" className="d-block">
                  Les modes de paiement
                  <span className="text-sm text-red">*</span>{" "}
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Mode de paiement"
                  id="paymentMethods"
                  {...register("paymentMethods")}
                />
                {errors.paymentMethods?.message ? (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.paymentMethods.message}
                  </div>
                ) : (
                  <span className="text-xs text-danger">
                    (Séparer par une virgule)
                  </span>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="mainType" className="d-block">
                  Le menu principal
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="mainType"
                  id="mainType"
                  {...register("mainType")}
                />
                {errors.mainType?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.mainType.message}
                  </div>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="secondaryType" className="d-block">
                  Le menu secondaire
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="SecondaryType"
                  id="secondaryType"
                  {...register("secondaryType")}
                />
                {errors.secondaryType?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.secondaryType.message}
                  </div>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="speciality" className="d-block">
                  Spécialités
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="speciality"
                  id="speciality"
                  {...register("speciality")}
                />
                {errors.speciality?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.speciality.message}
                  </div>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="chefName" className="d-block">
                  Nom du chef
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Chef Name"
                  id="chefName"
                  {...register("chefName")}
                />
                {errors.chefName?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.chefName.message}
                  </div>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="averagePrice" className="d-block">
                  Prix Moyen
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  id="averagePrice"
                  {...register("averagePrice")}
                />
                {errors.averagePrice?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.averagePrice.message}
                  </div>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="keywords" className="d-block">
                  Mots-clés
                  <span className="text-sm text-red">*</span>{" "}
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  id="keywords"
                  {...register("keywords")}
                />
                {errors.keywords?.message ? (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.keywords.message}
                  </div>
                ) : (
                  <span className="text-xs text-danger">
                    (Séparer par une virgule)
                  </span>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="Téléphone" className="d-block">
                  Téléphone <span className="text-sm text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Téléphone"
                  id="phone"
                  {...register("phone")}
                />
                {errors.phone?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.phone.message}
                  </div>
                )}
              </div>
              <div className="col-md-6  my-3 ">
                <label htmlFor="servicesSectionTitle" className="d-block">
                  Titre de la section service{" "}
                  <span className="text-sm text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  id="servicesSectionTitle"
                  {...register("servicesSectionTitle")}
                />
                {errors.servicesSectionTitle?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.servicesSectionTitle.message}
                  </div>
                )}
              </div>
              <div className="col-md-6  my-3 ">
                <label htmlFor="email" className="d-block">
                  Email <span className="text-sm text-red">*</span>
                </label>
                <CFormInput
                  type="email"
                  className="custom-input"
                  id="email"
                  {...register("email")}
                />
                {errors.email?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.email.message}
                  </div>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="website" className="d-block">
                  Site web
                  <br />
                </label>
                <CFormInput
                  type="url"
                  className="custom-input"
                  placeholder="Website"
                  id="website"
                  {...register("website")}
                />
                {errors.website?.message ? (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.website.message}
                  </div>
                ) : (
                  <span className="text-xs text-danger fst-italic">
                    {" "}
                    (Exemple : https://www.exemple.com/ )
                  </span>
                )}
              </div>
              <div className="col-md-6  my-3 ">
                <label htmlFor="facebookURL" className="d-block">
                  Facebook
                </label>
                <CFormInput
                  type="url"
                  pattern="https://.*"
                  className="custom-input"
                  placeholder="Facebook"
                  id="facebookURL"
                  {...register("facebookURL")}
                />
                {errors.facebookURL?.message ? (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.facebookURL.message}
                  </div>
                ) : (
                  <span className="text-xs text-danger fst-italic">
                    {" "}
                    (Exemple : https://www.Facebook.com/exemple )
                  </span>
                )}
              </div>

              <div className="col-md-6  my-3 ">
                <label htmlFor="instagramURL" className="d-block">
                  Instagram
                  <br />
                </label>
                <CFormInput
                  type="url"
                  pattern="https://.*"
                  className="custom-input"
                  placeholder="Instagram"
                  id="instagramURL"
                  {...register("instagramURL")}
                />
                {errors.instagramURL?.message ? (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.instagramURL.message}
                  </div>
                ) : (
                  <span className="text-xs text-danger fst-italic">
                    {" "}
                    (Exemple : https://www.Instagram.com/exemple )
                  </span>
                )}
              </div>

              <div className="my-3">
                <label htmlFor="description">Description</label>
                <RichTextEditor
                  onChange={data => {
                    setDescriptionChanged(true);
                    setDescription(data);
                  }}
                  // {...register("description")}
                  name="description"
                  defaultValue={
                    description || (user?.adminForPlace?.description ?? "")
                  }
                />
              </div>
            </div>
          </div>
          {/* COMPANY INFO */}

          <section className="my-3 bg-white rounded-sm p-4">
            <h5>Informations de la société</h5>

            <div className="row form ">
              <div className="col-md-6 col-lg-4 my-4 ">
                <label htmlFor="companyName" className="d-block">
                  Nom de la société
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder=" Nom de la société"
                  id="companyName"
                  {...register("companyName")}
                />
                {errors.companyName?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.companyName.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 col-lg-4 my-4 ">
                <label htmlFor="companyAddress" className="d-block">
                  Address de la société
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="  Address de la société"
                  id="companyAddress"
                  {...register("companyAddress")}
                />
                {errors.companyAddress?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.companyAddress.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 col-lg-4 my-4 ">
                <label htmlFor="IF" className="d-block">
                  IF
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder=" IF"
                  id="IF"
                  {...register("IF")}
                />
                {errors.IF?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.IF.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 col-lg-4 my-4 ">
                <label htmlFor="IC" className="d-block">
                  IC
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder=" IC"
                  id="IC"
                  {...register("IC")}
                />
                {errors.IC?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.IC.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 col-lg-4 my-4 ">
                <label htmlFor="rib" className="d-block">
                  RIB
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="RIB"
                  id="rib"
                  maxLength={24}
                  minLength={24}
                  {...register("rib")}
                />
                {errors.rib?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.rib.message}
                  </div>
                )}
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  );
}
