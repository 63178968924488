export default function ArrowUpIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 36 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.991 -6.10352e-05C17.3275 0.055939 16.5427 0.30485 16.116 0.65625L1.11599 12.6562C-0.215384 13.8261 -0.334795 15.6479 0.647015 16.8751C1.62881 18.1024 3.63882 18.3256 4.86599 17.3437L14.991 9.25L14.991 41C14.991 42.6568 16.3342 44 17.991 44C19.6479 44 20.991 42.6568 20.991 41L20.991 9.25L31.116 17.3438C32.3431 18.3256 34.3077 18.0647 35.3349 16.8751C36.451 15.5826 36.0933 13.638 34.866 12.6562L19.866 0.65625C19.2444 0.14835 18.788 0.00883896 17.991 -6.10352e-05Z"
        fill="black"
      />
    </svg>
  );
}
