import React, { useEffect, useMemo, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import { editorFormats } from "../config/constants";
import ImageUploader from "quill-image-uploader";
import { useCookie } from "../hooks/useCookie";
import axios from "axios";

// #2 register module
Quill.register("modules/imageUploader", ImageUploader);
interface Props {
  onChange: (data: string) => void;
  defaultValue?: string;
  name?: string;
}

const RichTextEditor: React.FC<Props> = ({
  onChange,
  defaultValue = "",
  name = "richText"
}: Props) => {
  const [value, setValue] = useState("");
  const { token } = useCookie("vToken");

  const editorModules = useMemo(
    () => ({
      toolbar: [
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" }
        ],
        ["link", "image"],
        ["clean"]
      ],
      imageUploader: {
        upload: (file: any) => {
          return new Promise((resolve, reject) => {
            const endpoint = `${process.env.REACT_APP_API_URL}files/image`;
            const formData = new FormData();
            formData.append("image", file);

            axios
              .post(endpoint, formData, {
                headers: { Authorization: `Bearer ${token}` }
              })
              .then(result => {
                // console.log(result);
                resolve(result.data.url);
              })
              .catch(error => {
                reject("Upload failed");
                console.error("Error:", error);
              });
          });
        }
      },
      clipboard: {
        matchVisual: false
      }
    }),
    [token]
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleEditorChange = (data: string) => {
    setValue(data);
    onChange(data);
  };

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleEditorChange}
        formats={editorFormats}
        modules={editorModules}
      />
    </div>
  );
};

export default RichTextEditor;
