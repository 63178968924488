import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BoxWrapper from "../../components/BoxWrapper";
import Pagination from "../../components/Pagination";
import RenderTable from "../../components/RenderTable";
import { BookingSvg } from "../../components/SideBarMenu";
import { useAuthContext } from "../../context/auth";
import { useBookingQuery } from "../../hooks/bookings/useBookingQuery";
import { useAuth } from "../../hooks/useAuth";
import { general } from "../../locales/general";
import BookingFilters from "./BookingFilters";
import BookingTable from "./BookingTable";
import FiltersModel, { FieldConfig } from "../../components/FiltersModel";
import FilterIcon from "../../components/icons/FitlerIcon";
import { pluralize } from "../../helpers/general";

const PAGE_SIZE = 10;

const bookingsFilter: FieldConfig[] = [
  {
    field: "date",
    type: "date",
    label: "Date de réservation",
    operators: ["$eq", "$gt", "$lt", "$gte", "$lte", "$between"]
  }
];

export default function Bookings() {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filters, setFilters] = useState([]);
  const {
    bookings,
    fetchingBookings,
    totalItems,
    currentPage,
    setCurrentPage,
    isPreviousData,
    totalCount,
    setFilters: setBookingFilters
  } = useBookingQuery();

  const { user } = useAuthContext();
  const { isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.adminForPlace?.supportReservations && !isAdmin) {
      navigate("/places", { replace: true });
    }
  }, [isAdmin, navigate, user?.adminForPlace?.supportReservations]);

  useEffect(() => {
    setBookingFilters(filters);
  }, [filters]);

  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Réservation</h5>
      <BoxWrapper>
        <FiltersModel
          open={filtersVisible}
          onClose={() => setFiltersVisible(false)}
          configs={bookingsFilter}
          callback={filters => {
            setFilters(filters);
          }}
        />
        <div className="d-flex flex-column-reverse flex-md-row  justify-content-end">
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ marginRight: "10px", cursor: "pointer" }}>
              <button
                onClick={() => setFiltersVisible(true)}
                className="outline-none btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
              >
                <FilterIcon />
              </button>
            </div>
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3 w-100"
              style={{ borderTopRightRadius: "8px" }}
            >
              <BookingSvg className="fill-white" />
              <p className="fs-5 fw-bold m-0  text-white">
                {totalCount} {pluralize("Réservation", totalCount)}
              </p>
            </div>
          </div>
        </div>
        <BookingFilters disableFilters={false} />

        <RenderTable
          loading={fetchingBookings || isPreviousData}
          render={() =>
            bookings?.length > 0 ? (
              <BookingTable bookings={bookings} />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={1}
        totalCount={totalItems}
        pageSize={PAGE_SIZE}
      />
    </section>
  );
}
