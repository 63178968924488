import React from "react";

export default function AlertIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 71 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.2142 53.5781L39.6195 2.12829C37.9283 -0.709428 33.7003 -0.709428 32.0091 2.12829L1.41437 53.5781C-0.276799 56.4158 1.79883 60 5.18119 60H66.3707C69.8292 60 71.9049 56.4153 70.2137 53.5781H70.2142ZM34.8535 17.8843C36.6985 17.4364 38.5432 18.3323 39.3887 19.9752C39.696 20.5726 39.7732 21.2446 39.696 21.842C39.5421 23.7088 39.4654 25.5757 39.3887 27.517C39.2349 30.4293 39.0043 33.4159 38.8504 36.3282C38.7737 37.2241 38.7737 38.1205 38.6965 39.091C38.6198 40.6594 37.3898 41.8538 35.7752 41.8538C34.238 41.8538 32.9312 40.6589 32.854 39.1655C32.6234 34.6105 32.3156 30.0554 32.0851 25.5003C32.0084 24.3054 31.9312 23.0361 31.8545 21.8412C31.8556 20.0498 33.0857 18.3326 34.8536 17.8846L34.8535 17.8843ZM35.853 52.0846C33.7006 52.0846 32.0094 50.3673 32.0094 48.2764C32.0094 46.1856 33.7773 44.4682 35.9297 44.4682C38.0821 44.4682 39.7733 46.1856 39.7733 48.3514C39.6966 50.3666 37.9286 52.0845 35.853 52.0845V52.0846Z"
        fill="#FF8533"
      />
    </svg>
  );
}
