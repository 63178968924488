import {
  CFormCheck,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import EditIcon from "../../components/icons/EditIcon";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { useQueryClient, useMutation } from "react-query";
import { useToast } from "../../hooks/useToast";
import { DELETE_ACTIONS } from "../../config/constants";
import { useCookie } from "../../hooks/useCookie";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import { Link } from "react-router-dom";
import PromptModal from "../../components/modals/PromptModal";
import TranslateIcon from "../../components/icons/TranslateIcon";
import { useAuth } from "../../hooks/useAuth";

function TableBodyRowContent({
  pack,
  deletedList
}: {
  pack: Pack;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useAuth();

  const deletePack = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`packs/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: pack.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: pack.id });
    }
  }, [deletedList, isChecked, pack.id]);

  const removePack = () => {
    deletePack.mutate(pack.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.packDeleted);
        queryClient.invalidateQueries("getAllPacks");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 ">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
        />
        <span className="ms-2 text-sm">
          {pack?.title}
        </span>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {pack?.place?.name}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {pack?.price}
      </CTableDataCell>
      <CTableDataCell className="p-3 justify-self-end">
        <Link to={`/packs/update/${pack.id}`} key={pack.id} className="ms-4">
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </Link>
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
          disabled={deletePack.isLoading}
        >
          {deletePack.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>
        {isAdmin && (
          <Link
            to={"/pack-translations/new"}
            state={{ pack: pack }}
            className="ms-4"
          >
            <TranslateIcon width="21" height="21" fill={"#000"} />
          </Link>
        )}
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removePack()}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  packs: Pack[];
  packsToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function PacksTable({ packs, packsToBeDeleted }: TableProps) {
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Titre</CTableHeaderCell>
            <CTableHeaderCell scope="col">Établissement</CTableHeaderCell>
            <CTableHeaderCell scope="col">Prix</CTableHeaderCell>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {packs?.map(pack => (
            <TableBodyRowContent
              pack={pack}
              key={pack?.id}
              deletedList={packsToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
