import React, { useRef, useState } from "react";
import { toQuery } from "../helpers/general";
import { useCookie } from "../hooks/useCookie";
import apiService from "../service/apiService";
import { AsyncPaginate } from "react-select-async-paginate";
import { colors } from "../config/theme";

const InfiniteScrollSelect = React.forwardRef((props: any, ref) => {
  const {
    url,
    required = false,
    isSearchable = true,
    isLoading = false,
    defaultValue = "",
    query = "",
    onChange = null,
    onSelect,
    placeholder = "Choisissez une option",
    formatOptions,
    ...rest
  } = props;

  const [loading, setLoading] = useState(false);
  const { token } = useCookie("vToken");
  const selectRef = useRef();

  const [selectedOption, setSelectedOption] = useState<any>(null);

  async function loadOptions(search: string, options: any[], additional: any) {
    try {
      if (defaultValue) {
        setSelectedOption(defaultValue);
        setLoading(false);
        return {
          options: [defaultValue],
          hasMore: false,
          additional: {
            currentPage: 1,
            nextPage: 2
          }
        };
      }
      setLoading(true);
      const endpoint = `${url.path}${toQuery({
        page: additional?.nextPage || 1,
        limit: 10,
        q: query || search
      })}`;

      const response = await apiService.MakeGetRequest(endpoint, token);
      const totalPages = response?.meta?.totalPages || 1;

      const currentPage = additional?.nextPage || 1;
      const fetchedOptions = response?.items || response || [];
      const formattedOptions = formatOptions
        ? formatOptions(fetchedOptions)
        : fetchedOptions;
      return {
        options: formattedOptions,
        hasMore: totalPages > currentPage,
        additional: {
          currentPage,
          nextPage: currentPage + 1
        }
      };
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <AsyncPaginate
      value={defaultValue || selectedOption}
      loadOptions={loadOptions}
      onChange={async option => {
        setSelectedOption(option);
        onChange && onChange(option);
        onSelect && onSelect(option);
      }}
      placeholder={placeholder}
      isLoading={loading || isLoading}
      isSearchable={isSearchable}
      isMulti={false}
      maxMenuHeight="200px"
      minMenuHeight="200px"
      loadingMessage={() => <p>Chargement...</p>}
      filterOption={(option, searchValue) => {
        const value = option?.label || option?.name;
        return value?.toLowerCase().includes(searchValue?.toLowerCase());
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: colors.primary
        }
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          boxShadow: state.isFocused
            ? "rgb(129, 227, 249) 0rem 0rem 0rem 0.125rem"
            : "none"
        }),
        menu: base => ({
          ...base,
          fontFamily: "sans-serif",
          fontSize: "15px"
        }),
        menuPortal: base => ({
          ...base,
          zIndex: 1400,
          fontFamily: "sans-serif",
          fontSize: "15px"
        })
      }}
      menuPosition={"absolute"}
      menuPortalTarget={document.body}
      cacheUniqs={[url]}
      selectRef={selectRef}
      // onChange
      defaultInputValue={defaultValue?.title ?? ""}
      {...rest}
    />
  );
});

export default InfiniteScrollSelect;
