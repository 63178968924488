import { CTableHeaderCell } from "@coreui/react";
import ArrowUpIcon from "./icons/ArrowUpIcon";
import ArrowDownIcon from "./icons/ArrowDownIcon";
import UnsortedIcon from "./icons/UnsortedIcon";

export function HeaderSort({
  field,
  sort,
  sortedField,
  setSort,
  clearSort,
  defaultField,
}: {
  field: string;
  sort: string;
  sortedField: { field: string; order: string };
  setSort: (field: string, order: string) => void;
  clearSort: () => void;
  defaultField?: string;
}) {
  const toggleSort = () => {
    if (sortedField.field !== field) {
      setSort(field, "asc");
    } else if (sortedField.order === "asc") {
      setSort(field, "desc");
    } else if (sortedField.order === "desc") {
      if (field === defaultField) {
        setSort(field, "asc");
      } else {
        clearSort();
      }
    }
  };

  return (
    <CTableHeaderCell scope="col" className="text-sm">
      <button className="btn btn-link" onClick={() => toggleSort()}>
        {sortedField.field !== field ? (
          <div>
            <UnsortedIcon />
          </div>
        ) : sortedField.order === "asc" ? (
          <ArrowUpIcon />
        ) : sortedField.order === "desc" ? (
          <ArrowDownIcon />
        ) : (
          <div style={{ visibility: "hidden" }}>
            <ArrowUpIcon />
          </div>
        )}
      </button>
    </CTableHeaderCell>
  );
}
