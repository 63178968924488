import { IconTypes } from "./types";

export default function InvoicesSvg({ className = ` ` }: Partial<IconTypes>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 656 683"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.22 0C231.4 0 379.56 0 527.74 0C597.79 0 655.01 57.23 655.01 127.27V246.72H499.45V682.84L416.2 631.74L332.96 682.84L249.72 631.74L166.48 682.84L83.24 631.74L0 682.84V83.24C0 37.46 37.45 0 83.23 0L83.22 0ZM55.5 331.6H444.02V360.35H55.5V331.6ZM55.5 246.12H444.02V274.87H55.5V246.12ZM55.5 160.64H444.02V189.39H55.5V160.64ZM55.5 75.16H444.02V103.91H55.5V75.16ZM499.45 46.37V200.37H608.65V127.28C608.65 82.82 572.2 46.37 527.74 46.37H499.45Z"
      />
    </svg>
  );
};
