import { IconTypes } from "./types";

export default function NotificationNotSentIcon({
  className = ``
}: Partial<IconTypes>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M27.9997 15.9877C27.9998 15.7738 27.9312 15.5655 27.8041 15.3934C27.6771 15.2213 27.4981 15.0945 27.2936 15.0316L11.6417 10.2157L1.70674 0.280712C1.51813 0.0985537 1.26553 -0.00224062 1.00333 3.78026e-05C0.741137 0.00231622 0.490325 0.107485 0.304917 0.292893C0.119508 0.478301 0.0143394 0.729114 0.012061 0.99131C0.00978259 1.25351 0.110577 1.50611 0.292735 1.69471L7.55674 8.95871L1.29364 7.03161C1.11801 6.97766 0.930908 6.97314 0.752877 7.01855C0.574847 7.06395 0.412766 7.15752 0.284425 7.28899C0.156084 7.42047 0.0664436 7.58475 0.0253428 7.76383C-0.0157579 7.9429 -0.00673018 8.12983 0.0514351 8.30411L2.05144 14.3041C2.09288 14.4287 2.15846 14.5438 2.24442 14.643C2.33038 14.7422 2.43505 14.8235 2.55244 14.8822L4.76343 15.9877L2.55244 17.0932C2.43505 17.1519 2.33038 17.2332 2.24442 17.3324C2.15846 17.4316 2.09288 17.5468 2.05144 17.6713L0.0514351 23.6713C0.00130478 23.8216 -0.012395 23.9817 0.011465 24.1383C0.0353249 24.2949 0.0960616 24.4437 0.18867 24.5722C0.281279 24.7008 0.403109 24.8055 0.544122 24.8777C0.685135 24.95 0.841295 24.9877 0.999735 24.9877C1.09933 24.9876 1.19836 24.9728 1.29364 24.9438L18.3068 19.7089L26.2927 27.6947C26.4813 27.8769 26.7339 27.9777 26.9961 27.9754C27.2583 27.9731 27.5091 27.8679 27.6946 27.6825C27.88 27.4971 27.9851 27.2463 27.9874 26.9841C27.9897 26.7219 27.8889 26.4693 27.7067 26.2807L20.4697 19.0435L27.2938 16.9435C27.4982 16.8806 27.6771 16.7538 27.8041 16.5818C27.9312 16.4098 27.9997 16.2016 27.9997 15.9877ZM2.56324 22.4604L3.81714 18.6967L7.44713 16.8822C7.61331 16.7992 7.75306 16.6715 7.85075 16.5135C7.94843 16.3555 8.00017 16.1734 8.00017 15.9877C8.00017 15.8019 7.94843 15.6198 7.85075 15.4618C7.75306 15.3038 7.61331 15.1761 7.44713 15.0931L3.81714 13.2786L2.56324 9.51601L10.5818 11.9838L16.7066 18.1086L2.56324 22.4604ZM18.8697 17.4431L14.6666 13.24L23.5997 15.9877L18.8697 17.4431Z" />
    </svg>
  );
}
