import React from "react";
import { IconTypes } from "./types";
export default function DeleteIcon({
  width = "16",
  height = "20",
  fill = "#FC376B",
  ...rest
}: Partial<IconTypes>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3601 15.3027V6.27916C10.3601 5.88157 10.0379 5.55912 9.64009 5.55912C9.24249 5.55912 8.92005 5.88139 8.92005 6.27916V15.3027C8.92005 15.7007 9.24231 16.0228 9.64009 16.0228C10.0379 16.0228 10.3601 15.7007 10.3601 15.3027ZM7.07921 15.3027V6.27916C7.07921 5.88157 6.75694 5.55912 6.35916 5.55912C5.96156 5.55912 5.63912 5.88139 5.63912 6.27916V15.3027C5.63912 15.7007 5.96138 16.0228 6.35916 16.0228C6.75676 16.0228 7.07921 15.7007 7.07921 15.3027ZM13.5845 5.55874C13.1869 5.55874 12.8644 5.881 12.8644 6.27878V17.1778C12.8644 17.4699 12.6271 17.708 12.3352 17.708L3.66482 17.7078C3.37252 17.7078 3.13463 17.4697 3.13463 17.1776V6.27904C3.13463 5.88144 2.81237 5.559 2.41459 5.559C2.01699 5.559 1.69455 5.88126 1.69455 6.27904V17.178C1.69455 18.264 2.57848 19.1481 3.66465 19.1481H12.3346C13.4205 19.1481 14.3039 18.264 14.3039 17.178L14.3041 6.27904C14.3044 5.8811 13.982 5.559 13.5844 5.559L13.5845 5.55874ZM15.1829 3.31542H11.9231V1.57234C11.9231 1.17474 11.6009 0.852295 11.2031 0.852295H4.79595C4.39835 0.852295 4.07591 1.17456 4.07591 1.57234V3.31542H0.816234C0.418635 3.31542 0.0961914 3.63769 0.0961914 4.03546C0.0961914 4.43306 0.418455 4.75551 0.816234 4.75551H15.1828C15.5804 4.75551 15.9029 4.43324 15.9029 4.03546C15.9027 3.63787 15.5804 3.31542 15.1828 3.31542H15.1829ZM10.4832 3.31542H5.51608V2.29238H10.4828V3.31542H10.4832Z"
        fill={fill}
      />
    </svg>
  );
}
