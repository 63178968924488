import { DAYS_OF_THE_WEEK } from "../config/constants";

export function getCurrentDayOpeningHours(
  openingHours: OpeningHours | null | undefined
) {
  const currentDate = new Date().getUTCDay();
  const currentDayOpeningHours =
    openingHours?.[DAYS_OF_THE_WEEK[currentDate - 1]?.en];

  return currentDayOpeningHours;
}

export function getLatLngFromCity(cities: City[], value: string) {
  const lat = cities.filter(c => Number(c.id) === Number(value))?.[0]?.latitude;
  const lng = cities.filter(c => Number(c.id) === Number(value))?.[0]
    ?.longitude;

  return { lat, lng };
}
export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

// @ts-ignore
export const verifyFileList = file => {
  if (Object.prototype.toString.call(file) !== "[object FileList]") {
    return "";
  }
  if (file.length <= 0) {
    return "";
  }
  return file[0];
};

export const toDateInputValue = function(value?: string) {
  if (!value) {
    return null;
  }
  const local = new Date(value);
  local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

export const createZoneAwareDate = (dateArg: Date) => {
  const date = new Date(dateArg);
  const timeZoneOffset = date.getTimezoneOffset();
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");
  const offsetSign = timeZoneOffset < 0 ? "+" : "-";
  const offsetHours = String(
    Math.abs(Math.floor(timeZoneOffset / 60))
  ).padStart(2, "0");
  const offsetMinutes = String(Math.abs(timeZoneOffset % 60)).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
};

export interface Query {
  [key: string]: string | number;
}
export function toQuery(queryObj: Query) {
  if (!queryObj || !Object.keys(queryObj).length) return "";
  const queries: string[] = [];
  Object.keys(queryObj).forEach(key => {
    if (queryObj[key]) {
      queries.push(`${key}=${queryObj[key]}`);
    }
  });
  return `?${queries.join("&")}`;
}
export function formatLargeNumber(number: number = 0) {
  if (isNaN(number)) {
    return "Invalid number";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short"
  });

  return formatter.format(number);
}

export function removeEmptyFilterQueries(inputString: string): string {
  return inputString
    .split("&")
    .map(query => query.split("="))
    .filter(([_, value]) => value)
    .map(([key, value], index) => `${index === 0 ? "" : "&"}${key}=${value}`)
    .join("");
}

export function formatDateString(dateString: string) {
  // dateString is in the format of "2021-01-01T00:00:00.000Z"
  // convert to "01/01/2021" without using Date object just regex
  const regex = /(\d{4})-(\d{2})-(\d{2})/;
  const match = dateString.match(regex);
  if (!match) {
    return "";
  }
  const [, year, month, day] = match;
  return `${day}/${month}/${year}`;
}

export function pluralize(text: string, count = 2, pluralForm?: string) {
  return count > 1 ? (pluralForm ? pluralForm : `${text}s`) : text;
}
