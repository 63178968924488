import { CFormInput, CSpinner, CAlert, CModal } from "@coreui/react";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import { useAuthContext } from "../../context/auth";
import { fetchUserProfile } from "../../lib/auth";

interface IProps {
    open: boolean;
    alert: string | null;
    onClose: () => void;
    onAccept: () => void;
}

const schema = yup.object({
    amount: yup.string().required("Ce champ est obligatoire"),
});

export default function WidthrawalRequestModal({
    alert = null,
    open,
    onClose,
    onAccept
}: IProps) {
    const { token } = useCookie("vToken");
    const { toastError, toastSuccess } = useToast();
    const { user, setUser } = useAuthContext()
    const queryClient = useQueryClient()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue: setFormValue,
    } = useForm({
        resolver: yupResolver(schema)
    });

    const { data } = useQuery<Variable[]>(["getAllVariables"], () => {
        return apiService.MakeGetRequest("variables");
    });

    const requestWithdrawMutation = useMutation(async requestData => {
        return await apiService.MakePostRequest(
            `payouts`,
            requestData,
            token);
    });

    const onSubmit = async (data: any) => {
        try {
            data.amount = +data.amount;
            await requestWithdrawMutation.mutateAsync(data);
            setUser(await fetchUserProfile(token))
            queryClient.invalidateQueries('getPayouts')
            toastSuccess(general.fr.message.payoutRequestCreated);
            onAccept();
        } catch (error) {
            toastError(general.fr.message.operationFailed);
            // onClose();
        }
    };

    return (
        <CModal alignment="center" visible={open} onClose={onClose}>
            <div className="">
                <section className="p-4">
                    <div className="border-bottom border-success mb-3">
                        <h5>Demande de retrait</h5>
                    </div>
                    <div className="mb-3">
                        <CAlert color="danger">{alert}</CAlert>
                    </div>
                    <form className="row form " onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-12 mb-3">
                            <label htmlFor="companyName" className="d-block">
                                Montant à retirer
                            </label>
                            <div className="d-flex align-items-center">
                                <CFormInput
                                    type="number"
                                    className="custom-input"
                                    placeholder="montant"
                                    id="amount"
                                    max={user?.adminForPlace?.balance}
                                    {...register("amount")}
                                />
                                <span className="mx-2 pointer text-danger" onClick={() => setFormValue('amount', user?.adminForPlace?.balance.toString() ?? '')}>MAX</span>
                            </div>
                            {errors.amount?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.amount.message}
                                </div>
                            )}
                        </div>
                        <p className="text-sm">*Le montant doit être supérieure au minimum de retrait ({data?.find((v) => v.label === 'minimumWithdrawal')?.value}DH)</p>

                        <section className="d-flex justify-content-center buttons gap-4 mt-3">
                            <button
                                className="btn btn-danger text-white shadow-primary w-20 px-4 py-2"
                                type="button"
                                onClick={onClose}
                            >
                                Annuler
                            </button>
                            <button
                                className="btn btn-success shadow-secondary w-20 text-white"
                                type="submit"
                                disabled={requestWithdrawMutation.isLoading}
                            >
                                {requestWithdrawMutation.isLoading ? (
                                    <div className="text-center">
                                        <CSpinner size="sm" />
                                    </div>
                                ) : (
                                    "Enregistrer"
                                )}
                            </button>
                        </section>
                    </form>
                </section>
            </div>
        </CModal>
    );
}
