// @ts-nocheck
import React, { useEffect } from "react";
import { CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import { useAuth } from "../../../hooks/useAuth";
import { useState } from "react";
import { useCookie } from "../../../hooks/useCookie";
import { general } from "../../../locales/general";
import { useToast } from "../../../hooks/useToast";
import { useMutation } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode } from "../../../helpers/enums";
import { useForm, Controller } from "react-hook-form";
import apiService from "../../../service/apiService";
import RichTextEditor from "../../../components/RichTextEditor";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";
import { verifyFileList } from "../../../helpers/general";
import FileUploader from "../../../components/FileUploader";

interface EventTranslationData {
  name: string;
  description: string;
  address?: string;
  picture?: File;
  lang: string;
  eventId: string;
}

export default function NewEventTranslation({ mode = FormMode.CREATE }) {
  const [formLoading, setFormLoading] = useState(false);
  const { isAdmin } = useAuth();
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [images, setImages] = useState({
    image: ""
  });

  const location = useLocation();
  const { event } = location.state || {};

  const schema = yup.object({
    name: yup.string().required(),
    description: yup.string().nullable(),
    address: yup.string().nullable(),
    picture: yup.mixed().nullable(),
    lang: yup.string().required(),
    eventId: yup.object().required()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  });

  useEffect(() => {
    setFormValue("eventId", event);
  }, [event?.id]);

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      const eventGetRequest = apiService.MakeGetRequest(
        `event-translations/${params.id}`,
        token
      );

      Promise.all([eventGetRequest]).then(([eventResp]) => {
        const formFields = Object.keys(schema.fields);
        setDescription(eventResp.description);
        setImages({ image: eventResp.image });
        setFormValue("picture", eventResp.image);
        setFormValue("eventId", eventResp.event);
        Object.entries(eventResp).forEach(entry => {
          if (formFields.indexOf(entry[0]) !== -1) {
            setFormValue(entry[0], entry[1]);
          }
        });
      });
    }
  }, [params?.id]);

  const createEventTranslationTranslationMutation = useMutation(
    async eventTranslationData => {
      return await apiService.MakePostRequest(
        "event-translations",
        eventTranslationData,
        token,
        true
      );
    }
  );

  const updateEventTranslationMutation = useMutation(
    async eventTranslationData => {
      return await apiService.MakePutRequest(
        `event-translations/${params.id}`,
        eventTranslationData,
        token,
        true
      );
    }
  );

  const createEventTranslation = async data => {
    try {
      setFormLoading(true);
      await createEventTranslationTranslationMutation.mutateAsync(data);
      toastSuccess(general.fr.message.translationCreated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updateEventTranslation = async data => {
    try {
      setFormLoading(true);
      const updateEventTranslationReq = updateEventTranslationMutation.mutateAsync(
        data
      );

      if (isAdmin) {
        await Promise.all([updateEventTranslationReq]);
        toastSuccess(general.fr.message.translationUpdated);
        navigate(-1);
      } else {
        await updateEventTranslationReq;
        toastSuccess(general.fr.message.translationUpdated);
        navigate(-1);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async (dataArg: EventTranslationData) => {
    const data = { ...dataArg };
    try {
      const pictureFile = verifyFileList(data.picture);
      data.picture = pictureFile;
      data.eventId = data.eventId.id;
      if (mode === FormMode.UPDATE) {
        updateEventTranslation({ ...data, description });
      } else {
        createEventTranslation({ ...data, description });
      }
    } catch (error) {
      setFormLoading(false);
      console.log(error);
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div className=" m-4  ">
      <BoxWrapper>
        <section className="p-4">
          <div className="border-bottom border-success">
            <h4>
              {mode === FormMode.UPDATE
                ? "Mise à jour"
                : "Ajouter une traduction"}
            </h4>
          </div>
          <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-6 my-3">
              <label htmlFor="eventId" className="d-block mb-2">
                Évènement <span className="text-md text-red">*</span>
              </label>
              <Controller
                name="eventId"
                control={formControl}
                render={({ field }) => {
                  return (
                    <InfiniteScrollSelect
                      name="eventId"
                      id="eventId"
                      isClearable={false}
                      defaultValue={event}
                      error={errors?.eventId?.message}
                      getOptionLabel={option => option?.name}
                      getOptionValue={option => option.id}
                      isOptionSelected={(option, selectedValue) => {
                        const isSelected =
                          option?.id === selectedValue?.[0]?.id;
                        return isSelected;
                      }}
                      url={{
                        path: "events"
                      }}
                      {...field}
                    />
                  );
                }}
              />
              {errors.eventId?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.eventId.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3">
              <label htmlFor="lang" className="d-block">
                La langue
                <span className="text-sm text-red"> *</span>
              </label>
              <CFormSelect name="lang" id="lang" {...register("lang")}>
                <option value="en">Anglais</option>
                <option value="ar">Arabic</option>
                <option value="fr">Français</option>
              </CFormSelect>
              {errors.lang?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.supportTableSelection.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="name" className="d-block">
                Nom <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                eventholder="Nom de Event"
                id="name"
                name="name"
                {...register("name")}
              />
              {errors.name?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.name.message}
                </div>
              )}
            </div>
            <div className="col-md-6 mt-4">
              <label htmlFor="picture" className="d-block mb-1">
                Image
              </label>
              <FileUploader
                name="picture"
                id="picture"
                accept="image/png,image/jpeg,image/jpg"
                {...register("picture")}
                file={images?.image}
                required={false}
              />
              {errors.picture?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.picture.message}
                </div>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="address" className="d-block">
                Adresse
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                placeholder="Adresse"
                id="address"
                name="address"
                {...register("address")}
              />
              {errors.address?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.address.message}
                </div>
              )}
            </div>

            <div className="my-3">
              <label htmlFor="description">Description</label>
              <RichTextEditor
                onChange={setDescription}
                // {...register("description")}
                name="description"
                defaultValue={description}
              />
            </div>

            <section className="d-flex justify-content-center buttons gap-4 mt-5">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
              >
                Annuler
              </button>
              <button
                className="btn btn-success green-shadow w-20 text-white"
                type="submit"
                disabled={formLoading}
              >
                {formLoading ? (
                  <div className="text-center">
                    <CSpinner size="sm" />
                  </div>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </section>
          </form>
        </section>
      </BoxWrapper>
    </div>
  );
}
