// @ts-nocheck
import React, { useEffect } from "react";
import { CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import { useAuth } from "../../../hooks/useAuth";
import { useState } from "react";
import { useCookie } from "../../../hooks/useCookie";
import { general } from "../../../locales/general";
import { useToast } from "../../../hooks/useToast";
import { useMutation } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode } from "../../../helpers/enums";
import { useForm, Controller } from "react-hook-form";
import apiService from "../../../service/apiService";
import RichTextEditor from "../../../components/RichTextEditor";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";
import { verifyFileList } from "../../../helpers/general";
import FileUploader from "../../../components/FileUploader";

interface PackTranslationData {
    title: string;
    shortDescription?: string;
    picture?: File;
    description?: string;
    lang: string;
    packId: string;
}

export default function NewPackTranslation({ mode = FormMode.CREATE }) {
    const location = useLocation();
    const { pack } = location.state || {};
    const [formLoading, setFormLoading] = useState(false);
    const { token } = useCookie("vToken");
    const { toastError, toastSuccess } = useToast();
    const params = useParams();
    const navigate = useNavigate();
    const [description, setDescription] = useState("");
    const [images, setImages] = useState({
        image: "",
    });
    const [itemsTranslations, setItemsTranslations] = useState([]);
    const [packItems, setPackItems] = useState([]);

    const [packId, setPackId] = useState(pack?.id || "");

    const schema = yup.object({
        title: yup.string().required(),
        description: yup.string().nullable(),
        shortDescription: yup.string().nullable(),
        lang: yup.string().required(),
        packId: yup.object().required(),
        picture: yup.mixed().nullable(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control: formControl,
        setValue: setFormValue,
        getValues: getFormValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });

    useEffect(() => {
        setFormValue("packId", pack); 
        setPackItems(pack?.items);
    }, [pack?.id]);

    useEffect(() => {
        if (mode === FormMode.UPDATE && params?.id) {
            const formFields = Object.keys(schema.fields);
            apiService.MakeGetRequest(
                `pack-translations/${params.id}`,
                token
            ).then((response) => {
                setDescription(response?.description);
                setFormValue("packId", response?.pack);
                setPackItems(response?.pack.items);
                // setFormValue("picture", response?.image);
                setImages({ image: response?.image });
                Object.entries(response).forEach(entry => {
                    if (formFields.indexOf(entry[0]) !== -1) {
                        setFormValue(entry[0], entry[1]);
                    }
                });
            });
        }
    }, [params?.id]);

    useEffect(() => {
        if (packId) {
            apiService.MakeGetRequest(
                `pack-translations/${packId}/items`,
                token
            ).then((response) => {
                const itemsTranslations = [];
                response?.forEach((item) => {
                    if (item.translations?.length) {
                        item.translations.forEach((translation) => {
                            const translationItem = { itemId: item.id, lang: translation.lang, translatedTitle: translation.title };
                            itemsTranslations.push(translationItem);
                        })
                    } else {
                        const translationItem = { itemId: item.id, lang: 'ar', translatedTitle: '' };
                        itemsTranslations.push(translationItem);
                    }
                })
                setItemsTranslations(itemsTranslations);
            })
        }
    }, [packId])

    const createPackTranslationTranslationMutation = useMutation(async packTranslationData => {
        return await apiService.MakePostRequest("pack-translations", packTranslationData, token, true);
    });

    const updatePackTranslationMutation = useMutation(async packTranslationData => {
        return await apiService.MakePutRequest(
            `pack-translations/${params.id}`,
            packTranslationData,
            token,
            true
        );
    });

    const updatePackItemsTranslationsMutation = useMutation(async packItemsTranslationsData => {
        return await apiService.MakePutRequest(
            `pack-translations/${packItemsTranslationsData.packId}/items-translations`,
            packItemsTranslationsData,
            token
        );
    });

    const createPackTranslation = async data => {
        try {
            setFormLoading(true);
            await createPackTranslationTranslationMutation.mutateAsync(data);
            await updatePackItemsTranslationsMutation.mutateAsync({
                packItemsTranslations: itemsTranslations.map((itemTranslation) => ({
                    title: itemTranslation.translatedTitle,
                    lang: itemTranslation.lang,
                    packItemId: itemTranslation.itemId
                })),
                packId: data.packId
            });
            toastSuccess(general.fr.message.translationCreated);
            navigate(-1);
        } catch (error) {
            setFormLoading(false);
            toastError(general.fr.message.operationFailed);
        }
    };

    const updatePackTranslation = async data => {
        try {
            setFormLoading(true);
            const updatePackTranslationReq = updatePackTranslationMutation.mutateAsync(data);
            const updatePackItemsTranslationsReq = updatePackItemsTranslationsMutation.mutateAsync({
                packItemsTranslations: itemsTranslations.map((itemTranslation) => ({
                    title: itemTranslation.translatedTitle,
                    lang: itemTranslation.lang,
                    packItemId: itemTranslation.itemId
                })),
                packId: data.packId
            });
            await Promise.all([updatePackTranslationReq, updatePackItemsTranslationsReq]);
            toastSuccess(general.fr.message.translationUpdated);
            navigate(-1);
        } catch (error) {
            setFormLoading(false);
            toastError(general.fr.message.operationFailed);
        }
    };

    const onSubmit = async (dataArg: PackTranslationData) => {
        const data = { ...dataArg };
        try {
            data.packId = data.packId.id;
            const imageFile = verifyFileList(data.picture);
            data.picture = imageFile;
            if (mode === FormMode.UPDATE) {
                updatePackTranslation({ ...data, description });
            } else {
                createPackTranslation({ ...data, description });
            }
        } catch (error) {
            setFormLoading(false);
            console.log(error);
            toastError(general.fr.message.operationFailed);
        }
    };

    const deleteItemTranslation = (index) => {
        const itemsTranslationsArray = [...itemsTranslations];
        itemsTranslationsArray.splice(index, 1);
        setItemsTranslations(itemsTranslationsArray);
    }

    const handleItemChange = (e, index) => {
        const { name, value } = e.target;
        setItemsTranslations(prevState => {
            const updatedItems = [...prevState];
            updatedItems[index] = {
                ...updatedItems[index],
                [name]: value
            };
            return updatedItems;
        });
    };

    return (
        <div className=" m-4  ">
            <BoxWrapper>
                <section className="p-4">
                    <div className="border-bottom border-success">
                        <h4>
                            {mode === FormMode.UPDATE
                                ? "Mise à jour"
                                : "Ajouter une traduction"}
                        </h4>
                    </div>
                    <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-6 my-3">
                            <label htmlFor="packId" className="d-block mb-2">
                                Pack <span className="text-md text-red">*</span>
                            </label>
                            <Controller
                                name="packId"
                                control={formControl}
                                render={({ field }) => {
                                    return (
                                        <InfiniteScrollSelect
                                            name="packId"
                                            id="packId"
                                            isClearable={false}
                                            defaultValue={pack}
                                            error={errors?.packId?.message}
                                            getOptionLabel={option => option?.title}
                                            getOptionValue={option => option.id}
                                            isOptionSelected={(option, selectedValue) => {
                                                const isSelected =
                                                    option?.id === selectedValue?.[0]?.id;
                                                return isSelected;
                                            }}
                                            onSelect={(pack) => {
                                                setPackId(pack.id);
                                                setPackItems(pack.items);
                                            }}
                                            url={{
                                                path: "packs"
                                            }}
                                            {...field}
                                        />
                                    );
                                }}
                            />
                            {errors.packId?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.packId.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6  my-3">
                            <label htmlFor="lang" className="d-block">
                                La langue
                                <span className="text-sm text-red"> *</span>
                            </label>
                            <CFormSelect
                                name="lang"
                                id="lang"
                                {...register("lang")}
                            >
                                <option value="en">Anglais</option>
                                <option value="ar">Arabic</option>
                                <option value="fr">Français</option>
                            </CFormSelect>
                            {errors.lang?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.supportTableSelection.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6  my-3 ">
                            <label htmlFor="name" className="d-block">
                                Titre du Pack <span className="text-sm text-red">*</span>
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                packholder="Titre du Pack"
                                id="title"
                                name="title"
                                {...register("title")}
                            />
                            {errors.title?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.title.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6  my-3 ">
                            <label htmlFor="picture" className="d-block">
                                Image
                            </label>
                            <FileUploader
                                name="picture"
                                id="picture"
                                accept="image/png,image/jpeg,image/jpg"
                                {...register("picture")}
                                file={images?.image}
                                required={false}
                            />
                            {errors.picture?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.picture.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6  my-3 ">
                            <label htmlFor="shortDescription" className="d-block">
                                Brève description
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                packholder="shortDescription"
                                id="shortDescription"
                                name="shortDescription"
                                {...register("shortDescription")}
                            />
                            {errors.shortDescription?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.shortDescription.message}
                                </div>
                            )}
                        </div>

                        <div className="my-3">
                            <label htmlFor="description">Description</label>
                            <RichTextEditor
                                onChange={setDescription}
                                // {...register("description")}
                                name="description"
                                defaultValue={description}
                            />
                        </div>

                        {packItems?.length !== 0 ? <div>
                            <section className="mt-5">
                                <h5>Éléments du pack</h5>
                            </section>
                            {itemsTranslations.map((item, index) => {
                                return (
                                    <div
                                        key={item?.id}
                                        className="mt-4 d-flex justify-items-center align-items-end"
                                    >
                                        <div
                                            className="d-flex justify-items-center align-items-end"
                                        // style={{
                                        //     opacity: packItem?.isDeleted ? 0.4 : 1,
                                        //     pointerEvents: packItem?.isDeleted ? "none" : "all"
                                        // }}
                                        >
                                            <div
                                                className="me-3"
                                                style={{
                                                    minWidth: "90px"
                                                }}
                                            >
                                                Élément {index + 1}:{" "}
                                            </div>
                                            <CFormSelect
                                                className="ms-3"
                                                name="itemId"
                                                id="itemId"
                                                value={item.itemId}
                                                onChange={(e) => handleItemChange(e, index)}
                                            >
                                                {packItems.map((packItem) => <option key={packItem.id} value={packItem.itemId}>{packItem.title}</option>)}
                                            </CFormSelect>

                                            <CFormSelect
                                                className="ms-3"
                                                name="lang"
                                                id="lang"
                                                value={item.lang}
                                                onChange={(e) => handleItemChange(e, index)}
                                            >
                                                <option value="en">Anglais</option>
                                                <option value="ar">Arabic</option>
                                                <option value="fr">Français</option>
                                            </CFormSelect>

                                            <input
                                                className="custom-input ms-3"
                                                type="text"
                                                name="translatedTitle"
                                                value={item.translatedTitle}
                                                onChange={(e) => handleItemChange(e, index)}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-danger green-shadow w-20 text-white ms-3"
                                                onClick={() => deleteItemTranslation(index)}
                                            >
                                                Supprimer
                                            </button>
                                        </div>
                                        {/* {packItem.isDeleted ? ( */}
                                        {/* <button
                                        type="button"
                                        className="btn btn-danger green-shadow w-20 text-white ms-3"
                                    // onClick={() =>
                                    //     updatePackItemTranslationField(index, {
                                    //         ...packItem,
                                    //         isDeleted: false
                                    //     })
                                    // }
                                    >
                                        <UndoIcon />
                                    </button> */}
                                        {/* ) : null} */}
                                    </div>
                                );
                            })}
                            <section className="mt-5">
                                <button
                                    type="button"
                                    className="btn btn-info green-shadow w-20 text-white"
                                    onClick={() => {
                                        setItemsTranslations(prev => [...prev, { itemId: packItems[0]?.id, lang: 'en', translatedTitle: '' }])
                                    }}
                                >
                                    Ajouter un élément de pack
                                </button>
                            </section>
                        </div> : null}


                        <section className="d-flex justify-content-center buttons gap-4 mt-5">
                            <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                            >
                                Annuler
                            </button>
                            <button
                                className="btn btn-success green-shadow w-20 text-white"
                                type="submit"
                                disabled={formLoading}
                            >
                                {formLoading ? (
                                    <div className="text-center">
                                        <CSpinner size="sm" />
                                    </div>
                                ) : (
                                    "Enregistrer"
                                )}
                            </button>
                        </section>
                    </form>
                </section>
            </BoxWrapper>
        </div>
    );
}
