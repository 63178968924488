import React, { useEffect, useMemo, useReducer, useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CSpinner } from "@coreui/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToast } from "../../hooks/useToast";
import { useCookie } from "../../hooks/useCookie";
import apiService from "../../service/apiService";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import { general } from "../../locales/general";
import BoxWrapper from "../../components/BoxWrapper";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { colors } from "../../config/theme";
import Pagination from "../../components/Pagination";
import RenderTable from "../../components/RenderTable";
import NewsletterTable from "./NewsletterTable";
import RestrictedRoute from "../auth/RestrictedRoute";
import { useParams } from "react-router-dom";

const PAGE_SIZE = 10;

export default function Newsletter() {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isLoading } = useQuery(["getNewsletter", currentPage], () => {
    return apiService.MakeGetRequest(
      `newsletter?page=${currentPage}&limit=${PAGE_SIZE}`,
      token
    );
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const deleteNewsletter = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`newsletter/${id}`, token);
  });

  const filteredNewsletter = useMemo(() => {
    const Newsletter = data?.items?.filter((Newsletter: any) =>
      Newsletter.email?.toLowerCase()?.includes(searchQuery)
    );
    return Newsletter;
  }, [data?.items, searchQuery]);

  const [NewsletterToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    NewsletterToBeDeleted.ids.forEach((id: string) => {
      if (!id) return;
      deleteNewsletter.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.emailDeleted);
          queryClient.invalidateQueries("getNewsletter");
          setCurrentPage(1);
        }
      });
    });
  };

  const NewsletterSvg = () => {
    return (
      <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9394 3C18.2804 3 19.5704 3.53 20.5194 4.481C21.4694 5.43 22.0004 6.71 22.0004 8.05V15.95C22.0004 18.74 19.7304 21 16.9394 21H7.06043C4.26943 21 2.00043 18.74 2.00043 15.95V8.05C2.00043 5.26 4.25943 3 7.06043 3H16.9394ZM18.5304 9.54L18.6104 9.46C18.8494 9.17 18.8494 8.75 18.5994 8.46C18.4604 8.311 18.2694 8.22 18.0704 8.2C17.8604 8.189 17.6604 8.26 17.5094 8.4L13.0004 12C12.4204 12.481 11.5894 12.481 11.0004 12L6.50043 8.4C6.18943 8.17 5.75943 8.2 5.50043 8.47C5.23043 8.74 5.20043 9.17 5.42943 9.47L5.56043 9.6L10.1104 13.15C10.6704 13.59 11.3494 13.83 12.0604 13.83C12.7694 13.83 13.4604 13.59 14.0194 13.15L18.5304 9.54Z"
        />{" "}
      </svg>
    );
  };

  return (
    <RestrictedRoute>
      <h5 className="fs-4 fw-bold mb-2">Newsletter</h5>
      <BoxWrapper>
        <div className="d-flex  justify-content-between align-items-baseline before-table">
          <div className="d-flex align-items-center gap-2 p-3 delete-add">
            <button
              disabled={
                deleteNewsletter.isLoading ||
                NewsletterToBeDeleted?.ids.length === 0
              }
              onClick={handleBulkDelete}
              className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
            >
              {!deleteNewsletter.isLoading ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer l&apos;email
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>
          <div className="search-form">
            <div className=" input-field">
              <MagnifyingGlassIcon color="gray" width={20} />
              <input
                type="text"
                className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                placeholder="Rechercher"
                onChange={e => setSearchQuery(e?.target?.value?.toLowerCase())}
              />
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
            style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
          >
            <NewsletterSvg />
            <p className="fs-5 fw-bold m-0  text-white">
              {" "}
              {data?.meta?.itemCount}{" "}
              {data?.meta?.itemCount > 1 ? "Emails" : "Email"}
            </p>
          </div>
        </div>

        <RenderTable
          loading={isLoading}
          render={() =>
            filteredNewsletter.length > 0 ? (
              <NewsletterTable
                Newsletters={filteredNewsletter as Newsletter[]}
                NewsletterTableToBeDeleted={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={data?.meta?.totalPages}
        pageSize={PAGE_SIZE}
      />
    </RestrictedRoute>
  );
}
