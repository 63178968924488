import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams, useSearchParams } from "react-router-dom";
import BoxWrapper from "../../components/BoxWrapper";
import Pagination from "../../components/Pagination";
import RenderTable from "../../components/RenderTable";
import { toQuery } from "../../helpers/general";
import { useCookie } from "../../hooks/useCookie";
import { usePromoCode } from "../../hooks/usePromoCode";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import PromoCodeList from "./PromoCodeList";
import TableBasicHeader from "./TableBasicHeader";
import { useToast } from "../../hooks/useToast";

type Views = "all" | "events";
interface Props {
  view?: Views;
}

const createEndpoint = (view: Views, id?: string) => {
  if (view !== "all" && !id) return "promo-codes";

  switch (view) {
    case "events":
      return `events/${id}/promo-codes`;
    default:
      return "promo-codes";
  }
};
export default function PromoCodes(props: Props) {
  const { view = "all" } = props;
  const { token } = useCookie("vToken");
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);

  const { isDeleting, deletePromoCode } = usePromoCode();
  const { toastError } = useToast();
  const params = useParams();

  const searchQuery = {
    page: currentPage,
    search: searchParams?.get("search") ?? ""
  };

  const endpoint = createEndpoint(view, params?.id);

  const { data, isLoading } = useQuery({
    queryKey: ["getPromoCode", token, endpoint],
    queryFn: () =>
      apiService.MakeGetRequest(`${endpoint}${toQuery(searchQuery)}`, token)
  });

  const handleCodeSelection = (id: string, action: "add" | "remove") => {
    if (action === "add") {
      setSelectedCodes(prevCodes => [...prevCodes, id]);
    } else {
      setSelectedCodes(prevCodes => prevCodes.filter(code => code !== id));
    }
  };

  const handleMultipleDelete = async () => {
    try {
      if (selectedCodes.length === 0) return;

      await Promise.all(selectedCodes.map(code => deletePromoCode(code)));
      setSelectedCodes([]);
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div>
      <h5 className="fs-4 fw-bold mb-2">Codes Promo</h5>
      <BoxWrapper>
        <TableBasicHeader
          disableDelete={selectedCodes.length === 0}
          deleting={isDeleting}
          onDelete={handleMultipleDelete}
          addComponent={
            <Link
              to="/promo-codes/new"
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
            >
              + Ajouter un code
            </Link>
          }
          totalItems={data?.meta?.totalItems}
        />
        <RenderTable
          loading={isLoading}
          render={() =>
            data?.items?.length > 0 ? (
              <PromoCodeList
                codes={data?.items as PromoCode[]}
                onSelect={handleCodeSelection}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={data?.meta?.totalPages}
        pageSize={10}
      />
    </div>
  );
}
