import React, { FormEvent, useState } from "react";
import { CContainer, CFormCheck, CSpinner } from "@coreui/react";

import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/icons/Logo";
import { useMutation } from "react-query";
import apiService from "../service/apiService";
import { useToast } from "../hooks/useToast";
import { useAuthContext } from "../context/auth";
import { useCookies } from "react-cookie";
import { general } from "../locales/general";
import ProtectedRoute from "./auth/ProtectedRoute";
import { ROLES } from "../config/constants";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
type LoginData = {
  email: string;
  password: string;
};
export default function Login() {
  const [isPassword, togglePassword] = useState(true);
  const attemptLogin = useMutation(
    async (data: LoginData) =>
      await apiService.MakePostRequest("auth/signin", data)
  );
  const { setUser } = useAuthContext();
  const { toastError } = useToast();
  const navigate = useNavigate();
  const [, setCookies] = useCookies(["vToken"]);

  const loginHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const email = formData.get("email") as string;
    const password = formData.get("password") as string;
    attemptLogin.mutate(
      { email, password },
      {
        onError(error: any) {
          toastError(error?.response?.data?.message);
          setUser(null);
        },
        onSuccess(data: { token: string; user: User }) {
          setUser(data.user);
          setCookies("vToken", data?.token, { path: "/" });
          data?.user?.role?.label?.toLowerCase() ===
          ROLES.PLACE_ADMIN.toLowerCase()
            ? navigate("/places")
            : navigate("/home");
        }
      }
    );
  };
  return (
    <ProtectedRoute route="Guest">
      <CContainer>
        <div
          className="d-flex flex-column align-items-center justify-content-center vh-100  pt-5 mx-auto w-md-75"
          // style={{ width: "75%" }}
        >
          {/* style={{ width: "75%" }} */}
          <form method="post" className="w-md-75" onSubmit={loginHandler}>
            <div className="d-flex flex-column align-items-center">
              <Logo width="230" height="100" />
              <p className="fw-bold mb-2 fs-5 fs-md-5 text-auto ">
                Connectez-vous à votre compte
              </p>
              <p className="text-muted fw-semibold">
                Tableau de bord de l&apos; Admin
              </p>
            </div>
            {/* style={{ width: "56%" }} */}
            <div className="mx-auto mt-3 mb-3 w-md-56 ">
              <div className="mb-3">
                <label htmlFor="email" className="d-block mb-1">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  placeholder="companyName@example.com"
                  className="rounded-sm p-2 w-100 outline-none border border-light shadow-sm text-sm"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="password" className="d-block mb-1">
                  Mot de passe
                </label>
                <div className="position-relative rounded-sm p-2 w-100 outline-none border border-light shadow-sm bg-white">
                  <input
                    placeholder="******"
                    type={isPassword ? "password" : "text"}
                    name="password"
                    id="password"
                    required
                    className="text-sm border-0 outline-none"
                    style={{ width: "89%" }}
                  />
                  <button
                    className="absolute-position top-0 left-0 border-0 outline-none bg-transparent"
                    onClick={() => togglePassword(state => !state)}
                    type="button"
                  >
                    {isPassword ? (
                      <EyeIcon className="w-4 h-4 text-light" />
                    ) : (
                      <EyeSlashIcon className="w-4 h-4 text-light" />
                    )}
                  </button>
                </div>
              </div>
              {/* <div className="d-flex align-items-center justify-content-between">
                <CFormCheck label="Rappelez-vous de moi" className="text-sm" />
                <Link
                  to="/forget-password"
                  className="text-danger text-sm mb-1 "
                >
                  Mot de passe oublié ?
                </Link>
              </div> */}
              <button
                type="submit"
                disabled={attemptLogin.isLoading}
                className=" w-100 mt-3 text-white border-0 rounded-2  btn btn-lg bg-gradient-primary d-flex align-items-center justify-content-center gap-2"
              >
                {attemptLogin.isLoading && <CSpinner size="sm" />}
                {attemptLogin.isLoading ? general.fr.wait : "SE CONNECTER"}
              </button>
            </div>
          </form>
          {/* <p className="text-sm text-center">
            Vous n&apos;avez pas de compte ?{" "}
            <Link className="text-danger text-decoration-none" to={""}>
              Enregistrez-vous
            </Link>
          </p> */}
        </div>
      </CContainer>
    </ProtectedRoute>
  );
}
