import React from "react";

export default function ImagePreview({ url }: { url: string }) {
  return (
    <a
      href={url}
      className="d-flex bg-success-shade p-1 rounded-sm text-black"
      target="_blank"
      style={{ width: "fit-content" }}
      rel="noreferrer"
    >
      <img src={url} alt="" className="w-5 h-5 rounded-sm me-1" />
      <span className="text-dark">Aperçu</span>
    </a>
  );
}
