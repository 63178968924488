import html2canvas from "html2canvas";
import React from "react";

export function useSvgScreenShoot() {
  const componentRef = React.createRef<any>();

  const createSvgElement = (width = "150", height = "150") => {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("width", width);
    svg.setAttribute("height", height);
    return svg;
  };

  const createSvgImage = (width = "150", height = "150", dataUrl: string) => {
    const image = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "image"
    );
    image.setAttribute("width", width);
    image.setAttribute("height", height);
    image.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", dataUrl);

    return image;
  };
  const convertToSVG = (canvas: HTMLCanvasElement) => {
    const width = canvas.width?.toString();
    const height = canvas.height?.toString();

    const svg = createSvgElement(width, height);
    const svgImage = createSvgImage(width, height, canvas?.toDataURL());

    svg.appendChild(svgImage);

    return svg;
  };

  const convertToCanvas = async () => {
    const canvas = await html2canvas(componentRef.current);
    return canvas;
  };

  const downloadSVG = async (fileName = "generated.svg") => {
    if (!componentRef.current) {
      throw new Error("Invalid component");
    }
    const canvas = await convertToCanvas();
    const svg = convertToSVG(canvas);

    const svgString = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([svgString], { type: "image/svg+xml" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.svg`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return {
    ref: componentRef,
    downloadSVG
  };
}
