export const DELETE_ACTIONS = {
  ADD: "ADD",
  REMOVE: "REMOVE"
};

export const OPENING_HOUR_TYPES = {
  CLOSED: "closed",
  OPEN_ALL_DAY: "openAllDay",
  CUSTOM: "custom"
};
export const ROLES = {
  SUPER_ADMIN: "Admin",
  USER: "user",
  INSPECTOR: "inspector",
  PLACE_ADMIN: "Place Admin"
};

export const BOOKING_STATUS = {
  WAITING: "waiting",
  CONFIRMED: "confirmed",
  CANCELED: "canceled",
  REFUSED: "canceled",
  PASSED: "passed",
  CASH_PRE_PAYMENT: "cash_pre_payment"
};

export const PAYMENT_METHODS = {
  CARTE_BANCAIRE: "carte_bancaire",
  WAFACASH: "wafacash",
  SUR_PLACE: "sur_place"
};

export const DAYS_OF_THE_WEEK = [
  { en: "monday", fr: "lundi" },
  { en: "tuesday", fr: "mardi" },
  { en: "wednesday", fr: "mercredi" },
  { en: "thursday", fr: "jeudi" },
  { en: "friday", fr: "vendredi" },
  { en: "saturday", fr: "samedi" },
  { en: "sunday", fr: "dimanche" }
];

export const editorConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "fontFamily",
      "fontSize",
      "fontColor",
      "fontBackgroundColor",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "link",
      //"imageUpload",
      "blockQuote",
      "insertTable",
      "undo",
      "redo"
    ]
  },
  language: "en",
  image: {
    toolbar: [
      "imageTextAlternative",
      "toggleImageCaption",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side"
    ]
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
  }
};

export const editorModules = {
  toolbar: [
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image"],
    ["clean"]
  ],
  imageUploader: {
    upload: (file: any) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);

        fetch("https://api.staging.venez.ma/api/v1/events", {
          method: "GET"
          // body: formData
        })
          .then(response => response.json())
          .then(result => {
            console.log(result);
            resolve(
              "https://venez-uploads-staging.s3.eu-west-3.amazonaws.com/events/1706191281899_1705080608792_Original_post__19.png"
            );
          })
          .catch(error => {
            reject("Upload failed");
            console.error("Error:", error);
          });
      });
    }
  },
  clipboard: {
    matchVisual: false
  }
};

export const editorFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video"
];
