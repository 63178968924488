import {
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React from "react";
import PromoCodeListRow from "./PromoCodeListRow";

interface Props {
  codes: PromoCode[];
  onSelect: (id: string, state: "add" | "remove") => void;
}
export default function PromoCodeList(props: Props) {
  const { codes, onSelect } = props;

  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm">
              Code
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Événement
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Établissements
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Discount (%)
            </CTableHeaderCell>

            <CTableHeaderCell scope="col" className="text-sm">
              Nombre maximum d'utilisations
            </CTableHeaderCell>

            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {codes?.map((promoCode, index) => (
            <PromoCodeListRow
              promoCode={promoCode}
              key={promoCode?.id}
              onSelect={onSelect}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
