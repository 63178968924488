import { useMutation, useQueryClient } from "react-query";
import { useCookie } from "./useCookie";
import apiService from "../service/apiService";
import { useToast } from "./useToast";
import { general } from "../locales/general";
import { createPromoDto } from "../rules/promo";

interface UpdatePayload extends createPromoDto {
  id: string;
}
export function usePromoCode() {
  const queryClient = useQueryClient();
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();

  const deletePromoCodesMutation = useMutation((id: string) =>
    apiService.MakeDeleteRequest(`promo-codes/${id}`, token)
  );
  const updatePromoCodesMutation = useMutation((data: UpdatePayload) => {
    return apiService.MakePutRequest(`promo-codes/${data.id}`, data, token);
  });

  return {
    isDeleting: deletePromoCodesMutation.isLoading,
    isLoading: deletePromoCodesMutation.isLoading,
    deletePromoCode: async (id: string) => {
      await deletePromoCodesMutation.mutateAsync(id);

      queryClient.invalidateQueries("getPromoCode");
    },

    updatePromoCode: async (id: string, payload: createPromoDto) => {
      await updatePromoCodesMutation.mutateAsync({ id, ...payload });

      queryClient.invalidateQueries("getPromoCode");
    }
  };
}
