import React, { useCallback, useState } from "react";
import { CSpinner } from "@coreui/react";
import { useEffect, useRef } from "react";
import { withCookies } from "react-cookie";
import { useAuthContext } from "../../context/auth";
import { fetchUserProfile } from "../../lib/auth";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../config/constants";
import RestrictedRoute from "./RestrictedRoute";
import Contract from "./Contract";

function ProtectedRoute(props: any) {
  const {
    authPageLoading,
    setUser,
    setAuthPageLoading,
    user
  } = useAuthContext();
  const [contractSigned, setContractSigned] = useState(true);
  const [placeAdmin, setPlaceAdmin] = useState<User | null>(null);
  const navigation = useNavigate();
  const runEffect = useRef(true);
  const { cookies } = props;

  const logout = useCallback(() => {
    setUser(null);
    cookies?.remove("vToken");
    navigation("/");
  }, [cookies, navigation, setUser]);

  useEffect(() => {
    if (runEffect.current) {
      (async () => {
        const token = cookies?.get("vToken");
        try {
          // @persist logged in user state
          // @desc: decode token to get userId
          const userProfile = await fetchUserProfile(token);

          if (userProfile && props.route === "Guest") {
            navigation("/home");
          }
          if (!userProfile && props.route === "Auth") {
            navigation("/");
          }
          if (
            !userProfile?.adminForPlace?.isContractSigned &&
            userProfile?.role?.label === ROLES.PLACE_ADMIN
          ) {
            setContractSigned(false);
            setPlaceAdmin(userProfile);
            setAuthPageLoading(false);
          } else {
            setUser(userProfile);
          }
        } catch (error) {
          logout();
        }
      })();
    }
    runEffect.current = false;
  }, [
    cookies,
    logout,
    navigation,
    props,
    props.route,
    setAuthPageLoading,
    setUser
  ]);

  return authPageLoading ? (
    <div className="d-flex vh-100 w-100 align-items-center justify-content-center">
      <CSpinner color="success" />
    </div>
  ) : contractSigned ? (
    <RestrictedRoute route={user?.role?.label}>
      {props.children}
    </RestrictedRoute>
  ) : (
    <Contract user={placeAdmin} />
  );
}

export default withCookies(ProtectedRoute);
