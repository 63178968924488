import * as yup from "yup";

export const placeAdminEditSchema = yup.object({
  name: yup.string().required(),
  picture: yup.mixed().required("Ce champ est obligatoire"),
  logo: yup.mixed().required("Ce champ est obligatoire"),
  address: yup.string().required("Ce champ est obligatoire"),
  phone: yup.string().required("Ce champ est obligatoire"),
  // description: yup.string().notRequired(),
  googleMapUrl: yup.string().nullable(),
  email: yup
    .string()
    .email("Veuillez saisir une adresse e-mail valide")
    .required("Ce champ est obligatoire"),
  website: yup
    .string()
    .url("Veuillez saisir une adresse URL valide")
    .nullable("Ce champ est obligatoire"),
  facebookURL: yup
    .string()
    .url("Veuillez saisir une adresse URL valide")
    .nullable(),
  instagramURL: yup
    .string()
    .url("Veuillez saisir une adresse URL valide")
    .nullable(),
  hasParking: yup.string().required("Ce champ est obligatoire"),
  priceRangeMin: yup
    .number()
    .nullable()
    .typeError("Valeur invalide")
    .transform((_, val) => (val ? Number(val) : null)),
  priceRangeMax: yup
    .number()
    .nullable()
    .typeError("Valeur invalide")
    .transform((_, val) => (val ? Number(val) : null)),
  paymentMethods: yup.string().required("Ce champ est obligatoire"),
  keywords: yup.string().required("Ce champ est obligatoire"),
  categoryId: yup
    .object()
    .required("Ce champ est obligatoire")
    .typeError("Ce champ est obligatoire"),
  cityId: yup
    .object()
    .required("Ce champ est obligatoire")
    .typeError("Ce champ est obligatoire"),
  servicesSectionTitle: yup.string().required("Ce champ est obligatoire"),
  mainType: yup.string().nullable(),
  secondaryType: yup.string().nullable(),
  speciality: yup.string().nullable(),
  chefName: yup.string().nullable(),
  videoIds: yup.string().nullable(),
  // company fields
  companyName: yup.string().nullable(),
  companyAddress: yup.string().nullable(),
  IF: yup.string().nullable(),
  IC: yup.string().nullable(),
  rib: yup.string().nullable(),
  hoursStep: yup
    .number()
    .integer()
    .positive()
    .required("Ce champ est obligatoire")
    .typeError("Ce champ est obligatoire"),
  // end company fields
  averagePrice: yup
    .number()
    .nullable()
    .typeError("Valeur invalide")
    .transform((_, val) => (val ? Number(val) : null))
});

export type PlaceAdminEditFormField = yup.InferType<
  typeof placeAdminEditSchema
>;
