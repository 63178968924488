import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../useCookie";
import { useToast } from "../useToast";
import { general } from "../../locales/general";

export const usePartnersQuery = () => {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();

  const { data, isLoading } = useQuery(["getAllPartners"], (query) => {
    return apiService.MakeGetRequest("partners");
  });

  const deletePartner = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`partners/${id}`, token);
  });
  return {
    Partners: data,
    fetchingPartners: isLoading,
    deletePartner(id: string) {
      deletePartner.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.partnerDeleted);
          queryClient.invalidateQueries("getAllPartners");
        },
      });
    },
    mutating: deletePartner.isLoading,
  };
};
