import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";

function TableBodyRowContent({ whatsappMessage }: { whatsappMessage: any }) {
  return (
    <CTableRow
      style={{
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 ">
        <span className="ms-2 text-sm">{whatsappMessage?.title}</span>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {new Date(whatsappMessage.date).toLocaleDateString("fr", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric"
        })}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        status
      </CTableDataCell>
    </CTableRow>
  );
}

interface TableProps {
  whatsappMessages: any[];
}
export default function WhatsappMessagesTable({
  whatsappMessages
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Message</CTableHeaderCell>
            <CTableHeaderCell scope="col">Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Statut</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {whatsappMessages?.map(whatsappMessage => (
            <TableBodyRowContent
              whatsappMessage={whatsappMessage}
              key={whatsappMessage?.id}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}