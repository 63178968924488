import { EyeSlashIcon } from "@heroicons/react/24/outline";
import { EyeIcon } from "@heroicons/react/24/outline";
import React, { InputHTMLAttributes, useState } from "react";

interface Props {
  labelText?: string;
  isRequired?: boolean;
  showAsteriks?: boolean;
}
export default function PasswordInput({
  labelText,
  onChange,
  onBlur,
  isRequired,
  showAsteriks = true,
  ...rest
}: Props & InputHTMLAttributes<HTMLInputElement>) {
  const [isPassword, setIsPassword] = useState(true);

  return (
    <div className="position-relative">
      {labelText && (
        <label htmlFor="password">
          {labelText}{" "}
          {isRequired && showAsteriks && (
            <span className="text-sm text-red"> *</span>
          )}
        </label>
      )}
      <input
        type={isPassword ? "password" : "text"}
        onChange={e => onChange && onChange(e)}
        onBlur={e => onBlur && onBlur(e)}
        required={isRequired}
        {...rest}
        id="password"
      />
      <button
        type="button"
        className="btn position-absolute"
        style={{ right: 0, top: "28px" }}
        onClick={e => {
          e.stopPropagation();
          setIsPassword(state => !state);
        }}
      >
        {isPassword ? (
          <EyeIcon className="w-5" />
        ) : (
          <EyeSlashIcon className="w-5" />
        )}
      </button>
    </div>
  );
}
