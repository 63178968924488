import { CSpinner } from "@coreui/react";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { FormEvent } from "react";
import BoxWrapper from "../../components/BoxWrapper";
import apiService from "../../service/apiService";
import { general } from "../../locales/general";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import RestrictedRoute from "../auth/RestrictedRoute";

interface NewCategoryData {
  label: string;
}

interface Props {
  onClose: () => void;
  onAccept: () => void;
}

export default function NewCategory({ onClose }: Props) {
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();

  // @desc create new user
  const createNewCategoryMutation = useMutation(async (data: NewCategoryData) => {
    return await apiService.MakePostRequest("service-categories", data, token);
  });

  // @desc form submit handler
  const createCategoryHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const label = formData.get("label") as string;

    const data = {
      label
    };
    createNewCategoryMutation.mutate(data, {
      onSuccess(data: any) {
        toastSuccess(general.fr.message.categoryCreated);
        queryClient.invalidateQueries("getAllServiceCategories");

        onClose();
      },
      onError(error: any) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      }
    });
  };

  return (
    <RestrictedRoute>
      <div className=" mt-4 ">
        <BoxWrapper className="w-100 bg-white rounded-lg rounded-bottom mb-4">
          <section className="px-4">
            <div className="border-bottom border-success mb-3">
              <h4>Ajouter une catégories</h4>
            </div>
            <form className="row form " onSubmit={createCategoryHandler}>
              <div className="col-12 mb-3">
                <label htmlFor="label" className="d-block">
                  Nom de la catégorie
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Nom de la catégorie"
                  id="label"
                  name="label"
                  required
                />
              </div>

              <section className="d-flex justify-content-start buttons gap-4 mt-3">
                <button
                  className="btn btn-danger text-white shadow-primary w-20 px-4 py-2"
                  type="button"
                  onClick={onClose}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-success shadow-secondary w-20 text-white"
                  type="submit"
                  disabled={createNewCategoryMutation.isLoading}
                >
                  {createNewCategoryMutation.isLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </RestrictedRoute>
  );
}
