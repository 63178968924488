import {  CSpinner } from "@coreui/react";
import { useMutation, useQueryClient } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  monthYear: yup
    .date()
    .typeError("Ce champ est requis")
    .required("Ce champ est requis")
});

interface FormData {
  monthYear: Date;
}

interface IProps {
  placeId: string;
  onClose: () => void;
  onAccept: () => void;
  onError: (error: any) => void;
}

export default function NewInvoice({ placeId, onClose, onError }: IProps) {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const createNewInvoiceMutation = useMutation(
    async (data: { month: number; year: number }) => {
      return apiService.MakePostRequest(
        `places/${placeId}/invoice`,
        data,
        token
      );
    }
  );

  const onSubmit = (data: FormData) => {
    const year = data.monthYear?.getFullYear();
    const month = data.monthYear?.getMonth() + 1;
    createNewInvoiceMutation.mutate(
      { year, month },
      {
        onError(error: any) {
          onError(error);
        },
        onSuccess() {
          queryClient.invalidateQueries(["getPlaceInvoices"]);
          onClose();
        }
      }
    );
  };

  return (
    <div className="">
      <section className="p-4">
        <div className="border-bottom border-success mb-3">
          <h4>Générer une facture</h4>
        </div>
        <form className="row form " onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 mb-3">
            <label htmlFor="monthYear" className="d-block">
              Mois - Année
            </label>
            <input
              type="month"
              className="custom-input"
              id="monthYear"
              {...register("monthYear")}
            />
            {errors.monthYear?.message && (
              <div
                className="text-red-500 text-opacity-50"
                style={{ color: "red" }}
              >
                {errors.monthYear.message}
              </div>
            )}
          </div>

          <section className="d-flex justify-content-start buttons gap-4 mt-3">
            <button
              className="btn btn-danger text-white shadow-primary w-20 px-4 py-2"
              type="button"
              onClick={onClose}
            >
              Annuler
            </button>
            <button
              className="btn btn-success shadow-secondary w-20 text-white"
              type="submit"
              disabled={createNewInvoiceMutation.isLoading}
            >
              {createNewInvoiceMutation.isLoading ? (
                <div className="text-center">
                  <CSpinner size="sm" />
                </div>
              ) : (
                "Enregistrer"
              )}
            </button>
          </section>
        </form>
      </section>
    </div>
  );
}
