import React, { Fragment, useEffect, useState } from "react";
import { FormSelectAPI } from "../../../components/FormReactSelect";
import { useAuth } from "../../../hooks/useAuth";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";

interface Props {
  target: "place" | "offer" | "event";
  onChange: (value: string) => string;
}
export default function NotificationTargets({ target, onChange }: Props) {
  const { isAdmin } = useAuth();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const offerEndpoint = !isAdmin ? "specials/ours" : "specials/all";

  const inputDetails = {
    place: {
      placeholder: "sélectionnez un établissement",
      url: "places",
      labelKey: "name"
    },
    event: {
      placeholder: "sélectionnez un événement",
      url: "events/ours",
      labelKey: "name"
    },
    offer: {
      placeholder: "sélectionnez un offre",
      url: offerEndpoint,
      labelKey: "title"
    }
  }[target || "place"];

  useEffect(() => {
    setSelectedItem(null);
    onChange("");
  }, [target]);

  return (
    <Fragment>
      <InfiniteScrollSelect
        isClearable={false}
        getOptionLabel={(option: any) => option[inputDetails.labelKey]}
        getOptionValue={(option: any) => option.id}
        isOptionSelected={(option: any, selectedValue: any[]) => {
          const isSelected = option.id === selectedValue?.[0]?.id;
          return isSelected;
        }}
        url={{
          path: inputDetails.url
        }}
        value={selectedItem}
        placeholder={inputDetails.placeholder}
        onChange={(item: any) => {
          setSelectedItem(item);
          onChange(item?.id);
        }}
      />
    </Fragment>
  );
}
