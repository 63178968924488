import React, {
  ChangeEvent,
  InputHTMLAttributes,
  forwardRef,
  useState
} from "react";

interface FileUploaderProps extends InputHTMLAttributes<HTMLInputElement> {
  file?: string;
}
const FileUploader = forwardRef(function Uploader(
  props: FileUploaderProps,
  ref
) {
  const [editMode, setEditMode] = useState(false);
  return (
    <div className="border rounded-sm px-2 py-1 bg-white">
      {props.file && props.file?.length > 0 && !editMode ? (
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <div>
            <img src={props.file} alt="" className="w-5 h-5 rounded-sm me-1" />
            <a
              href={props.file}
              target="_blank"
              className="font-semibold text-sm text-dark"
              rel="noreferrer"
            >
              Téléchargé
            </a>
          </div>
          <button
            className="btn btn-sm  text-danger"
            type="button"
            onClick={() => setEditMode(true)}
          >
            Modifier
          </button>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-between">
          {/* @ts-ignore */}
          <input type="file" {...props} ref={ref} />
          {props.file && props.file?.length > 0 && (
            <button
              className="btn btn-sm  text-danger"
              type="button"
              onClick={() => setEditMode(false)}
            >
              Annulez
            </button>
          )}
        </div>
      )}
    </div>
  );
});

export default FileUploader;
