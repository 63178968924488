import { IconTypes } from "./types";

export default function PlusIcon({
  width = "16",
  height = "20",
  fill = "#FC376B",
  ...rest
}: Partial<IconTypes>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 95 95"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95 36.8H58.2V0H36.8V36.8H0V58.2H36.8V95H58.2V58.2H95V36.8Z"
        fill={fill}
      />
    </svg>
  );
}
