import {
  CFormCheck,
  CFormInput,
  CModal,
  CModalBody,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { createRef, useEffect, useState } from "react";
import EditIcon from "../../components/icons/EditIcon";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { ArrowTopRightOnSquareIcon, EyeIcon } from "@heroicons/react/24/solid";
import { useQueryClient, useMutation } from "react-query";
import { DELETE_ACTIONS } from "../../config/constants";
import { useCookie } from "../../hooks/useCookie";
import { general } from "../../locales/general";
import { useToast } from "../../hooks/useToast";

import apiService from "../../service/apiService";
import PromptModal from "../../components/modals/PromptModal";
import { Link } from "react-router-dom";
import TranslateIcon from "../../components/icons/TranslateIcon";
import { useAuth } from "../../hooks/useAuth";

interface newCategoryModalProps {
  open: boolean;
  onClose: () => void;
  label?: string;
  image?: File | string;
  id?: string;
}
interface updateCategoryProp {
  label: string;
  picture?: File;
}
const CategoryModal = ({
  open,
  onClose,
  label = "",
  image = "",
  id = ""
}: newCategoryModalProps) => {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const [categoryName, setCategoryName] = useState(label);
  const queryClient = useQueryClient();
  const pictureRef = createRef<HTMLInputElement>();

  const updateCategory = useMutation((data: updateCategoryProp) => {
    return apiService.MakePutRequest(`categories/${id}`, data, token, true);
  });

  const updateCategoryHandler = () => {
    updateCategory.mutate(
      {
        label: categoryName,
        picture: pictureRef?.current?.files?.[0]! || " "
      },

      {
        onError() {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          queryClient.invalidateQueries("getCategory");
          toastSuccess(general.fr.message.categoryUpdated);
          onClose();
          setCategoryName("");
        }
      }
    );
  };
  return (
    <CModal visible={open} onClose={onClose} alignment="center">
      <CModalBody>
        <h5 className="fs-5 mb-4 border-bottom pb-1">
          Mise à jour de la catégorie
        </h5>
        <div className="mb-4">
          <label htmlFor="name" className="text-sm">
            Nom de la catégorie
          </label>
          <input
            type="text"
            className="custom-input w-100 mt-1"
            name="label"
            placeholder="Nom de la catégorie"
            value={categoryName}
            onChange={e => setCategoryName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="picture" className="text-sm">
            Image de catégorie
          </label>

          <CFormInput
            type="file"
            id="formFile"
            className="mt-1"
            ref={pictureRef}
          />

          <div className="w-fit my-5">
            <img src={image as string} alt="" className="w-full" />
          </div>
        </div>

        <button
          type="button"
          onClick={updateCategoryHandler}
          disabled={updateCategory.isLoading}
          className="d-flex align-items-center justify-content-center btn btn-danger shadow-primary d-block w-100 mt-2 p-2 fw-bold text-white"
        >
          {updateCategory.isLoading ? (
            <>
              <CSpinner size="sm" /> {general.fr.wait}
            </>
          ) : (
            "Sauvegarder"
          )}
        </button>
      </CModalBody>
    </CModal>
  );
};

function TableBodyRowContent({
  category,
  deletedList
}: {
  category: Category;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const {isAdmin} = useAuth();

  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();

  const queryClient = useQueryClient();

  const deleteCategory = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`categories/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: category.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: category.id });
    }
  }, [deletedList, isChecked, category.id]);

  const removeCategory = () => {
    deleteCategory.mutate(category.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.categoryDeleted);
        queryClient.invalidateQueries("getAllCategories");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 text-capitalize text-sm d-flex align-items-center gap-2">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
        />
        {category.label}
      </CTableDataCell>

      <CTableDataCell>
        <button
          onClick={() => setShowEditModal(true)}
          className="outline-none border-0 bg-transparent"
        >
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </button>
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
        >
          {deleteCategory.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>
        {isAdmin && (
          <Link
            to={"/category-translations/new"}
            state={{ category: category }}
            className="ms-4"
          >
            <TranslateIcon width="21" height="21" fill={"#000"} />
          </Link>
        )}
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={removeCategory}
        onClose={() => setShowModal(false)}
      />
      <CategoryModal
        onClose={() => setShowEditModal(false)}
        open={showEditModal}
        id={category?.id}
        label={category?.label}
        image={category?.image}
      />
    </CTableRow>
  );
}

interface TableProps {
  categories: Category[];
  categoriesToBeDeletedList: React.Dispatch<BulkDeleteActions>;
}

export default function CategoriesTable({
  categories,
  categoriesToBeDeletedList
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="w-100">
              Nom de la catégorie
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="w-100"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {categories?.map(category => (
            <TableBodyRowContent
              category={category}
              key={category?.id}
              deletedList={categoriesToBeDeletedList}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
