import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import DeleteIcon from "../../../components/icons/DeleteIcon";

export interface DisplayUser {
  id: string;
  displayValue: string;
  profilePicture?: string;
  email?: string;
}

function TableBodyRowContent({
  user,
  deleteUser,
  showDeleteBtn = true
}: {
  user: DisplayUser;
  deleteUser: () => void;
  showDeleteBtn: boolean;
}) {
  return (
    <CTableRow
      style={{
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="px-3 text-sm">
        <div className="d-flex align-items-center gap-3">
          <img
            src={
              user.profilePicture ??
              `https://ui-avatars.com/api/?name=${user?.displayValue}`
            }
            alt=""
            width={30}
            height={30}
            style={{ borderRadius: "100%" }}
          />
          <div>
            <p className="fw-bold m-0">{user?.displayValue}</p>
            <p className="m-0">{user.email}</p>
          </div>
        </div>
      </CTableDataCell>
      <CTableDataCell className="px-3 text-sm">
        {showDeleteBtn === true ? (
          <button
            type="button"
            className="btn text-red"
            onClick={() => deleteUser()}
          >
            <DeleteIcon width="20" height="20" />
          </button>
        ) : null}
      </CTableDataCell>
    </CTableRow>
  );
}

interface TableProps {
  users: DisplayUser[];
  deleteUser: (userId: string) => void;
  showDeleteBtn: boolean;
}

export default function UsersDisplayTable({
  users,
  deleteUser,
  showDeleteBtn = true
}: TableProps) {
  return (
    <div className="">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {users?.map(user => (
            <TableBodyRowContent
              deleteUser={() => deleteUser(user.id)}
              key={user.id}
              user={user}
              showDeleteBtn={showDeleteBtn}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
