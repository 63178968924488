import { CSpinner } from "@coreui/react";
import React, { FormEvent, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import apiService from "../../../service/apiService";
import { updatePlaceProp } from "../UpdatePlace";
import VideoInput from "./VideoInput";
import { TrashIcon } from "@heroicons/react/24/solid";

interface Props {
  place?: Place;
}

function createApiFields(place: Place) {
  return {
    name: place?.name,
    categoryId: place?.category?.id,
    cityId: place?.city?.id,
    hasParking: place?.hasParking,
    priceRange: place?.priceRange,
    paymentMethods: place?.paymentMethods,
    mainType: place?.mainType,
    secondaryType: place?.secondaryType,
    speciality: place?.speciality,
    chefName: place?.chefName,
    averagePrice: place?.averagePrice,
    keywords: place?.keywords,
    phone: place?.phone,
    email: place?.email,
    website: place?.website,
    facebookURL: place?.facebookURL,
    instagramURL: place?.instagramURL,
    address: place?.address,
    slug: place?.slug,
    latitude: place?.latitude,
    longitude: place?.longitude,
    reductionPercent: place?.reductionPercent,
    servicesSectionTitle: place?.servicesSectionTitle,
    googleMapUrl: place?.googleMapUrl,
    supportTableSelection: place?.supportTableSelection
  };
}

export default function YoutubeVideoSection({ place }: Props) {
  const [videos, setVideos] = useState<JSX.Element[] | undefined>([]);
  const { toastError, toastSuccess } = useToast();
  const { token } = useCookie("vToken");
  const params = useParams();

  const updatePlace = useMutation((data: updatePlaceProp) => {
    return apiService.MakePutRequest(
      `places/${params.placeId}`,
      data,
      token,
      true
    );
  });

  const deleteVideo = useMutation((videoId: string) => {
    return apiService.MakeDeleteRequest(
      `places/${params.placeId}/ytbvideo/${videoId}`,
      token
    );
  });

  useEffect(() => {
    const hasVideos =
      place?.videoIds?.length && place?.videoIds.every(video => video.length);
    if (hasVideos) {
      setVideos(() =>
        place?.videoIds?.map((videoId, index) => (
          <VideoInput
            key={videoId}
            defaultValue={videoId}
            saved={true}
            onRemove={() => onRemove(index, true)}
          />
        ))
      );
    }
  }, [place]);

  const onRemove = async (index: number, saved: boolean) => {
    // const updatedVideo = videos?.filter((_, i) => i !== index)
    if (!saved) {
      setVideos(videos => videos?.filter((_, i) => i !== index));
    } else {
      // delete video from backend
      await deleteVideo.mutateAsync(place?.videoIds![index] ?? "");
      setVideos(videos => videos?.filter((_, i) => i !== index));
    }
  };

  const addNewInput = () =>
    setVideos(videos => {
      if (videos && videos.length > 0) {
        return [
          ...videos,
          <VideoInput
            key={videos?.length}
            defaultValue=""
            saved={false}
            onRemove={() => onRemove(videos?.length, false)}
          />
        ];
      } else {
        return [
          <VideoInput
            key={0}
            defaultValue=""
            saved={false}
            onRemove={() => onRemove(0, false)}
          />
        ];
      }
    });

  const saveVideoIdsHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    var youtubeVideoRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    const videoUrls = new FormData(e.currentTarget).getAll("ytVid") as string[];

    const videoIds = videoUrls?.map(url => {
      const match = url.match(youtubeVideoRegex);
      if (match && match[1]) {
        return match[1];
      }
    });
    const data = createApiFields(place!);
    updatePlace.mutate(
      // @ts-ignore
      { ...data, videoIds },
      {
        onSuccess() {
          toastSuccess("Vidéos mises à jour avec succès");
          window.location.reload();
        },
        onError(error) {
          toastError("Échec de l'opération, réessayer");
        }
      }
    );
  };
  return (
    <div>
      <h5 className="fs-4 fw-bold my-2">Youtube video</h5>
      <BoxWrapper>
        <form onSubmit={saveVideoIdsHandler}>
          <div className="p-3 gap-5 d-flex  align-items-center  flex-wrap">
            {videos?.map((video, ind) =>
              React.cloneElement(video, {
                onRemove: () => onRemove(ind, video.props.saved)
              })
            )}

            {/* ?.map((video, ind) => {
              video.props = {
                ...video.props,
                onRemove: onRemove(ind, video.props.saved)
              };
              return video;
            }) */}
          </div>
          <div className="d-flex justify-content-center my-2 pb-2 gap-2">
            <button
              className="btn btn-primary"
              onClick={addNewInput}
              type="button"
            >
              + Ajoutez
            </button>
            <button className="btn btn-danger" disabled={!videos}>
              {updatePlace.isLoading ? <CSpinner size="sm" /> : "Enregistrer"}
            </button>
          </div>
        </form>
      </BoxWrapper>
    </div>
  );
}
