import { CSpinner } from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import BoxWrapper from "../../components/BoxWrapper";
import { useCookie } from "../../hooks/useCookie";
import { usePromoCode } from "../../hooks/usePromoCode";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import { createPromoCodeSchema } from "../../rules/promo";
import apiService from "../../service/apiService";
import CreatePromoCode from "./CreatePromoCode";

const keys = [
  "name",
  "discount",
  "maxUses",
  "description",
  "event",
  "isActive"
] as const;

export default function UpdatePromoCode() {
  const params = useParams();
  const { token } = useCookie("vToken");
  const {
    control: formControl,
    handleSubmit,
    watch,
    setValue,
    setError
  } = useForm<yup.InferType<typeof createPromoCodeSchema>>({
    resolver: yupResolver(createPromoCodeSchema)
  });

  const navigate = useNavigate();
  const { toastError } = useToast();

  const { data } = useQuery({
    queryKey: ["getPromoCode", params.id],
    queryFn: () => apiService.MakeGetRequest(`promo-codes/${params.id}`, token)
  });

  const { isLoading, updatePromoCode } = usePromoCode();

  const selectedEvent = watch("event");
  const maxUsage = watch("maxUses");

  useEffect(() => {
    if (data) {
      keys.forEach(key => setValue(key, data[key]));
    }

    setValue(
      "items",
      data?.services?.map((item: any) => ({
        serviceId: item?.service?.id,
        maxUses: item?.maxUses
      }))
    );
  }, [data]);

  const onSubmit = async (data: any) => {
    try {
      if (!params?.id) {
        return toastError("Code promo invalide");
      }
      const { event, ...rest } = data;
      const payload = {
        ...rest,
        items: rest.items.map((item: any) => ({
          serviceId: item.serviceId,
          maxUses: item.maxUses
        })),
        eventId: event?.id
      };

      const totalServiceUsage =
        payload.items?.reduce(
          (sum: number, item: { maxUses: string }) =>
            sum + (parseInt(item.maxUses) || 0),
          0
        ) || 0;

      if (totalServiceUsage > payload.maxUses) {
        return setError(`items.${payload.items.length - 1}.maxUses`, {
          message: `L'utilisation totale des services (${totalServiceUsage}) dépasse l'utilisation maximale autorisée (${payload.maxUses})`
        });
      }
      await updatePromoCode(params.id, payload);
      navigate(-1);
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div className="px-4">
      <h5 className="fs-4 fw-bold mb-2">Mettre à jour le code promo</h5>
      <BoxWrapper className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <CreatePromoCode
            control={formControl}
            selectedEvent={selectedEvent}
            maxUsage={maxUsage}
            defaultValues={data as PromoCode}
          />
          <section className="d-flex justify-content-center buttons gap-4 mt-4">
            <button
              type="button"
              disabled={isLoading}
              onClick={() => navigate(-1)}
              className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
            >
              Annuler
            </button>
            <button
              className="btn btn-success green-shadow w-20 text-white"
              type="submit"
            >
              {isLoading ? (
                <div className="text-center">
                  <CSpinner size="sm" />
                </div>
              ) : (
                "Enregistrer"
              )}
            </button>
          </section>
        </form>
      </BoxWrapper>
    </div>
  );
}
