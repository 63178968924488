import { IconTypes } from "./types";

export default function VisibleSvg({ className = ` ` }: Partial<IconTypes>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M14.9459 10.9944C16.6287 10.9944 17.9929 9.6302 17.9929 7.94739C17.9929 6.26458 16.6287 4.90039 14.9459 4.90039C13.2631 4.90039 11.8989 6.26458 11.8989 7.94739C11.8989 9.6302 13.2631 10.9944 14.9459 10.9944Z" />
      <path d="M0 7.90844C0 7.90844 13.425 25.9834 29.893 7.90844C13.425 -9.88556 0 7.90844 0 7.90844ZM15.409 13.9134C15.207 13.9374 15.002 13.9534 14.794 13.9534C14.023 13.9534 13.289 13.7794 12.623 13.4704C10.469 12.5634 8.956 10.4324 8.956 7.94844C8.956 4.63944 11.637 1.95844 14.945 1.95844C18.253 1.95844 20.935 4.64044 20.935 7.94844C20.936 11.0994 18.501 13.6764 15.409 13.9134Z" />
    </svg>
  );
}
