import React from "react";
import AlertIcon from "../icons/AlertIcon";
import { CModal } from "@coreui/react";
import { general } from "../../locales/general";

interface Props {
  onClose: () => void;
  onAccept: () => void;
  message?: string;
  open: boolean;
}
export default function PromptModal({
  onClose,
  onAccept,
  open,
  message = general.fr.delete,
}: Props) {
  return (
    <CModal alignment="center" visible={open}>
      <div className="d-flex flex-column justify-content-center align-items-center py-3">
        <AlertIcon />
        <p className="mt-3 mb-4 fw-bold fs-6 w-75 text-center">{message}</p>
        <div className="d-flex align-items-center gap-4">
          <button
            onClick={onAccept}
            className="btn btn-danger text-white text-uppercase shadow-primary"
            style={{ width: "140px" }}
          >
            OUI
          </button>
          <button
            onClick={onClose}
            className="btn btn-primary text-white text-uppercase shadow-secondary"
            style={{ width: "140px" }}
          >
            NON
          </button>
        </div>
      </div>
    </CModal>
  );
}
