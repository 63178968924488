import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface QueryProps {
  [key: string]: string;
}
export function useQueryParam() {
  const { pathname, search } = useLocation();
  const router = useNavigate();

  const removeQueryString = useCallback(
    (name: string) => {
      const params = new URLSearchParams(search.toString());
      params.delete(name);
      return params.toString();
    },
    [search]
  );

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(search.toString());
      params.set(name, value);

      return params.toString();
    },
    [search]
  );

  const addMultiple = (queryParams: QueryProps) => {
    const params = new URLSearchParams(search.toString());
    for (const key in queryParams) {
      if (queryParams[key]) {
        params.set(key, queryParams[key]);
      }
    }
    const path = pathname + "?" + params.toString();
    router(path);
  };

  const remove = (key: string) => {
    if (!search) return;

    const path = pathname + "?" + removeQueryString(key);
    router(path);
  };

  const add = (key: string, value: string) => {
    if (!value || value === "") return remove(key);
    const path = pathname + "?" + createQueryString(key, value);
    router(path);
  };

  return { add, addMultiple, remove };
}
