// @ts-nocheck
import React, { useEffect } from "react";
import { CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import { useAuth } from "../../../hooks/useAuth";
import { useState } from "react";
import { useCookie } from "../../../hooks/useCookie";
import { general } from "../../../locales/general";
import { useToast } from "../../../hooks/useToast";
import { useMutation } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode } from "../../../helpers/enums";
import { useForm, Controller } from "react-hook-form";
import apiService from "../../../service/apiService";
import RichTextEditor from "../../../components/RichTextEditor";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";

interface CategoryTranslationData {
    label: string;
    lang: string;
    categoryId: string;
}

export default function NewCategoryTranslation({ mode = FormMode.CREATE }) {
    const [formLoading, setFormLoading] = useState(false);
    const { isAdmin } = useAuth();
    const { token } = useCookie("vToken");
    const { toastError, toastSuccess } = useToast();
    const params = useParams();
    const navigate = useNavigate();
    const [description, setDescription] = useState("");
    const location = useLocation();
    const { category } = location.state || {};

    const schema = yup.object({
        label: yup.string().required(),
        lang: yup.string().required(),
        categoryId: yup.object().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control: formControl,
        setValue: setFormValue,
        getValues: getFormValue
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });

    useEffect(() => {
        setFormValue("categoryId", category);
    }, [category?.id]);

    useEffect(() => {
        if (mode === FormMode.UPDATE && params?.id) {
            const formFields = Object.keys(schema.fields);
            const categoryGetRequest = apiService.MakeGetRequest(
                `category-translations/${params.id}`,
                token
            );

            Promise.all([categoryGetRequest]).then(
                ([categoryResp]) => {
                    setDescription(categoryResp?.description);
                    // setFormValue("keywords", categoryResp.keywords?.join(","));
                    // setFormValue("paymentMethods", categoryResp.paymentMethods?.join(","));

                    setFormValue("categoryId", categoryResp.category);
                    
                    Object.entries(categoryResp).forEach(entry => {
                        if (formFields.indexOf(entry[0]) !== -1) {
                            if (
                                entry[0] === "keywords"
                            ) {
                                setFormValue(entry[0], entry[1]?.join(","));
                            } else {
                                setFormValue(entry[0], entry[1]);
                            }
                        }
                    });
                }
            );
        }
    }, [params?.id]);

    const createCategoryTranslationTranslationMutation = useMutation(async categoryTranslationData => {
        return await apiService.MakePostRequest("category-translations", categoryTranslationData, token);
    });

    const updateCategoryTranslationMutation = useMutation(async categoryTranslationData => {
        return await apiService.MakePutRequest(
            `category-translations/${params.id}`,
            categoryTranslationData,
            token
        );
    });

    const createCategoryTranslation = async data => {
        try {
            setFormLoading(true);
            await createCategoryTranslationTranslationMutation.mutateAsync(data);
            toastSuccess(general.fr.message.translationCreated);
            navigate(-1);
        } catch (error) {
            setFormLoading(false);
            toastError(general.fr.message.operationFailed);
        }
    };

    const updateCategoryTranslation = async data => {
        try {
            setFormLoading(true);
            const updateCategoryTranslationReq = updateCategoryTranslationMutation.mutateAsync(data);

            if (isAdmin) {
                await Promise.all([updateCategoryTranslationReq]);
                toastSuccess(general.fr.message.translationUpdated);
                navigate(-1);
            } else {
                await updateCategoryTranslationReq;
                toastSuccess(general.fr.message.translationUpdated);
                navigate(-1);
            }
        } catch (error) {
            setFormLoading(false);
            toastError(general.fr.message.operationFailed);
        }
    };

    const onSubmit = async (dataArg: CategoryTranslationData) => {
        const data = { ...dataArg };
        try {
            data.categoryId = data.categoryId.id;
            if (mode === FormMode.UPDATE) {
                updateCategoryTranslation({ ...data, description });
            } else {
                createCategoryTranslation({ ...data, description });
            }
        } catch (error) {
            setFormLoading(false);
            console.log(error);
            toastError(general.fr.message.operationFailed);
        }
    };

    return (
        <div className=" m-4  ">
            <BoxWrapper>
                <section className="p-4">
                    <div className="border-bottom border-success">
                        <h4>
                            {mode === FormMode.UPDATE
                                ? "Mise à jour"
                                : "Ajouter une traduction"}
                        </h4>
                    </div>
                    <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-6 my-3">
                            <label htmlFor="categoryId" className="d-block mb-2">
                                Catégorie <span className="text-md text-red">*</span>
                            </label>
                            <Controller
                                name="categoryId"
                                control={formControl}
                                render={({ field }) => {
                                    return (
                                        <InfiniteScrollSelect
                                            name="categoryId"
                                            id="categoryId"
                                            isClearable={false}
                                            defaultValue={category}
                                            error={errors?.categoryId?.message}
                                            getOptionLabel={option => option?.label}
                                            getOptionValue={option => option.id}
                                            isOptionSelected={(option, selectedValue) => {
                                                const isSelected =
                                                    option?.id === selectedValue?.[0]?.id;
                                                return isSelected;
                                            }}
                                            url={{
                                                path: "categories"
                                            }}
                                            {...field}
                                        />
                                    );
                                }}
                            />
                            {errors.categoryId?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.categoryId.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6  my-3">
                            <label htmlFor="lang" className="d-block">
                                La langue
                                <span className="text-sm text-red"> *</span>
                            </label>
                            <CFormSelect
                                name="lang"
                                id="lang"
                                {...register("lang")}
                            >
                                <option value="en">Anglais</option>
                                <option value="ar">Arabic</option>
                                <option value="fr">Français</option>
                            </CFormSelect>
                            {errors.lang?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.supportTableSelection.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6  my-3 ">
                            <label htmlFor="label" className="d-block">
                                Nom de la catégorie
                                <span className="text-sm text-red">*</span>
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                categoryholder="Titre de l'offre"
                                id="label"
                                name="label"
                                {...register("label")}
                            />
                            {errors.label?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.label.message}
                                </div>
                            )}
                        </div>

                        <section className="d-flex justify-content-center buttons gap-4 mt-5">
                            <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                            >
                                Annuler
                            </button>
                            <button
                                className="btn btn-success green-shadow w-20 text-white"
                                type="submit"
                                disabled={formLoading}
                            >
                                {formLoading ? (
                                    <div className="text-center">
                                        <CSpinner size="sm" />
                                    </div>
                                ) : (
                                    "Enregistrer"
                                )}
                            </button>
                        </section>
                    </form>
                </section>
            </BoxWrapper>
        </div>
    );
}
