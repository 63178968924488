import { IconTypes } from "./types";

export default function NotVisibleSvg({ className = ` ` }: Partial<IconTypes>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_390_47"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="64"
        height="64"
      >
        <path d="M64 0H0V64H64V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_390_47)">
        <path
          d="M60.898 59.495L58.745 61.648C58.354 62.038 57.721 62.038 57.331 61.648L2.31203 6.62903C1.92203 6.23903 1.92203 5.60603 2.31203 5.21503L4.46503 3.06203C4.85603 2.67203 5.48903 2.67203 5.87903 3.06203L60.898 58.081C61.288 58.471 61.288 59.104 60.898 59.495Z"
          // fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M51.309 44.0742C55.255 41.7602 59.13 37.9752 61.56 33.8172C62.147 32.8142 62.147 31.1842 61.56 30.1812C56.474 21.4782 45.056 14.4082 32 14.4082C28.429 14.4082 24.98 14.9372 22.71 15.6032C22.18 15.7582 22.067 16.2012 22.457 16.5912L25.667 19.8012C26.058 20.1922 26.777 20.3112 27.272 20.0682C28.073 19.6752 29.982 19.2062 32 19.2062C39.06 19.2062 44.793 24.9392 44.793 31.9992C44.793 34.0172 44.324 35.9262 43.931 36.7272C43.688 37.2222 43.807 37.9412 44.198 38.3322L49.739 43.8732C50.129 44.2632 50.832 44.3532 51.309 44.0742ZM43.753 47.6532C40.781 48.7552 36.487 49.5902 32 49.5902C18.944 49.5902 7.52601 42.5202 2.44001 33.8172C1.85301 32.8142 1.85301 31.1842 2.44001 30.1812C5.21601 25.4312 9.87701 21.1682 14.767 18.7232C15.261 18.4762 15.978 18.5922 16.368 18.9822L21.036 23.6502C21.426 24.0402 21.475 24.7162 21.145 25.1582C20.151 26.4902 19.207 29.1352 19.207 31.9992C19.207 39.0592 24.94 44.7922 32 44.7922C34.864 44.7922 37.509 43.8482 38.841 42.8542C39.283 42.5242 39.959 42.5732 40.349 42.9632L43.984 46.5982C44.374 46.9882 44.271 47.4612 43.753 47.6532Z"
          // fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.959 26.9642C22.376 27.8062 21.7 29.8242 21.7 31.9992C21.7 37.6842 26.315 42.2992 32 42.2992C34.175 42.2992 36.193 41.6232 37.035 41.0402C37.488 40.7262 37.54 40.1542 37.15 39.7642L24.235 26.8492C23.845 26.4592 23.273 26.5112 22.959 26.9642ZM29.1959 22.0352C29.4229 21.9472 30.683 21.6992 32 21.6992C37.685 21.6992 42.2999 26.3142 42.2999 31.9992C42.2999 33.3162 42.052 34.5762 41.964 34.8032C41.763 35.3172 41.284 35.4182 40.893 35.0272L28.972 23.1062C28.581 22.7152 28.6819 22.2362 29.1959 22.0352Z"
          // fill="black"
        />
      </g>
    </svg>
  );
}
