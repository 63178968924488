import { useState } from "react";
import {
  CModal,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import NotificationSentIcon from "../../components/icons/NotificationSentIcon";
import NotificationNotSentIcon from "../../components/icons/NotificationNotSentIcon";
import PendingIcon from "../../components/icons/PendingIcon";
import InfoIcon from "../../components/icons/InfoIcon";
import NotificationViewModel from "./NotificationViewModel";

export function NotificationStatus({ sent, date }: { sent: boolean; date: string }) {
  if (sent === true) {
    return (
      <div>
        <NotificationSentIcon className="fill-success" /> Envoyé
      </div>
    );
  }
  const now = new Date();
  const notificationDate = new Date(date);
  const diff = notificationDate.getTime() - now.getTime();
  if (diff > 0) {
    return (
      <div>
        <PendingIcon className="fill-danger" /> En attente
      </div>
    );
  }
  return (
    <div>
      <NotificationNotSentIcon className="fill-red" /> Pas envoyé
    </div>
  );
}

function TableBodyRowContent({
  notification,
  viewDetails
}: {
  notification: ScheduledNotification;
  viewDetails: () => void;
}) {
  return (
    <CTableRow
      style={{
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 ">
        <span className="ms-2 text-sm">{notification?.title}</span>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {new Date(notification.date).toLocaleDateString("fr", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric"
        })}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        <NotificationStatus
          sent={notification?.sent}
          date={notification.date}
        />
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => viewDetails()}
        >
          <InfoIcon className="fill-primary" />
        </button>
      </CTableDataCell>
    </CTableRow>
  );
}

interface TableProps {
  notifications: ScheduledNotification[];
}

export default function NotificationsTable({ notifications }: TableProps) {
  const [selectedNotification, setSelectedNotification] = useState<ScheduledNotification>();
  const [visible, setVisible] = useState(false);

  return (
    <div className="mt-3">
      <CModal visible={visible} onClose={() => setVisible(false)}>
        <NotificationViewModel
          notification={selectedNotification!}
          onClose={() => setVisible(false)}
        />
      </CModal>
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Titre</CTableHeaderCell>
            <CTableHeaderCell scope="col">Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Statut</CTableHeaderCell>
            <CTableHeaderCell scope="col">Voir les détails</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {notifications?.map(notification => (
            <TableBodyRowContent
              notification={notification}
              key={notification?.id}
              viewDetails={() => {
                setSelectedNotification(notification);
                setVisible(true);
              }}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
