// @ts-nocheck
import { useEffect, useMemo, useState } from "react";
import { CModal, CFormSelect } from "@coreui/react";
import DeleteIcon from "./icons/DeleteIcon";
import * as yup from "yup";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { format as dateFnsFormat, add as dateFnsAdd } from "date-fns";

type FitlerTypes = "text" | "number" | "date" | "enum";

export interface FieldConfig {
  field: string;
  type: FitlerTypes;
  label: string;
  operators?: string[];
  // options?: OperatorOption[];
}

export interface FiltersModelProps {
  open: boolean;
  onClose: () => void;
  configs: FieldConfig[];
  callback: (filters: any) => void;
}

interface FormattedConfigs {
  [key: string]: FieldConfig;
}

interface OperatorOption {
  value: string;
  label: string;
}

class Operator {
  private displayValue: string;
  private operator: string;
  private type: FitlerTypes;
  private options: OperatorOption[] = [];
  private operatorCallback: (value: any) => any;

  getDisplayValue() {
    return this.displayValue;
  }

  getOperator() {
    return this.operator;
  }

  getQueryValue(value: any) {
    return this.operatorCallback(value);
  }

  getValueInputField({ field: fieldProps }: any, operator: Operator) {
    if (
      operator?.getOperator() == "$null" ||
      operator?.getOperator() == "$notNull"
    ) {
      return (
        <input
          style={{ visibility: "hidden" }}
          className="custom-input"
          type="text"
          {...fieldProps}
          value="true"
        />
      );
    }
    switch (this.type) {
      case "text":
        return <input className="custom-input" type="text" {...fieldProps} />;
      case "number":
        return <input className="custom-input" type="number" {...fieldProps} />;
      case "date":
        if (operator?.getOperator() === "$between") {
          const onChange = fieldProps.onChange;
          const value = fieldProps.value;
          return (
            <>
              <input
                className="custom-input"
                type="date"
                value={value[0]}
                onChange={e => onChange([e.target.value, value[1]])}
              />
              <input
                className="custom-input"
                type="date"
                value={value[1]}
                onChange={e => onChange([value[0], e.target.value])}
              />
            </>
          );
        }
        return <input className="custom-input" type="date" {...fieldProps} />;
      case "enum":
        return (
          <select className="custom-input" {...fieldProps}>
            {this.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      default:
        return <input className="custom-input" type="text" {...fieldProps} />;
    }
  }

  static fromOperatorConfig(operatorConfig: any, fieldConfig: FieldConfig) {
    if (
      fieldConfig.type === "enum" &&
      (!fieldConfig.options || fieldConfig.options.length === 0)
    ) {
      throw new Error("enum field must have options");
    }
    const operator = new Operator();
    operator.displayValue = operatorConfig.displayValue;
    operator.operator = operatorConfig.operator;
    operator.type = fieldConfig.type;
    operator.options = operatorConfig.options;
    operator.operatorCallback = operatorConfig.callback;
    return operator;
  }
}

// const operatorsPerType = {
//   text: ["$eqi", "$containsi", "$startsWithi", "$endsWithi"],
//   number: ["$eq", "$gt", "$lt", "$gte", "$lte"],
//   date: ["$eq", "$gt", "$lt", "$gte", "$lte", "$between", "$null", "$notNull"],
//   enum: ["$eq"]
// };

const operators = {
  text: [
    {
      displayValue: "égal à",
      operator: "$eqi",
      callback: value => ({ $eq: value })
    },
    {
      displayValue: "contient",
      operator: "$containsi",
      callback: value => ({ $contains: value })
    },
    {
      displayValue: "commence par",
      operator: "$startsWithi",
      callback: value => ({ $starts_with: value })
    },
    {
      displayValue: "termine par",
      operator: "$endsWithi",
      callback: value => ({ $ends_with: value })
    }
  ],
  number: [
    {
      displayValue: "égal à",
      operator: "$eq",
      callback: value => ({ $eq: value })
    },
    {
      displayValue: "supérieur à",
      operator: "$gt",
      callback: value => ({ $gt: value })
    },
    {
      displayValue: "inférieur à",
      operator: "$lt",
      callback: value => ({ $lt: value })
    },
    {
      displayValue: "supérieur ou égal à",
      operator: "$gte",
      callback: value => ({ $gte: value })
    },
    {
      displayValue: "inférieur ou égal à",
      operator: "$lte",
      callback: value => ({ $lte: value })
    }
  ],
  date: [
    {
      displayValue: "égal à",
      operator: "$eq",
      callback: value => {
        console.log(value);
        try {
          const date = new Date(value);
          return {
            $between: [
              dateFnsFormat(date, "yyyy-MM-dd"),
              dateFnsFormat(dateFnsAdd(date, { days: 1 }), "yyyy-MM-dd")
            ]
          };
        } catch (error) {
          return [];
        }
      }
    },
    {
      displayValue: "supérieur à",
      operator: "$gt",
      callback: value => {
        return {
          $gte: dateFnsFormat(
            dateFnsAdd(new Date(value), { days: 1 }),
            "yyyy-MM-dd"
          )
        };
      }
    },
    {
      displayValue: "inférieur à",
      operator: "$lt",
      callback: value => ({ $lt: value })
    },
    {
      displayValue: "supérieur ou égal à",
      operator: "$gte",
      callback: value => ({ $gte: value })
    },
    {
      displayValue: "inférieur ou égal à",
      operator: "$lte",
      callback: value => {
        return {
          $lte: dateFnsFormat(
            dateFnsAdd(new Date(value), { days: 1 }),
            "yyyy-MM-dd"
          )
        };
      }
    },
    {
      displayValue: "entre",
      operator: "$between",
      callback: value => {
        const date1 = new Date(value[0]);
        const date2 = new Date(value[1]);
        return {
          $between: [
            dateFnsFormat(date1, "yyyy-MM-dd"),
            dateFnsFormat(dateFnsAdd(date2, { days: 1 }), "yyyy-MM-dd")
          ]
        };
      }
    },
    {
      displayValue: "est vide",
      operator: "$null",
      callback: value => ({ $null: true })
    },
    {
      displayValue: "n'est pas vide",
      operator: "$notNull",
      callback: value => ({ $notNull: true })
    }
  ],
  enum: [
    {
      displayValue: "égal à",
      operator: "$eq",
      callback: value => ({ $eq: value })
    }
    // { displayValue: "contient", operator: "$containsi", callback: (value) => ({"$contains": value}) },
  ]
};

interface FilterRowProps {
  config: FormattedConfigs;
  reactHooksProps: {
    formControl: any;
    filterIndex: number;
    removeFilter: () => void;
    registerField: () => {};
    registerOperator: () => {};
    registerValue: () => {};
    setValue: (name: string, value: any) => void;
    getValue: (name: string) => any;
  };
}

function FilterRow({ config, reactHooksProps }: FilterRowProps) {
  const [selectedField, setSelectedField] = useState(
    config[
      reactHooksProps.getValue(`filters.${reactHooksProps.filterIndex}.field`)
    ] ?? config[Object.keys(config)[0]]
  );
  const [selectedOperator, setSelectedOperator] = useState(
    selectedField.operators?.[0]
  );

  useEffect(() => {
    setSelectedField(
      config[
        reactHooksProps.getValue(`filters.${reactHooksProps.filterIndex}.field`)
      ] ?? config[Object.keys(config)[0]]
    );
    setSelectedOperator(
      (
        config[
          reactHooksProps.getValue(
            `filters.${reactHooksProps.filterIndex}.field`
          )
        ] ?? config[Object.keys(config)[0]]
      ).operators?.[0]
    );
  }, []);

  useEffect(() => {
    setSelectedOperator(selectedField.operators?.[0]);
    // reactHooksProps.setValue(
    //   `filters.${reactHooksProps.filterIndex}.value`,
    //   ""
    // );
  }, [selectedField]);

  return (
    <div className="col-12 mb-3">
      <div className="d-flex justify-content-center mb-2">
        <div className="me-1 mt-1">
          {/* delete filter input (not visible for first element) */}
          <button
            className="btn"
            onClick={() => reactHooksProps.removeFilter()}
          >
            <DeleteIcon />
          </button>
        </div>
        <div className="flex me-1">
          {/* select input for selecting the field */}
          <Controller
            control={reactHooksProps.formControl}
            name={`filters.${reactHooksProps.filterIndex}.field`}
            render={({ field }) => (
              <CFormSelect
                id=""
                {...field}
                onChange={e => {
                  setSelectedField(config[e.target.value]);
                  field.onChange(e);
                }}
              >
                {Object.keys(config).map(field => (
                  <option key={field} value={field}>
                    {config[field].label}
                  </option>
                ))}
              </CFormSelect>
            )}
          />
        </div>
        <div className="flex mx-1">
          {/* select input for selecting the operator */}
          <Controller
            control={reactHooksProps.formControl}
            name={`filters.${reactHooksProps.filterIndex}.operator`}
            render={({ field }) => (
              <CFormSelect
                id=""
                {...field}
                onChange={e => {
                  setSelectedOperator(
                    selectedField.operators.find(
                      operator => operator.getOperator() === e.target.value
                    )
                  );
                  field.onChange(e);
                }}
              >
                {selectedField.operators.map(operator => {
                  return (
                    <option
                      key={operator.getOperator()}
                      value={operator.getOperator()}
                    >
                      {operator.getDisplayValue()}
                    </option>
                  );
                })}
              </CFormSelect>
            )}
          />
        </div>
        <div className="flex ms-1">
          {/* input for entering value for operator */}
          <Controller
            control={reactHooksProps.formControl}
            name={`filters.${reactHooksProps.filterIndex}.value`}
            render={fieldProps =>
              selectedOperator?.getValueInputField?.(
                fieldProps,
                reactHooksProps.getValue(
                  `filters.${reactHooksProps.filterIndex}.operator`
                )
                  ? selectedField.operators.find(
                      operator =>
                        operator.getOperator() ===
                        reactHooksProps.getValue(
                          `filters.${reactHooksProps.filterIndex}.operator`
                        )
                    )
                  : selectedOperator
              ) || null
            }
          />
        </div>
      </div>
    </div>
  );
}

const filterSchema = yup.object().shape({
  field: yup.string().required(),
  operator: yup.string().required(),
  value: yup.mixed().required()
});

const schema = yup.object().shape({
  filters: yup.array().of(filterSchema)
});

export default function FiltersModel({
  open,
  onClose,
  configs,
  callback: onFiltersChange
}: FiltersModelProps) {
  const formattedConfigs = useMemo(() => {
    const a = configs.reduce((acc: any, config) => {
      acc[config.field] = {
        ...config,
        operators: operators[config.type]
          .filter(fieldOperator => {
            if (config.operators && Array.isArray(config.operators)) {
              return config.operators.indexOf(fieldOperator.operator) !== -1;
            }
            return true;
          })
          .map(operator => Operator.fromOperatorConfig(operator, config))
      };
      return acc;
    }, {});
    // console.log("!!!! formattedConfigs", a);
    return a;
  }, [configs]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue,
    watch: watchFormValue,
    reset: resetForm
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // filters: [],
      filters: [
        {
          field: formattedConfigs[Object.keys(formattedConfigs)[0]].field,
          operator: formattedConfigs[
            Object.keys(formattedConfigs)[0]
          ].operators[0].getOperator(),
          value: ""
        }
      ]
    }
  });

  const {
    fields: filterFields,
    append: appendFilterItemField,
    remove: removeFilterItemField,
    update: updateFilterItemField
  } = useFieldArray({
    control: formControl,
    name: "filters"
  });

  const onSubmit = (data: any) => {
    const filters = [];
    data.filters.forEach((filter: any) => {
      const newFilter = {
        [filter.field]: formattedConfigs[filter.field].operators
          .find((operator: any) => operator.getOperator() === filter.operator)
          .operatorCallback(filter.value)
      };
      filters.push(newFilter);
      // filters[filter.field] = filter;
    });
    onFiltersChange(filters);
    onClose();
  };

  return (
    <CModal visible={open} size="lg" backdrop="static">
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="p-4">
            <div className="border-bottom border-success mb-3">
              <h4>Filtre</h4>
            </div>
            <div className="col-12 mb-3">
              {filterFields.map((field, index) => (
                <FilterRow
                  key={field.id}
                  config={formattedConfigs}
                  reactHooksProps={{
                    filterIndex: index,
                    formControl,
                    removeFilter: () => removeFilterItemField(index),
                    registerField: () => register(`filters.${index}.field`),
                    registerOperator: () =>
                      register(`filters.${index}.operator`),
                    registerValue: () => register(`filters.${index}.value`),
                    getValue: getFormValue,
                    setValue: setFormValue
                  }}
                />
              ))}
              <button
                type="button"
                className="btn btn-link"
                onClick={() =>
                  appendFilterItemField({
                    field:
                      formattedConfigs[Object.keys(formattedConfigs)[0]].field,
                    operator: formattedConfigs[
                      Object.keys(formattedConfigs)[0]
                    ].operators[0].getOperator(),
                    value: ""
                  })
                }
              >
                + Ajouter un filtre
              </button>
              <button
                style={{ color: "red" }}
                className="btn btn-link"
                onClick={() => resetForm({ filters: [] })}
              >
                &times; Supprimer tous les filtres
              </button>
            </div>
            <section className="d-flex justify-content-between buttons gap-4 mt-3">
              <div className="d-flex justify-content-start buttons gap-4">
                <button
                  className="btn btn-danger text-white shadow-primary w-20 px-4 py-2"
                  type="button"
                  onClick={onClose}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-success shadow-secondary w-20 text-white"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
              {/* <button
                className="btn btn-danger text-white shadow-primary w-20 px-4 py-2"
                type="button"
                onClick={onClose}
              >
                Effacer tous les filtres
              </button> */}
            </section>
          </section>
        </form>
      </div>
    </CModal>
  );
}
