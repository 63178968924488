export default function ArrowDownIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 36 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0152 44.0001C18.6787 43.9441 19.4635 43.6951 19.8902 43.3438L34.8902 31.3438C36.2216 30.1739 36.341 28.3521 35.3592 27.1249C34.3774 25.8976 32.3674 25.6744 31.1402 26.6562L21.0152 34.75V3C21.0152 1.3432 19.672 0 18.0152 0C16.3583 0 15.0152 1.3432 15.0152 3V34.75L4.89016 26.6562C3.66306 25.6744 1.69846 25.9353 0.671259 27.1249C-0.444841 28.4174 -0.0871419 30.362 1.14016 31.3438L16.1402 43.3438C16.7618 43.8516 17.2182 43.9912 18.0152 44.0001Z"
        fill="black"
      />
    </svg>
  );
}
