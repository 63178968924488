import { CCol, CFormInput, CRow } from "@coreui/react";
import "../../styles/floorPlanner.scss";
import Redo from "../icons/Redo";
import Undo from "../icons/Undo";
import Copy from "../icons/Copy";
import FileDown from "../icons/FileDown";
import Save from "../icons/Save";
import Trash from "../icons/Trash";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import "fabric-history";
import { fabric } from "fabric";
import RichTextEditor from "../RichTextEditor";
import UploadPhoto from "./UploadPhoto";
import CustomSelectInput from "./CustomSelectInput";
import { useEffect, useState } from "react";
import { usePlaceZone } from "../../hooks/usePlaceZone";
import { useQuery } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import Skeleton from "react-loading-skeleton";

const ELEMENT_TYPE = {
  CHAIR: "chair",
  TABLE: "table"
};

const EXTRA_DATA_KEYS = [
  "seat_number",
  "tableNumber",
  "description",
  "images"
] as const;
const ELEMENTS = [
  {
    label: ELEMENT_TYPE.TABLE,
    objectUrl:
      "https://res.cloudinary.com/fedev/image/upload/v1689932551/moment/Group_1_tn5hnl.png"
  }
];

export type ExtraDataHandlerProps = {
  data: any;
  key: typeof EXTRA_DATA_KEYS[number];
};

function MainFloorPlanner() {
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const [selectedZone, setSelectedZone] = useState("");
  const { token } = useCookie("vToken");

  const { updateZone, updatingZone, addingPhoto, addPhoto } = usePlaceZone();

  const { data: existingObjects, isLoading: fetchingZone } = useQuery<Zone>(
    ["getObjectsByZone", selectedZone],
    () => {
      return apiService.MakeGetRequest(`places/zones/${selectedZone}`, token);
    },
    { enabled: selectedZone.length > 0 }
  );

  useEffect(() => {
    if (existingObjects?.objects?.length) {
      const data = { objects: existingObjects?.objects };
      editor?.canvas?.loadFromJSON(data);
      // editor?.canvas?.renderAll();
    }
  }, [editor?.canvas, existingObjects?.objects]);

  // const addCircle = () => {
  //   editor?.addCircle();
  // };

  function deleteSelection() {
    editor?.deleteSelected();
  }

  function cloneObject(object: any) {
    object.clone(function(clone: any) {
      editor?.canvas.add(
        clone.set({
          left: object.left + 120,
          top: object.top + 120
        })
      );
    });
  }
  function cloneSelection() {
    var object = editor?.canvas.getActiveObject();
    if (object?._objects?.length) {
      object?._objects?.forEach((object: any) => cloneObject(object));
    } else {
      cloneObject(object);
    }
  }
  function undoHandler() {
    editor?.canvas?.undo();
  }
  function redoHandler() {
    editor?.canvas?.undo();
  }

  function saveAsImage() {
    editor?.canvas?.setBackgroundColor("white");
    const base64Url = editor?.canvas?.toDataURL({
      format: "jpeg",
      multiplier: 2
    });
    downloadImage(base64Url);
  }

  function downloadImage(dataUrl: string, fileName = "floor-planner") {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = fileName;
    // @ts-ignore
    link.crossOrigin = "anonymous";
    link.click();
  }

  function uploadObjectImages(id: string, images: File[]) {
    images.forEach(image => {
      addPhoto({ objectId: id, data: { picture: image } });
    });
  }
  function saveAsJSON() {
    const { objects = [] } = editor?.canvas?.toJSON();
    const placeObjects = objects.map((obj: any, i: number) => {
      const objects = {
        description: editor?.canvas?.item(i)?.extraData?.description ?? "",
        represents: "table",
        top: obj?.top,
        left: obj?.left,
        width: obj?.width,
        height: obj?.height,
        type: obj?.type,
        color: obj?.backgroundColor,
        scaleX: obj?.scaleX,
        scaleY: obj?.scaleY,
        src: obj?.src,
        // images: editor?.canvas?.item(i)?.extraData?.images,
        objectId: editor?.canvas?.item(i)?.id
      };
      const tableNumber =
        editor?.canvas?.item(i)?.extraData?.tableNumber ??
        editor?.canvas?.item(i)?.tableNumber;

      return tableNumber
        ? { ...objects, tableNumber: Number(tableNumber) }
        : objects;
    });

    // placeObjects.forEach((object: any) => {
    //   if (object?.objectId && object?.images?.length) {
    //     uploadObjectImages(object?.objectId, object?.images);
    //   }
    // });

    updateZone({ zoneId: selectedZone, placeObjects });
  }

  function addImage(image: string, type: string) {
    fabric.Image.fromURL(
      image,
      function(oImg: any) {
        console.log(oImg);
        oImg.hasControls = false;
        oImg.type = "image";
        oImg.represents = type;
        oImg.scale(0.05);
        oImg.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false
        });
        editor?.canvas.add(oImg);
      },
      {
        crossOrigin: "anonymous"
      }
    );
  }

  function addExtraDataHandler(props: ExtraDataHandlerProps) {
    var object = editor?.canvas.getActiveObject();
    const existingData = object.get("extraData");
    if (existingData) {
      object.set("extraData", {
        ...existingData,
        [props.key]: props.data
      });
    } else {
      object.set("extraData", {
        [props.key]: props.data
      });
    }
  }

  const canvasHasObjects = editor?.canvas?.toJSON().objects.length > 0;

  return (
    <CRow className="vh-100">
      <CCol className="d-xxl-none bg-white w-full w-lg-50 p-3 px-4 border-bottom">
        <CustomSelectInput
          onZoneSelect={value => {
            setSelectedZone(() => value);
          }}
        />
      </CCol>
      <CCol sm={2} className="h-full d-none d-xxl-block  p-0">
        <div className="bg-white py-3 px-4 h-full border-end">
          <CustomSelectInput
            onZoneSelect={value => setSelectedZone(() => value)}
          />
          <h3 className="border-bottom fs-6 pb-2 mb-4 mt-4">Éléments</h3>

          {ELEMENTS.map(element => (
            <button
              key={element.label}
              onClick={() => addImage(element.objectUrl, element.label)}
              className="btn px-0 d-flex align-items-center  mb-2"
            >
              <img src={element.objectUrl} alt="" width="40px" height="40px" />
              <p className="mb-0 ms-2">{element.label}</p>
            </button>
          ))}
        </div>
      </CCol>
      <CCol xxl={10} className="h-full  p-0 ">
        <div className="bg-white border-bottom py-1 px-4 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm px-1"
              onClick={undoHandler}
              disabled={!editor?.canvas?.historyUndo?.length}
              title="Undo"
            >
              <Undo />
            </button>
            <button
              className="btn btn-sm px-1"
              onClick={redoHandler}
              disabled={!editor?.canvas?.historyRedo?.length}
              title="Redo"
            >
              <Redo />
            </button>
            <button
              className="btn btn-sm px-1"
              onClick={cloneSelection}
              disabled={!selectedObjects?.length}
              title="Duplicate"
            >
              <Copy />
            </button>
            <button
              className="btn btn-sm px-1"
              onClick={deleteSelection}
              disabled={!selectedObjects?.length}
              title="Delete"
            >
              <Trash />
            </button>
          </div>
          <div className="d-flex align-items-center">
            <button
              title="Download image"
              className="btn btn-sm px-1"
              onClick={saveAsImage}
              disabled={!canvasHasObjects || updatingZone}
            >
              <FileDown />
            </button>
            <button
              title="Save"
              className="btn btn-sm px-1"
              onClick={saveAsJSON}
              disabled={!canvasHasObjects}
            >
              {updatingZone || addingPhoto ? "enregistrement..." : <Save />}
            </button>
          </div>
        </div>
        <div className="canvas-container align-items-start gap-x-5 mt-3 ms-3">
          <div className="bg-white canvas-wrapper">
            {!selectedZone ? (
              <div className="canvas-container">
                <p className="d-flex align-items-center justify-content-center text-center h-full fw-bold">
                  Veuillez sélectionner une zone pour pouvoir ajouter des tables
                </p>
              </div>
            ) : !fetchingZone ? (
              <FabricJSCanvas className="sample-canvas" onReady={onReady} />
            ) : (
              <Skeleton
                className="canvas-container"
                style={{ backgroundColor: "#ffff" }}
                highlightColor="#ffff"
                baseColor="#fcfcfc"
              />
            )}
          </div>
          {selectedObjects && selectedObjects?.length === 1 && (
            <div className="bg-white p-3 extra-info">
              <div>
                <h5>Détails du tableau</h5>
                <div className="my-4">
                  <div className="mb-3">
                    <label
                      htmlFor="item-number "
                      className="d-block text-capitalize"
                    >
                      Numéro de table :
                    </label>
                    <CFormInput
                      type="number"
                      id="item-number"
                      name={`${selectedObjects?.[0]?.type}-number`}
                      className="custom-input"
                      required
                      onChange={e => {
                        const key = "tableNumber";
                        addExtraDataHandler({
                          key,
                          data: e.target.value
                        });
                      }}
                      defaultValue={
                        selectedObjects?.[0]?.extraData?.tableNumber ||
                        selectedObjects?.[0]?.tableNumber
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="item-number" className="d-block">
                      Description:
                    </label>
                    <RichTextEditor
                      onChange={data =>
                        addExtraDataHandler({
                          key: "description",
                          data
                        })
                      }
                      defaultValue={
                        selectedObjects?.[0]?.extraData?.description ||
                        selectedObjects?.[0]?.description
                      }
                    />
                  </div>
                </div>
              </div>
              {/* {selectedObjects?.[0]?.id && (
                <div>
                  <h5 className="fs-6">Télécharger des photos</h5>
                  <UploadPhoto
                    onFileUpload={addExtraDataHandler}
                    defaultFiles={
                      selectedObjects?.[0]?.extraData?.images ??
                      selectedObjects?.[0]?.images ??
                      []
                    }
                  />
                </div>
              )} */}
            </div>
          )}
        </div>
        <div className="bg-white p-3 shapes-wrapper">
          <p className="fw-bold mb-0">Éléments: </p>
          <div className="d-flex align-items-center gap-x-2 mt-1">
            {ELEMENTS.map(element => (
              <button
                key={element.label}
                onClick={() => addImage(element.objectUrl, element.label)}
                className="btn px-0"
              >
                <img
                  src={element.objectUrl}
                  alt=""
                  width="30px"
                  height="30px"
                />
                <p className="mb-0 ms-2 mt-1">{element.label}</p>
              </button>
            ))}
          </div>
        </div>
      </CCol>
    </CRow>
  );
}

export default MainFloorPlanner;
