import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../useCookie";
import { useToast } from "../useToast";
import { general } from "../../locales/general";
import { useAuth } from "../useAuth";
import { useEffect, useState } from "react";
import { useDebounce } from "../useDebounce";
import { useSearchParams } from "react-router-dom";
import { useQueryParam } from "../useQueryParam";
import { toQuery } from "../../helpers/general";

const PAGE_SIZE = 10;
interface UseOfferQueryParams {
  refetchOnWindowFocus?: boolean;
}
export const useOffersQuery = ({
  refetchOnWindowFocus = true
}: UseOfferQueryParams) => {
  const { token } = useCookie("vToken");
  const { isAdmin } = useAuth();
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();
  // const [searchQuery, handleSearch] = useState("");
  const [searchParams] = useSearchParams();

  const query = searchParams?.get("search") ?? "";
  const currentPage = searchParams?.get("page") ?? "1";

  const searchQuery = useDebounce(query, 1000);
  const { addMultiple } = useQueryParam();

  useEffect(() => {
    addMultiple({ search: searchQuery, page: "1" });
  }, [searchQuery]);

  const endPoint = `specials/all${toQuery({
    page: currentPage,
    limit: PAGE_SIZE,
    query: searchQuery
  })}`;

  const queryDependencies = ["getAllOffers", endPoint];
  const { data, isLoading } = useQuery(
    queryDependencies,
    () => {
      return apiService.MakeGetRequest(endPoint, token);
    },
    { enabled: isAdmin, refetchOnWindowFocus: refetchOnWindowFocus }
  );

  const { data: placeAdminOffers, isLoading: fetching } = useQuery(
    ["getAllOffers"],
    () => {
      return apiService.MakeGetRequest("specials/ours", token);
    },
    {
      enabled: !isAdmin,
      refetchOnWindowFocus: refetchOnWindowFocus
    }
  );

  const deleteOffer = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`specials/${id}`, token);
  });

  return {
    Offers: isAdmin
      ? (data?.items as SpecialOffers[])
      : ((placeAdminOffers ?? []) as SpecialOffers[])?.slice(
          (+currentPage - 1) * PAGE_SIZE,
          +currentPage * PAGE_SIZE
        ),
    fetchingOffers: isLoading || fetching,
    deleteOffer(id: string) {
      deleteOffer.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.offerDeleted);
          queryClient.invalidateQueries("getAllOffers");
        }
      });
    },
    mutating: deleteOffer.isLoading,
    totalPages: isAdmin
      ? data?.meta?.totalPages
      : Math.ceil((placeAdminOffers?.length || 0) / PAGE_SIZE),
    totalOffers: isAdmin
      ? data?.meta?.totalItems
      : placeAdminOffers?.length || 0,
    currentPage
  };
};
