import React from "react";
import { IconTypes } from "./types";
export default function TranslateIcon({
  width = "19",
  height = "19",
  fill = "#29129D"
}: Partial<IconTypes>) {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <path d="M62.4 101c-1.5-2.1-2.1-3.4-1.8-3.9.2-.5 1.6-.7 3.9-.5 2.3.2 4.2.5 5.8.9 1.5.4 2.8 1 3.8 1.7s1.8 1.5 2.3 2.6c.6 1 1 2.3 1.4 3.7.7 2.8.5 4.7-.5 5.7-1.1 1-2.6.8-4.6-.6-2.1-1.4-3.9-2.8-5.5-4.2-1.7-1.3-3.3-3.2-4.8-5.4zm-21.7 89.1c4.8-2.1 9-4.2 12.6-6.4 3.5-2.1 6.6-4.4 9.3-6.8 2.6-2.3 5-4.9 7-7.7 2-2.7 3.8-5.8 5.4-9.2 1.3 1.2 2.5 2.4 3.8 3.5 1.2 1.1 2.5 2.2 3.8 3.4 1.3 1.2 2.8 2.4 4.3 3.8s3.3 2.8 5.3 4.5c.7.5 1.4.9 2.1 1 .7.1 1.7 0 3.1-.6 1.3-.5 3-1.4 5.1-2.8 2.1-1.3 4.7-3.1 7.9-5.4 1.6-1.1 2.4-2 2.3-2.7-.1-.7-1-1-2.7-.9-3.1.1-5.9.1-8.3-.1-2.5-.2-5-.6-7.4-1.4-2.4-.8-4.9-1.9-7.5-3.4-2.6-1.5-5.6-3.6-9.1-6.2 1-3.9 1.8-8 2.4-12.4.3-2.5.6-4.3.8-5.6.2-1.2.5-2.4.9-3.3.3-.8.4-1.4.5-1.9.1-.5-.1-1-.4-1.6-.4-.5-1-1.1-1.9-1.7-.9-.6-2.2-1.4-3.9-2.3 2.4-.9 5.1-1.7 7.9-2.6 2.7-.9 5.7-1.8 8.8-2.7 3-.9 4.5-1.9 4.6-3.1.1-1.2-.9-2.3-3.2-3.5-1.5-.8-2.9-1.1-4.3-.9-1.4.2-3.2.9-5.4 2.2-.6.4-1.8.9-3.4 1.6-1.7.7-3.6 1.5-6 2.5s-5 2-7.8 3.1c-2.9 1.1-5.8 2.2-8.7 3.2-2.9 1.1-5.7 2-8.2 2.8-2.6.8-4.6 1.4-6.1 1.6-3.8.8-5.8 1.6-5.9 2.4 0 .8 1.5 1.6 4.4 2.4 1.2.3 2.3.6 3.1.6.8.1 1.7.1 2.5 0s1.6-.3 2.4-.5c.8-.3 1.7-.7 2.8-1.1 1.6-.8 3.9-1.7 6.9-2.8 2.9-1 6.6-2.4 11.2-4 .9 2.7 1.4 6 1.4 9.8 0 3.8-.4 8.1-1.4 13-1.3-1.1-2.7-2.3-4.2-3.6-1.5-1.3-2.9-2.6-4.3-3.9-1.6-1.5-3.2-2.5-4.7-3-1.6-.5-3.4-.5-5.5 0-3.3.9-5 1.9-4.9 3.1 0 1.2 1.3 1.8 3.8 1.9.9.1 1.8.3 2.7.6.9.3 1.9.9 3.2 1.8 1.3.9 2.9 2.2 4.7 3.8 1.8 1.6 4.2 3.7 7 6.3-1.2 2.9-2.6 5.6-4.1 8-1.5 2.5-3.4 5-5.5 7.3-2.2 2.4-4.7 4.8-7.7 7.2-3 2.5-6.6 5.1-10.8 7.8-4.3 2.8-6.5 4.7-6.5 5.6.1 1.3 2.1.9 5.8-.7zM250.5 81.8v165.3l-111.6-36.4-128.4 42.7V76.1l29.9-10V10.4l81.2 28.7L231.3 2.6v73.1l19.2 6.1zM124.2 50.6l-101.9 34v152.2l101.9-33.9V50.6zm95.2 21.3V19l-81.3 27 81.3 25.9zm7.6 130L196.5 92 176 85.6l-30.9 90.8 18.9 5.9 5.8-18.7 31.9 10 5.7 22.3 19.6 6zm-52.2-54.2l22.2 6.9-10.9-42.9-11.3 36z" />
    </svg>
  );
}
