import {
  CButton,
  CFormCheck,
  CFormInput,
  CModal,
  CModalBody,
  CPopover,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useToast } from "../../../hooks/useToast";
import { useMutation, useQueryClient } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import { DELETE_ACTIONS } from "../../../config/constants";
import { general } from "../../../locales/general";
import { colors } from "../../../config/theme";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import EditIcon from "../../../components/icons/EditIcon";
import PromptModal from "../../../components/modals/PromptModal";
import { useAuth } from "../../../hooks/useAuth";

function TableBodyRowContent({
  Review,
  deletedList
}: {
  Review: Review;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useAuth();

  const deleteReview = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`reviews/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: Review.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: Review.id });
    }
  }, [deletedList, isChecked, Review.id]);

  const removeReview = () => {
    deleteReview.mutate(Review.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.reviewDeleted);
        queryClient.invalidateQueries("getPlaceReviews");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 ">
        {isAdmin === true ? (
          <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          />
        ) : null}
        <span className="ms-2 text-sm">
          {new Date(Review?.createdAt).toLocaleDateString("fr")}
        </span>
      </CTableDataCell>

      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Review?.user?.fullName}
      </CTableDataCell>

      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Review?.user?.email}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        <CPopover
          content={Review?.comment}
          placement="bottom"
          trigger="click"
          title="Review"
        >
          <span className="pointer">
            {" "}
            {Review?.comment.substring(0, 8)} ...
          </span>
        </CPopover>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Review?.rating}/5
      </CTableDataCell>

      <CTableDataCell className="p-3">
        {isAdmin === true ? (
          <button
            className="outline-none border-0 bg-transparent"
            onClick={() => setShowModal(true)}
          >
            {deleteReview.isLoading ? (
              <CSpinner size="sm" />
            ) : (
              <DeleteIcon
                width="20"
                height="20"
                fill={colors.primary}
                className="ms-2"
              />
            )}
          </button>
        ) : null}
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeReview()}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  Reviews: Review[];
  ReviewToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function PlaceReviewsTable({
  Reviews,
  ReviewToBeDeleted
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Nom complet</CTableHeaderCell>

            <CTableHeaderCell scope="col">Email</CTableHeaderCell>
            <CTableHeaderCell scope="col">Avis</CTableHeaderCell>
            <CTableHeaderCell scope="col">
              Note d&apos;évaluation
            </CTableHeaderCell>

            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {Reviews?.map(Review => (
            <TableBodyRowContent
              Review={Review}
              key={Review?.id}
              deletedList={ReviewToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
