import React, { useMemo, useReducer, useState } from "react";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../../context/bulkDeleteReducer";
import BoxWrapper from "../../../components/BoxWrapper";
import { Link, useParams } from "react-router-dom";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { colors } from "../../../config/theme";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CSpinner } from "@coreui/react";
import RenderTable from "../../../components/RenderTable";
import { usePaginatedContent } from "../../../hooks/usePaginatedContent";
import { general } from "../../../locales/general";
import Pagination from "../../../components/Pagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../../service/apiService";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import PlaceInspectorsTable from "./PlaceInspectorsTable";

const PAGE_SIZE = 10;

export default function PlaceInspectors() {
  const params = useParams();
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isLoading } = useQuery(
    ["getPlaceInspectors", currentPage],
    () => {
      return apiService.MakeGetRequest(
        `places/${params?.placeId}/inspectors?page=${currentPage}`,
        token
      );
    }
  );

  const deleteinspectors = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`inspectors/${id}`, token);
  });

  const paginatedContent = usePaginatedContent(
    currentPage,
    PAGE_SIZE,
    data?.items
  );

  const filteredinspectors = useMemo(() => {
    const inspectors = paginatedContent?.filter(
      (Inspector: any) =>
        Inspector?.email?.toLowerCase()?.includes(searchQuery) ||
        Inspector?.fullName?.toLowerCase()?.includes(searchQuery) ||
        Inspector?.phone?.toLowerCase()?.includes(searchQuery)
    );
    return inspectors;
  }, [paginatedContent, searchQuery]);

  const [inspectorsToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    inspectorsToBeDeleted.ids.forEach((id: string) => {
      if (!id) return;
      deleteinspectors.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.inspectorDeleted);
          queryClient.invalidateQueries("getPlaceInspectors");
        }
      });
    });
  };
  const PlaceSVG = () => {
    return (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17 0H1V2H17V0ZM18 10V8L17 3H1L0 8V10H1V16H11V10H15V16H17V10H18ZM9 14H3V10H9V14Z" />
      </svg>
    );
  };
  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Inspecteurs</h5>
      <BoxWrapper>
        <div className="d-flex  justify-content-between align-items-baseline before-table">
          <div className="d-flex align-items-center gap-2 p-3 delete-add">
            <Link
              to={`/places/${params.placeId}/inspectors/new`}
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
            >
              + Ajouter un inspecteur
            </Link>

            <button
              disabled={
                deleteinspectors.isLoading ||
                inspectorsToBeDeleted?.ids.length === 0
              }
              onClick={handleBulkDelete}
              className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
            >
              {!deleteinspectors.isLoading ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>
          <div className="search-form">
            <div className=" input-field">
              <MagnifyingGlassIcon color="gray" width={20} />
              <input
                type="text"
                className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                placeholder="Rechercher"
                onChange={e => setSearchQuery(e?.target?.value?.toLowerCase())}
              />
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
            style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
          >
            <PlaceSVG />
            <p className="fs-5 fw-bold m-0  text-white text-pre">
              {" "}
              {data?.meta?.totalItems}{" "}
              {data?.meta?.totalItems > 1 ? "Inspecteurs" : "Inspecteur"}
            </p>
          </div>
        </div>

        <RenderTable
          loading={isLoading}
          render={() =>
            data?.meta?.totalItems > 0 ? (
              <PlaceInspectorsTable
                inspectors={data?.items as Inspector[]}
                inspectorsToBeDeleted={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={data?.meta?.totalPages}
        pageSize={PAGE_SIZE}
      />
    </section>
  );
}
