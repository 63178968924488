import { IconTypes } from "./types";

export default function PendingIcon({ className = ` ` }: Partial<IconTypes>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 96 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M89.25 8.62442C88.75 6.42442 85.95 5.62441 84.35 7.22441L79.35 12.2244C60.65 -4.57558 31.95 -4.07558 13.95 13.8244C-4.65 32.4244 -4.65 62.6244 13.95 81.2244C14.55 81.8244 15.15 82.3244 15.65 82.9244C16.65 83.8244 17.85 84.2244 19.05 84.2244C20.45 84.2244 21.85 83.6244 22.85 82.5244C24.75 80.4244 24.55 77.2244 22.45 75.3244L21.05 74.0244C6.55 59.4244 6.55 35.6244 21.15 21.0244C35.15 7.02442 57.45 6.62442 72.15 19.4244L66.65 24.9244C65.05 26.5244 65.85 29.3244 68.05 29.8244L91.45 35.5244C93.55 36.0244 95.55 34.1244 95.05 31.9244L89.25 8.62442Z" />
      <path d="M64.35 52.1238L52.6499 44.7238V26.0238C52.6499 23.2238 50.3499 20.9238 47.5499 20.9238C44.7499 20.9238 42.45 23.2238 42.45 26.0238V47.4238C42.45 49.1238 43.3499 50.8238 44.8499 51.7238L58.8499 60.6238C59.6499 61.1238 60.6499 61.4238 61.5499 61.4238C63.2499 61.4238 64.85 60.6238 65.85 59.0238C67.45 56.7238 66.75 53.6238 64.35 52.1238Z" />
      <path d="M37.85 83.6241L35.95 83.0241C33.25 82.1241 30.35 83.6241 29.55 86.2241C28.65 88.9241 30.15 91.8241 32.75 92.6241C33.55 92.9241 34.35 93.1241 35.15 93.3241C35.55 93.4241 36.05 93.5241 36.45 93.5241C38.65 93.5241 40.75 92.0241 41.35 89.7241C42.15 87.1241 40.55 84.3241 37.85 83.6241Z" />
      <path d="M55.15 84.1244L53.15 84.4244C50.35 84.8244 48.45 87.4244 48.85 90.2244C49.25 92.7244 51.45 94.6244 53.85 94.6244C54.05 94.6244 54.35 94.6244 54.65 94.5244C55.45 94.4244 56.35 94.2244 57.15 94.1244C59.95 93.5244 61.65 90.9244 61.15 88.1244C60.55 85.4244 57.85 83.6244 55.15 84.1244Z" />
      <path d="M70.7499 76.9236L69.1499 78.1236C66.8499 79.7236 66.3499 82.9236 67.9499 85.2236C68.9499 86.6236 70.5499 87.4236 72.1499 87.4236C73.1499 87.4236 74.1499 87.1236 75.0499 86.5236C75.7499 86.0236 76.4499 85.5236 77.0499 85.0236C79.2499 83.3236 79.6499 80.0236 77.8499 77.8236C76.2499 75.5236 72.9499 75.2236 70.7499 76.9236Z" />
    </svg>
  );
}
