import { CForm, CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import React, { FormEvent, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../hooks/useToast";
import { useCookie } from "../../../../hooks/useCookie";
import apiService from "../../../../service/apiService";
import { general } from "../../../../locales/general";
import BoxWrapper from "../../../../components/BoxWrapper";
import RichTextEditor from "../../../../components/RichTextEditor";

interface SpecialOffers {
  title: string;
  text: string;
  reductionPercent: number;
  picture?: File | string;
  carouselImage?: File | string;
}

export default function PlaceNewOffer() {
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const params = useParams();
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // @desc create new user
  const createNewOfferMutation = useMutation(async (data: SpecialOffers) => {
    return await apiService.MakePostRequest("specials", data, token, true);
  });

  // @desc form submit handler
  const createOfferHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const placeId = params.placeId;
    const title = formData.get("title") as string;
    const reductionPercent = formData.get("reductionPercent") as string;
    const offerType = formData.get("offerType") as string;
    const picture = formData.get("picture") as File;
    const carouselImage = formData.get("carouselImage") as File;

    const data = {
      placeId,
      title,
      text: description,
      type: offerType,
      reductionPercent: Number(reductionPercent),
      carouselImage,
      picture
    };

    createNewOfferMutation.mutate(data, {
      onSuccess(data: any) {
        toastSuccess(general.fr.message.offerCreated);
        queryClient.invalidateQueries("getPlaceOffers");

        navigate(`/places/${params.placeId}/offers`);
      },
      onError(error: any) {
        toastError(general.fr.message.operationFailed);
      }
    });
  };
  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter une offre</h4>
            </div>
            <CForm
              validated={true}
              className="row form mt-4"
              onSubmit={createOfferHandler}
            >
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Titre de l&apos;offre
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Titre de l'offre"
                  id="title"
                  name="title"
                  required
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Sélectionnez le type d&apos;offre{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormSelect
                  name="offerType"
                  id="offerType"
                  className="custom-input"
                  required
                  feedbackInvalid="Example invalid select feedback"
                  // onChange={e => setOfferType(e.target.value)}
                >
                  <option value="SPECIAL_OFFER">SPECIAL OFFER</option>
                  <option value="EVENT_OFFER">EVENT OFFER</option>
                  <option value="TOUR_OFFER">TOUR OFFER</option>
                </CFormSelect>
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="reductionPercent" className="d-block">
                  Remise
                  <span className="text-sm text-danger"> (optionnel)</span>
                </label>

                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Offer Reduction"
                  id="reductionPercent"
                  name="reductionPercent"
                />
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="text" className="d-block">
                  Description de l&apos;offre
                </label>
                <RichTextEditor onChange={data => setDescription(data)} />
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="picture" className="d-block">
                  image de l&apos;offre
                </label>
                <CFormInput
                  type="file"
                  id="picture"
                  name="picture"
                  className="my-3"
                  required
                />
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="carouselImage" className="d-block">
                  Image du carrousel
                </label>
                <CFormInput
                  type="file"
                  id="carouselImage"
                  name="carouselImage"
                  className="my-3"
                  required
                />
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to={`/places/${params.placeId}/offers`}
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={createNewOfferMutation.isLoading}
                >
                  {createNewOfferMutation.isLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </CForm>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
