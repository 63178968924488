// @ts-nocheck
import React, { useEffect } from "react";
import { CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import { useAuth } from "../../../hooks/useAuth";
import { useState } from "react";
import { useCookie } from "../../../hooks/useCookie";
import { general } from "../../../locales/general";
import { useToast } from "../../../hooks/useToast";
import { useMutation } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode } from "../../../helpers/enums";
import { useForm, Controller } from "react-hook-form";
import apiService from "../../../service/apiService";
import RichTextEditor from "../../../components/RichTextEditor";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";
import { verifyFileList } from "../../../helpers/general";


interface PlaceTranslationData {
    name: string;
    mainType?: string;
    picture?: File;
    address?: string;
    secondaryType?: string;
    speciality?: string;
    keywords?: string[];
    servicesSectionTitle?: string;
    lang: string;
    placeId: string;
}

export default function NewPlaceTranslation({ mode = FormMode.CREATE }) {
    const [formLoading, setFormLoading] = useState(false);
    const { isAdmin } = useAuth();
    const { token } = useCookie("vToken");
    const { toastError, toastSuccess } = useToast();
    const params = useParams();
    const navigate = useNavigate();
    const [description, setDescription] = useState("");

    const schema = yup.object({
        name: yup.string().required(),
        keywords: yup.string().nullable(),
        servicesSectionTitle: yup.string().nullable(),
        mainType: yup.string().nullable(),
        secondaryType: yup.string().nullable(),
        address: yup.string().nullable(),
        speciality: yup.string().nullable(),
        lang: yup.string().required(),
        placeId: yup.object().required(),
        picture: yup.mixed().nullable(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control: formControl,
        setValue: setFormValue,
        getValues: getFormValue
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });

    useEffect(() => {
        if (mode === FormMode.UPDATE && params?.id) {
            const formFields = Object.keys(schema.fields);
            apiService.MakeGetRequest(
                `place-translations/${params.id}`,
                token
            ).then((response) => {
                setDescription(response?.description);
                setFormValue("placeId", response?.place);
                setFormValue("picture", response?.image);
                Object.entries(response).forEach(entry => {
                    if (formFields.indexOf(entry[0]) !== -1) {
                        if (
                            entry[0] === "keywords"
                        ) {
                            setFormValue(entry[0], entry[1]?.join(","));
                        } else {
                            setFormValue(entry[0], entry[1]);
                        }
                    }
                });
            });
        }
    }, [params?.id]);

    const createPlaceTranslationTranslationMutation = useMutation(async placeTranslationData => {
        return await apiService.MakePostRequest("place-translations", placeTranslationData, token, true);
    });

    const updatePlaceTranslationMutation = useMutation(async placeTranslationData => {
        return await apiService.MakePutRequest(
            `place-translations/${params.id}`,
            placeTranslationData,
            token,
            true
        );
    });

    const createPlaceTranslation = async data => {
        try {
            setFormLoading(true);
            await createPlaceTranslationTranslationMutation.mutateAsync(data);
            toastSuccess(general.fr.message.translationCreated);
            navigate(-1);
        } catch (error) {
            setFormLoading(false);
            toastError(general.fr.message.operationFailed);
        }
    };

    const updatePlaceTranslation = async data => {
        try {
            setFormLoading(true);
            const updatePlaceTranslationReq = updatePlaceTranslationMutation.mutateAsync(data);

            if (isAdmin) {
                await Promise.all([updatePlaceTranslationReq]);
                toastSuccess(general.fr.message.translationUpdated);
                navigate(-1);
            } else {
                await updatePlaceTranslationReq;
                toastSuccess(general.fr.message.translationUpdated);
                navigate(-1);
            }
        } catch (error) {
            setFormLoading(false);
            toastError(general.fr.message.operationFailed);
        }
    };

    const onSubmit = async (dataArg: PlaceTranslationData) => {
        const data = { ...dataArg };
        try {
            data.placeId = data.placeId.id;
            const imageFile = verifyFileList(data.picture);
            data.picture = imageFile;
            if (mode === FormMode.UPDATE) {
                updatePlaceTranslation({ ...data, description });
            } else {
                createPlaceTranslation({ ...data, description });
            }
        } catch (error) {
            setFormLoading(false);
            console.log(error);
            toastError(general.fr.message.operationFailed);
        }
    };

    return (
        <div className=" m-4  ">
            <BoxWrapper>
                <section className="p-4">
                    <div className="border-bottom border-success">
                        <h4>
                            {mode === FormMode.UPDATE
                                ? "Mise à jour"
                                : "Ajouter une traduction"}
                        </h4>
                    </div>
                    <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-6 my-3">
                            <label htmlFor="placeId" className="d-block mb-2">
                                Établissement <span className="text-md text-red">*</span>
                            </label>
                            <Controller
                                name="placeId"
                                control={formControl}
                                render={({ field }) => {
                                    return (
                                        <InfiniteScrollSelect
                                            name="placeId"
                                            error={errors?.placeId?.message}
                                            getOptionLabel={option => option?.name}
                                            getOptionValue={option => option.id}
                                            isOptionSelected={(option: Place, selectedValue: Place[]) => {
                                                const isSelected =
                                                    option?.id === selectedValue?.[0]?.id;
                                                return isSelected;
                                            }}
                                            url={{
                                                path: 'places'
                                            }}
                                            {...field}
                                        />
                                    );
                                }}
                            />
                            {errors.placeId?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.placeId.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6  my-3">
                            <label htmlFor="lang" className="d-block">
                                La langue
                                <span className="text-sm text-red"> *</span>
                            </label>
                            <CFormSelect
                                name="lang"
                                id="lang"
                                {...register("lang")}
                            >
                                <option value="en">Anglais</option>
                                <option value="ar">Arabic</option>
                                <option value="fr">Français</option>
                            </CFormSelect>
                            {errors.lang?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.supportTableSelection.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6  my-3 ">
                            <label htmlFor="name" className="d-block">
                                Nom <span className="text-sm text-red">*</span>
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                placeholder="Nom de Place"
                                id="name"
                                name="name"
                                {...register("name")}
                            />
                            {errors.name?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.name.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6  my-3 ">
                            <label htmlFor="picture" className="d-block">
                                Grande image d'établissement
                            </label>
                            <CFormInput
                                type="file"
                                id="picture"
                                name="picture"
                                className="custom-input"
                                required={false}
                                accept="image/png,image/jpeg,image/jpg"
                                {...register("picture")}
                            />
                            {errors.picture?.message ? (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.picture.message}
                                </div>
                            ) : (
                                <span className="text-xs text-danger">
                                    (préférée 1425&times;520)
                                </span>
                            )}
                        </div>

                        <div className="col-md-6  my-3 ">
                            <label htmlFor="address" className="d-block">
                                Adresse
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                placeholder="Adresse"
                                id="address"
                                name="address"
                                {...register("address")}
                            />
                            {errors.address?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.address.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6  my-3 ">
                            <label htmlFor="mainType" className="d-block">
                                Le menu principal
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                placeholder="mainType"
                                id="mainType"
                                name="mainType"
                                {...register("mainType")}
                            />
                            {errors.mainType?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.mainType.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6  my-3 ">
                            <label htmlFor="secondaryType" className="d-block">
                                Le menu secondaire
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                placeholder="SecondaryType"
                                id="secondaryType"
                                name="secondaryType"
                                {...register("secondaryType")}
                            />
                            {errors.secondaryType?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.secondaryType.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6  my-3 ">
                            <label htmlFor="speciality" className="d-block">
                                Spécialités
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                placeholder="speciality"
                                id="speciality"
                                name="speciality"
                                {...register("speciality")}
                            />
                            {errors.speciality?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.speciality.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6  my-3 ">
                            <label htmlFor="keywords" className="d-block">
                                Mots-clés
                                <span className="text-sm text-red">*</span>{" "}
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                id="keywords"
                                name="keywords"
                                {...register("keywords")}
                            />
                            {errors.keywords?.message ? (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.keywords.message}
                                </div>
                            ) : (
                                <span className="text-xs text-danger">
                                    (Séparer par une virgule)
                                </span>
                            )}
                        </div>
                        <div className="col-md-6  my-3 ">
                            <label htmlFor="servicesSectionTitle" className="d-block">
                                Titre de la section service{" "}
                                <span className="text-sm text-red">*</span>
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                id="servicesSectionTitle"
                                name="servicesSectionTitle"
                                {...register("servicesSectionTitle")}
                            />
                            {errors.servicesSectionTitle?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.servicesSectionTitle.message}
                                </div>
                            )}
                        </div>

                        <div className="my-3">
                            <label htmlFor="description">Description</label>
                            <RichTextEditor
                                onChange={setDescription}
                                // {...register("description")}
                                name="description"
                                defaultValue={description}
                            />
                        </div>

                        <section className="d-flex justify-content-center buttons gap-4 mt-5">
                            <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                            >
                                Annuler
                            </button>
                            <button
                                className="btn btn-success green-shadow w-20 text-white"
                                type="submit"
                                disabled={formLoading}
                            >
                                {formLoading ? (
                                    <div className="text-center">
                                        <CSpinner size="sm" />
                                    </div>
                                ) : (
                                    "Enregistrer"
                                )}
                            </button>
                        </section>
                    </form>
                </section>
            </BoxWrapper>
        </div>
    );
}
