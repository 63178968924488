import React, { useEffect, useMemo, useReducer, useState } from "react";
import Pagination from "../../components/Pagination";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import BoxWrapper from "../../components/BoxWrapper";
import { usePaginatedContent } from "../../hooks/usePaginatedContent";
import { general } from "../../locales/general";
import { CModal, CSpinner } from "@coreui/react";
import RenderTable from "../../components/RenderTable";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import NewCategory from "./NewCity";
import { useCitiesQuery } from "../../hooks/Cities/useCitiesQuery";
import CityTable from "./CityTable";
import AdminRoute from "../auth/RestrictedRoute";
import { CitiesSvg } from "../../components/SideBarMenu";

const PAGE_SIZE = 10;

export default function Cities() {
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const { Cities, fetchingCities, deleteCity, mutating } = useCitiesQuery();

  const filteredCities = useMemo(() => {
    const results = Cities?.filter((City: any) =>
      City.label?.toLowerCase()?.includes(searchQuery)
    );
    return results;
  }, [Cities, searchQuery]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredCities?.length / PAGE_SIZE));
  }, [searchQuery, filteredCities]);
  

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const paginatedContent = usePaginatedContent(
    currentPage,
    PAGE_SIZE,
    filteredCities!
  );

  const [CitiesToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    CitiesToBeDeleted.ids.forEach(id => {
      if (!id) return;
      deleteCity(id);
    });
  };

  return (
    <AdminRoute>
      <h5 className="fs-4 fw-bold mb-2">Villes</h5>
      <BoxWrapper>
        <div className="d-flex flex-column-reverse flex-md-row  justify-content-between">
          <div className="d-flex align-items-center gap-2 p-3">
            <button
              onClick={() => setVisible(!visible)}
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
            >
              + Ajouter une ville
            </button>
            <CModal
              alignment="center"
              visible={visible}
              onClose={() => setVisible(false)}
            >
              <NewCategory
                onAccept={() => {}}
                onClose={() => setVisible(false)}
              />
            </CModal>

            <button
              disabled={mutating || CitiesToBeDeleted?.ids.length === 0}
              onClick={handleBulkDelete}
              className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
            >
              {!mutating ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer les villes
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>
          <div className="mt-3">
            <div className="search-form">
              <div className=" input-field">
                <MagnifyingGlassIcon color="gray" width={20} />
                <input
                  type="text"
                  className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                  placeholder="Rechercher"
                  onChange={e =>
                    setSearchQuery(e?.target?.value?.toLowerCase())
                  }
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3 w-100"
              style={{ borderTopRightRadius: "8px" }}
            >
              <CitiesSvg className="fill-white" />
              <p className="fs-5 fw-bold m-0 text-white">
                {Cities?.length} {Cities?.length > 1 ? "Villes" : "Ville"}
              </p>
            </div>
          </div>
        </div>
        <RenderTable
          loading={fetchingCities}
          render={() =>
            paginatedContent?.length > 0 ? (
              <CityTable
                Cities={paginatedContent as City[]}
                CitiesToBeDeletedList={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={1}
        totalCount={totalPages}
        pageSize={PAGE_SIZE}
      />
    </AdminRoute>
  );
}
