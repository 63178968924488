import { CModalHeader } from "@coreui/react";
import RenderTable from "../../components/RenderTable";
import UsersDisplayTable, {
  DisplayUser
} from "./NewNotification/UsersDisplayTable";
import { NotificationStatus } from "./NotificationsTable";

interface IProps {
  notification: ScheduledNotification;
  onClose: () => void;
}

export default function NotificationViewModel({
  notification,
  onClose
}: IProps) {
  return (
    <>
      <CModalHeader>
        <h5 className="fs-5">Détails de la notification</h5>
      </CModalHeader>
      <div className="">
        <section className="p-4">
          <div className="col-12 mb-3">
            <div className="d-flex justify-content-end">
              <NotificationStatus
                sent={notification?.sent}
                date={notification.date}
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="categoryId" className="d-block">
              Titre
            </label>
            <input
              type="text"
              className="custom-input"
              disabled
              value={notification?.title}
            />
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="categoryId" className="d-block">
              Description
            </label>
            <textarea
              name="text"
              id="text"
              cols={30}
              rows={3}
              className="custom-input"
              disabled
            >
              {notification?.text}
            </textarea>
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="categoryId" className="d-block">
              Date
            </label>
            <input
              type="text"
              className="custom-input"
              disabled
              value={new Date(notification.date).toLocaleDateString("fr", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
              })}
            />
          </div>
          {notification?.categories?.length > 0 ? (
            <div className="col-12 mb-3">
              <label htmlFor="categoryId" className="d-block">
                Catégories
              </label>
              {notification.categories?.map(category => (
                <li className="ms-3">{category.label}</li>
              ))}
            </div>
          ) : null}
          {notification?.users?.length > 0 ? (
            <div className="col-12 mb-3">
              <label htmlFor="categoryId" className="d-block">
                Utilisateurs
              </label>
              <RenderTable
                loading={false}
                render={() => {
                  const users = notification.users?.map(user => ({
                    id: user.id,
                    displayValue: user.fullName,
                    email: user.email,
                    profilePicture: user.profilePicture
                  }));
                  return (
                    <UsersDisplayTable
                      users={users as DisplayUser[]}
                      showDeleteBtn={false}
                      deleteUser={(userId: string) => {}}
                    />
                  );
                }}
              />
            </div>
          ) : null}
        </section>
      </div>
    </>
  );
}
