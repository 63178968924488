import React, { useMemo, useReducer, useState } from "react";
import Pagination from "../../components/Pagination";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { Link, useSearchParams } from "react-router-dom";
import BoxWrapper from "../../components/BoxWrapper";
import OffersTable from "./OfferTable";
import { usePaginatedContent } from "../../hooks/usePaginatedContent";
import { general } from "../../locales/general";
import { useOffersQuery } from "../../hooks/Offers/useOffersQuery";
import {
  CSpinner,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem
} from "@coreui/react";
import RenderTable from "../../components/RenderTable";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import { OffersSvg } from "../../components/SideBarMenu";
import { useAuth } from "../../hooks/useAuth";
import { pluralize } from "../../helpers/general";
import { useQueryParam } from "../../hooks/useQueryParam";

const PAGE_SIZE = 10;

export default function Offers() {
  const { add } = useQueryParam();
  const [searchParams] = useSearchParams();

  const {
    Offers,
    fetchingOffers,
    deleteOffer,
    mutating,
    totalOffers,
    totalPages,
    currentPage
  } = useOffersQuery({ refetchOnWindowFocus: true });
  const { isAdmin } = useAuth();

  const [OffersToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    OffersToBeDeleted.ids.forEach(id => {
      if (!id) return;
      deleteOffer(id);
    });
  };

  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Offres</h5>
      <BoxWrapper>
        <div
          className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
          style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
        >
          <OffersSvg className="fill-white" />
          <p className="fs-5 fw-bold m-0  text-white text-pre">
            {totalOffers || 0} {pluralize("Offre", totalOffers)}
          </p>
        </div>
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
          <div className="d-flex flex-wrap align-items-center gap-2 p-3">
            <CDropdown>
              <CDropdownToggle
                className="outline-none rounded-sm bg-primary shadow-secondary text-white d-flex align-items-center gap-2 justify-content-center"
                // color="primary"
              >
                + Ajouter une offre
              </CDropdownToggle>
              <CDropdownMenu className="w-100">
                <CDropdownItem
                  href="/offers/new/special-offer"
                  className="p-0 m-0"
                >
                  <Link
                    to="/offers/new/special-offer"
                    className="text-black w-full p-2"
                    style={{
                      display: "block"
                    }}
                  >
                    Offre spéciale
                  </Link>
                </CDropdownItem>
                <CDropdownItem
                  href="/offers/new/event-offer"
                  className="p-0 m-0"
                >
                  <Link
                    to="/offers/new/event-offer"
                    className="text-black w-full p-2"
                    style={{
                      display: "block"
                    }}
                  >
                    Offre d&apos;événement
                  </Link>
                </CDropdownItem>
                <CDropdownItem
                  href="/offers/new/tour-offer"
                  className="p-0 m-0"
                >
                  <Link
                    to="/offers/new/tour-offer"
                    className="text-black w-full p-2"
                    style={{
                      display: "block"
                    }}
                  >
                    Offre de tourisme
                  </Link>
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
            {isAdmin && (
              <Link
                to="/offers/organize"
                className="w-lg-full p-2 border btn btn-sm btn-primary rounded-sm shadow-secondary text-white"
              >
                Organisez les offres
              </Link>
            )}
            <button
              disabled={mutating || OffersToBeDeleted?.ids.length === 0}
              onClick={handleBulkDelete}
              className="outline-none bg-white btn btn-outline-danger rounded-sm text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
            >
              {!mutating ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer l'offre
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>
          <div className="search-form">
            <div className=" input-field">
              <MagnifyingGlassIcon color="gray" width={20} />
              <input
                type="text"
                className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                placeholder="Rechercher"
                onChange={e => add("search", e?.target?.value?.toLowerCase())}
                defaultValue={searchParams?.get("search") ?? ""}
              />
            </div>
          </div>
        </div>

        <RenderTable
          loading={fetchingOffers}
          render={() =>
            Offers?.length > 0 ? (
              <OffersTable
                offers={Offers as SpecialOffers[]}
                OffersToBeDeleted={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={+currentPage}
        onPageChange={page => {
          add("page", page.toString());
        }}
        siblingCount={0}
        totalCount={totalPages}
        pageSize={PAGE_SIZE}
      />
    </section>
  );
}
