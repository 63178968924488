import {
  CFormCheck,
  CFormInput,
  CModal,
  CModalBody,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useToast } from "../../../hooks/useToast";
import { useMutation, useQueryClient } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import { DELETE_ACTIONS } from "../../../config/constants";
import { general } from "../../../locales/general";
import { colors } from "../../../config/theme";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import EditIcon from "../../../components/icons/EditIcon";
import PromptModal from "../../../components/modals/PromptModal";

function TableBodyRowContent({
  Service,
  deletedList
}: {
  Service: Service;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);

  const deleteService = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`Services/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: Service.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: Service.id });
    }
  }, [deletedList, isChecked, Service.id]);

  const removeService = () => {
    deleteService.mutate(Service.id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.serviceDeleted);
        queryClient.invalidateQueries("getPlaceServices");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 ">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
        />
        <span className="ms-2">02/11/2023</span>
      </CTableDataCell>

      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Service?.label}
      </CTableDataCell>

      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Service?.startingPrice}
      </CTableDataCell>

      <CTableDataCell className="p-3">
        <Link to={`/service/update/${Service.id}`} key={Service.id}>
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </Link>
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
        >
          {deleteService.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeService()}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  Services: Service[];
  ServicesToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function PlaceServicesTable({
  Services,
  ServicesToBeDeleted
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Nom</CTableHeaderCell>

            <CTableHeaderCell scope="col"> Prix de base</CTableHeaderCell>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {Services?.map(Service => (
            <TableBodyRowContent
              Service={Service}
              key={Service?.id}
              deletedList={ServicesToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
