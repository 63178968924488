import { CModal, CModalContent, CSpinner } from "@coreui/react";
import React, { FormEvent, useState } from "react";
import PasswordInput from "../inputs/PasswordInput";
import { useMutation } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";

interface Props {
  visible: boolean;
  onClose: () => void;
  place: Place;
}
interface UpdatePassword {
  newPassword: string;
  confirmPassword: string;
}

const DEFAULT_PASSWORD = {
  password: "",
  confirmation: "",
  isSame: false
};
export default function ModifyAdminPasswordModal({
  onClose,
  visible,
  place
}: Props) {
  const [passwords, setPasswords] = useState(DEFAULT_PASSWORD);
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();

  const changePassword = useMutation((data: UpdatePassword) => {
    return apiService.MakePutRequest(
      `places/admin/reset-password/${place.id}`,
      data,
      token
    );
  });

  const handleInputChange = (
    key: keyof typeof passwords,
    value: string | boolean
  ) => {
    setPasswords(val => ({ ...val, [key]: value }));
  };

  const changePasswordHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    changePassword.mutate(
      {
        newPassword: passwords.password,
        confirmPassword: passwords.confirmation
      },
      {
        onSuccess() {
          onClose();
          setPasswords(DEFAULT_PASSWORD);
          toastSuccess(general.fr.message.password.success);
        },
        onError() {
          toastError(general.fr.message.password.failed);
        }
      }
    );
  };

  return (
    <CModal visible={visible} onClose={onClose}>
      <CModalContent className="py-2">
        <form className="row px-4 mt-3" onSubmit={changePasswordHandler}>
          <h3 className="fs-5 mb-3">Réinitialiser le mot de passe</h3>

          <div className="col-12 mb-3">
            <PasswordInput
              labelText="Nouveau mot de passe"
              className="custom-input"
              name="newPassword"
              id="password"
              minLength={8}
              onChange={e => handleInputChange("password", e.target.value)}
            />
          </div>
          <div className="col-12 mb-3">
            <PasswordInput
              labelText="Confirmez le mot de passe"
              className="custom-input"
              name="confirmPassword"
              minLength={8}
              id="confirmPassword"
              onChange={e => handleInputChange("confirmation", e.target.value)}
              onBlur={() =>
                handleInputChange(
                  "isSame",
                  passwords.confirmation === passwords.password
                )
              }
            />
            {!passwords.isSame &&
              passwords.password.length > 0 &&
              passwords.confirmation.length > 0 && (
                <p className="my-1 text-danger">
                  Les mots de passe ne correspondent pas
                </p>
              )}
          </div>
          <button
            type="submit"
            className="bg-danger p-2 rounded-sm col-12 text-white text-center border-0 mx-auto my-2"
            style={{ width: "95%" }}
            disabled={!passwords.isSame || changePassword.isLoading}
          >
            {changePassword.isLoading ? (
              <CSpinner size="sm" />
            ) : (
              "Mettre à jour le mot de passe"
            )}
          </button>
        </form>
      </CModalContent>
    </CModal>
  );
}
