import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../useCookie";
import { useToast } from "../useToast";
import { general } from "../../locales/general";
import { useState } from "react";
import { toQuery } from "../../helpers/general";

export const usePayoutsQuery = () => {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(["getPayouts", currentPage], query => {
    return apiService.MakeGetRequest(
      `payouts${toQuery({ page: currentPage })}`,
      token
    );
  });

  const processWithdrawalRequest = useMutation((id: string) => {
    return apiService.MakePutRequest(`payouts/${id}/process`, {}, token);
  });

  return {
    processWithdrawalRequest(id: string) {
      processWithdrawalRequest.mutate(id, {
        onError(error) {
          console.log(error);
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.withdrawalProcessed);
          queryClient.invalidateQueries("getPayouts");
        }
      });
    },
    payouts: data?.items,
    totalPayouts: data?.meta?.totalItems,
    totalPages: data?.meta?.totalPages,
    isLoading,
    currentPage,
    setCurrentPage
  };
};
