import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

function TableBodyRowContent({ invoice }: { invoice: Invoice }) {
  return (
    <CTableRow>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {invoice.place.name}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {invoice.year}-{invoice.month}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {invoice.totalHT}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {invoice.totalTTC}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        <a
          className=""
          href={invoice.documentUrl}
          target="_blank"
          rel="noreferrer"
        >
          <ArrowTopRightOnSquareIcon className="me-1" width="23" height="23" />
        </a>
      </CTableDataCell>
    </CTableRow>
  );
}

interface TableProps {
  invoices: Invoice[];
  // invoicesToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function InvoicesTable({ invoices }: TableProps) {
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Établissement</CTableHeaderCell>
            <CTableHeaderCell scope="col">Année-Mois</CTableHeaderCell>
            <CTableHeaderCell scope="col">Total HT</CTableHeaderCell>
            <CTableHeaderCell scope="col">Total TTC</CTableHeaderCell>
            <CTableHeaderCell scope="col">Voir la facture</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {invoices?.map(invoice => (
            <TableBodyRowContent
              invoice={invoice}
              key={invoice?.id}
              // deletedList={packsToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
