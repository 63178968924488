import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { colors } from "../config/theme";
import { usePagination } from "../hooks/usePagination";

interface propsType {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
}
export default function Pagination({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize
}: propsType) {
  const pageLinks = [];
  const visiblePages = pageSize; // Number of visible page links, adjust as needed
  const halfVisible = Math.floor(visiblePages / 2);

  let startPage = Math.max(1, currentPage - halfVisible);
  let endPage = Math.min(totalCount, startPage + visiblePages - 1);

  if (endPage - startPage + 1 < visiblePages) {
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageLinks.push(i);
  }

  if (totalCount <= 1) {
    return null;
  }

  return (
    <nav className="d-flex overflow-auto align-items-center justify-content-center px-4 sm:px-0 gap-5">
      <div className="d-flex align-items-center">
        <button
          type="button"
          onClick={() => onPageChange(currentPage > 1 ? currentPage - 1 : 1)}
          className={`d-flex align-items-center pr-1 text-sm font-medium btn ${
            currentPage === 1 ? "text-light cursor-not-allowed" : "text-black "
          }`}
          aria-disabled={currentPage > 1}
          disabled={currentPage === 1}
        >
          <ChevronLeftIcon className="mr-1 h-5 w-5" aria-hidden="true" />
          Precedent
        </button>
      </div>
      <div className="d-flex align-items-center gap-1">
        {startPage > 1 && (
          <>
            <button
              type="button"
              onClick={() => onPageChange(1)}
              className={`text-black d-flex align-items-center justify-content-center rounded-sm btn btn-sm w-5 h-5 ${
                1 === currentPage ? "bg-danger text-white" : ""
              } `}
              aria-current={1 === currentPage ? "page" : undefined}
            >
              1
            </button>
            {startPage > 2 && (
              <span className="text-black inline-flex items-center text-base font-medium text-black mr-1">
                ...
              </span>
            )}
          </>
        )}
        {pageLinks.map(pageNumber => (
          <button
            key={pageNumber}
            type="button"
            onClick={() => onPageChange(pageNumber)}
            className={`d-flex align-items-center justify-content-center rounded-sm btn btn-sm w-5 h-5 ${
              pageNumber === currentPage ? "bg-danger text-white" : ""
            } `}
            aria-current={pageNumber === currentPage ? "page" : undefined}
          >
            {pageNumber}
          </button>
        ))}
        {endPage < totalCount && (
          <span className="inline-flex items-center text-base font-medium text-black mr-1">
            ...
          </span>
        )}
        {endPage < totalCount && (
          <button
            type="button"
            onClick={() => onPageChange(totalCount)}
            className={`d-flex align-items-center btn ${
              currentPage === totalCount
                ? "text-light cursor-not-allowed"
                : "border-transparent text-black "
            }  text-base font-medium`}
            aria-current={currentPage === totalCount ? "page" : undefined}
          >
            {totalCount}
          </button>
        )}
      </div>
      <div className="d-flex w-0 flex-1">
        <button
          type="button"
          onClick={() =>
            onPageChange(
              currentPage === totalCount ? currentPage : currentPage + 1
            )
          }
          className={`d-flex align-items-center  pl-1 text-sm font-medium btn ${
            currentPage === totalCount
              ? "text-light cursor-not-allowed"
              : "text-black"
          }`}
          aria-disabled={currentPage > totalCount}
          disabled={currentPage === totalCount}
        >
          Suivant
          <ChevronRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
}
