// @ts-nocheck
import { CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../hooks/useToast";
import { useCookie } from "../../../../hooks/useCookie";
import apiService from "../../../../service/apiService";
import { general } from "../../../../locales/general";
import BoxWrapper from "../../../../components/BoxWrapper";
import RichTextEditor from "../../../../components/RichTextEditor";
import ImagePreview from "../../../../components/ImagePreview";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode } from "../../../../helpers/enums";
import { verifyFileList } from "../../../../helpers/general";
import { useForm, Controller } from "react-hook-form";
import { FormSelectAPI } from "../../../../components/FormReactSelect";

interface ServiceType {
  placeId: string;
  label: string;
  picture: File;
  startingPrice: number | string;
  isSpecial: string;
  description: string;
  otherInfo: string | null;
}

const schema = yup.object({
  label: yup.string().required("Ce champ est requis"),
  startingPrice: yup
    .number()
    .nullable(true)
    .typeError()
    .transform((_, val) => {
      const result = val == Number(val) ? Number(val) : null;
      return result;
    }),
  otherInfo: yup.string().nullable(true),
  isSpecial: yup.string().required("Ce champ est requis"),
  picture: yup.mixed().nullable(),
  description: yup.string().nullable(),
  subCategoryId: yup.object().nullable(),
  showInApp: yup.boolean().default(false)
});

export default function PlaceNewService({ mode = FormMode.CREATE }) {
  const [formLoading, setFormLoading] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const [placeId, setPlaceId] = useState<string>("");
  const params = useParams();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");

  const {
    control: formControl,
    register,
    handleSubmit,
    formState: { errors },
    setValue: setFormValue,
    getValues: getFormValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      const formFields = Object.keys(schema.fields);
      apiService
        .MakeGetRequest(`services/${params.id}`, token)
        .then(response => {
          setPlaceId(response?.place.id);
          setDescription(response?.description);
          // setFormValue("price", response?.startingPrice);
          setFormValue("subCategoryId", response?.subCategory);
          setFormValue("picture", response.image);
          setFormValue("showInApp", response?.showInApp);
          Object.entries(response).forEach(entry => {
            if (formFields.indexOf(entry[0]) !== -1) {
              setFormValue(entry[0], entry[1]);
            }
          });
        });
    }
  }, []);

  // @desc create new user
  const createNewServiceMutation = useMutation(async (data: ServiceType) => {
    return await apiService.MakePostRequest("services", data, token, true);
  });

  const updateServiceMutation = useMutation(async (data: ServiceType) => {
    return await apiService.MakePutRequest(
      `services/${params.id}`,
      data,
      token,
      true
    );
  });

  const createService = async data => {
    try {
      setFormLoading(true);
      await createNewServiceMutation.mutateAsync(data);
      toastSuccess(general.fr.message.serviceAdded);
      navigate(`/places/${params?.placeId}/services`);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updateService = async data => {
    try {
      setFormLoading(true);
      await updateServiceMutation.mutateAsync(data);
      toastSuccess(general.fr.message.serviceUpdated);
      navigate(`/places/${placeId}/services`);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async data => {
    try {
      data.placeId = params.placeId;
      data.subCategoryId = data.subCategoryId?.id;
      const imageFile = verifyFileList(data.picture);
      data.picture = imageFile;
      if (mode === FormMode.UPDATE) {
        updateService(data);
      } else {
        createService(data);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter Service</h4>
            </div>
            <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6 mt-4">
                <label htmlFor="label" className="d-block">
                  Nom du service <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder=" Nom du service"
                  id="label"
                  name="label"
                  {...register("label")}
                />
                {errors.label?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.label.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="startingPrice" className="d-block">
                  Prix de base
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Prix de base"
                  id="startingPrice"
                  name="startingPrice"
                  {...register("startingPrice")}
                />
                {errors.startingPrice?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.startingPrice.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="otherInfo" className="d-block">
                  Autres informations
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Autres informations"
                  id="otherInfo"
                  name="otherInfo"
                  {...register("otherInfo")}
                />
                {errors.otherInfo?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.otherInfo.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="isSpecial" className="d-block">
                  Offre spéciale <span className="text-md text-red">*</span>
                </label>
                <CFormSelect
                  name="isSpecial"
                  id="isSpecial"
                  {...register("isSpecial")}
                >
                  <option selected value="">
                    Choisir une option
                  </option>
                  <option value="true">Oui</option>
                  <option value="false">Non</option>
                </CFormSelect>
                {errors.isSpecial?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.isSpecial.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="picture" className="d-block">
                  Image
                </label>
                {mode === FormMode.UPDATE && getFormValue("picture") ? (
                  <ImagePreview url={getFormValue("picture")} />
                ) : null}
                <CFormInput
                  type="file"
                  id="picture"
                  name="picture"
                  className="my-3"
                  {...register("picture")}
                />
                {errors.picture?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.picture.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="price" className="d-block mb-3">
                  Catégorie
                </label>
                <Controller
                  name="subCategoryId"
                  control={formControl}
                  render={({ field }) => {
                    return (
                      <FormSelectAPI
                        name="subCategoryId"
                        id="subCategoryId"
                        isClearable={false}
                        error={errors?.subCategoryId?.message}
                        getOptionLabel={option => option?.label}
                        getOptionValue={option => option.id}
                        isOptionSelected={(option, selectedValue) => {
                          const isSelected =
                            option?.id === selectedValue?.[0]?.id;
                          return isSelected;
                        }}
                        url={{
                          path: "service-categories/sub-categories"
                        }}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.subCategoryId?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.subCategoryId.message}
                  </div>
                )}
              </div>
              <div className="mt-4 col-md-6">
                <input
                  type="checkbox"
                  id="showInApp"
                  {...register("showInApp")}
                />
                <label htmlFor="showInApp" className="ms-2">
                  Afficher le service dans l'application ?
                </label>
              </div>
              <div className="mt-4">
                <label htmlFor="eventDescription" className="d-block">
                  Description
                </label>
                <RichTextEditor
                  onChange={data => {
                    setFormValue("description", data);
                    setDescription(data);
                  }}
                  defaultValue={description}
                />
                {errors.description?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.description.message}
                  </div>
                )}
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to={`/places/${params?.placeId!}/services`}
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success green-shadow  w-20 text-white"
                  type="submit"
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
