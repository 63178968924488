import { TrashIcon } from "@heroicons/react/24/solid";

interface VideoInputProps {
  defaultValue: string;
  saved: boolean;
  onRemove: () => void;
  index?: number;
}

const YOUTUBE_BASE_URL = `https://www.youtube.com/watch?v=`;

export default function VideoInput({
  defaultValue,
  onRemove,
  index
}: VideoInputProps) {
  console.log(index);
  return (
    <div className="d-flex align-items-center gap-2" style={{ width: "45%" }}>
      <input
        type="url"
        name="ytVid"
        id="ytVid"
        className="custom-input"
        placeholder="Youtube video url"
        defaultValue={
          defaultValue?.length > 0
            ? `${YOUTUBE_BASE_URL}${defaultValue}`
            : defaultValue
        }
        required
        pattern="^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$"
      />

      <button type="button" className="btn btn-danger" onClick={onRemove}>
        <TrashIcon className="w-5 h-5" color="white" />
      </button>
    </div>
  );
}
