import {
  CPopover,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useState } from "react";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { colors } from "../../config/theme";
import PromptModal from "../../components/modals/PromptModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import Pagination from "../../components/Pagination";
import { format } from "date-fns";

const TableContent = ({ Request }: { Request: PartnerRequest }) => {
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);

  const deletePartnerRequest = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`partners/requests/${id}`, token);
  });

  const deletePequest = (id: string) => {
    deletePartnerRequest.mutate(id, {
      onError() {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        queryClient.invalidateQueries("getPartnersRequest");
        toastSuccess(general.fr.message.partnerDeleted);
        setShowModal(false);
      }
    });
  };

  return (
    <>
      <CTableRow key={Request?.id}>
        <CTableDataCell className="text-sm">
          {format(new Date(Request.createdAt), "dd/MM/yyyy")}
        </CTableDataCell>
        <CTableDataCell className="text-sm">{Request?.fullName}</CTableDataCell>
        <CTableDataCell className="text-sm">{Request?.email}</CTableDataCell>
        <CTableDataCell className="text-sm">{Request?.phone}</CTableDataCell>
        <CTableDataCell className="text-sm">
          {Request?.businessName}
        </CTableDataCell>
        <CTableDataCell className="text-sm">
          {Request?.city?.label}
        </CTableDataCell>

        <CTableDataCell>
          <div className="d-flex align-items-center gap-3">
            <button
              onClick={() => setShowModal(true)}
              className="outline-none border-0 bg-transparent"
            >
              <DeleteIcon
                width="20"
                height="20"
                fill={colors.primary}
                className="ms-2"
              />
            </button>
          </div>
        </CTableDataCell>
      </CTableRow>
      <PromptModal
        onAccept={() => deletePequest(Request?.id)}
        onClose={() => setShowModal(false)}
        open={showModal}
      />
    </>
  );
};

const PAGE_SIZE = 10;
export default function PartnerRequests() {
  const { token } = useCookie("vToken");
  const [currentPage, setCurrentPage] = useState(1);

  const { data: partnerRequests } = useQuery(
    ["getPartnersRequest", currentPage],
    () => {
      return apiService.MakeGetRequest(
        `partners/requests?page=${currentPage}&limit=${PAGE_SIZE}`,
        token
      );
    }
  );

  return (
    <div>
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm">
              Date
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Nom complet
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Email
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Téléphone
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Nom de l'entreprise
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Ville
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {partnerRequests?.items?.map((Request: PartnerRequest) => (
            <TableContent key={Request?.id} Request={Request} />
          ))}
        </CTableBody>
      </CTable>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={partnerRequests?.meta?.totalPages}
        pageSize={PAGE_SIZE}
      />
    </div>
  );
}
