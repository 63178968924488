import React from "react";
import { IconTypes } from "./types";
export default function CheckIcon({
    width = "16",
    height = "20",
    fill = "#FC376B",
    ...rest
}: Partial<IconTypes>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" {...rest}>
            <path d="m9 11 3 3L22 4" />
            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
        </svg>
    );
}
