import {
  CFormCheck,
  CFormInput,
  CModal,
  CModalBody,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useToast } from "../../../hooks/useToast";
import { useMutation, useQueryClient } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import { DELETE_ACTIONS } from "../../../config/constants";
import { general } from "../../../locales/general";
import { colors } from "../../../config/theme";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import EditIcon from "../../../components/icons/EditIcon";
import PromptModal from "../../../components/modals/PromptModal";

function TableBodyRowContent({
  Offer,
  deletedList
}: {
  Offer: SpecialOffers;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);

  const deleteOffer = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`specials/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: Offer.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: Offer.id });
    }
  }, [deletedList, isChecked, Offer.id]);

  const removeOffer = () => {
    deleteOffer.mutate(Offer.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.offerDeleted);
        queryClient.invalidateQueries("getPlaceOffers");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 ">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
        />
        <span className="ms-2 text-sm">
          {new Date(Offer.createdAt).toLocaleDateString("fr")}
        </span>{" "}
      </CTableDataCell>

      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Offer.title}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Offer.place?.name}
      </CTableDataCell>

      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Offer.reductionPercent > 0 ? Offer.reductionPercent : "0"}%
      </CTableDataCell>
      <CTableDataCell className="p-3">
        <Link to={`/offers/update/${Offer.id}`} key={Offer.id}>
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </Link>
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
        >
          {deleteOffer.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeOffer()}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  offers: SpecialOffers[];
  OffersToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function PlaceOfferTable({
  offers,
  OffersToBeDeleted
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Nom</CTableHeaderCell>
            <CTableHeaderCell scope="col">Place</CTableHeaderCell>

            <CTableHeaderCell scope="col">Remise</CTableHeaderCell>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {offers?.map(offer => (
            <TableBodyRowContent
              Offer={offer}
              key={offer?.id}
              deletedList={OffersToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
