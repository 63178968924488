import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { BookingStatus, BookingStatusDisplayValue } from "../../helpers/enums";
import { toQuery } from "../../helpers/general";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import { useDebounce } from "../../hooks/useDebounce";
import { useQueryParam } from "../../hooks/useQueryParam";
import { FormSelectAPI } from "../../components/FormReactSelect";
import { useAuth } from "../../hooks/useAuth";
import InfiniteScrollSelect from "../../components/InfiniteScrollSelect";

const Filters = [
  { label: BookingStatusDisplayValue.CONFIRMED, key: BookingStatus.CONFIRMED },
  { label: BookingStatusDisplayValue.PASSED, key: BookingStatus.PASSED },
  { label: BookingStatusDisplayValue.CANCELED, key: BookingStatus.CANCELED },
  { label: BookingStatusDisplayValue.REFUSED, key: BookingStatus.REFUSED },
  { label: BookingStatusDisplayValue.WAITING, key: BookingStatus.WAITING }
];

interface FormFields {
  search: string;
  place: string;
}
export default function BookingFilters({ disableFilters = false }) {
  const [searchParams] = useSearchParams();
  const { register, watch, setValue } = useForm<FormFields>();
  const { add, remove } = useQueryParam();
  const search = useDebounce(watch("search"), 1000);
  const [selectedPlace, setSelectedPlace] = useState("");

  const { isAdmin } = useAuth();

  useEffect(() => {
    setValue("search", searchParams.get("search") as string);
  }, []);

  useEffect(() => {
    if (search && search !== undefined) {
      add("search", search);
    } else {
      remove("search");
    }
  }, [search]);

  const resetStateFilters = () => {
    remove("status");
    setValue("search", "");
    setSelectedPlace("");
  };

  return (
    <nav className="d-flex flex-column flex-lg-row px-3 border-bottom mb-4">
      <div className="d-flex overflow-auto gap-1 align-items-center h-100 pt-3">
        <Link
          to={disableFilters ? "#" : "/bookings"}
          className={`text-sm py-3 fw-semibold  ${
            searchParams.size === 0
              ? "text-primary border-bottom border-primary"
              : "text-light"
          }`}
          onClick={resetStateFilters}
          style={{ height: "100%" }}
        >
          Toutes
        </Link>
        {Filters.map(filter => {
          const selected = searchParams.get("status") === filter.key;
          return (
            <button
              onClick={() => add("status", filter.key)}
              key={filter.key}
              style={{ height: "100%", borderRadius: "0" }}
              className={`text-sm py-3 fw-semibold h-100 border-0 d-block btn ${
                selected
                  ? "text-primary border-bottom border-primary"
                  : "text-light"
              } `}
              disabled={disableFilters}
            >
              {filter.label}
            </button>
          );
        })}
      </div>
      <div className="pt-3 d-flex align-items-center gap-2 ms-lg-auto booking-search-wrapper ">
        {isAdmin && (
          <div className="mb-2 w-75 ">
            <InfiniteScrollSelect
              key={"places"}
              isClearable={false}
              getOptionLabel={(option: Place) => option?.name}
              getOptionValue={(option: Place) => option.id}
              isOptionSelected={(option: Place, selectedValue: Place[]) => {
                const isSelected = option.name === searchParams.get("place");
                return isSelected;
              }}
              url={{
                path: "places"
              }}
              value={selectedPlace}
              placeholder={
                selectedPlace.length > 0 ? selectedPlace : "Filtrer par lieu"
              }
              onChange={(place: Place) => {
                setSelectedPlace(place.name);
                add("place", place?.id);
              }}
            />
          </div>
        )}
        <div
          className="search border rounded-sm px-2 mb-2 w-75"
          style={{ paddingBlock: "3px" }}
        >
          <div className="input-field">
            <MagnifyingGlassIcon color="gray" width={20} />
            <input
              type="text"
              className="w-75 border-0  outline-none rounded-sm p-1 text-gray fs-6 "
              placeholder="Rechercher"
              {...register("search")}
              readOnly={disableFilters}
              disabled={disableFilters}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
