import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { CModal } from "@coreui/react";
import { useCookie } from "../../hooks/useCookie";
import apiService from "../../service/apiService";
import BoxWrapper from "../../components/BoxWrapper";
import NewInvoice from "./NewInvoice";
import InvoicesSvg from "../../components/icons/InvoiceIcon";
import Pagination from "../../components/Pagination";
import RenderTable from "../../components/RenderTable";
import { general } from "../../locales/general";
import { useAuthContext } from "../../context/auth";
import InvoicesTable from "./InvoicesTable";
import UpdateCompanyInfoModal from "../../components/modals/UpdateCompanyInfoModal";
import { useToast } from "../../hooks/useToast";

const PAGE_SIZE = 10;

export default function Invoices() {
  const [currentPage, setCurrentPage] = useState(1);
  const [companyInfoVisible, setCompanyInfoVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();
  const params = useParams();
  const { user } = useAuthContext();

  const endpoint = `places/${params?.placeId}/invoices?limit=${PAGE_SIZE}&page=${currentPage}`;

  const queryKey = ["getPlaceInvoices", endpoint, token];

  const { data: placeInvoices, isLoading, isPreviousData } = useQuery(
    queryKey,
    () => {
      return apiService.MakeGetRequest(endpoint, token);
    }
  );

  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Factures</h5>
      <BoxWrapper>
        <div className="d-flex  justify-content-between align-items-baseline before-table">
          <div className="d-flex align-items-center gap-2 p-3 delete-add">
            <button
              onClick={() => setVisible(!visible)}
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
            >
              Générer une facture
            </button>
            <CModal alignment="center" visible={visible}>
              <NewInvoice
                placeId={params?.placeId ?? ""}
                onAccept={() => {}}
                onClose={() => setVisible(false)}
                onError={(error: any) => {
                  console.log(error?.response?.data?.EN);
                  if (
                    error?.response?.data?.message ===
                    "Company's informations are missing !"
                  ) {
                    // show company info modal
                    setVisible(false);
                    setCompanyInfoVisible(true);
                  } else if (
                    error?.response?.data?.message ===
                    "There is no bookings on this month !"
                  ) {
                    toastError(
                      "Il n'y a aucune réservation dans le mois sélectionné"
                    );
                    setVisible(false);
                  }
                }}
              />
            </CModal>
            <CModal alignment="center" visible={companyInfoVisible}>
              <UpdateCompanyInfoModal
                alert="Veuillez saisir les informations de votre société pour générer la facture"
                placeId={params?.placeId ?? ""}
                onAccept={() => {
                  setVisible(true);
                  setCompanyInfoVisible(false);
                }}
                onClose={() => setCompanyInfoVisible(false)}
              />
            </CModal>

            {/* <button
            disabled={deletePack.isLoading || packsToBeDeleted?.ids?.length === 0}
            onClick={handleBulkDelete}
            className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
          >
            {!deletePack.isLoading ? (
              <>
                <DeleteIcon fill={colors.primary} /> Supprimer le Pack
              </>
            ) : (
              <>
                <CSpinner size="sm" /> {general.fr.wait}
              </>
            )}
          </button> */}
          </div>
          {/* <div className="search-form">
          <div className=" input-field">
            <MagnifyingGlassIcon color="gray" width={20} />
            <input
              type="text"
              className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
              placeholder="Rechercher"
              onChange={(e) =>
                // setQuery(e?.target?.value?.toLowerCase())
                console.log(e)
              }
            />
          </div>
        </div> */}
          <div
            className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
            style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
          >
            <InvoicesSvg className="fill-white" />
            <p className="fs-5 fw-bold m-0  text-white text-pre">
              {placeInvoices?.meta?.totalItems}{" "}
              {placeInvoices?.meta?.totalItems > 1 ? "Factures" : "Facture"}
            </p>
          </div>
        </div>

        <RenderTable
          loading={isLoading || isPreviousData}
          render={() =>
            placeInvoices?.items?.length > 0 || user?.adminForPlace ? (
              <InvoicesTable
                invoices={placeInvoices?.items as Invoice[]}
                // packsToBeDeleted={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={placeInvoices?.meta?.totalPages}
        pageSize={PAGE_SIZE}
      />
    </section>
  );
}
