import { useState } from "react";
import { Link } from "react-router-dom";
import BoxWrapper from "../../components/BoxWrapper";
import AdminRoute from "../auth/RestrictedRoute";
import { MessagesWhatsappSvg } from "../../components/SideBarMenu";
import RenderTable from "../../components/RenderTable";
import WhatsappMessagesTable from "./WhatsappMessagesTable";
import Pagination from "../../components/Pagination";
import { useCookie } from "../../hooks/useCookie";
import { pluralize, toQuery } from "../../helpers/general";
import { useQuery } from "react-query";
import apiService from "../../service/apiService";
import { general } from "../../locales/general";

const PAGE_SIZE = 10;

export default function WhatsappMessages() {
  const [currentPage, setCurrentPage] = useState(1);

  const data = {
    meta: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 0
    },
    items: [
      {
        id: 0,
        title: "test",
        date  : new Date()
      },
      {
        id: 0,
        title: "test",
        date  : new Date()
      }
    ]
  };
  const isLoading = false;
  const isPreviousData = false;

  return (
    <AdminRoute>
      <section>
        <h5 className="fs-4 fw-bold mb-2">Messages de WhatsApp</h5>
        <BoxWrapper>
          <div className="d-flex  justify-content-between align-items-baseline before-table">
            <div className="d-flex align-items-center gap-2 p-3 delete-add">
              <Link
                to=""
                className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
              >
                + Ajouter un message
              </Link>
            </div>
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
              style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
            >
              <MessagesWhatsappSvg className="fill-white" />
              <p className="fs-5 fw-bold m-0  text-white text-pre">
                {data?.meta?.totalItems}{" "}
                {pluralize("Message", data?.meta?.totalItems)}
              </p>
            </div>
          </div>

          <RenderTable
            loading={isLoading || isPreviousData}
            render={() =>
              data?.items?.length > 0 ? (
                <WhatsappMessagesTable whatsappMessages={data?.items} />
              ) : (
                <p className="text-center fs-5 my-2">{general.fr.noData}</p>
              )
            }
          />
        </BoxWrapper>
        <Pagination
          currentPage={currentPage}
          onPageChange={page => {
            setCurrentPage(page);
          }}
          siblingCount={1}
          totalCount={data?.meta?.totalPages}
          pageSize={PAGE_SIZE}
        />
      </section>
    </AdminRoute>
  );
}
