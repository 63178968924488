import { CFormInput, CSpinner } from "@coreui/react";
import React, { FormEvent } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import BoxWrapper from "../../../components/BoxWrapper";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";
import RestrictedRoute from "../../auth/RestrictedRoute";

interface Partner {
  name: string;
  logo: File;
  link: string;
}

interface Props {
  onClose: () => void;
  onAccept: () => void;
}

export default function NewPartner({ onClose }: Props) {
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // @desc create new user
  const createNewPartnerMutation = useMutation(async (data: Partner) => {
    return await apiService.MakePostRequest("partners", data, token, true);
  });

  // @desc form submit handler
  const createPartnerHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get("PartnerName") as string;
    const logo = formData.get("logo") as File;
    const link = formData.get("PartnerLink") as string;

    const data = {
      logo,
      name,
      link
    };
    createNewPartnerMutation.mutate(data, {
      onSuccess(data: any) {
        toastSuccess(general.fr.message.partnerCreated);
        queryClient.invalidateQueries("getAllPartners");
        navigate(`/partners`);
        onClose();
      },
      onError(error: any) {
        toastError(general.fr.message.operationFailed);
      }
    });
  };
  return (
    <RestrictedRoute>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter un partenaire</h4>
            </div>
            <form className="row form mt-4" onSubmit={createPartnerHandler}>
              <div className="col-md-6 mt-4">
                <label htmlFor="PartnerName" className="d-block">
                  Nom du partenaire
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="  Nom du partenaire"
                  id="PartnerName"
                  name="PartnerName"
                  required
                />
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="logo" className="d-block">
                  Logo du partenaire
                </label>
                <CFormInput
                  type="file"
                  id="logo"
                  name="logo"
                  className="mt-1"
                  required
                />
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="PartnerLink" className="d-block">
                  Site web du partenaire <br />
                  <span className="text-sm text-danger fst-italic">
                    {" "}
                    (Exemple : https://www.exemple.com )
                  </span>
                </label>
                <input
                  type="url"
                  className="custom-input"
                  placeholder="Site web du partenaire"
                  id="PartnerLink"
                  name="PartnerLink"
                  required
                />
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <button
                  className="btn btn-danger shadow-primary w-20 px-4 py-2 text-white"
                  type="button"
                  onClick={onClose}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-primary shadow-secondary green-shadow w-20 text-white"
                  type="submit"
                  disabled={createNewPartnerMutation.isLoading}
                >
                  {createNewPartnerMutation.isLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </RestrictedRoute>
  );
}
