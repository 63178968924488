import { CTabContent } from "@coreui/react";
import BoxWrapper from "../../components/BoxWrapper";
import { usePayoutsQuery } from "../../hooks/payouts/usePayoutsQuery";
import RenderTable from "../../components/RenderTable";
import PayoutsTable from "./PayoutsTable";
import { general } from "../../locales/general";
import { useAuthContext } from "../../context/auth";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import WidthrawalRequestModal from "../../components/modals/WithdrawalRequestModal";
import Pagination from "../../components/Pagination";

const MoneySvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <circle cx="8" cy="8" r="6" />
      <path d="M18.09 10.37A6 6 0 1 1 10.34 18" />
      <path d="M7 6h1v4" />
      <path d="m16.71 13.88.7.71-2.82 2.82" />{" "}
    </svg>
  );
};

const PAGE_SIZE = 10;
export default function Payouts() {
  const { user } = useAuthContext();
  const { isAdmin } = useAuth();
  const [withdrawalModalVisible, setWithdrawalModalVisible] = useState(false);
  const {
    payouts,
    totalPayouts,
    totalPages,
    isLoading,
    currentPage,
    setCurrentPage
  } = usePayoutsQuery();

  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Paiements</h5>
      <BoxWrapper>
        <CTabContent>
          {!isAdmin && (
            <div
              className="d-flex justify-content-between align-items-baseline before-table"
              id="payouts"
            >
              <button
                className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary mx-5 my-3"
                onClick={() => setWithdrawalModalVisible(true)}
                disabled={payouts?.length === 0}
              >
                Retirer le solde
              </button>
              <div
                className="d-flex col-end align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{
                  borderTopRightRadius: "8px",
                  alignSelf: "flex-start"
                }}
              >
                <MoneySvg />
                <p className="fs-5 fw-bold m-0  text-white">
                  {user?.adminForPlace?.balance + " DH"}
                </p>
              </div>
            </div>
          )}
          <RenderTable
            loading={isLoading}
            render={() =>
              payouts?.length > 0 ? (
                <PayoutsTable payouts={payouts as Payout[]} />
              ) : (
                <p className="text-center fs-5 py-5">{general.fr.noData}</p>
              )
            }
          />

          <WidthrawalRequestModal
            alert="Veuillez saisir le montant à retirer"
            open={withdrawalModalVisible}
            onAccept={() => {
              setWithdrawalModalVisible(false);
            }}
            onClose={() => setWithdrawalModalVisible(false)}
          />
        </CTabContent>
      </BoxWrapper>
      {/* TODO: Add pagination */}
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={totalPages}
        pageSize={PAGE_SIZE}
      />
    </section>
  );
}
