import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../useCookie";
import { general } from "../../locales/general";
import { useToast } from "../useToast";

export const useUserQuery = () => {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();

  const { data, isLoading } = useQuery(["getAllUsers"], query => {
    return apiService.MakeGetRequest("users/clients", token);
  });

  const deleteUser = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`users/${id}`, token);
  });

  return {
    Users: data?.items,
    totalUsers: data?.meta?.totalItems,
    totalPages: data?.meta?.totalPages,
    removeUser(id: string) {
      deleteUser.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.userDeleted);
          queryClient.invalidateQueries("getAllUsers");
        }
      });
    },
    mutating: deleteUser.isLoading
  };
};
