import React, { useMemo } from "react";
import Icon from "../../components/icons";
import { general } from "../../locales/general";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";

SwiperCore.use([Navigation]);

interface Props {
  menuURL: string | null | undefined;
  services: Service[];
}
export default function Services({ menuURL, services }: Props) {
  const nextBtn = `popular-meals-swiper-button-next`;
  const prevBtn = `popular-meals-swiper-button-prev`;

  const specialOffers = useMemo(
    () => services?.filter(service => service?.isSpecial),
    [services]
  );
  return (
    <section className="mt-4 col-md-8">
      {!menuURL && services?.length === 0 && (
        <p className="text-gray">{general.fr.noData}</p>
      )}
      {services?.map(service => (
        <div key={service?.id} className="mb-3">
          <h6 className="mb-2 fw-semibold text-sm text-capitalize">
            {service?.label}
          </h6>
          <div className="d-flex justify-content-between">
            <p className="text-sm ">{service?.otherInfo}</p>

            <p className="text-gray whitespace-nowrap overflow-hidden">
              ----------------------------
            </p>
            <p className="text-sm whitespace-nowrap ps-1">
              {service?.startingPrice} DH
            </p>
          </div>
        </div>
      ))}
      {specialOffers?.length > 0 && (
        <div className="mt-12">
          <h6 className="mb-1 fw-semibold text-sm">Services spéciaux</h6>
          <div className="position-relative mt-10">
            <button
              className={`${prevBtn} d-flex align-items-center justify-content-center rounded-md border hover:bg-gray-200 slider-btn position-absolute  `}
              style={{
                width: "45px",
                height: "45px",
                top: "-10%",
                right: "11%",
                cursor: "pointer",
                zIndex: "9999"
              }}
            >
              <Icon name="arrowLeft" />
            </button>

            <Swiper
              spaceBetween={20}
              className="venez-meal-swiper"
              navigation={{
                nextEl: `.${nextBtn}`,
                prevEl: `.${prevBtn}`
              }}
            >
              {specialOffers?.map((service, index) => (
                <SwiperSlide key={index}>
                  <MealCard service={service} />
                </SwiperSlide>
              ))}
            </Swiper>

            <button
              className={`${nextBtn} d-flex align-items-center justify-content-center rounded-md border position-absolute`}
              style={{
                width: "45px",
                height: "45px",
                top: "-10%",
                right: "0",
                cursor: "pointer",
                zIndex: "9999"
              }}
            >
              <Icon name="arrowRight" />
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

function MealCard({ service }: { service: Service }) {
  return (
    <div style={{ width: "140px" }}>
      <img
        src={service?.image}
        alt="meal"
        height={96}
        width={"100%"}
        className="rounded-tr-xl rounded-tl-xl"
      />

      <p className="mt-2 mb-0">{service?.label}</p>
      <p className="text-gradient-primary fw-semibold">
        {service?.startingPrice} DH
      </p>
    </div>
  );
}
