// @ts-nocheck
import React, { useEffect } from "react";
import { CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import { useState } from "react";
import { useCookie } from "../../../hooks/useCookie";
import { general } from "../../../locales/general";
import { useToast } from "../../../hooks/useToast";
import { useMutation } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode } from "../../../helpers/enums";
import { useForm, Controller } from "react-hook-form";
import apiService from "../../../service/apiService";
import RichTextEditor from "../../../components/RichTextEditor";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";
import { verifyFileList } from "../../../helpers/general";
import FileUploader from "../../../components/FileUploader";

interface SpecialTranslationData {
  name: string;
  text: string;
  picture?: File;
  carouselImage: File;
  lang: string;
  specialId: string;
}

export default function NewSpecialTranslation({ mode = FormMode.CREATE }) {
  const location = useLocation();
  const { offer } = location.state || {};
  const [formLoading, setFormLoading] = useState(false);
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [images, setImages] = useState({
    image: "",
    carouselImage: ""
  });
  const [variantsTranslations, setVariantsTranslations] = useState([]);
  const [specialVariants, setSpecialVariants] = useState([]);
  const [specialId, setSpecialId] = useState(offer?.id || "");

  const schema = yup.object({
    title: yup.string().required(),
    text: yup.string().nullable(),
    picture: yup.mixed().nullable(),
    carouselImage: yup.mixed().required(),
    lang: yup.string().required(),
    specialId: yup.object().required()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  });

  useEffect(() => {
    setFormValue("specialId", offer);
    setSpecialVariants(offer?.variants);
  },[offer?.id]);

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      const specialGetRequest = apiService.MakeGetRequest(
        `special-translations/${params.id}`,
        token
      );

      Promise.all([specialGetRequest]).then(([specialResp]) => {
        const formFields = Object.keys(schema.fields);
        setText(specialResp?.text);
        setImages({
          image: specialResp.image,
          carouselImage: specialResp.carouselPicture
        });
        setFormValue("specialId", specialResp.special);
        setSpecialVariants(specialResp.special.variants);
        setSpecialId(specialResp.special.id);
        Object.entries(specialResp).forEach(entry => {
          if (formFields.indexOf(entry[0]) !== -1) {
            setFormValue(entry[0], entry[1]);
          }
        });
      });
    }
  }, [params?.id]);

  useEffect(() => {
    if (specialId) {
      apiService
        .MakeGetRequest(`special-translations/${specialId}/variants`, token)
        .then(response => {
          const variantsTranslations = [];
          response?.forEach(variant => {
            if (variant.translations?.length) {
              variant.translations.forEach(translation => {
                const translationVariant = {
                  variantId: variant.id,
                  lang: translation.lang,
                  translatedDescription: translation.description
                };
                variantsTranslations.push(translationVariant);
              });
            } else {
              const translationVariant = {
                variantId: variant.id,
                lang: "ar",
                translatedDescription: ""
              };
              variantsTranslations.push(translationVariant);
            }
          });
          setVariantsTranslations(variantsTranslations);
        });
    }
  }, [specialId]);

  const createSpecialTranslationTranslationMutation = useMutation(
    async specialTranslationData => {
      return await apiService.MakePostRequest(
        "special-translations",
        specialTranslationData,
        token,
        true
      );
    }
  );

  const updateSpecialTranslationMutation = useMutation(
    async specialTranslationData => {
      return await apiService.MakePutRequest(
        `special-translations/${params.id}`,
        specialTranslationData,
        token,
        true
      );
    }
  );

  const updateSpecialVariantsTranslationsMutation = useMutation(
    async specialVariantsTranslationsData => {
      return await apiService.MakePutRequest(
        `special-translations/${specialVariantsTranslationsData.specialId}/variants-translations`,
        specialVariantsTranslationsData,
        token
      );
    }
  );

  const createSpecialTranslation = async data => {
    try {
      setFormLoading(true);
      await createSpecialTranslationTranslationMutation.mutateAsync(data);
      await updateSpecialVariantsTranslationsMutation.mutateAsync({
        specialVariantsTranslations: variantsTranslations.map(
          variantTranslation => ({
            description: variantTranslation.translatedDescription,
            lang: variantTranslation.lang,
            specialVariantId: variantTranslation.variantId
          })
        ),
        specialId: data.specialId
      });
      toastSuccess(general.fr.message.translationCreated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updateSpecialTranslation = async data => {
    try {
      setFormLoading(true);
      const updateSpecialTranslationReq = updateSpecialTranslationMutation.mutateAsync(
        data
      );
      const updateSpecialVariantsTranslationsReq = updateSpecialVariantsTranslationsMutation.mutateAsync(
        {
          specialVariantsTranslations: variantsTranslations.map(
            variantTranslation => ({
              description: variantTranslation.translatedDescription,
              lang: variantTranslation.lang,
              specialVariantId: variantTranslation.variantId
            })
          ),
          specialId: data.specialId
        }
      );
      await Promise.all([
        updateSpecialTranslationReq,
        updateSpecialVariantsTranslationsReq
      ]);
      toastSuccess(general.fr.message.translationUpdated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async (dataArg: SpecialTranslationData) => {
    const data = { ...dataArg };
    try {
      data.specialId = data.specialId.id;
      const pictureFile = verifyFileList(data.picture);
      const carouselPictureFile = verifyFileList(data.carouselImage);
      data.picture = pictureFile;
      data.carouselImage = carouselPictureFile;
      if (mode === FormMode.UPDATE) {
        updateSpecialTranslation({ ...data, text });
      } else {
        createSpecialTranslation({ ...data, text });
      }
    } catch (error) {
      setFormLoading(false);
      console.log(error);
      toastError(general.fr.message.operationFailed);
    }
  };

  const deleteVariantTranslation = index => {
    const variantsTranslationsArray = [...variantsTranslations];
    variantsTranslationsArray.splice(index, 1);
    setVariantsTranslations(variantsTranslationsArray);
  };

  const handleVariantChange = (e, index) => {
    const { name, value } = e.target;
    setVariantsTranslations(prevState => {
      const updatedItems = [...prevState];
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value
      };
      return updatedItems;
    });
  };

  return (
    <div className="m-4">
      <BoxWrapper>
        <section className="p-4">
          <div className="border-bottom border-success">
            <h4>
              {mode === FormMode.UPDATE
                ? "Mise à jour"
                : "Ajouter une traduction"}
            </h4>
          </div>
          <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-6 my-3">
              <label htmlFor="specialId" className="d-block mb-2">
                L'offre <span className="text-md text-red">*</span>
              </label>
              <Controller
                name="specialId"
                control={formControl}
                render={({ field }) => {
                  return (
                    <InfiniteScrollSelect
                      name="specialId"
                      id="specialId"
                      isClearable={false}
                      defaultValue={offer}
                      error={errors?.specialId?.message}
                      getOptionLabel={option => option?.title}
                      getOptionValue={option => option.id}
                      isOptionSelected={(option, selectedValue) => {
                        const isSelected =
                          option?.id === selectedValue?.[0]?.id;
                        return isSelected;
                      }}
                      onSelect={special => {
                        setSpecialId(special.id);
                        setSpecialVariants(special.variants);
                      }}
                      url={{
                        path: "specials/all"
                      }}
                      {...field}
                    />
                  );
                }}
              />
              {errors.specialId?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.specialId.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3">
              <label htmlFor="lang" className="d-block">
                La langue
                <span className="text-sm text-red"> *</span>
              </label>
              <CFormSelect name="lang" id="lang" {...register("lang")}>
                <option value="en">Anglais</option>
                <option value="ar">Arabic</option>
                <option value="fr">Français</option>
              </CFormSelect>
              {errors.lang?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.supportTableSelection.message}
                </div>
              )}
            </div>
            <div className="col-md-6  mt-4 ">
              <label htmlFor="title" className="d-block">
                Titre de l'offre <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                specialholder="Titre de l'offre"
                id="title"
                name="title"
                {...register("title")}
              />
              {errors.title?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.title.message}
                </div>
              )}
            </div>

            <div className="col-md-6 mt-4">
              <label htmlFor="picture" className="d-block mb-1">
                Image
              </label>
              <FileUploader
                name="picture"
                id="picture"
                accept="image/png,image/jpeg,image/jpg"
                {...register("picture")}
                file={images?.image}
                required={false}
              />
              {errors.picture?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.picture.message}
                </div>
              )}
            </div>

            <div className="col-md-6 mt-4">
              <label htmlFor="carouselImage" className="d-block mb-1">
                Image du carrousel <span className="text-md text-red">*</span>
              </label>
              <FileUploader
                name="carouselImage"
                id="carouselImage"
                accept="image/png,image/jpeg,image/jpg"
                {...register("carouselImage")}
                file={images?.carouselImage}
                required={true}
              />
              {errors.carouselImage?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.carouselImage.message}
                </div>
              )}
            </div>

            <div className="mt-4">
              <label htmlFor="text">Description de l'offre</label>
              <RichTextEditor
                onChange={setText}
                // {...register("text")}
                name="text"
                defaultValue={text}
              />
            </div>

            {specialVariants?.length !== 0 ? (
              <div>
                <section className="mt-5">
                  <h5>Les variants</h5>
                </section>
                {variantsTranslations.map((variant, index) => {
                  return (
                    <div
                      key={variant?.id}
                      className="mt-4 d-flex justify-items-center align-items-end"
                    >
                      <div
                        className="d-flex justify-items-center align-items-end"
                        // style={{
                        //     opacity: packItem?.isDeleted ? 0.4 : 1,
                        //     pointerEvents: packItem?.isDeleted ? "none" : "all"
                        // }}
                      >
                        <div
                          className="me-3"
                          style={{
                            minWidth: "90px"
                          }}
                        >
                          Traduction {index + 1}:{" "}
                        </div>
                        <CFormSelect
                          className="ms-3"
                          name="variantId"
                          id="variantId"
                          value={variant.variantId}
                          onChange={e => handleVariantChange(e, index)}
                        >
                          {specialVariants.map(specialVariant => (
                            <option
                              key={specialVariant.id}
                              value={specialVariant.id}
                            >
                              {specialVariant.description}
                            </option>
                          ))}
                        </CFormSelect>

                        <CFormSelect
                          className="ms-3"
                          name="lang"
                          id="lang"
                          value={variant.lang}
                          onChange={e => handleVariantChange(e, index)}
                        >
                          <option value="en">Anglais</option>
                          <option value="ar">Arabic</option>
                          <option value="fr">Français</option>
                        </CFormSelect>

                        <input
                          className="custom-input ms-3"
                          type="text"
                          name="translatedDescription"
                          value={variant.translatedDescription}
                          onChange={e => handleVariantChange(e, index)}
                        />
                        <button
                          type="button"
                          className="btn btn-danger green-shadow w-20 text-white ms-3"
                          onClick={() => deleteVariantTranslation(index)}
                        >
                          Supprimer
                        </button>
                      </div>
                      {/* {packItem.isDeleted ? ( */}
                      {/* <button
                                        type="button"
                                        className="btn btn-danger green-shadow w-20 text-white ms-3"
                                    // onClick={() =>
                                    //     updatePackItemTranslationField(index, {
                                    //         ...packItem,
                                    //         isDeleted: false
                                    //     })
                                    // }
                                    >
                                        <UndoIcon />
                                    </button> */}
                      {/* ) : null} */}
                    </div>
                  );
                })}
                <section className="mt-5">
                  <button
                    type="button"
                    className="btn btn-info green-shadow w-20 text-white"
                    onClick={() => {
                      setVariantsTranslations(prev => [
                        ...prev,
                        {
                          variantId: specialVariants[0]?.id,
                          lang: "en",
                          translatedDescription: ""
                        }
                      ]);
                    }}
                  >
                    Ajouter un variant
                  </button>
                </section>
              </div>
            ) : null}

            <section className="d-flex justify-content-center buttons gap-4 mt-5">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
              >
                Annuler
              </button>
              <button
                className="btn btn-success green-shadow w-20 text-white"
                type="submit"
                disabled={formLoading}
              >
                {formLoading ? (
                  <div className="text-center">
                    <CSpinner size="sm" />
                  </div>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </section>
          </form>
        </section>
      </BoxWrapper>
    </div>
  );
}
