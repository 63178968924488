// @ts-nocheck
import { useState, useEffect } from "react";
import {
  CFormInput,
  CSpinner,
  CFormTextarea,
  CFormCheck,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CModal
} from "@coreui/react";
import BoxWrapper from "../../../components/BoxWrapper";
import RestrictedRoute from "../../auth/RestrictedRoute";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../../../service/apiService";
import { useToast } from "../../../hooks/useToast";
import CategoryModel from "./CategoryModel";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import UsersModel from "./UsersModel";
import { general } from "../../../locales/general";
import { useMutation } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import RenderTable from "../../../components/RenderTable";
import UsersDisplayTable, { DisplayUser } from "./UsersDisplayTable";
import { createZoneAwareDate } from "../../../helpers/general";
import NotificationTargets from "./NotificationTargets";

enum SendNotificationType {
  NOW = "now",
  CUSTOM = "custom"
}

enum NotificationUserType {
  CATEGORY = "category",
  USERS = "users"
}

const userItemSchema = yup.object({
  id: yup.string().required("Ce champ est obligatoire"),
  displayValue: yup.string().required("Ce champ est obligatoire"),
  profilePicture: yup.string().nullable(),
  email: yup.string().nullable()
});

const usersSchema = yup.object({
  type: yup.string().required("Ce champ est obligatoire"),
  items: yup
    .array()
    .of(userItemSchema)
    .min(1, "Ce champ est obligatoire")
});

const schema = yup.object().shape({
  title: yup.string().required("Ce champ est obligatoire"),
  description: yup.string().nullable(),
  sendType: yup.string().required("Ce champ est obligatoire"),
  sendToAllUsers: yup.string().nullable(),
  target: yup
    .string()
    .required("Ce champ est obligatoire")
    .default("place"),
  targetId: yup
    .string()
    .nullable()
    .when("target", {
      is: "none",
      thene: () => yup.string().nullable(),
      otherwise: () => yup.string().required("Ce champ est obligatoire")
    }),
  date: yup
    .string()
    .nullable()
    .when("sendType", {
      is: "custom",
      then: () =>
        yup
          .date()
          .required("Ce champ est obligatoire")
          .typeError("Ce champ est obligatoire")
    }),
  users: yup
    .array()
    .ensure()
    .when("sendToAllUsers", {
      is: "false",
      then: () =>
        yup
          .array()
          .of(usersSchema)
          .min(1, "Ce champ est obligatoire"),
      otherwise: () =>
        yup
          .array()
          .of(usersSchema)
          .min(0)
    })
});

export default function NewNotification() {
  const [formLoading, setFormLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [categoryModelVisible, setCategoryModelVisible] = useState(false);
  const [usersModelVisible, setUsersModelVisible] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    watch: watchFormValue,
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  const createNotiticationMutation = useMutation((data: any) => {
    return apiService.MakePostRequest("notifications", data, token);
  });

  const {
    fields: usersFields,
    append: appendUsersField,
    remove: removeUsersField
  } = useFieldArray({
    control: formControl,
    name: "users"
  });

  useEffect(() => {
    apiService.MakeGetRequest("categories").then(res => {
      setCategories(res);
    });
  }, []);

  const deleteUsersField = (item: any, index: number) => {
    if (item.type === NotificationUserType.CATEGORY) {
      setSelectedCategories(current =>
        current.filter(cat => cat.id !== item.items?.[0].id)
      );
    }
    removeUsersField(index);
  };

  const deleteUserField = (userId: string) => {
    const userField = usersFields.find(
      (item: any) => item.type === NotificationUserType.USERS
    );
    if (userField) {
      const userIndex = userField?.items?.findIndex(
        (user: any) => user.id === userId
      );
      if (userIndex !== -1) {
        const users = userField?.items?.filter(
          (user: any) => user.id !== userId
        );
        removeUsersField(usersFields.indexOf(userField));
        appendUsersField({
          type: NotificationUserType.USERS,
          items: users
        });
        setSelectedUsers(users);
      }
    }
  };

  const isCustomDate =
    watchFormValue("sendType") === SendNotificationType.CUSTOM;
  const isSendToAllUsers = watchFormValue("sendToAllUsers");

  const onSubmit = async (dataArg: any) => {
    const data = { ...dataArg };
    try {
      setFormLoading(true);
      const notificationUsers: string[] = [];
      const notificationCategories: string[] = [];
      data.users.forEach((item: any) => {
        if (item.type === NotificationUserType.CATEGORY) {
          notificationCategories.push(item.items?.[0].id);
        } else if (item.type === NotificationUserType.USERS) {
          notificationUsers.push(...item.items.map((user: any) => user.id));
        }
      });
      const timezonedDate = createZoneAwareDate(data.date);
      const payload = {
        title: data.title,
        text: data.description,
        target: data.target,
        targetId: data.targetId,
        ...(data.sendType === SendNotificationType.NOW
          ? { sendNow: true }
          : { date: timezonedDate }),
        sendToAllUsers: data.sendToAllUsers,
        users: data.sendToAllUsers === "true" ? [] : notificationUsers,
        categories: notificationCategories
      };
      await createNotiticationMutation.mutateAsync(payload);
      toastSuccess(general.fr.message.notificationCreated);
      navigate("/notifications");
    } catch (error) {
      setFormLoading(false);
      console.log(error);
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <RestrictedRoute>
      <CModal visible={categoryModelVisible}>
        <CategoryModel
          categories={categories.filter(
            cat => !selectedCategories.find(selected => selected.id === cat.id)
          )}
          onSelect={category => {
            appendUsersField({
              type: NotificationUserType.CATEGORY,
              items: [
                {
                  id: category.id,
                  displayValue: category.label
                }
              ]
            });
            setSelectedCategories(current => [...current, category]);
            setCategoryModelVisible(false);
          }}
          onClose={() => setCategoryModelVisible(false)}
        />
      </CModal>
      <CModal visible={usersModelVisible} size="lg">
        <UsersModel
          selectedUsers={selectedUsers}
          onSelect={users => {
            const prvUsersField = usersFields.find(
              (item: any) => item.type === NotificationUserType.USERS
            );
            if (prvUsersField) {
              removeUsersField(usersFields.indexOf(prvUsersField));
            }
            appendUsersField({
              type: NotificationUserType.USERS,
              items: users?.map(user => ({
                id: user.id,
                displayValue: user.fullName,
                profilePicture: user?.profilePicture,
                email: user.email
              }))
            });
            setSelectedUsers(users);
            setUsersModelVisible(false);
          }}
          onClose={() => setUsersModelVisible(false)}
        />
      </CModal>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter une notification</h4>
            </div>
            <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Titre <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className={`custom-input ${errors?.title?.message ? "is-invalid" : ""
                    }`}
                  placeholder="Titre de la notification"
                  id="title"
                  {...register("title")}
                />
                {errors.title?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.title.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="image" className="d-block mb-1">
                  Description
                </label>
                <CFormTextarea
                  className={`custom-input ${errors?.description?.message ? "is-invalid" : ""
                    }`}
                  placeholder="Description de la notification"
                  id="description"
                  {...register("description")}
                />
                {errors.description?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.description.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="date" className="d-block mb-1">
                  Cible de notification{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <div className="d-flex gap-4 flex-wrap">
                  <Controller
                    name="target"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="place"
                        label="Établissements"
                        value="place"
                        checked={field.value === "place" || !field.value}
                        onChange={val => field.onChange(val)}
                      />
                    )}
                  />
                  <Controller
                    name="target"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="offer"
                        label="offres"
                        value="offer"
                        checked={field.value === "offer"}
                        onChange={val => field.onChange(val)}
                      />
                    )}
                  />
                  <Controller
                    name="target"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="event"
                        label="Evénements"
                        value="event"
                        checked={field.value === "event"}
                        onChange={val => field.onChange(val)}
                      />
                    )}
                  />
                  <Controller
                    name="target"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="none"
                        label="Aucun"
                        value="none"
                        checked={field.value === "none"}
                        onChange={val => field.onChange(val)}
                      />
                    )}
                  />
                </div>
                {errors.target?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.target.message}
                  </div>
                )}
              </div>
              {watchFormValue("target") !== "none" ? (
                <div className="col-md-6 mt-4">
                  <label htmlFor="date" className="d-block mb-1">
                    Choisissez une cible
                    <span className="text-md text-red">*</span>
                  </label>
                  <NotificationTargets
                    target={watchFormValue("target")}
                    onChange={(val: string) => setValue("targetId", val)}
                  />
                  {errors.targetId?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.targetId.message}
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-md-6 mt-4"></div>
              )}

              <div className="col-md-6 mt-4">
                <label htmlFor="date" className="d-block mb-1">
                  Quand envoyer? <span className="text-md text-red">*</span>
                </label>
                <div className="d-flex gap-4 flex-wrap">
                  <Controller
                    name="sendType"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="Immédiatement"
                        label="Immédiatement"
                        value="now"
                        checked={field.value === "now"}
                        onChange={val => field.onChange(val)}
                      />
                    )}
                  />
                  <Controller
                    name="sendType"
                    control={formControl}
                    render={({ field }) => (
                      <CFormCheck
                        type="radio"
                        id="Personnalisé"
                        label="Personnalisé"
                        value="custom"
                        checked={field.value === "custom"}
                        onChange={val => field.onChange(val)}
                      />
                    )}
                  />
                </div>

                {isCustomDate && (
                  <div className="">
                    <CFormInput
                      type="datetime-local"
                      className="custom-input"
                      placeholder="Description de la notification"
                      id="date"
                      {...register("date")}
                    />
                    {errors.date?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.date.message}
                      </div>
                    )}
                  </div>
                )}

                {errors.sendType?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.sendType.message}
                  </div>
                )}
              </div>

              <div className="col-md-12 mt-4">
                <label htmlFor="users" className="d-block mb-2">
                  Utilisateurs <span className="text-md text-red">*</span>
                </label>
                {errors.users?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.users.message}
                  </div>
                )}
                <div className="col-md-6 mb-2">
                  <input
                    type="checkbox"
                    name="sendToAllUsers"
                    id="sendToAllUsers"
                    {...register("sendToAllUsers")}
                  />
                  <label
                    htmlFor="sendToAllUsers"
                    className="ms-2 d-inline-block"
                  >
                    Envoyer à tous les utilisateurs
                  </label>
                  {errors.sendToAllUsers?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.sendToAllUsers.message}
                    </div>
                  )}
                </div>
                {usersFields.map((item, index) => {
                  if (item.type === NotificationUserType.CATEGORY) {
                    return (
                      <div
                        key={item.id}
                        className="ms-3 d-flex align-items-center mb-2"
                      >
                        <div className="text-black me-2">- Category: </div>
                        <div>{item.items?.[0].displayValue}</div>
                        <button
                          type="button"
                          className="btn text-red"
                          onClick={() => deleteUsersField(item, index)}
                        >
                          <DeleteIcon width="20" height="20" />
                        </button>
                      </div>
                    );
                  } else if (
                    item.type === NotificationUserType.USERS &&
                    item?.items?.length
                  ) {
                    return (
                      <div key={item.id} className="ms-3 mb-2">
                        <div className="text-black me-2">
                          - Utilisateurs sélectionnés:
                        </div>
                        <div
                          className="ms-3"
                          style={{
                            maxWidth: "400px"
                          }}
                        >
                          <RenderTable
                            loading={false}
                            render={() => (
                              <UsersDisplayTable
                                users={item.items as DisplayUser[]}
                                deleteUser={(userId: string) => {
                                  deleteUserField(userId);
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>

              <div className="mt-2 w-[100px]">
                <CDropdown>
                  <CDropdownToggle
                    className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center"
                    color="primary"
                    disabled={isSendToAllUsers}
                  >
                    + Ajouter des utilisateurs
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem
                      className="m-0 text-black w-full p-2"
                      onClick={() => {
                        setCategoryModelVisible(true);
                      }}
                    >
                      Utilisateurs d'une catégorie
                    </CDropdownItem>
                    <CDropdownItem
                      className="m-0 text-black w-full p-2"
                      onClick={() => {
                        setUsersModelVisible(true);
                      }}
                    >
                      Sélectionnez les utilisateurs
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to="/notifications"
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success shadow-secondary w-20 text-white"
                  type="submit"
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </RestrictedRoute>
  );
}
