import {
  CFormCheck,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import EditIcon from "../../components/icons/EditIcon";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { useQueryClient, useMutation } from "react-query";
import { useToast } from "../../hooks/useToast";
import { DELETE_ACTIONS } from "../../config/constants";
import { useCookie } from "../../hooks/useCookie";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import { Link } from "react-router-dom";
import PromptModal from "../../components/modals/PromptModal";
import { useAuth } from "../../hooks/useAuth";
import { OFFER_TYPES } from "../../helpers/enums";
import TranslateIcon from "../../components/icons/TranslateIcon";

const getOfferUpdateLink = (offer: { id: string; type: string }) => {
  if (offer.type === OFFER_TYPES.SPECIAL) {
    return `/offers/update/special-offer/${offer.id}`;
  } else if (offer.type === OFFER_TYPES.EVENT) {
    return `/offers/update/event-offer/${offer.id}`;
  } else if (offer.type === OFFER_TYPES.TOUR) {
    return `/offers/update/tour-offer/${offer.id}`;
  }
  return "";
};

export const offersDisplayType: { [key: string]: string } = {
  SPECIAL_OFFER: "Offre spéciale",
  TOUR_OFFER: "Offre de tourisme",
  EVENT_OFFER: "Offre d'événement"
};

function TableBodyRowContent({
  Offer,
  deletedList
}: {
  Offer: SpecialOffers;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useAuth();

  const deleteOffer = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`specials/${id}`, token);
  });
  const updateOfferStatus = useMutation((url: string) => {
    return apiService.MakePutRequest(`${url}`, {}, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: Offer.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: Offer.id });
    }
  }, [deletedList, isChecked, Offer.id]);

  const removeOffer = () => {
    deleteOffer.mutate(Offer.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.offerDeleted);
        queryClient.invalidateQueries("getAllOffers");
      }
    });
  };

  const approveOffer = (id: string) => {
    updateOfferStatus.mutate(`specials/${id}/approve`, {
      onSuccess() {
        toastSuccess("Offre approuvée");
        queryClient.invalidateQueries("getAllOffers");
      },
      onError() {
        toastError(general.fr.message.operationFailed);
      }
    });
  };
  const rejectOffer = (id: string) => {
    updateOfferStatus.mutate(`specials/${id}/reject`, {
      onSuccess() {
        toastSuccess("Offre rejetée");
        queryClient.invalidateQueries("getAllOffers");
      },
      onError() {
        toastError(general.fr.message.operationFailed);
      }
    });
  };

  const offerStatus = Offer?.status?.label;
  const approved = offerStatus === "approved";
  const rejected = offerStatus === "rejected";
  const pending = offerStatus?.toLowerCase() === "pending";

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 ">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
        />
        <span className="ms-2 text-sm">
          {Offer?.title ?? Offer?.event?.name}
        </span>
      </CTableDataCell>

      {isAdmin && (
        <CTableDataCell className="p-3 text-capitalize text-sm">
          {Offer?.place?.name ?? Offer?.event?.address}
        </CTableDataCell>
      )}
      <CTableDataCell className="p-3 text-capitalize text-sm text-danger">
        {approved ? "Approuvé" : pending ? "En attente" : "Refusé"}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Offer?.reductionPercent > 0 ? `${Offer?.reductionPercent}%` : ""}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Offer?.price > 0 ? Offer?.price : ""}
      </CTableDataCell>

      <CTableDataCell className="p-3 ">
        <span className="text-sm py-1 px-3 bg-primary rounded-full text-white text-center text-capitalize fw-bold">
          {" "}
          {/* @ts-ignore */}
          {offersDisplayType[Offer?.type]}
        </span>
      </CTableDataCell>
      <CTableDataCell className="p-3 justify-self-end">
        {!approved && isAdmin && (
          <button
            disabled={updateOfferStatus.isLoading}
            onClick={() => approveOffer(Offer?.id)}
            className="border-0 bg-primary text-center outline-none px-3 py-2 text-white text-sm rounded-full me-1"
          >
            Approuver
          </button>
        )}
        {!rejected && isAdmin && (
          <button
            disabled={updateOfferStatus.isLoading}
            onClick={() => rejectOffer(Offer?.id)}
            className="border-0 bg-danger text-center outline-none px-3 py-2 text-white text-sm rounded-full"
          >
            Refuser
          </button>
        )}

        <Link to={getOfferUpdateLink(Offer)} key={Offer.id} className="ms-4">
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </Link>
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
          disabled={updateOfferStatus.isLoading}
        >
          {deleteOffer.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>

        {isAdmin && (
          <Link
            to={"/special-translations/new"}
            state={{ offer: Offer }}
            className="ms-4"
          >
            <TranslateIcon width="21" height="21" fill={"#000"} />
          </Link>
        )}
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeOffer()}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  offers: SpecialOffers[];
  OffersToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function OffersTable({ offers, OffersToBeDeleted }: TableProps) {
  const { isAdmin } = useAuth();
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Titre</CTableHeaderCell>
            {isAdmin && (
              <CTableHeaderCell scope="col">Établissement</CTableHeaderCell>
            )}
            <CTableHeaderCell scope="col">Statut</CTableHeaderCell>
            <CTableHeaderCell scope="col">Remise</CTableHeaderCell>
            <CTableHeaderCell scope="col">Prix</CTableHeaderCell>
            <CTableHeaderCell scope="col">Type</CTableHeaderCell>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {offers?.map(offer => (
            <TableBodyRowContent
              Offer={offer}
              key={offer?.id}
              deletedList={OffersToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
