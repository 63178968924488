import React, { useState } from "react";
import BoxWrapper from "../../components/BoxWrapper";
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react";
import CategoriesTable from "./CategoriesTable";
import SubCategoriesTable from "./SubCategoriesTable";


export default function ServiceCategories() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Catégories de services</h5>
      <BoxWrapper className="w-100 rounded-lg my-3 rounded-top">
        <CNav variant="tabs" className="">
          <CNavItem
            href="#categories"
            active={activeTab === 0}
            onClick={() => setActiveTab(0)}
          >
            <CNavLink className="text-black">Catégories</CNavLink>
          </CNavItem>

          <CNavItem
            href="#subcategories"
            active={activeTab === 1}
            onClick={() => setActiveTab(1)}
          >
            <CNavLink className="text-black">Sous-catégories</CNavLink>
          </CNavItem>
        </CNav>

        <CTabContent>
          <CTabPane
            style={{ backgroundColor: "transparent" }}
            visible={activeTab === 0}
          >
            <CategoriesTable />
          </CTabPane>
          <CTabPane visible={activeTab === 1}>
            <SubCategoriesTable />
          </CTabPane>
        </CTabContent>
      </BoxWrapper>
    </section>
  );
}
