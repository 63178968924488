import { useState, useEffect } from "react";
import { CFormInput, CSpinner, CAlert } from "@coreui/react";
import * as yup from "yup";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";

interface IProps {
  alert: string | null;
  placeId: string;
  onClose: () => void;
  onAccept: () => void;
}

const schema = yup.object({
  companyName: yup.string().required("Ce champ est obligatoire"),
  companyAddress: yup.string().required("Ce champ est obligatoire"),
  IF: yup.string().required("Ce champ est obligatoire"),
  IC: yup.string().required("Ce champ est obligatoire")
});

export default function UpdateCompanyInfoModal({
  alert = null,
  placeId,
  onClose,
  onAccept
}: IProps) {
  const [formLoading, setFormLoading] = useState(true);
  const [placeInfo, setPlaceInfo] = useState({} as any);
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setFormValue,
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    // fetch company info in case place has partially filled in some of company info
    const formFields = Object.keys(schema.fields);
    apiService.MakeGetRequest(`places/${placeId}`).then(response => {
      formFields.forEach(field => {
        // @ts-ignore
        setFormValue(field, response?.[field]);
      })
      setPlaceInfo(response);
      setFormLoading(false);
    });
  }, [placeId, setFormValue]);

  const updatePlaceMutation = useMutation(async placeData => {
    return await apiService.MakePutRequest(
      `places/${placeId}`,
      placeData,
      token,
      true
    );
  });

  const onSubmit = async (data: any) => {
    try {
      data.name = placeInfo.name;
      await updatePlaceMutation.mutateAsync(data);
      toastSuccess(general.fr.message.companyInfoUpdated);
      onAccept();
    } catch (error) {
      toastError(general.fr.message.operationFailed);
      onClose();
    }
  };

  return (
    <div className="">
      <section className="p-4">
        <div className="border-bottom border-success mb-3">
          <h5>Informations de la société</h5>
        </div>
        <div className="mb-3">
          <CAlert color="danger">{alert}</CAlert>
        </div>
        <form className="row form " onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 mb-3">
            <label htmlFor="companyName" className="d-block">
              Nom de la société
            </label>
            <CFormInput
              type="text"
              className="custom-input"
              placeholder="Nom de Place"
              id="companyName"
              {...register("companyName")}
            />
            {errors.companyName?.message && (
              <div
                className="text-red-500 text-opacity-50"
                style={{ color: "red" }}
              >
                {errors.companyName.message}
              </div>
            )}
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="companyAddress" className="d-block">
              Address de la société
            </label>
            <CFormInput
              type="text"
              className="custom-input"
              placeholder="Nom de Place"
              id="companyAddress"
              {...register("companyAddress")}
            />
            {errors.companyAddress?.message && (
              <div
                className="text-red-500 text-opacity-50"
                style={{ color: "red" }}
              >
                {errors.companyAddress.message}
              </div>
            )}
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="IF" className="d-block">
              IF
            </label>
            <CFormInput
              type="text"
              className="custom-input"
              placeholder="Nom de Place"
              id="IF"
              {...register("IF")}
            />
            {errors.IF?.message && (
              <div
                className="text-red-500 text-opacity-50"
                style={{ color: "red" }}
              >
                {errors.IF.message}
              </div>
            )}
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="IC" className="d-block">
              IC
            </label>
            <CFormInput
              type="text"
              className="custom-input"
              placeholder="Nom de Place"
              id="IC"
              {...register("IC")}
            />
            {errors.IC?.message && (
              <div
                className="text-red-500 text-opacity-50"
                style={{ color: "red" }}
              >
                {errors.IC.message}
              </div>
            )}
          </div>

          <section className="d-flex justify-content-start buttons gap-4 mt-3">
            <button
              className="btn btn-danger text-white shadow-primary w-20 px-4 py-2"
              type="button"
              onClick={onClose}
            >
              Annuler
            </button>
            <button
              className="btn btn-success shadow-secondary w-20 text-white"
              type="submit"
              disabled={updatePlaceMutation.isLoading || formLoading}
            >
              {updatePlaceMutation.isLoading ? (
                <div className="text-center">
                  <CSpinner size="sm" />
                </div>
              ) : (
                "Enregistrer"
              )}
            </button>
          </section>
        </form>
      </section>
    </div>
  );
}
