import { useState } from "react";
import AdminRoute from "../auth/RestrictedRoute";
import BoxWrapper from "../../components/BoxWrapper";
import { Link } from "react-router-dom";
import { NotificationsSvg } from "../../components/SideBarMenu";
import Pagination from "../../components/Pagination";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { useQuery } from "react-query";
import RenderTable from "../../components/RenderTable";
import { general } from "../../locales/general";
import NotificationsTable from "./NotificationsTable";
import { pluralize, toQuery } from "../../helpers/general";

const PAGE_SIZE = 10;

export default function Notifications() {
  const [currentPage, setCurrentPage] = useState(1);
  const { token } = useCookie("vToken");

  const endpoint = `notifications/scheduled${toQuery({
    page: currentPage,
    limit: PAGE_SIZE
  })}`;
  const queryKeys = ["getAllNotifications", token, currentPage, endpoint];
  const { data, isLoading, isPreviousData } = useQuery(queryKeys, query => {
    return apiService.MakeGetRequest(endpoint, query.queryKey[1]);
  });

  return (
    <AdminRoute>
      <section>
        <h5 className="fs-4 fw-bold mb-2">Notifications</h5>
        <BoxWrapper>
          <div className="d-flex  justify-content-between align-items-baseline before-table">
            <div className="d-flex align-items-center gap-2 p-3 delete-add">
              <Link
                to="/notifications/new"
                className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
              >
                + Ajouter une notification
              </Link>
            </div>
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
              style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
            >
              <NotificationsSvg className="fill-white" />
              <p className="fs-5 fw-bold m-0  text-white text-pre">
                {data?.meta?.totalItems}{" "}
                {pluralize("Notification", data?.meta?.totalItems)}
              </p>
            </div>
          </div>

          <RenderTable
            loading={isLoading || isPreviousData}
            render={() =>
              data?.items?.length > 0 ? (
                <NotificationsTable
                  notifications={data?.items as ScheduledNotification[]}
                />
              ) : (
                <p className="text-center fs-5 my-2">{general.fr.noData}</p>
              )
            }
          />
        </BoxWrapper>
        <Pagination
          currentPage={currentPage}
          onPageChange={page => {
            setCurrentPage(page);
          }}
          siblingCount={0}
          totalCount={data?.meta?.totalPages}
          pageSize={PAGE_SIZE}
        />
      </section>
    </AdminRoute>
  );
}
