import React from "react";
import "./styles/main.scss";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import router from "./route";
import AuthContextProvider from "./context/auth";
import "swiper/css";
import "react-loading-skeleton/dist/skeleton.css";
import { Toaster } from "react-hot-toast";

const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <AuthContextProvider>
          <RouterProvider router={router} />
          <Toaster position="top-right" />
        </AuthContextProvider>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
