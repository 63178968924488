import React from "react";
import { useMutation } from "react-query";
import apiService from "../../service/apiService";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth";
import { useToast } from "../../hooks/useToast";
import { useCookie } from "../../hooks/useCookie";
import { CSpinner } from "@coreui/react";

interface Props {
  user: User | null;
}

const CONTENT = [
  {
    title: "Objet du contrat ",
    content:
      "Le propriétaire / gérant de l'établissement autorise le Bénéficiaire à utiliser le nom, la description et les images de l'établissement dans son application de réservation et de référencement de restaurants, clubs,événements etc. ('l'Application'). "
  },
  {
    title: "Autorisation d'utilisation ",
    content:
      "Le propriétaire / gérant de l'établissement accorde au Bénéficiaire une licence non exclusive, non transférable et révocable pour utiliser le nom, la description et les images de l'établissement dans l'Application. Le Bénéficiaire est autorisé à utiliser ces éléments uniquement dans le cadre de la promotion, de la réservation et du référencement de l'établissement dans l'Application. "
  },
  {
    title: "Propriété intellectuelle ",
    content:
      "Le propriétaire / gérant de l'établissement déclare et garantit qu'il détient tous les droits de propriété intellectuelle nécessaires sur le nom, la description et les images de l'établissement, et qu'il est autorisé à les accorder au Bénéficiaire aux termes du présent Contrat. Le Bénéficiaire reconnaît que tous les droits de propriété intellectuelle sur le nom, la description et les images de l'établissement restent la propriété exclusive du propriétaire / gérant de l'établissement. "
  },
  {
    title: "Utilisation restreinte ",
    content:
      "Le Bénéficiaire s'engage à n'utiliser le nom, la description et les images de l'établissement que dans le cadre de l'Application et conformément aux termes du présent Contrat. Le Bénéficiaire s'engage à ne pas modifier, altérer, copier, reproduire, distribuer ou exploiter les éléments concédés sous licence sans l'autorisation écrite préalable du propriétaire / gérant de l'établissement. "
  },
  {
    title: "Durée et résiliation ",
    content:
      "Le présent Contrat entre en vigueur à la date de sa signature et demeure en vigueur jusqu'à ce qu'il soit résilié par l'une ou l'autre des Parties. Chaque Partie peut résilier le Contrat moyennant un préavis écrit de 30 jours à l'autre Partie. "
  },
  {
    title: "Confidentialité ",
    content:
      "Le Bénéficiaire s'engage à maintenir la confidentialité de toute information confidentielle fournie par le propriétaire / gérant de l'établissement dans le cadre du présent Contrat."
  },
  {
    title: "Responsabilité ",
    content: "",
    subContent: [
      {
        title: "Responsabilité du propriétaire / gérant de l'établissement ",
        content:
          "Le propriétaire / gérant de l'établissement est seul responsable du contenu, de l'exactitude et de la légalité du nom, de la description et des images de l'établissement fournis à des fins d'utilisation dans l'Application. Le propriétaire / gérant de l'établissement garantit que ces éléments ne portent pas atteinte aux droits de tiers et ne contiennent aucun contenu diffamatoire, offensant, ou contraire à la loi. "
      },
      {
        title: "Décharge de responsabilité du Bénéficiaire ",
        content:
          "Le Bénéficiaire décline toute responsabilité découlant de l'utilisation du nom, de la description et des images de l'établissement dans l'Application, pour autant que ces éléments aient été fournis par le propriétaire / gérant de l'établissement. Le Bénéficiaire n'assume aucune responsabilité en cas de réclamations, pertes, dommages ou litiges résultant de l'utilisation de ces éléments dans l'Application. "
      },
      {
        title: "Indemnisation ",
        content:
          "En cas de réclamation, d'action en justice ou de demande de tiers contre le Bénéficiaire liée à l'utilisation du nom, de la description et des images de l'établissement dans l'Application, le propriétaire / gérant de l'établissement s'engage à indemniser le Bénéficiaire et à le tenir indemne de tous les frais, dommages-intérêts, pertes ou dépenses encourus, y compris les frais juridiques raisonnables. "
      }
    ]
  },
  {
    title: "Accès à l'espace administratif ",
    content:
      "En plus de l'autorisation d'utilisation, le Bénéficiaire accorde au propriétaire / gérant de l'établissement un accès à un espace administratif sécurisé dans l'Application. Cet accès permettra au propriétaire / gérant de l'établissement de modifier les informations sur son établissement, de gérer les réservations et d'effectuer d'autres tâches liées à l'administration de son établissement dans l'Application. ",
    subContent: [
      {
        title: "Identifiants d'accès ",
        content:
          "Le propriétaire / gérant de l'établissement s'engage à fournir au Bénéficiaire les informations nécessaires pour la création des identifiants d'accès à l'espace administratif de l'Application. Ces identifiants seront strictement confidentiels et ne devront être utilisés que par le propriétaire / gérant de l'établissement et son personnel autorisé. Le propriétaire / gérant de l'établissement est entièrement responsable de la sécurité et de l'utilisation appropriée de ces identifiants. "
      },
      {
        title: "Responsabilité de l'utilisation ",
        content:
          "Le propriétaire / gérant de l'établissement reconnaît et accepte que l'utilisation des identifiants d'accès à l'espace administratif de l'Application relève de sa seule responsabilité. Le propriétaire / gérant de l'établissement est tenu de prendre toutes les mesures nécessaires pour prévenir tout accès non autorisé à l'espace administratif de l'Application."
      },
      {
        title: "Utilisation restreinte ",
        content:
          "Le propriétaire / gérant de l'établissement s'engage à utiliser l'espace administratif de l'Application uniquement dans le but de gérer les informations sur son établissement et les réservations associées. Le propriétaire / gérant de l'établissement s'engage à ne pas utiliser cet accès à des fins frauduleuses, illégales, diffamatoires ou préjudiciables aux intérêts du Bénéficiaire ou de tout tiers. "
      },
      {
        title: "Résiliation de l'accès ",
        content:
          "Le Bénéficiaire se réserve le droit de résilier l'accès à l'espace administratif de l'Application en cas de violation par le propriétaire / gérant de l'établissement des termes du présent Contrat ou en cas d'utilisation abusive de cet accès. En cas de résiliation, le propriétaire / gérant de l'établissement devra cesser immédiatement d'utiliser l'espace administratif et supprimer toutes les informations et données associées à son établissement de l'Application. "
      }
    ]
  },
  {
    title: "Accès au service de l'application ",
    content:
      "Le Bénéficiaire accorde au propriétaire / gérant de l'établissement l'accès gratuit au service de l'application pour une période d'un an à compter de la date d'entrée en vigueur du présent Contrat. Pendant cette période, le propriétaire / gérant de l'établissement pourra utiliser le service de l'application pour la promotion, la réservation et le référencement de son établissement sans frais, à l'exception des ventes de billets en ligne et du référencement de l'établissement sponsorisé. ",
    subContent: [
      {
        title: "Ventes de billets en ligne ",
        content:
          "Le propriétaire / gérant de l'établissement reconnaît et accepte que toute vente de billets en ligne effectuée via l'application sera soumise à une tarification spécifique et à des frais de transaction convenus séparément entre les Parties. Ces tarifs et frais de transaction seront régis par des accords supplémentaires entre le propriétaire / gérant de l'établissement et le Bénéficiaire. "
      },
      {
        title: "Référencement de l'établissement sponsorisé ",
        content:
          "Le propriétaire / gérant de l'établissement a la possibilité de bénéficier d'un référencement sponsorisé dans l'application moyennant des frais supplémentaires convenus entre les Parties. Le propriétaire / gérant reconnaît que le référencement sponsorisé peut offrir une visibilité accrue de son établissement dans l'application, ce qui peut potentiellement augmenter sa fréquentation et ses réservations. "
      },
      {
        title: "Modifications tarifaires ",
        content:
          "À la fin de la première année d'accès gratuit au service de l'application, le Bénéficiaire se réserve le droit de modifier les tarifs et les frais applicables à l'utilisation continue de l'application. Tout changement de tarification sera notifié par écrit au propriétaire / gérant de l'établissement au moins 30 jours avant son entrée en vigueur, afin de permettre au propriétaire / gérant de l'établissement de prendre une décision éclairée concernant son utilisation continue du service de l'application."
      }
    ]
  },
  {
    title: "Loi applicable et juridiction compétente ",
    content:
      "Le présent Contrat est régi et interprété conformément aux lois en vigueur au Maroc. Tout différend découlant du présent Contrat sera soumis à la juridiction exclusive des tribunaux compétents du Maroc. "
  }
];
export default function Contract({ user }: Props) {
  const navigate = useNavigate();
  const { setUser } = useAuthContext();
  const { toastError } = useToast();
  const { removeCookie, token } = useCookie("vToken");

  const signContractMutation = useMutation((id: string) => {
    return apiService.MakePutRequest(`places/${id}/contract/sign`, {}, token);
  });

  const signContractHandler = () => {
    signContractMutation.mutate(user!?.adminForPlace!?.id, {
      onSuccess() {
        setUser(user);
        window.location.reload();
      },
      onError() {
        toastError("Une erreur inconnue s'est produite, réessayer");
      }
    });
  };

  const logout = () => {
    navigate("/");
    removeCookie("vToken");
  };

  return (
    <>
      <div className="contract-container">
        <div className="contract-content-container">
          <h5 className="text-center mb-4 fs-4 fw-bold">
            Contrat d&apos;autorisation d&apos;utilisation{" "}
          </h5>
          <p className="mb-3">
            Ce contrat d'autorisation d'utilisation (le "Contrat") est conclu
            entre
            <span className="fw-bold"> Devotech SARL</span> (le Bénéficiaire)
            l&apos;entreprise qui a développé l'application{" "}
            <span className="fw-bold">Venez</span> , dont il est question dans
            ce contrat et vous (l&apos;établissement).
          </p>
          <p className="mb-4">
            Le propriétaire / gérant de l'établissement et le Bénéficiaire sont
            individuellement désignés comme une "Partie" et collectivement
            désignés comme les "Parties".{" "}
          </p>
          <div className="contract">
            {CONTENT.map((mainContent, i) => (
              <div className="mb-2" key={i}>
                <h6 className="fs-6 fw-bold mb-1">
                  {i + 1}. {mainContent.title}
                </h6>
                <p>{mainContent.content}</p>
                {mainContent?.subContent &&
                  mainContent?.subContent?.length > 0 && (
                    <>
                      {mainContent.subContent.map((subContent, index) => (
                        <div className="mt-2 ps-4" key={index}>
                          <p className="fw-semibold mb-1">
                            {i + 1}.{index + 1} {subContent.title}{" "}
                          </p>
                          <p>{subContent.content}</p>
                        </div>
                      ))}
                    </>
                  )}
              </div>
            ))}
            <p>
              En signant ce Contrat, les Parties reconnaissent avoir lu, compris
              et accepté l&apos;ensemble de ses termes et conditions, y compris
              la clause supplémentaire concernant l&apos;accès gratuit au
              service de l&apos;application, les ventes de billets en ligne et
              le référencement de l&apos;établissement sponsorisé.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="contract-btns-container">
        <div className="contract-btns-wrapper">
          <button
            onClick={logout}
            disabled={signContractMutation.isLoading}
            className="contract-action-btn btn btn-primary shadow-secondary rounded-sm text-white"
          >
            Annuler
          </button>
          <button
            disabled={signContractMutation.isLoading}
            onClick={signContractHandler}
            className="contract-action-btn btn btn-danger shadow-primary rounded-sm text-white d-flex justify-content-center"
          >
            {signContractMutation.isLoading ? (
              <CSpinner size="sm" />
            ) : (
              "Signer le contrat"
            )}
          </button>
        </div>
      </div>
    </>
  );
}
