import React from "react";

export default function UndoIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 80 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.2999 91.2003C62.4999 89.2003 77.0999 74.6003 79.1999 56.4003C81.9999 32.5003 63.4999 12.2003 40.1999 12.0003V1.3003C40.1999 0.700296 39.4999 0.400296 38.9999 0.800296L16.9999 16.9003C16.5999 17.2003 16.5999 17.7003 16.9999 18.0003L38.9999 34.2003C39.4999 34.6003 40.1999 34.2003 40.1999 33.7003V22.9003C56.4999 23.2003 69.5999 37.0003 68.4999 53.6003C67.4999 67.9003 55.8999 79.4003 41.6999 80.4003C26.5999 81.4003 13.6999 70.6003 11.3999 56.3003C10.9999 53.6003 8.69989 51.7003 5.99989 51.7003C2.69989 51.7003 0.0998915 54.7003 0.599891 57.9003C3.79989 78.3003 22.5999 93.6003 44.2999 91.2003Z"
        fill="#ffffff"
      />
    </svg>
  );
}