import React, { useEffect, useReducer, useState } from "react";
import Pagination from "../../../components/Pagination";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { colors } from "../../../config/theme";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import PlacesTable from "../PlaceTable";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import { CSpinner } from "@coreui/react";
import { general } from "../../../locales/general";
import RenderTable from "../../../components/RenderTable";
import { useToast } from "../../../hooks/useToast";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../../context/bulkDeleteReducer";
import { useAuth } from "../../../hooks/useAuth";
import { useAuthContext } from "../../../context/auth";
import { useDebounce } from "usehooks-ts";
import { toQuery } from "../../../helpers/general";
import { useQueryParam } from "../../../hooks/useQueryParam";

const PAGE_SIZE = 10;

const PlaceSVG = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 0H1V2H17V0ZM18 10V8L17 3H1L0 8V10H1V16H11V10H15V16H17V10H18ZM9 14H3V10H9V14Z" />
    </svg>
  );
};

export default function AdminPlacesView() {
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();
  const queryClient = useQueryClient();

  const { user } = useAuthContext();

  const [searchParams] = useSearchParams();

  const query = searchParams?.get("search") ?? "";
  const currentPage = searchParams?.get("page") ?? "1";

  const searchQuery = useDebounce(query, 1000);
  const { addMultiple, add } = useQueryParam();

  useEffect(() => {
    if (searchQuery) {
      addMultiple({ search: searchQuery, page: "1" });
    }
  }, [searchQuery]);

  const fetchPlaces = async (
    currentPage: number,
    searchQuery: string,
    token: string
  ) => {
    const endpoint = `places${toQuery({
      page: currentPage,
      limit: PAGE_SIZE,
      q: searchQuery
    })}`;
    return apiService.MakeGetRequest(endpoint, token);
  };

  const queryKey = ["getAllPlaces", token, currentPage, searchQuery];

  const { data, isLoading, isPreviousData } = useQuery(
    queryKey,
    query => fetchPlaces(+currentPage, searchQuery, query.queryKey[1]),
    { keepPreviousData: true }
  );

  const deletePlace = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`places/${id}`, token);
  });

  const [PlacesToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    PlacesToBeDeleted.ids.forEach((id: string) => {
      if (!id) return;
      deletePlace.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.placeDeleted);
          dispatch({ type: "", id: "" });
          queryClient.invalidateQueries("getAllPlaces");
        }
      });
    });
  };

  return (
    <>
      <h5 className="fs-4 fw-bold mb-2">Établissements</h5>
      <BoxWrapper>
        <div className="d-flex  justify-content-between align-items-baseline before-table ps-2">
          <div className="d-flex align-items-center gap-2 p-3 ps-1 delete-add">
            <Link
              to="/places/new"
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
            >
              + Ajouter un établissement
            </Link>

            <button
              disabled={
                deletePlace.isLoading || PlacesToBeDeleted?.ids?.length === 0
              }
              onClick={handleBulkDelete}
              className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary text-pre"
            >
              {!deletePlace.isLoading ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>

          <div className="search-form mt-3">
            <div className=" input-field">
              <MagnifyingGlassIcon color="gray" width={20} />
              <input
                type="text"
                className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                placeholder="Rechercher"
                onChange={e => add("search", e?.target?.value?.toLowerCase())}
                defaultValue={query}
              />
            </div>
          </div>

          <div
            className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3 "
            style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
          >
            <PlaceSVG />
            <p className="fs-5 fw-bold m-0 text-white text-pre">
              {data?.meta?.totalItems}{" "}
              {data?.meta?.totalItems > 1 ? "Établissements" : "Établissement"}
            </p>
          </div>
        </div>

        <RenderTable
          loading={isLoading || isPreviousData}
          render={() =>
            data?.items?.length > 0 || user?.adminForPlace ? (
              <PlacesTable
                places={data?.items as Place[]}
                placesToBeDeletedList={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={+currentPage}
        onPageChange={page => {
          // setCurrentPage(page);
          add("page", page.toString());
        }}
        siblingCount={1}
        totalCount={data?.meta?.totalPages}
        pageSize={PAGE_SIZE}
      />
    </>
  );
}
