import { useMemo } from "react";

export function usePaginatedContent<T extends unknown[]>(
  currentPage: number,
  pageSize: number,
  content: T
) {
  const paginatedContent = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return content?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, content]);

  return paginatedContent;
}
