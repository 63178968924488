import { useState, useEffect, FormEvent } from "react";
import { useQuery } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import Pagination from "../../../components/Pagination";
import RenderTable from "../../../components/RenderTable";
import { general } from "../../../locales/general";
import { useDebounce } from "usehooks-ts";
import { useAuth } from "../../../hooks/useAuth";
import UsersTable from "./UsersTable";
import { useToast } from "../../../hooks/useToast";

interface IProps {
  selectedUsers: User[];
  onClose: () => void;
  onSelect: (category: User[]) => void;
}

const PAGE_SIZE = 10;

export default function UsersModel({
  selectedUsers: prevSelectedUsers = [],
  onClose,
  onSelect
}: IProps) {
  const { token } = useCookie("vToken");
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { isAdmin } = useAuth();
  const [selectedUsers, setSelectedUsers] = useState<User[]>(prevSelectedUsers);
  const { toastSuccess, toastError } = useToast();

  const searchQuery = useDebounce(query, 1000);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery])

  const fetchUsers = async (
    currentPage: number,
    searchQuery: string,
    token: string
  ) => {
    const endpoint = `users/clients?page=${currentPage}&limit=${PAGE_SIZE}&query=${searchQuery}`;
    return apiService.MakeGetRequest(endpoint, token);
  };

  const queryKey = [
    "notifications-users-dialog",
    token,
    currentPage,
    searchQuery
  ];

  const { data, isLoading } = useQuery(
    queryKey,
    query => fetchUsers(currentPage, searchQuery, query.queryKey[1]),
    { enabled: isAdmin, keepPreviousData: true }
  );

  const addUser = (user: User) => {
    setSelectedUsers([...selectedUsers, user]);
  };

  const removeUser = (user: User) => {
    setSelectedUsers(selectedUsers.filter(u => u.id !== user.id));
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (selectedUsers.length <= 0) {
      toastError("Veuillez sélectionner au moins un utilisateur");
      return;
    }
    onSelect(selectedUsers);
  };

  return (
    <div className="">
      <section className="p-4">
        <div className="border-bottom border-success mb-3">
          <h4>Sélectionnez les utilisateurs</h4>
        </div>
        <form className="row form " onSubmit={onSubmit}>
          <input
            className="custom-input mb-2 w-80 mx-auto"
            name="search"
            placeholder="Recherchez un utilisateur"
            onChange={e => {
              // setCurrentPage(1);
              setQuery(e?.target?.value.trim().toLowerCase())
            }}
          />
          <div className="col-12 mb-3">
            <RenderTable
              loading={isLoading}
              render={() =>
                data?.items?.length > 0 ? (
                  <UsersTable
                    users={data?.items as User[]}
                    // @ts-ignore
                    selectedUsers={selectedUsers}
                    setSearchQuery={value =>
                      setQuery(value.trim().toLowerCase())
                    }
                    addUesr={addUser}
                    removeUser={removeUser}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
            <Pagination
              currentPage={currentPage}
              onPageChange={page => {
                setCurrentPage(page);
              }}
              siblingCount={0}
              totalCount={data?.meta?.totalPages}
              pageSize={PAGE_SIZE}
            />
          </div>

          <section className="d-flex justify-content-center buttons gap-4 mt-4">
            <button
              className="btn btn-danger text-white shadow-primary w-20 px-4 py-2"
              type="button"
              onClick={onClose}
            >
              Annuler
            </button>
            <button
              className="btn btn-success shadow-secondary w-20 text-white"
              type="submit"
            >
              Enregistrer
            </button>
          </section>
        </form>
      </section>
    </div>
  );
}
