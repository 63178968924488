// @ts-nocheck
import { CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import BoxWrapper from "../../../components/BoxWrapper";
import { FormSelectAPI } from "../../../components/FormReactSelect";
import { FormMode } from "../../../helpers/enums";
import { useAuth } from "../../../hooks/useAuth";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";

interface ServiceCategoryTranslationData {
  label: string;
  description: string;
  lang: string;
  serviceSubCategoryId: string;
}

export default function NewServiceSubCategoryTranslation({
  mode = FormMode.CREATE
}) {
  const [formLoading, setFormLoading] = useState(false);
  const { isAdmin } = useAuth();
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const location = useLocation();
  const { category } = location.state || {};

  const schema = yup.object({
    label: yup.string().required(),
    lang: yup.string().required(),
    serviceSubCategoryId: yup.object().required()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  });

  useEffect(() => {
    setFormValue("serviceSubCategoryId", category);
  }, [category?.id]);

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      const formFields = Object.keys(schema.fields);
      const serviceCategoryGetRequest = apiService.MakeGetRequest(
        `service-category-translations/sub-categories-translation/${params.id}`,
        token
      );

      Promise.all([serviceCategoryGetRequest]).then(([serviceCategoryResp]) => {
        setDescription(serviceCategoryResp?.description);
        setFormValue(
          "serviceSubCategoryId",
          serviceCategoryResp?.serviceSubCategory
        );
        Object.entries(serviceCategoryResp).forEach(entry => {
          if (formFields.indexOf(entry[0]) !== -1) {
            if (entry[0] === "keywords") {
              setFormValue(entry[0], entry[1]?.join(","));
            } else {
              setFormValue(entry[0], entry[1]);
            }
          }
        });
      });
    }
  }, [params?.id]);

  const createServiceCategoryTranslationTranslationMutation = useMutation(
    async serviceCategoryTranslationData => {
      return await apiService.MakePostRequest(
        "service-category-translations/sub-categories-translation",
        serviceCategoryTranslationData,
        token
      );
    }
  );

  const updateServiceCategoryTranslationMutation = useMutation(
    async serviceCategoryTranslationData => {
      return await apiService.MakePutRequest(
        `service-category-translations/sub-categories-translation/${params.id}`,
        serviceCategoryTranslationData,
        token
      );
    }
  );

  const createServiceCategoryTranslation = async data => {
    try {
      setFormLoading(true);
      await createServiceCategoryTranslationTranslationMutation.mutateAsync(
        data
      );
      toastSuccess(general.fr.message.translationCreated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updateServiceCategoryTranslation = async data => {
    try {
      setFormLoading(true);
      const updateServiceCategoryTranslationReq = updateServiceCategoryTranslationMutation.mutateAsync(
        data
      );

      if (isAdmin) {
        await Promise.all([updateServiceCategoryTranslationReq]);
        toastSuccess(general.fr.message.translationUpdated);
        navigate(-1);
      } else {
        await updateServiceCategoryTranslationReq;
        toastSuccess(general.fr.message.translationUpdated);
        navigate(-1);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async (data: ServiceCategoryTranslationData) => {
    try {
      data.serviceSubCategoryId = data.serviceSubCategoryId.id;
      if (mode === FormMode.UPDATE) {
        updateServiceCategoryTranslation({ ...data, description });
      } else {
        createServiceCategoryTranslation({ ...data, description });
      }
    } catch (error) {
      setFormLoading(false);
      console.log(error);
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div className=" m-4  ">
      <BoxWrapper>
        <section className="p-4">
          <div className="border-bottom border-success">
            <h4>
              {mode === FormMode.UPDATE
                ? "Mise à jour"
                : "Ajouter une traduction"}
            </h4>
          </div>
          <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-6 my-3">
              <label htmlFor="serviceSubCategoryId" className="d-block mb-2">
                Sous catégorie <span className="text-md text-red">*</span>
              </label>
              <Controller
                name="serviceSubCategoryId"
                control={formControl}
                render={({ field }) => {
                  return (
                    <FormSelectAPI
                      name="serviceSubCategoryId"
                      id="serviceSubCategoryId"
                      isClearable={false}
                      defaultValue={category}
                      getOptionLabel={(option: ServiceCategory) =>
                        option?.label
                      }
                      getOptionValue={(option: ServiceCategory) => option.id}
                      isOptionSelected={(
                        option: ServiceCategory,
                        selectedValue: ServiceCategory[]
                      ) => {
                        const isSelected =
                          option?.id === selectedValue?.[0]?.id;
                        return isSelected;
                      }}
                      url={{
                        path: "service-categories/sub-categories"
                      }}
                      {...field}
                    />
                  );
                }}
              />

              {errors.serviceSubCategoryId?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.serviceSubCategoryId.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3">
              <label htmlFor="lang" className="d-block">
                La langue
                <span className="text-sm text-red"> *</span>
              </label>
              <CFormSelect name="lang" id="lang" {...register("lang")}>
                <option value="en">Anglais</option>
                <option value="ar">Arabic</option>
                <option value="fr">Français</option>
              </CFormSelect>
              {errors.lang?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.supportTableSelection.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="name" className="d-block">
                Nom de la catégorie <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                serviceCategoryholder="Nom de la catégorie"
                id="label"
                name="label"
                {...register("label")}
              />
              {errors.label?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.label.message}
                </div>
              )}
            </div>

            <section className="d-flex justify-content-center buttons gap-4 mt-5">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
              >
                Annuler
              </button>
              <button
                className="btn btn-success green-shadow w-20 text-white"
                type="submit"
                disabled={formLoading}
              >
                {formLoading ? (
                  <div className="text-center">
                    <CSpinner size="sm" />
                  </div>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </section>
          </form>
        </section>
      </BoxWrapper>
    </div>
  );
}
