import React, { ReactNode } from "react";
import Loader from "./Loader";

interface IProps {
  loading: boolean;
  render: () => ReactNode;
}
export default function RenderTable({ loading, render }: IProps) {
  return <div>{loading ? <Loader /> : render()}</div>;
}
