import {
  CFormCheck,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";

import { useMutation, useQueryClient } from "react-query";
import { useToast } from "../../hooks/useToast";
import { useCookie } from "../../hooks/useCookie";
import apiService from "../../service/apiService";
import { DELETE_ACTIONS } from "../../config/constants";
import { general } from "../../locales/general";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import PromptModal from "../../components/modals/PromptModal";

function TableBodyRowContent({
  Newsletter,
  deletedList
}: {
  Newsletter: Newsletter;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);

  const deleteEmail = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`newsletter/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: Newsletter.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: Newsletter.id });
    }
  }, [deletedList, isChecked, Newsletter.id]);

  const removeEmail = () => {
    deleteEmail.mutate(Newsletter.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.emailDeleted);
        queryClient.invalidateQueries("getNewsletter");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3  text-sm">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
        />
        <span className="ms-2">{Newsletter?.email?.toLowerCase()}</span>
      </CTableDataCell>

      <CTableDataCell className="p-3">
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
        >
          {deleteEmail.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeEmail()}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  Newsletters: Newsletter[];
  NewsletterTableToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function NewsletterTable({
  Newsletters,
  NewsletterTableToBeDeleted
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="w-100">
              Email
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="w-100"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {Newsletters?.map(Newsletter => (
            <TableBodyRowContent
              Newsletter={Newsletter}
              key={Newsletter?.id}
              deletedList={NewsletterTableToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
