import { Query, toQuery } from "../helpers/general";

export const apiConfig = {
  offers: {
    set_order: () => "specials/orders",
    all: (query: Query) => `specials/all${toQuery({ ...query })}`
  },
  events: {
    set_order: () => "events/orders",
    all: (query: Query) => `events/ours${toQuery({ ...query })}`
  },
  users: {
    list: (query: string) => `users/filter?${query}`
  },
  places: {
    get: (placeId: string) => `places/${placeId}`
  }
};
