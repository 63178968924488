import { ROLES } from "../config/constants";
import { useAuthContext } from "../context/auth";

export function useAuth() {
  const { user } = useAuthContext();

  return {
    isPlaceAdmin:
      user?.role.label?.toLocaleLowerCase() === ROLES.PLACE_ADMIN.toLowerCase(),
    isAdmin:
      user?.role.label?.toLocaleLowerCase() === ROLES.SUPER_ADMIN.toLowerCase(),
    isInspector:
      user?.role.label?.toLocaleLowerCase() === ROLES.INSPECTOR.toLowerCase(),
  };
}
