import { CCol, CRow } from "@coreui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useMutation } from "react-query";
import { ReactSortable } from "react-sortablejs";
import { apiConfig } from "../../config/apiConfig";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import apiService from "../../service/apiService";
import { offersDisplayType } from "./OfferTable";
import AdminRoute from "../auth/RestrictedRoute";
const offerPlaceholderImage = "/venez.jpeg";

interface MutationProps {
  specialsOrder: {
    spacialId: string;
    order: number;
  }[];
}

export default function OrganizeOffers() {
  const { token } = useCookie("vToken");
  const [arrangedOffers, setArrangedOffers] = useState<SpecialOffers[]>([]);
  const [ordered, setOrdered] = useState(false);
  const currentPageRef = useRef<null | number>(1);

  const endOfOffersListRef = useRef(null);

  window.onbeforeunload = function() {
    window.scrollTo(0, 0);
  };
  const fetchSpecials = async (page: number) => {
    const endpoint = apiConfig.offers.all({ page, limit: "10" });
    return apiService.MakeGetRequest(endpoint, token);
  };

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading
  } = useInfiniteQuery(
    "getSpecials",
    ({ pageParam }) => {
      return fetchSpecials(pageParam);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const { currentPage, totalPages } = lastPage?.meta;
        const nextPage =
          currentPage === totalPages ? undefined : currentPage + 1;

        currentPageRef.current = currentPage;

        return nextPage;
      },
      refetchOnWindowFocus: false
    }
  );

  const handleObserver = useCallback(
    (entries: any) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    const sortOffersByOrder = (offer: SpecialOffers[]) =>
      offer.sort((a, b) => {
        if (a?.order !== null && b?.order !== null) {
          return a.order - b.order;
        } else {
          return 0;
        }
      });

    if (!isLoading) {
      const fetchedOffers = data?.pages[data?.pageParams?.length - 1]?.items;

      if (ordered) {
        setArrangedOffers(offers =>
          sortOffersByOrder([
            ...offers.map((offer, i) => ({ ...offer, order: i + 1 })),
            ...fetchedOffers
          ])
        );
      } else {
        setArrangedOffers(offers =>
          sortOffersByOrder([...offers, ...fetchedOffers])
        );
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (endOfOffersListRef.current)
      observer.observe(endOfOffersListRef.current);
  }, [handleObserver]);

  const { toastError, toastSuccess } = useToast();

  const specialsOrderMutation = useMutation((data: MutationProps) =>
    apiService.MakePutRequest(apiConfig.offers.set_order(), data, token)
  );

  const savePositions = () => {
    const specialsOrder = arrangedOffers.map((special, index) => ({
      spacialId: special.id,
      order: index + 1
    }));

    specialsOrderMutation.mutate(
      { specialsOrder: specialsOrder },
      {
        onError(e) {
          toastError("Quelque chose n'a pas fonctionné");
        },
        onSuccess() {
          toastSuccess("Mise à jour de la liste des offres");
        }
      }
    );
  };

  return (
    <AdminRoute>
      <CRow className="bg-white p-3 rounded-md mb-3 mx-auto align-items-center">
        <CCol className="col-12 col-sm-7 col-md-8">
          <p className="organize__offer__description">
            Déplacer une offre pour spécifier son emplacement dans l'application
          </p>
        </CCol>
        <CCol className="col-12 col-sm-5 col-md-4 mt-3 mt-sm-0">
          <button
            onClick={savePositions}
            className="btn btn-danger text-white ms-auto d-block shadow-primary"
            disabled={
              !arrangedOffers?.length || specialsOrderMutation.isLoading
            }
          >
            Mettre à jour
          </button>
        </CCol>
      </CRow>
      {!isLoading ? (
        <>
          <ReactSortable
            list={arrangedOffers}
            setList={setArrangedOffers}
            className="organize__offers_wrapper"
            onChange={e => setOrdered(true)}
          >
            {arrangedOffers?.map((offer, index) => (
              <div key={offer?.id}>
                <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-2">
                  <p className="text-danger my-0 text-uppercase">
                    {offersDisplayType[offer?.type]}
                  </p>
                  <p
                    className="fw-bold text-danger p-1 border text-center my-0"
                    style={{
                      marginLeft: "auto",
                      width: "30px",
                      height: "30px"
                    }}
                  >
                    {index + 1}
                  </p>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <img
                    src={
                      offer?.image ||
                      offer?.event?.image ||
                      offerPlaceholderImage
                    }
                    alt={offer?.title}
                    width="45px"
                    height="45px"
                    style={{ objectFit: "cover" }}
                  />
                  <p className="my-0 fw-semibold">
                    {offer?.title || offer?.event?.name}
                  </p>
                </div>
              </div>
            ))}
          </ReactSortable>
          {isFetchingNextPage && <p>Chargement...</p>}
          <div ref={endOfOffersListRef} />
        </>
      ) : null}
    </AdminRoute>
  );
}
