import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../useCookie";
import { useToast } from "../useToast";
import { general } from "../../locales/general";

export const useCategoryQuery = () => {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();

  const { data, isLoading } = useQuery(["getAllCategories"], (query) => {
    return apiService.MakeGetRequest("categories");
  });
  const deleteCategory = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`categories/${id}`, token);
  });
  return {
    categories: data,
    fetchingCategories: isLoading,
    deleteCategory(id: string) {
      deleteCategory.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.categoryDeleted);
          queryClient.invalidateQueries("getAllCategories");
        },
      });
    },
    mutating: deleteCategory.isLoading,
  };
};
