import {
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import { useState } from "react";
import { colors } from "../../../config/theme";

function TableBodyRowContent({
  user,
  selected,
  addUser,
  removeUser
}: {
  user: User;
  selected: boolean;
  addUser: () => void;
  removeUser: () => void;
}) {
  const [isChecked, setIsChecked] = useState(selected);

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="px-3 text-sm">
        <div className="d-flex align-items-center gap-3">
          <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e =>
              setIsChecked(current => {
                if (current) {
                  removeUser();
                } else {
                  addUser();
                }
                return !current;
              })
            }
            color={colors.yellow}
          />

          <img
            src={
              user.profilePicture ??
              `https://ui-avatars.com/api/?name=${user?.fullName}`
            }
            alt=""
            width={30}
            height={30}
            style={{ borderRadius: "100%" }}
          />

          <div>
            <p className="fw-bold m-0">{user?.fullName}</p>
            <p className="m-0">{user.email}</p>
          </div>
        </div>
      </CTableDataCell>
    </CTableRow>
  );
}

interface TableProps {
  users: User[];
  selectedUsers: User[];
  setSearchQuery: (query: string) => void;
  addUesr: (user: User) => void;
  removeUser: (user: User) => void;
}

export default function UsersTable({
  users,
  selectedUsers,
  setSearchQuery,
  addUesr,
  removeUser
}: TableProps) {
  return (
    <div className="">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {users?.map(user => (
            <TableBodyRowContent
              key={user.id}
              user={user}
              selected={selectedUsers?.some(u => u.id === user.id)}
              addUser={() => addUesr(user)}
              removeUser={() => removeUser(user)}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
