import { DELETE_ACTIONS } from "../config/constants";

export const bulkDeleteState: { ids: string[] } = { ids: [] };

export const bulkDeleteReducer = (
  state: typeof bulkDeleteState,
  action: BulkDeleteActions
) => {
  if (action?.type === DELETE_ACTIONS.ADD) {
    return {
      ids: [...state.ids, action.id],
    };
  } else if (action?.type === DELETE_ACTIONS.REMOVE) {
    const filteredResult = state.ids.filter((id) => id !== action.id);
    return {
      ids: filteredResult,
    };
  } else {
    return {
      ids: [],
    };
  }
};
