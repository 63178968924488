import {
  CFormCheck,
  CFormInput,
  CModal,
  CModalBody,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { createRef, useEffect, useState } from "react";
import EditIcon from "../../components/icons/EditIcon";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { useQueryClient, useMutation } from "react-query";
import { useToast } from "../../hooks/useToast";
import { DELETE_ACTIONS } from "../../config/constants";
import { useCookie } from "../../hooks/useCookie";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import PromptModal from "../../components/modals/PromptModal";

interface newPartnerModalProps {
  open: boolean;
  onClose: () => void;
  name?: string;
  link?: string;
  logo?: File | string;
  id?: string;
}
interface updatePartnerProp {
  name: string;
  link: string;
  logo?: File;
}
const PartnerModal = ({
  open,
  onClose,
  name = "",
  link = "",
  logo = "",
  id = ""
}: newPartnerModalProps) => {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const [PartnerName, setPartnerName] = useState(name);
  const [PartnerLink, setPartnerLink] = useState(link);
  const pictureRef = createRef<HTMLInputElement>();

  const queryClient = useQueryClient();

  const updatePartner = useMutation((data: updatePartnerProp) => {
    return apiService.MakePutRequest(`partners/${id}`, data, token, true);
  });

  const updatePartnerHandler = () => {
    updatePartner.mutate(
      {
        name: PartnerName,
        link: PartnerLink,
        logo: pictureRef?.current?.files?.[0]! || " "
      },
      {
        onError() {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          queryClient.invalidateQueries("getPartner");
          toastSuccess(general.fr.message.partnerUpdated);
          onClose();
          setPartnerName("");
          setPartnerLink("");
        }
      }
    );
  };
  return (
    <CModal visible={open} onClose={onClose} alignment="center">
      <CModalBody>
        <h5 className="fs-5 mb-4 border-bottom pb-1">
          Mise à jour du partenaire
        </h5>
        <div className="mb-4">
          <label htmlFor="name" className="text-sm">
            Nom du partenaire
          </label>
          <input
            type="text"
            className="custom-input w-100 mt-1"
            name="name"
            placeholder="Nom du partenaire"
            value={PartnerName}
            onChange={e => setPartnerName(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="link" className="text-sm">
            Site web du partenaire
            <br />
            <span className="text-sm text-danger fst-italic">
              {" "}
              (Exemple : https://www.exemple.com )
            </span>
          </label>
          <input
            type="url"
            className="custom-input w-100 mt-1"
            name="link"
            placeholder="Partner Website"
            value={PartnerLink}
            onChange={e => setPartnerLink(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="logo" className="text-sm">
            Logo du partenaire
          </label>
          <CFormInput
            type="file"
            id="formFile"
            className="mt-1"
            ref={pictureRef}
          />
        </div>

        <div className="w-fit my-5 m-auto">
          <img
            src={logo as string}
            alt=""
            className="w-full"
            width={100}
            height={100}
          />
        </div>

        <button
          type="button"
          onClick={updatePartnerHandler}
          disabled={updatePartner.isLoading}
          className="d-flex align-items-center justify-content-center btn btn-danger shadow-primary d-block w-100 mt-2 p-2 fw-bold text-white"
        >
          {updatePartner.isLoading ? (
            <>
              <CSpinner size="sm" /> {general.fr.wait}
            </>
          ) : (
            "Enregistrer"
          )}
        </button>
      </CModalBody>
    </CModal>
  );
};

function TableBodyRowContent({
  Partner,
  deletedList
}: {
  Partner: Partner;
  deletedList: React.Dispatch<BulkDeleteActions>;
}) {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showEditModal, setShowEditModal] = useState(false);

  const deletePartner = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`partners/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: Partner.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: Partner.id });
    }
  }, [deletedList, isChecked, Partner.id]);

  const removePartner = () => {
    deletePartner.mutate(Partner.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.partnerDeleted);
        queryClient.invalidateQueries("getAllPartners");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className=" text-capitalize text-sm">
        <CFormCheck
          id="flexCheckDefault"
          label=""
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          color={colors.yellow}
        />
        <span className="ms-3">{Partner?.name}</span>
      </CTableDataCell>
      <CTableDataCell className="text-capitalize text-sm">
        <a href={Partner?.link} target="__blank" referrerPolicy="no-referrer">
          {Partner?.link}
        </a>
      </CTableDataCell>

      <CTableDataCell>
        <button
          onClick={() => setShowEditModal(true)}
          className="outline-none border-0 bg-transparent"
        >
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </button>{" "}
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
        >
          {deletePartner.isLoading ? (
            <CSpinner size="sm" />
          ) : (
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          )}
        </button>
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={removePartner}
        onClose={() => setShowModal(false)}
      />
      <PartnerModal
        onClose={() => setShowEditModal(false)}
        open={showEditModal}
        id={Partner?.id}
        name={Partner?.name}
        link={Partner?.link}
        logo={Partner?.logoImage}
      />
    </CTableRow>
  );
}

interface TableProps {
  Partners: Partner[];
  PartnersToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function PartnersTable({
  Partners,
  PartnersToBeDeleted
}: TableProps) {
  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Nom du partenaire</CTableHeaderCell>
            <CTableHeaderCell scope="col">
              Site web du partenaire
            </CTableHeaderCell>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {Partners?.map(Partner => (
            <TableBodyRowContent
              Partner={Partner}
              key={Partner?.id}
              deletedList={PartnersToBeDeleted}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
    </div>
  );
}
