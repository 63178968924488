import React, { useReducer, useState } from "react";
import Pagination from "../../components/Pagination";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { colors } from "../../config/theme";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { Link, useNavigate } from "react-router-dom";
import BoxWrapper from "../../components/BoxWrapper";
import EventTable from "./EventTable";
import { useEventQuery } from "../../hooks/events/useEventQuery";
import RenderTable from "../../components/RenderTable";
import { general } from "../../locales/general";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react";
import TypesTable from "./TypesTable";
import { useAuth } from "../../hooks/useAuth";
import { useQueryClient } from "react-query";

const PAGE_SIZE = 10;
export default function Events() {
  const [activeTab, setActiveTab] = useState(0);
  const router = useNavigate();
  const queryClient = useQueryClient();

  const {
    events,
    fetchingEvents,
    mutating,
    removeEvent,
    searchHandler,
    totalEvents,
    totalPages,
    setCurrentPage,
    currentPage
  } = useEventQuery();

  const [eventsToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    eventsToBeDeleted.ids.forEach(id => {
      if (!id) return;
      removeEvent(id);
    });
    dispatch({ type: "", id: "" });
  };

  const EventSvg = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.761 12.001C19.761 12.8145 20.429 13.4764 21.25 13.4764C21.664 13.4764 22 13.8093 22 14.2195V16.8958C22 19.159 20.142 21 17.858 21H6.143C3.859 21 2 19.159 2 16.8958V14.2195C2 13.8093 2.336 13.4764 2.75 13.4764C3.572 13.4764 4.24 12.8145 4.24 12.001C4.24 11.2083 3.599 10.6118 2.75 10.6118C2.551 10.6118 2.361 10.5335 2.22 10.3938C2.079 10.2541 2 10.0648 2 9.86866L2.002 7.10514C2.002 4.84201 3.86 3 6.144 3H17.856C20.14 3 21.999 4.84201 21.999 7.10514L22 9.78245C22 9.97864 21.921 10.1689 21.781 10.3076C21.64 10.4473 21.45 10.5256 21.25 10.5256C20.429 10.5256 19.761 11.1875 19.761 12.001ZM14.252 12.648L15.431 11.5105C15.636 11.3143 15.707 11.025 15.618 10.7575C15.53 10.4899 15.3 10.2997 15.022 10.261L13.393 10.0252L12.664 8.5627C12.539 8.31102 12.285 8.15446 12.002 8.15347H12C11.718 8.15347 11.464 8.31003 11.337 8.56171L10.608 10.0252L8.982 10.26C8.701 10.2997 8.471 10.4899 8.382 10.7575C8.294 11.025 8.365 11.3143 8.569 11.5105L9.748 12.648L9.47 14.2562C9.422 14.5336 9.535 14.8091 9.765 14.9746C9.895 15.0667 10.046 15.1143 10.199 15.1143C10.316 15.1143 10.434 15.0855 10.542 15.0291L12 14.2701L13.455 15.0271C13.707 15.1608 14.006 15.14 14.235 14.9736C14.466 14.8091 14.579 14.5336 14.531 14.2562L14.252 12.648Z"
        />{" "}
      </svg>
    );
  };

  const { isAdmin } = useAuth();
  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Evénements</h5>
      <BoxWrapper>
        {isAdmin && (
          <CNav variant="tabs" className="mb-2">
            <CNavItem
              href="#events"
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              <CNavLink className="text-black">Evénements</CNavLink>
            </CNavItem>

            <CNavItem
              href="#eventsType"
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              <CNavLink className="text-black">Type d&apos;événements</CNavLink>
            </CNavItem>
          </CNav>
        )}

        <CTabContent>
          <CTabPane visible={activeTab === 0}>
            <div
              className="d-flex  justify-content-between align-items-baseline before-table"
              id="events"
            >
              <div className="d-flex align-items-center gap-2 p-3 delete-add">
                <Link
                  to="/events/new"
                  className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
                >
                  + Nouvel événement
                </Link>

                <button
                  onClick={handleBulkDelete}
                  disabled={mutating || eventsToBeDeleted.ids.length === 0}
                  className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
                >
                  <DeleteIcon fill={colors.primary} /> Supprimer
                </button>
                {isAdmin && (
                  <button
                    className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
                    onClick={() => {
                      queryClient.invalidateQueries('getEventsOrganize');
                      router("/events/organize");
                    }}
                  >
                    Organisez les événements
                  </button>
                )}
              </div>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    onChange={e => searchHandler(e.target.value.toLowerCase())}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
              >
                <EventSvg />
                <p className="fs-5 fw-bold m-0  text-white">
                  {totalEvents} {totalEvents > 1 ? "Evénements" : "Evénement"}
                </p>
              </div>
            </div>
            <RenderTable
              loading={fetchingEvents}
              render={() =>
                events?.length > 0 ? (
                  <EventTable
                    events={events as Events[]}
                    eventsToBeDeletedList={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </CTabPane>
          <CTabPane visible={activeTab === 1}>
            <TypesTable />
          </CTabPane>
        </CTabContent>
      </BoxWrapper>
      {activeTab === 0 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={page => {
            setCurrentPage(page);
          }}
          siblingCount={0}
          totalCount={totalPages}
          pageSize={PAGE_SIZE}
        />
      )}
    </section>
  );
}
