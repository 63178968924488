export default function UnsortedIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 58 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.4875 1.93828L56.9875 27.4383C57.6875 28.1383 57.9875 29.0383 57.9875 29.9383C57.9875 30.8383 57.6875 31.7383 56.9875 32.4383C55.5875 33.8383 53.3875 33.8383 52.0875 32.4383L29.0875 9.43828L6.08751 32.4383C4.68751 33.8383 2.48751 33.8383 1.08751 32.4383C-0.312494 31.0383 -0.312494 28.8383 1.08751 27.5383L26.5875 1.93828C27.9875 0.638278 30.1875 0.638278 31.4875 1.93828ZM1.08751 55.4383C-0.312494 56.8383 -0.312494 59.0383 1.08751 60.4383L26.5875 85.9383C27.9875 87.3383 30.1875 87.3383 31.4875 85.9383L56.9875 60.4383C57.6875 59.7383 57.9875 58.8383 57.9875 57.9383C57.9875 57.0383 57.6875 56.1383 56.9875 55.4383C55.5875 54.0383 53.3875 54.0383 52.0875 55.4383L29.0875 78.4383L6.08751 55.4383C4.68751 54.1383 2.48751 54.1383 1.08751 55.4383Z"
        fill="#7A7A7A"
      />
    </svg>
  );
}
