import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BoxWrapper from "../../components/BoxWrapper";
import CreatePromoCode from "./CreatePromoCode";
import { useNavigate } from "react-router-dom";
import { createPromoCodeSchema, createPromoDto } from "../../rules/promo";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import { useMutation } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { CSpinner } from "@coreui/react";

export default function AddNewPromoCode() {
  const { control: formControl, handleSubmit, watch, setError } = useForm<
    yup.InferType<typeof createPromoCodeSchema>
  >({
    resolver: yupResolver(createPromoCodeSchema)
  });

  const navigate = useNavigate();
  const { toastError } = useToast();
  const { token } = useCookie("vToken");

  const selectedEvent = watch("event");
  const maxUsage = watch("maxUses");

  const createPromoCodeMutation = useMutation({
    mutationFn: (data: createPromoDto) =>
      apiService.MakePostRequest("promo-codes", data, token)
  });

  const onSubmit = async (data: any) => {
    try {
      const { event, ...rest } = data;
      const payload = {
        ...rest,
        items: rest.items.map((item: any) => ({
          serviceId: item.serviceId,
          maxUses: item.maxUses
        })),
        eventId: event?.id
      };

      const totalServiceUsage =
        payload.items?.reduce(
          (sum: number, item: { maxUses: string }) =>
            sum + (parseInt(item.maxUses) || 0),
          0
        ) || 0;

      if (totalServiceUsage > payload.maxUses) {
        return setError(`items.${payload.items.length - 1}.maxUses`, {
          message: `L'utilisation totale des services (${totalServiceUsage}) dépasse l'utilisation maximale autorisée (${payload.maxUses})`
        });
      }
      await createPromoCodeMutation.mutateAsync(payload);
      navigate(-1);
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div className="px-4">
      <h5 className="fs-4 fw-bold mb-2">Ajouter un nouveau code promo</h5>
      <BoxWrapper className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <CreatePromoCode
            control={formControl}
            selectedEvent={selectedEvent}
            maxUsage={maxUsage}
          />
          <section className="d-flex justify-content-center buttons gap-4 mt-4">
            <button
              type="button"
              disabled={createPromoCodeMutation.isLoading}
              onClick={() => navigate(-1)}
              className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
            >
              Annuler
            </button>
            <button
              className="btn btn-success green-shadow w-20 text-white"
              type="submit"
            >
              {createPromoCodeMutation.isLoading ? (
                <div className="text-center">
                  <CSpinner size="sm" />
                </div>
              ) : (
                "Enregistrer"
              )}
            </button>
          </section>
        </form>
      </BoxWrapper>
    </div>
  );
}
