import React, { FormEvent, useCallback, useState } from "react";
import { Link } from "react-router-dom";

import BoxWrapper from "../../../components/BoxWrapper";
import { useMutation } from "react-query";
import apiService from "../../../service/apiService";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import RestrictedRoute from "../../auth/RestrictedRoute";

const DEFAULT_USER_INFO = {
  email: "",
  phone: "",
  fullName: "",
  password: "",
  confirmPassword: "",
};
interface Props {
  onClose: () => void;
  onAccept: () => void;
}

interface createUserType {
  email: string;
  phone: string;
  fullName: string;
  password: string;
}

const validationErrorMessages = {
  INVALID_PASSWORD: "Veuillez fournir un email valide",
  PASSWORD_MISMATCH: "Les mots de passe ne correspondent pas",
  PHONE_INCORRECT: "Numéro de téléphone incorrect",
};
export default function NewUser({ onClose, onAccept }: Props) {
  const [userInfo, setUserInfo] = useState(DEFAULT_USER_INFO);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const handleUserInfo = useCallback((key: string, value: string) => {
    setUserInfo((info) => ({ ...info, [key]: value }));
  }, []);

  const createUser = useMutation((userData: createUserType) => {
    return apiService.MakePostRequest("auth/signup", userData, token);
  });

  const validateInputs = () => {
    const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const phoneReg = /(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}/g;

    let validationError = false;
    if (!emailReg.test(userInfo.email)) {
      setEmailError(validationErrorMessages.INVALID_PASSWORD);
      validationError = true;
    } else {
      setEmailError("");
    }
    if (userInfo.confirmPassword !== userInfo.password) {
      validationError = true;
      setPasswordError(validationErrorMessages.PASSWORD_MISMATCH);
    } else {
      setPasswordError("");
    }
    if (!phoneReg.test(userInfo.phone)) {
      validationError = true;
      setPhoneError(validationErrorMessages.PHONE_INCORRECT);
    } else {
      setPhoneError("");
    }

    return validationError;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateInputs()) return;
    createUser.mutate(userInfo, {
      onError() {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.userCreated);
        setUserInfo(DEFAULT_USER_INFO);
      },
    });
  };

  return (
    <RestrictedRoute>
      <div className="">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter User</h4>
            </div>
            <form className="row form mt-2" onSubmit={handleSubmit}>
              <div className="col-md-6 mt-3">
                <label htmlFor="fullName" className="d-block">
                  Nom et Prénom
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Full Name"
                  id="fullName"
                  name="fullName"
                  onChange={(e) => handleUserInfo("fullName", e.target.value)}
                  required
                  value={userInfo.fullName}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="email" className="d-block">
                  Email
                </label>
                <input
                  type="email"
                  className="custom-input"
                  placeholder="Email"
                  id="email"
                  name="email"
                  onChange={(e) => handleUserInfo("email", e.target.value)}
                  required
                  value={userInfo.email}
                />
                {emailError && (
                  <p className="text-danger text-sm">{emailError}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="phone" className="d-block">
                  Téléphone
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Téléphone"
                  id="phone"
                  name="phone"
                  onChange={(e) => handleUserInfo("phone", e.target.value)}
                  required
                  value={userInfo.phone}
                />{" "}
                {phoneError && (
                  <p className="text-danger text-sm">{phoneError}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="Password" className="d-block">
                  Mot de passe
                </label>
                <input
                  type="password"
                  className="custom-input"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={(e) => handleUserInfo("password", e.target.value)}
                  required
                  value={userInfo.password}
                />{" "}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="cPassword" className="d-block">
                  Confirmer le mot de passe
                </label>
                <input
                  type="password"
                  className="custom-input"
                  placeholder="confirm password"
                  id="cPassword"
                  name="cPassword"
                  onChange={(e) =>
                    handleUserInfo("confirmPassword", e.target.value)
                  }
                  value={userInfo.confirmPassword}
                  required
                />{" "}
                {passwordError && (
                  <p className="text-danger text-sm">{passwordError}</p>
                )}
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <button
                  className="btn btn-danger red-shadow w-20 px-4 py-2 shadow-primary text-white"
                  type="button"
                  onClick={onClose}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-primary text-white shadow-secondary w-20 px-4"
                  type="submit"
                >
                  Enregistrer
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </RestrictedRoute>
  );
}
