import React, { FormEvent, useState } from "react";
import BoxWrapper from "../../components/BoxWrapper";
import {
  CForm,
  CFormInput,
  CModal,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import { colors } from "../../config/theme";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { general } from "../../locales/general";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import PromptModal from "../../components/modals/PromptModal";
import AdminRoute from "../auth/RestrictedRoute";

export default function Variables() {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const { data, isLoading } = useQuery<Variable[]>(["getAllVariables"], () => {
    return apiService.MakeGetRequest("variables");
  });

  return (
    <AdminRoute>
      <h5 className="fs-4 fw-bold mb-2">Variables</h5>
      <BoxWrapper>
        <div className="d-flex  justify-content-between align-items-baseline before-table">
          <div className="d-flex align-items-center gap-2 p-3 delete-add">
            <button
              onClick={openModal}
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
            >
              + Ajouter une variable
            </button>
          </div>
        </div>

        <div className="mt-3 w-100">
          {isLoading ? (
            <div className="flex justify-center w-100">
              <CSpinner />
            </div>
          ) : data?.length ? (
            <CTable>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">Nom</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Valeur</CTableHeaderCell>
                  <CTableHeaderCell scope="col"></CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {data?.map(variable => (
                  <TableRow key={variable?.id} variable={variable} />
                ))}
              </CTableBody>
            </CTable>
          ) : (
            <p className="text-center">{general.fr.noData}</p>
          )}
        </div>
        <VariableModal open={showModal} onClose={closeModal} />
      </BoxWrapper>
    </AdminRoute>
  );
}

function TableRow({ variable }: { variable: Variable }) {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const queryClient = useQueryClient();

  const deleteVariable = useMutation((id: number) => {
    return apiService.MakeDeleteRequest(`variables/${id}`, token);
  });

  const removeVariable = () => {
    deleteVariable.mutate(variable.id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.variableDeleted);
        queryClient.invalidateQueries("getAllVariables");
      }
    });
  };

  return (
    <CTableRow>
      <CTableDataCell>{variable?.label}</CTableDataCell>
      <CTableDataCell>
        {variable?.value.startsWith("https") ||
        variable?.value.startsWith("www") ? (
          <a href={variable?.value}>{variable?.value}</a>
        ) : (
          <span className="text-sm">{variable?.value}</span>
        )}
      </CTableDataCell>
      <CTableDataCell>
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowModal(true)}
        >
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </button>
        <button
          className="outline-none border-0 bg-transparent"
          onClick={() => setShowDeleteModal(true)}
        >
          <DeleteIcon
            width="20"
            height="20"
            fill={colors.primary}
            className="ms-2"
          />
        </button>
      </CTableDataCell>
      <VariableModal
        onClose={() => setShowModal(false)}
        editMode
        open={showModal}
        variable={variable}
      />
      <PromptModal
        open={showDeleteModal}
        onAccept={removeVariable}
        onClose={() => setShowDeleteModal(false)}
      />
    </CTableRow>
  );
}

interface ModalProps {
  open: boolean;
  onClose: () => void;
  variable?: Variable;
  editMode?: boolean;
}

function VariableModal({
  onClose,
  open,
  variable,
  editMode = false
}: ModalProps) {
  const { token } = useCookie("vToken");

  const createVariable = useMutation((data: Omit<Variable, "id">) => {
    return apiService.MakePostRequest("variables", data, token);
  });
  const updateVariable = useMutation((data: Variable) => {
    return apiService.MakePatchRequest(`variables/${data.id}`, data, token);
  });

  const { toastError } = useToast();
  const queryClient = useQueryClient();

  const getFormData = (e: FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const label = formData.get("label") as string;
    const value = formData.get("value") as string;

    return { label, value };
  };

  const formSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const options = {
      onSuccess() {
        queryClient.invalidateQueries("getAllVariables");
        onClose();
      },
      onError() {
        toastError(general.fr.message.operationFailed);
      }
    };
    const data = getFormData(e);

    !editMode
      ? createVariable.mutate(data, options)
      : updateVariable.mutate({ ...data, id: variable?.id! }, options);
  };

  return (
    <CModal alignment="center" visible={open}>
      <CForm className="p-3" onSubmit={formSubmitHandler}>
        <h4 className="fw-bold mb-4">
          {editMode ? "Mise à jour de la variable" : "Ajouter une variable"}
        </h4>
        <div className="mb-3">
          <label htmlFor="label" className="d-block">
            Label
          </label>
          <CFormInput
            type="text"
            className="custom-input"
            placeholder="Label"
            id="label"
            name="label"
            defaultValue={variable?.label}
          />
        </div>
        <div>
          <label htmlFor="value" className="d-block">
            Valeur
          </label>
          <CFormInput
            type="text"
            className="custom-input"
            placeholder="Valeur"
            id="value"
            name="value"
            defaultValue={variable?.value}
          />
        </div>
        <section className="d-flex justify-content-center buttons gap-4 mt-4">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
          >
            Annuler
          </button>
          <button
            className="btn btn-success green-shadow w-20 text-white"
            type="submit"
          >
            Enregistrer
          </button>
        </section>
      </CForm>
    </CModal>
  );
}
