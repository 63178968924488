import React, { ReactNode, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROLES } from "../../config/constants";
import { useAuthContext } from "../../context/auth";
import { useAuth } from "../../hooks/useAuth";
import { useCookie } from "../../hooks/useCookie";
import { CSpinner } from "@coreui/react";

interface Props {
  children: ReactNode;
  route?: keyof typeof ROLES | string;
}

export default function RestrictedRoute({
  children,
  route = ROLES.SUPER_ADMIN
}: Props) {
  const { token } = useCookie("vToken");
  const { isAdmin, isPlaceAdmin } = useAuth();
  const [pageLoading, setPageLoading] = useState(true);
  const navigate = useNavigate();

  // @ts-ignore
  useEffect(() => {
    if (isPlaceAdmin && route === ROLES.SUPER_ADMIN) {
      navigate("/places");
      setPageLoading(false);
    } else if (route !== ROLES.SUPER_ADMIN && isAdmin) {
      navigate("/home");
      setPageLoading(false);
    } else if (!isPlaceAdmin && !isAdmin && token) {
      return <Forbidden />;
    } else {
      setPageLoading(false);
    }
  }, [isAdmin, isPlaceAdmin, navigate, route, token]);

  return pageLoading ? (
    <div className="d-flex vh-100 w-100 align-items-center justify-content-center">
      <CSpinner color="success" />
    </div>
  ) : (
    <main>{children}</main>
  );
}

function Forbidden() {
  const { removeCookie } = useCookie("vToken");

  const navigate = useNavigate();

  const logout = () => {
    navigate("/");
    removeCookie("vToken");
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <h2 className="fw-bold fs-3 text-danger text-center mb-2">
        Accès interdit
      </h2>
      <p className="text-dark text-center mb-4">
        L&apos;accès à cette ressource a été refusé
      </p>
      <button
        type="button"
        onClick={logout}
        className="btn btn-danger shadow-secondary"
      >
        Réessayez de se connecter
      </button>
    </div>
  );
}
