import { CFormInput, CSpinner, CForm, CFormSelect } from "@coreui/react";
import { FormEvent, useEffect, useState } from "react";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import { Link, useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "../../components/RichTextEditor";
import BoxWrapper from "../../components/BoxWrapper";
import { useEventQuery } from "../../hooks/events/useEventQuery";
import { usePlacesQuery } from "../../hooks/place/usePlacesQuery";
import { OFFER_TYPES } from "./NewOffer";
import { useAuth } from "../../hooks/useAuth";
import ImagePreview from "../../components/ImagePreview";
import { FormSelectAPI } from "../../components/FormReactSelect";

interface updateOfferProp {
  title: string;
  text: string | undefined;
  reductionPercent: number;
  carouselImage: File | string;
  picture: File | string;
  type: string;
  eventId: string;
  numberOfPersons: string;
  date: string;
  duration: string;
  hour: string;
  price: string;
}
export default function UpdateOffer() {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const params = useParams();
  const queryClient = useQueryClient();
  const [OfferDescription, setOfferDescription] = useState("");
  const [placeSearchQuery, setPlaceSearchQuery] = useState("");
  const navigate = useNavigate();
  const [offerType, setOfferType] = useState("");

  const { data, isLoading } = useQuery<SpecialOffers>(
    ["getSingleOffer"],
    () => {
      return apiService.MakeGetRequest(`specials/${params?.id}`);
    }
  );

  const { events } = useEventQuery();
  const { Places } = usePlacesQuery();
  const { isAdmin } = useAuth();
  const updateOffer = useMutation((data: updateOfferProp) => {
    return apiService.MakePutRequest(
      `specials/${params.id}`,
      data,
      token,
      true
    );
  });

  useEffect(() => {
    setOfferType(data?.type!);
  }, [data]);

  const getFormData = (e: FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);

    const title = formData.get("title") as string;
    const offerType = formData.get("offerType") as string;
    const eventId = formData.get("eventId") as string;
    const placeId = formData.get("placeId") as string;
    const numberOfPersons = formData.get("numberOfPerson") as string;
    const date = formData.get("date") as string;
    const duration = formData.get("duration") as string;
    const hour = formData.get("hour") as string;
    const price = formData.get("price") as string;
    const reductionPercent = formData.get("reductionPercent") as string;
    const picture = formData.get("picture") as File;
    const carouselImage = formData.get("carouselImage") as File;

    return {
      title,
      text: OfferDescription || data?.text || "",
      reductionPercent: Number(reductionPercent),
      carouselImage: carouselImage || " ",
      picture: picture || "",
      type: offerType,
      eventId,
      placeId,
      numberOfPersons,
      date: date ? new Date(date).toISOString() : "",
      duration,
      hour,
      price
    };
  };

  const updateOfferHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = getFormData(e);

    updateOffer.mutate(data, {
      onError() {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        queryClient.invalidateQueries("getAllOffers");
        navigate(`/offers`);
        toastSuccess(general.fr.message.offerUpdated);
      }
    });
  };

  Date.prototype.toDateInputValue = function() {
    const local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  };

  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Mise à jour de l'offre</h4>
            </div>
            <CForm
              validated={true}
              className="row form mt-4"
              onSubmit={updateOfferHandler}
            >
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Titre de l&apos;offre{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Titre de l'offre"
                  id="title"
                  name="title"
                  required
                  defaultValue={data?.title}
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Sélectionnez le type d&apos;offre{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormSelect
                  name="offerType"
                  id="offerType"
                  className="custom-input"
                  required
                  onChange={e => setOfferType(e.target.value)}
                  defaultValue={data?.type}
                >
                  <option
                    value={OFFER_TYPES.SPECIAL}
                    selected={data?.type === OFFER_TYPES.SPECIAL}
                  >
                    OFFRE SPÉCIALE
                  </option>
                  <option
                    value={OFFER_TYPES.EVENT}
                    selected={data?.type === OFFER_TYPES.EVENT}
                  >
                    OFFRE D&apos;ÉVÉNEMENT
                  </option>
                  <option
                    value="TOUR_OFFER"
                    selected={data?.type === OFFER_TYPES.TOUR}
                  >
                    OFFRE DE TOURISME
                  </option>
                </CFormSelect>
              </div>

              {(offerType === OFFER_TYPES.EVENT ||
                data?.type === OFFER_TYPES.EVENT) && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="eventId" className="d-block mb-2">
                    Événement <span className="text-md text-red">*</span>
                  </label>
                  <FormSelectAPI
                    // @ts-ignore
                    name="eventId"
                    id="eventId"
                    isClearable={false}
                    getOptionLabel={(option: Events) => option?.name}
                    getOptionValue={(option: Events) => option.id}
                    isOptionSelected={(
                      option: Events,
                      selectedValue: Events[]
                    ) => {
                      const isSelected = option?.id === selectedValue?.[0]?.id;
                      return isSelected;
                    }}
                    url={{
                      path: "events/ours"
                    }}
                    defaultValue={data?.event ?? ""}
                    required
                    isLoading={isLoading}
                    isSearchable
                  />

                  {/* <CFormSelect
                    name="eventId"
                    id="eventId"
                    className="custom-input"
                    required
                    defaultValue={data?.event?.id}
                  >
                    {events?.map((event: Events) => (
                      <option value={event?.id} key={event?.id}>
                        {event?.name}
                      </option>
                    ))}
                  </CFormSelect> */}
                </div>
              )}

              {(offerType === OFFER_TYPES.SPECIAL ||
                data?.type === OFFER_TYPES.SPECIAL) &&
                isAdmin && (
                  <div className="col-md-6 mt-4">
                    <label htmlFor="placeId" className="d-block mb-2">
                      Place <span className="text-md text-red">*</span>
                    </label>
                    <FormSelectAPI
                      // @ts-ignore
                      name="placeId"
                      id="placeId"
                      isClearable={false}
                      getOptionLabel={(option: Place) => option?.name}
                      getOptionValue={(option: Place) => option.id}
                      isOptionSelected={(
                        option: Place,
                        selectedValue: Place[]
                      ) => {
                        const isSelected =
                          option?.id === selectedValue?.[0]?.id;
                        return isSelected;
                      }}
                      url={{
                        path: "places"
                      }}
                      required
                      defaultValue={data?.place ?? ""}
                      isLoading={isLoading}
                      isSearchable
                    />

                    {/* <CFormSelect
                      name="placeId"
                      id="placeId"
                      className="custom-input"
                      required
                      defaultValue={data?.place?.id}
                    >
                      {Places?.map((place: Place) => (
                        <option value={place?.id} key={place?.id}>
                          {place?.name}
                        </option>
                      ))}
                    </CFormSelect> */}
                  </div>
                )}

              <div className="col-md-6 mt-4">
                <label htmlFor="price" className="d-block">
                  Prix
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Prix"
                  id="price"
                  name="price"
                  defaultValue={data?.price}
                  disabled={offerType !== OFFER_TYPES.TOUR}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="reductionPercent" className="d-block">
                  Remise
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Remise"
                  id="reductionPercent"
                  name="reductionPercent"
                  defaultValue={data?.reductionPercent}
                  disabled={offerType !== OFFER_TYPES.SPECIAL}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="numberOfPerson" className="d-block">
                  Nombre de personnes
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder=" Nombre de personnes"
                  id="numberOfPerson"
                  name="numberOfPerson"
                  defaultValue={data?.numberOfPersons}
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="date" className="d-block">
                  Date
                </label>
                <input
                  type="date"
                  className="custom-input"
                  placeholder="Date"
                  id="date"
                  name="date"
                  defaultValue={
                    data?.date && new Date(data?.date).toDateInputValue()
                  }
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="duration" className="d-block">
                  Durée de l&apos;offre
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Durée de l'offre"
                  id="duration"
                  name="duration"
                  defaultValue={data?.duration}
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="hour" className="d-block">
                  Heure
                </label>
                <CFormInput
                  type="time"
                  className="custom-input"
                  placeholder="Heure"
                  id="hour"
                  name="hour"
                  defaultValue={data?.hour}
                  disabled={offerType === OFFER_TYPES.EVENT}
                />
              </div>
              {offerType !== "EVENT_OFFER" && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="text" className="d-block">
                    Description de l&apos;offre{" "}
                    <span className="text-md text-red">*</span>
                  </label>

                  <RichTextEditor
                    onChange={data => setOfferDescription(data)}
                    defaultValue={data?.text ?? ""}
                  />
                </div>
              )}

              <div className="col-md-6 mt-4">
                <label htmlFor="picture" className="d-block mb-1">
                  Image de l&apos;offre
                </label>
                {data?.image && <ImagePreview url={data?.image} />}
                <CFormInput
                  type="file"
                  id="formFile"
                  name="picture"
                  className="custom-input"
                  disabled={offerType === OFFER_TYPES.EVENT}
                  accept="image/png,image/jpeg,image/jpg"
                />
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="carouselImage" className="d-block mb-1">
                  Image du carrousel
                </label>
                {data?.carouselPicture && (
                  <ImagePreview url={data?.carouselPicture} />
                )}
                <CFormInput
                  type="file"
                  id="carouselImage"
                  name="carouselImage"
                  className="custom-input"
                  accept="image/png,image/jpeg,image/jpg"
                />
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to="/offers"
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={updateOffer.isLoading}
                >
                  {updateOffer.isLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </CForm>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
