import { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import BoxWrapper from "../../components/BoxWrapper";
import { PacksSvg } from "../../components/SideBarMenu";
import { CModal, CSpinner } from "@coreui/react";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { colors } from "../../config/theme";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import RenderTable from "../../components/RenderTable";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import { useAuthContext } from "../../context/auth";
import { useDebounce } from "usehooks-ts";
import PacksTable from "./PacksTable";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import Pagination from "../../components/Pagination";

const PAGE_SIZE = 10;

export default function Packs() {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();
  const queryClient = useQueryClient();

  const { isAdmin } = useAuth();
  const { user } = useAuthContext();

  const searchQuery = useDebounce(query, 1000);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery])

  const fetchPacks = async (
    currentPage: number,
    searchQuery: string,
    token: string
  ) => {
    const endpoint = `packs?page=${currentPage}&limit=${PAGE_SIZE}&q=${searchQuery}&${
      !isAdmin ? `place=${user?.adminForPlace?.id}` : ""
    }`;
    return apiService.MakeGetRequest(endpoint, token);
  };

  const querykey = ["getAllPacks", token, currentPage, searchQuery];

  const { data, isLoading, isPreviousData } = useQuery(
    querykey,
    query => fetchPacks(currentPage, searchQuery, query.queryKey[1]),
    { keepPreviousData: true }
  );

  const deletePack = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`packs/${id}`, token);
  });

  const [packsToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    packsToBeDeleted.ids.forEach((id: string) => {
      if (!id) return;
      deletePack.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.packDeleted);
          dispatch({ type: "", id: "" });
          queryClient.invalidateQueries("getAllPacks");
        }
      });
    });
  };

  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Packs</h5>
      <BoxWrapper>
        <div className="d-flex  justify-content-between align-items-baseline before-table">
          <div className="d-flex align-items-center gap-2 p-3 delete-add">
            <Link
              to="/packs/new"
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
            >
              + Ajouter un Pack
            </Link>

            <button
              disabled={
                deletePack.isLoading || packsToBeDeleted?.ids?.length === 0
              }
              onClick={handleBulkDelete}
              className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
            >
              {!deletePack.isLoading ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer le Pack
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>
          <div className="search-form">
            <div className=" input-field">
              <MagnifyingGlassIcon color="gray" width={20} />
              <input
                type="text"
                className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                placeholder="Rechercher"
                onChange={e => setQuery(e?.target?.value?.toLowerCase())}
              />
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
            style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
          >
            <PacksSvg className="fill-white" />
            <p className="fs-5 fw-bold m-0  text-white text-pre">
              {data?.meta?.totalItems}{" "}
              {data?.meta?.totalItems > 1 ? "Packs" : "Pack"}
            </p>
          </div>
        </div>

        <RenderTable
          loading={isLoading || isPreviousData}
          render={() =>
            data?.items?.length > 0 ? (
              <PacksTable
                packs={data?.items as Pack[]}
                packsToBeDeleted={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={data?.meta?.totalPages}
        pageSize={PAGE_SIZE}
      />
    </section>
  );
}
