import toast from "react-hot-toast";
import { colors } from "../config/theme";

export const useToast = () => {
  return {
    toastSuccess(message: string) {
      toast.success(message, {
        iconTheme: {
          primary: colors.primary,
          secondary: colors.light
        },
        style: {
          background: "#31b509",
          color: colors.light
        },
        duration: 3000
      });
    },
    toastError(message: string) {
      toast.error(message, {
        iconTheme: {
          primary: colors.light,
          secondary: colors.primary
        },
        style: {
          background: "#e11d48",
          color: colors.light
        },
        duration: 3000
      });
    }
  };
};
