// @ts-nocheck
import React, { useEffect } from "react";
import { CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import { useAuth } from "../../../hooks/useAuth";
import { useState } from "react";
import { useCookie } from "../../../hooks/useCookie";
import { general } from "../../../locales/general";
import { useToast } from "../../../hooks/useToast";
import { useMutation } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode } from "../../../helpers/enums";
import { useForm, Controller } from "react-hook-form";
import apiService from "../../../service/apiService";
import RichTextEditor from "../../../components/RichTextEditor";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";

interface ServiceTranslationData {
    name: string;
    description: string;
    lang: string;
    serviceId: string;
}

export default function NewServiceTranslation({ mode = FormMode.CREATE }) {
    const [formLoading, setFormLoading] = useState(false);
    const { isAdmin } = useAuth();
    const { token } = useCookie("vToken");
    const { toastError, toastSuccess } = useToast();
    const params = useParams();
    const navigate = useNavigate();
    const [description, setDescription] = useState("");

    const schema = yup.object({
        label: yup.string().required(),
        description: yup.string().nullable(),
        otherInfo: yup.string().nullable(),
        lang: yup.string().required(),
        serviceId: yup.object().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control: formControl,
        setValue: setFormValue,
        getValues: getFormValue
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });

    useEffect(() => {
        if (mode === FormMode.UPDATE && params?.id) {
            const formFields = Object.keys(schema.fields);
            const serviceGetRequest = apiService.MakeGetRequest(
                `service-translations/${params.id}`,
                token
            );

            Promise.all([serviceGetRequest]).then(
                ([serviceResp]) => {
                    setDescription(serviceResp?.description);
                    setFormValue("serviceId", serviceResp?.service);
                    Object.entries(serviceResp).forEach(entry => {
                        if (formFields.indexOf(entry[0]) !== -1) {
                            setFormValue(entry[0], entry[1]);
                        }
                    });
                }
            );
        }
    }, [params?.id]);

    const createServiceTranslationMutation = useMutation(async serviceTranslationData => {
        return await apiService.MakePostRequest("service-translations", serviceTranslationData, token);
    });

    const updateServiceTranslationMutation = useMutation(async serviceTranslationData => {
        return await apiService.MakePutRequest(
            `service-translations/${params.id}`,
            serviceTranslationData,
            token
        );
    });

    const createServiceTranslation = async data => {
        try {
            setFormLoading(true);
            await createServiceTranslationMutation.mutateAsync(data);
            toastSuccess(general.fr.message.translationCreated);
            navigate(-1);
        } catch (error) {
            setFormLoading(false);
            toastError(general.fr.message.operationFailed);
        }
    };

    const updateServiceTranslation = async data => {
        try {
            setFormLoading(true);
            const updateServiceTranslationReq = updateServiceTranslationMutation.mutateAsync(data);

            if (isAdmin) {
                await Promise.all([updateServiceTranslationReq]);
                toastSuccess(general.fr.message.translationUpdated);
                navigate(-1);
            } else {
                await updateServiceTranslationReq;
                toastSuccess(general.fr.message.translationUpdated);
                navigate(-1);
            }
        } catch (error) {
            setFormLoading(false);
            toastError(general.fr.message.operationFailed);
        }
    };

    const onSubmit = async (dataArg: ServiceTranslationData) => {
        const data = { ...dataArg };
        try {
            data.serviceId = data.serviceId.id;
            if (mode === FormMode.UPDATE) {
                updateServiceTranslation({ ...data, description });
            } else {
                createServiceTranslation({ ...data, description });
            }
        } catch (error) {
            setFormLoading(false);
            console.log(error);
            toastError(general.fr.message.operationFailed);
        }
    };

    return (
        <div className=" m-4  ">
            <BoxWrapper>
                <section className="p-4">
                    <div className="border-bottom border-success">
                        <h4>
                            {mode === FormMode.UPDATE
                                ? "Mise à jour"
                                : "Ajouter une traduction"}
                        </h4>
                    </div>
                    <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-6 my-3">
                            <label htmlFor="serviceId" className="d-block mb-2">
                                Service <span className="text-md text-red">*</span>
                            </label>
                            <Controller
                                name="serviceId"
                                control={formControl}
                                render={({ field }) => {
                                    return (
                                        <InfiniteScrollSelect
                                            name="serviceId"
                                            id="serviceId"
                                            isClearable={false}
                                            error={errors?.serviceId?.message}
                                            getOptionLabel={option => option?.label}
                                            getOptionValue={option => option.id}
                                            isOptionSelected={(option, selectedValue) => {
                                                const isSelected =
                                                    option?.id === selectedValue?.[0]?.id;
                                                return isSelected;
                                            }}
                                            url={{
                                                path: 'services'
                                            }}
                                            {...field}
                                        />
                                    );
                                }}
                            />
                            {errors.serviceId?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.serviceId.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6  my-3">
                            <label htmlFor="lang" className="d-block">
                                La langue
                                <span className="text-sm text-red"> *</span>
                            </label>
                            <CFormSelect
                                name="lang"
                                id="lang"
                                {...register("lang")}
                            >
                                <option value="en">Anglais</option>
                                <option value="ar">Arabic</option>
                                <option value="fr">Français</option>
                            </CFormSelect>
                            {errors.lang?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.supportTableSelection.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6  my-3 ">
                            <label htmlFor="name" className="d-block">
                                Nom de la service <span className="text-sm text-red">*</span>
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                serviceholder="Nom de la service"
                                id="label"
                                name="label"
                                {...register("label")}
                            />
                            {errors.label?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.label.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6  my-3 ">
                            <label htmlFor="name" className="d-block">
                                Autres informations
                            </label>
                            <CFormInput
                                type="text"
                                className="custom-input"
                                serviceholder="Autres informations"
                                id="otherInfo"
                                name="otherInfo"
                                {...register("otherInfo")}
                            />
                            {errors.otherInfo?.message && (
                                <div
                                    className="text-red-500 text-opacity-50"
                                    style={{ color: "red" }}
                                >
                                    {errors.otherInfo.message}
                                </div>
                            )}
                        </div>

                        <div className="my-3">
                            <label htmlFor="description">Description</label>
                            <RichTextEditor
                                onChange={setDescription}
                                // {...register("description")}
                                name="description"
                                defaultValue={description}
                            />
                        </div>

                        <section className="d-flex justify-content-center buttons gap-4 mt-5">
                            <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                            >
                                Annuler
                            </button>
                            <button
                                className="btn btn-success green-shadow w-20 text-white"
                                type="submit"
                                disabled={formLoading}
                            >
                                {formLoading ? (
                                    <div className="text-center">
                                        <CSpinner size="sm" />
                                    </div>
                                ) : (
                                    "Enregistrer"
                                )}
                            </button>
                        </section>
                    </form>
                </section>
            </BoxWrapper>
        </div>
    );
}
