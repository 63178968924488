import React, { createRef, FormEvent } from "react";
import { Link } from "react-router-dom";
import { general } from "../../locales/general";
import { useMutation, useQuery } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import Change_avatar from "../../components/staticsLogos/Change_avatar.png";
import { useToast } from "../../hooks/useToast";
import { useAuthContext } from "../../context/auth";
import { CSpinner } from "@coreui/react";
import { useCitiesQuery } from "../../hooks/Cities/useCitiesQuery";

interface UpdateUserProp {
  profilePicture?: File;
}
interface Profile {
  fullName: string;
  phone: string;
  cityId: number;
}

export default function Compte({ profilePicture }: UpdateUserProp) {
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const { user, setUser } = useAuthContext();

  const pictureRef = createRef<HTMLInputElement>();

  const updateUserAvatar = useMutation((data: UpdateUserProp) => {
    return apiService.MakePutRequest("users/picture", data, token, true);
  });

  const updateProfile = useMutation((data: Profile) => {
    return apiService.MakePutRequest("users", data, token);
  });

  const getFormData = (e: FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const fullName = formData.get("fullName") as string;
    const phone = formData.get("phone") as string;
    const cityId = formData.get("cityId") as string;

    return { fullName, cityId: +cityId, phone };
  };

  const updateProfileHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateProfile.mutate(
      { ...getFormData(e) },
      {
        onError() {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess(data) {
          toastSuccess(general.fr.message.profileUpdated);
        }
      }
    );
  };

  const updatePhotoHandler = () => {
    updateUserAvatar.mutate(
      {
        profilePicture: pictureRef?.current?.files?.[0]! || " "
      },
      {
        onError() {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess(data) {
          setUser(data);
        }
      }
    );
  };

  const { Cities: cities } = useCitiesQuery();

  return (
    <section className="my-3">
      <h5 className="fs-4 fw-bold mb-4">Compte</h5>
      <div className="d-flex flex-column align-items-center bg-white py-4 px-2 mx-auto ">
        <form className="col-md-4 form">
          <div className="d-flex flex-column justify-content-center align-items-center mb-4">
            <div className="position-relative">
              <img
                src={user?.profilePicture as string}
                alt=""
                width={100}
                height={100}
                className="rounded-circle border border-2 border-danger overflow-hidden bg-white "
              />

              <label htmlFor="profilePicture">
                {!updateUserAvatar.isLoading ? (
                  <img
                    src={Change_avatar}
                    alt=""
                    width={30}
                    className="position-absolute bottom-0 end-0 pointer"
                  />
                ) : (
                  <CSpinner
                    className="position-absolute bottom-25 pointer"
                    size="sm"
                    style={{ right: "40%" }}
                  />
                )}

                <input
                  type="file"
                  name="file"
                  id="profilePicture"
                  ref={pictureRef}
                  hidden
                  onChange={updatePhotoHandler}
                />
              </label>
            </div>
          </div>
        </form>
        <form onSubmit={updateProfileHandler} className="col-md-8 mt-4">
          <div className="row">
            <div className="col-md-6 mb-4">
              <label htmlFor="fullName" className="text-sm">
                Nom complet
              </label>
              <input
                type="text"
                className="custom-input w-100 mt-1"
                name="fullName"
                placeholder="Nom complet"
                defaultValue={user?.fullName}
              />
            </div>

            <div className="col-md-6 mb-4">
              <label htmlFor="email" className="text-sm">
                Email
              </label>
              <input
                type="email"
                className="custom-input w-100 mt-1"
                name="email"
                placeholder="Full Name"
                defaultValue={user?.email}
                disabled
              />
            </div>

            <div className="col-md-6 mb-4">
              <label htmlFor="phone" className="text-sm">
                Téléphone
              </label>
              <input
                type="number"
                className="custom-input w-100 mt-1"
                name="phone"
                placeholder="Téléphone"
                defaultValue={user?.phone}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="cityId" className="d-block text-sm">
                Ville
              </label>
              <select name="cityId" id="cityId" defaultValue={user?.city?.id}>
                {cities?.map((city: City) => (
                  <option
                    value={city?.id}
                    key={city?.id}
                    selected={user?.city?.id === city?.id}
                  >
                    {city?.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <Link to={"/change"} className="text-danger text-sm pb-4 w-fit">
            Changer le mot de passe
          </Link>

          <button
            type="submit"
            disabled={updateProfile.isLoading}
            className="d-flex align-items-center justify-content-center btn btn-danger shadow-primary d-block w-50 ml-auto mt-2 p-2 fw-bold text-white"
          >
            Valider
          </button>
        </form>
      </div>
    </section>
  );
}
