import React from "react";
import { createContext, ReactNode, useContext, useState } from "react";

interface authTypes {
  user: User | null;
  setUser: (payload: User | null) => void;
  setAuthPageLoading: (payload: boolean) => void;
  authPageLoading: boolean;
}
const AuthContext = createContext<authTypes>({
  setUser: () => {},
  user: null,
  authPageLoading: true,
  setAuthPageLoading: () => {},
});
export function useAuthContext() {
  return useContext(AuthContext);
}

export default function AuthContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [user, setUser] = useState<User | null>(null);

  const [authPageLoading, setAuthPageLoading] = useState(true);

  const updateUser = (payload: User | null) => {
    setUser(payload);
    setAuthPageLoading(false);
  };

  const defaultValue = {
    setUser: updateUser,
    user,
    authPageLoading,
    setAuthPageLoading,
  };

  return (
    <AuthContext.Provider value={defaultValue}>{children}</AuthContext.Provider>
  );
}
