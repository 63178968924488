import React, { useMemo, useReducer, useState } from "react";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../../context/bulkDeleteReducer";
import BoxWrapper from "../../../components/BoxWrapper";
import { Link, useParams } from "react-router-dom";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { colors } from "../../../config/theme";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CSpinner } from "@coreui/react";
import { BuildingStorefrontIcon } from "@heroicons/react/24/outline";
import RenderTable from "../../../components/RenderTable";
import { usePaginatedContent } from "../../../hooks/usePaginatedContent";
import { general } from "../../../locales/general";
import Pagination from "../../../components/Pagination";
import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../../service/apiService";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import PlaceServicesTable from "./EventServiceTable";
import { pluralize } from "../../../helpers/general";

const PAGE_SIZE = 10;

export default function EventServices() {
  const params = useParams();
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const { data: Services, isLoading } = useQuery(["getEventsServices"], () => {
    return apiService.MakeGetRequest(`events/${params?.eventId}`);
  });

  const deleteServices = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`services/${id}`, token);
  });

  const totalItems = Services?.services?.length;
  const totalPages = Math.ceil(totalItems / PAGE_SIZE);

  const paginatedContent = usePaginatedContent(
    currentPage,
    PAGE_SIZE,
    Services?.services
  );

  const filteredServices = useMemo(() => {
    const Services = paginatedContent?.filter((Service: any) =>
      Service.label?.toLowerCase()?.includes(searchQuery)
    );
    return Services;
  }, [paginatedContent, searchQuery]);

  const [ServicesToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    ServicesToBeDeleted.ids.forEach((id: string) => {
      if (!id) return;
      deleteServices.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          queryClient.invalidateQueries(["getEventsServices"]);
          toastSuccess(general.fr.message.serviceDeleted);
        }
      });
    });
  };

  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Services</h5>
      <BoxWrapper>
        <div className="d-flex flex-column-reverse flex-md-row  justify-content-between">
          <div className="d-flex flex-column flex-md-row align-items-md-center gap-2 p-3">
            <Link
              to={`/events/${params?.eventId}/services/new`}
              className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary"
            >
              + Ajouter un service
            </Link>

            <button
              disabled={
                deleteServices.isLoading ||
                ServicesToBeDeleted?.ids.length === 0
              }
              onClick={handleBulkDelete}
              className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
            >
              {!deleteServices.isLoading ? (
                <>
                  <DeleteIcon fill={colors.primary} /> Supprimer
                </>
              ) : (
                <>
                  <CSpinner size="sm" /> {general.fr.wait}
                </>
              )}
            </button>
          </div>
          <div className="d-flex flex-column-reverse flex-md-row  align-items-md-center justify-content-between">
            <div className="search-form">
              <div className=" input-field">
                <MagnifyingGlassIcon color="gray" width={20} />
                <input
                  type="text"
                  className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                  placeholder="Rechercher"
                  onChange={e =>
                    setSearchQuery(e?.target?.value?.toLowerCase())
                  }
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
              style={{ borderTopRightRadius: "8px" }}
            >
              <BuildingStorefrontIcon width="25" height="25" color="white" />
              <p className="fs-5 fw-bold m-0  text-white">
                {totalItems} {pluralize("Service", totalItems)}
              </p>
            </div>
          </div>
        </div>

        <RenderTable
          loading={isLoading}
          render={() =>
            filteredServices?.length > 0 ? (
              <PlaceServicesTable
                event={Services}
                Services={filteredServices as Service[]}
                ServicesToBeDeleted={dispatch}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={totalPages}
        pageSize={PAGE_SIZE}
      />
    </section>
  );
}
