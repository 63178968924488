import { CSpinner } from "@coreui/react";
import React from "react";

export default function Loader() {
  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <CSpinner />
    </div>
  );
}
