import React from "react";

export default function ClockIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 4C9.55228 4 10 4.44772 10 5V9.58582L12.7071 12.2929C13.0976 12.6834 13.0976 13.3166 12.7071 13.7071C12.3166 14.0977 11.6834 14.0977 11.2929 13.7071L8.29289 10.7071C8.1932 10.6074 8.11895 10.4919 8.07016 10.3687C8.02488 10.2546 8 10.1302 8 10V5C8 4.44772 8.44771 4 9 4Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z"
        fill="black"
      />
    </svg>
  );
}
