import { useMutation, useQuery, useQueryClient } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../useCookie";
import { useToast } from "../useToast";
import { general } from "../../locales/general";
import { useAuth } from "../useAuth";

export const usePlacesQuery = () => {
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const { toastError, toastSuccess } = useToast();
  const { isAdmin } = useAuth();

  const { data, isLoading } = useQuery(
    ["getAllPlaces"],
    query => {
      return apiService.MakeGetRequest("places", token);
    },
    { enabled: isAdmin }
  );
  const deletePlaces = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`places/${id}`, token);
  });

  return {
    Places: data?.items,
    totalPlaces: data?.meta?.totalItems,
    totalPages: data?.meta?.totalPages,
    fetchingPlaces: isLoading,
    deletePlaces(id: string) {
      deletePlaces.mutate(id, {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(general.fr.message.placeDeleted);
          queryClient.invalidateQueries("getAllPlaces");
        }
      });
    },
    mutating: deletePlaces.isLoading
  };
};
