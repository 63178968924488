import { useQuery } from "react-query";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { useAuthContext } from "../../context/auth";
import { useToast } from "../../hooks/useToast";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { general } from "../../locales/general";
import { CSpinner } from "@coreui/react";

export default function Change() {
  const { token, removeCookie } = useCookie("vToken");
  const { toastError } = useToast();

  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [loggingOut, setLoggingOut] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setPasswordError(null);
    }, 3000);
  }, [passwordError]);
  // @desc: handle onsubmit
  const updatePasswordHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const oldPassword = formData.get("oldPassword")?.toString();
    const newPassword = formData.get("newPassword")?.toString();
    const confirmPassword = formData.get("confirmPassword")?.toString();

    if (!newPassword?.includes(confirmPassword || "")) {
      setPasswordError(general.fr.message.passwordFailed);
      return;
    }

    try {
      setIsSaving(true);
      await apiService.MakePutRequest(
        "users/password",
        { oldPassword, newPassword, confirmPassword },
        token
      );
      setLoggingOut(true);
      setTimeout(() => {
        removeCookie("vToken");
        navigate("/");
      }, 2000);
    } catch (error) {
      toastError(general.fr.message.passwordFailed);
    } finally {
      setIsSaving(false);
    }
  };

  if (loggingOut) {
    return (
      <div className="logout-wrapper">
        <div className="d-flex flex-column h-100 align-items-center justify-content-center">
          <CSpinner color="warning" />
        </div>
      </div>
    );
  }
  return (
    <section className="my-5 d-flex flex-column align-items-center w-full">
      <h5 className="fs-4 fw-bold mb-4 ">Changer le mot de passe</h5>
      <form
        className="row form py-5 px-3 col-8 m-auto bg-white"
        onSubmit={updatePasswordHandler}
      >
        <div className="col-12 mb-4 m-auto">
          <label htmlFor="oldPassword" className="text-sm">
            Ancien mot de passe
          </label>
          <input
            type="text"
            className="custom-input w-100 mt-1"
            name="oldPassword"
            placeholder="Ancien mot de passe"
            required
          />
        </div>
        <div className="col-md-6 mb-4 m-auto">
          <label htmlFor="newPassword" className="text-sm">
            Nouveau mot de passe
          </label>
          <input
            type="text"
            className="custom-input w-100 mt-1"
            name="newPassword"
            placeholder="Nouveau mot de passe"
            required
          />
        </div>
        <div className="col-md-6 mb-4 m-auto">
          <label htmlFor="confirmPassword" className="text-sm">
            Confirmation du Mot de passe
          </label>
          <input
            type="text"
            className="custom-input w-100 mt-1"
            name="confirmPassword"
            placeholder="Confirmation du Mot de passe "
            required
          />
        </div>
        {passwordError && (
          <p
            className="my-1 text-danger text-center"
            style={{ fontSize: "14px" }}
          >
            {passwordError}
          </p>
        )}
        <button className="d-flex align-items-center justify-content-center btn btn-danger shadow-primary d-block w-100 mt-2 p-2 fw-bold text-white">
          {isSaving ? (
            <div className="text-center">
              <CSpinner size="sm" />
            </div>
          ) : (
            "Valider"
          )}{" "}
        </button>
      </form>
    </section>
  );
}
