import { Query, toQuery } from "../helpers/general";

const DASHBOARD_ROUTE = "/";
const path = (baseUrl: string, subUrl: string) => `${baseUrl}${subUrl}`;

export const DASHBOARD_ROUTES = {
  places: {
    list: path(DASHBOARD_ROUTE, "places"),
    invoice: (placeId: string) =>
      path(DASHBOARD_ROUTE, `places/${placeId}/invoices`),
    service: (placeId: string) =>
      path(DASHBOARD_ROUTE, `places/${placeId}/services`),
    gallery: (placeId: string) =>
      path(DASHBOARD_ROUTE, `places/${placeId}/gallery`),
    reviews: (placeId: string) =>
      path(DASHBOARD_ROUTE, `places/${placeId}/ratings`),
    inspectors: (placeId: string) =>
      path(DASHBOARD_ROUTE, `places/${placeId}/inspectors`),
    floor_planner: (placeId: string) =>
      path(DASHBOARD_ROUTE, `places/${placeId}/planner`)
  },
  payment: {
    list: (query: Query) => path(DASHBOARD_ROUTE, `payouts${toQuery(query)}`)
  }
};
