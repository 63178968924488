import { CFormCheck, CTableDataCell, CTableRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../../config/theme";
import EditIcon from "../../components/icons/EditIcon";
import { Link } from "react-router-dom";
import DeleteIcon from "../../components/icons/DeleteIcon";
import PromptModal from "../../components/modals/PromptModal";
import { usePromoCode } from "../../hooks/usePromoCode";

interface Props {
  promoCode: PromoCode;
  onSelect: (id: string, state: "add" | "remove") => void;
}
export default function PromoCodeListRow(props: Props) {
  const { onSelect, promoCode } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { deletePromoCode } = usePromoCode();

  useEffect(() => {
    if (isChecked) {
      onSelect(promoCode.id, "add");
    } else {
      onSelect(promoCode.id, "remove");
    }
  }, [isChecked, promoCode.id]);

  return (
    <>
      <CTableRow
        style={{
          borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
          width: "100%",
          position: "relative"
        }}
      >
        <CTableDataCell className="p-3 text-sm">
          <div className="d-flex align-items-center gap-2">
            <CFormCheck
              id="flexCheckDefault"
              label=""
              defaultChecked={isChecked}
              onChange={e => setIsChecked(e.target.checked)}
              color={colors.yellow}
            />
            {promoCode.name}
          </div>
        </CTableDataCell>
        <CTableDataCell className="p-3 text-sm">
          {promoCode.event?.name}
        </CTableDataCell>
        <CTableDataCell className="p-3 text-sm">-</CTableDataCell>
        <CTableDataCell className="p-3 text-sm">
          {promoCode.discount}
        </CTableDataCell>
        <CTableDataCell className="p-3 text-sm">
          {promoCode.maxUses}
        </CTableDataCell>
        <CTableDataCell>
          <Link
            to={`/promo-codes/${promoCode.id}/edit`}
            className="outline-none border-0 bg-transparent"
          >
            <EditIcon width="18" height="18" fill={colors.secondary} />
          </Link>
          <button
            className="outline-none border-0 bg-transparent"
            onClick={() => setShowModal(true)}
          >
            <DeleteIcon
              width="20"
              height="20"
              fill={colors.primary}
              className="ms-2"
            />
          </button>
        </CTableDataCell>
      </CTableRow>

      <PromptModal
        open={showModal}
        onAccept={() => deletePromoCode(promoCode?.id)}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}
