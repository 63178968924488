// @ts-nocheck
import { CFormInput, CSpinner } from "@coreui/react";
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import RichTextEditor from "../../../components/RichTextEditor";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";
import { useAuth } from "../../../hooks/useAuth";
import ImagePreview from "../../../components/ImagePreview";
import { FormSelectAPI } from "../../../components/FormReactSelect";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode, OFFER_TYPES } from "../../../helpers/enums";
import { verifyFileList } from "../../../helpers/general";
import { useForm, Controller } from "react-hook-form";
import { useAuthContext } from "../../../context/auth";
import FileUploader from "../../../components/FileUploader";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";

export default function NewTourOffer({ mode = FormMode.CREATE }) {
  const [formLoading, setFormLoading] = useState(false);
  const [description, setDescription] = useState("");
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const navigate = useNavigate();
  const params = useParams();
  const { isAdmin } = useAuth();
  const { user } = useAuthContext();
  const [images, setImages] = useState({
    carousel: "",
    main: ""
  });
  const createNewOfferMutation = useMutation(async (data: SpecialOffers) => {
    return await apiService.MakePostRequest("specials", data, token, true);
  });

  const updateOfferMutation = useMutation(async (data: SpecialOffers) => {
    return await apiService.MakePutRequest(
      `specials/${params.id}`,
      data,
      token,
      true
    );
  });

  const schema = yup.object({
    title: yup.string().required("Ce champ est obligatoire"),
    text: yup.string().required("Ce champ est obligatoire"),
    price: yup
      .number()
      .positive()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire"),
    date: yup
      .date()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire"),
    hour: yup.string().required("Ce champ est obligatoire"),
    duration: yup
      .number()
      .integer()
      .positive()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire"),
    numberOfPersons: yup
      .number()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? Number(val) : null)),
    picture: yup.mixed().nullable(),
    carouselImage: yup.mixed().required("Ce champ est obligatoire"),
    ...(isAdmin
      ? {
          placeId: yup
            .object()
            .required("Ce champ est obligatoire")
            .typeError("Ce champ est obligatoire")
        }
      : {})
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue,
    watch: watchFormValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  const selectedPlace = watchFormValue("placeId");

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      const formFields = Object.keys(schema.fields);
      apiService.MakeGetRequest(`specials/${params.id}`).then(response => {
        setDescription(response?.text);
        setFormValue("placeId", response.place);
        setFormValue("picture", response.image);
        setFormValue("carouselImage", response.carouselPicture);
        setImages({ carousel: response.carouselPicture, main: response.image });
        Object.entries(response).forEach(entry => {
          if (formFields.indexOf(entry[0]) !== -1) {
            setFormValue(entry[0], entry[1]);
          }
        });
      });
    }
  }, [mode, params.id]);

  const createTourOffer = async data => {
    try {
      setFormLoading(true);
      await createNewOfferMutation.mutateAsync(data);
      toastSuccess(general.fr.message.tourOfferCreated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updateTourOffer = async data => {
    try {
      setFormLoading(true);
      await updateOfferMutation.mutateAsync(data);
      toastSuccess(general.fr.message.offerUpdated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async dataArg => {
    const data = { ...dataArg };
    try {
      data.type = OFFER_TYPES.TOUR;
      const imageFile = verifyFileList(data.picture);
      data.picture = imageFile;
      const carouselImageFile = verifyFileList(data.carouselImage);
      data.carouselImage = carouselImageFile;
      data.placeId = isAdmin ? data.placeId.id : user?.adminForPlace?.id;
      if (mode === FormMode.UPDATE) {
        updateTourOffer(data);
      } else {
        createTourOffer(data);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter une offre de tourisme</h4>
            </div>
            <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Titre de l&apos;offre{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Titre de l'offre"
                  id="title"
                  name="title"
                  {...register("title")}
                />
                {errors.title?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.title.message}
                  </div>
                )}
              </div>

              {isAdmin && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="price" className="d-block mb-2">
                    Établissement <span className="text-md text-red">*</span>
                    {selectedPlace?.isVisible === false ? (
                      <span className="text-attention">
                        {" "}
                        (cet établissement est invisible)
                      </span>
                    ) : null}
                  </label>
                  <Controller
                    name="placeId"
                    control={formControl}
                    render={({ field }) => {
                      return (
                        <InfiniteScrollSelect
                          name="placeId"
                          id="placeId"
                          isClearable={false}
                          error={errors?.placeId?.message}
                          getOptionLabel={option => option?.name}
                          getOptionValue={option => option.id}
                          isOptionSelected={(option, selectedValue) => {
                            const isSelected =
                              option?.id === selectedValue?.[0]?.id;
                            return isSelected;
                          }}
                          url={{
                            path: "places"
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  {errors.placeId?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.placeId.message}
                    </div>
                  )}
                </div>
              )}

              <div className="col-md-6 mt-4">
                <label htmlFor="price" className="d-block">
                  Prix <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Prix"
                  id="price"
                  name="price"
                  {...register("price")}
                />
                {errors.price?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.price.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="numberOfPersons" className="d-block">
                  Nombre de personnes
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Nombre de personnes"
                  id="numberOfPersons"
                  name="numberOfPersons"
                  {...register("numberOfPersons")}
                />
                {errors.numberOfPersons?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.numberOfPersons.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="date" className="d-block">
                  Date <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="date"
                  className="custom-input"
                  placeholder="Date"
                  id="date"
                  name="date"
                  {...register("date")}
                />
                {errors.date?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.date.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="duration" className="d-block">
                  Durée de l&apos;offre (en heures){" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Durée de l'offre"
                  id="duration"
                  name="duration"
                  {...register("duration")}
                />
                {errors.duration?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.duration.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="hour" className="d-block">
                  Heure <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="time"
                  className="custom-input"
                  placeholder="Heure"
                  id="hour"
                  name="hour"
                  {...register("hour")}
                />
                {errors.hour?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.hour.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="picture" className="d-block mb-1">
                  Image de l&apos;offre
                </label>
                {/* {mode === FormMode.UPDATE && getFormValue("picture") ? (
                  <ImagePreview url={getFormValue("picture")} />
                ) : null} */}
                <FileUploader
                  type="file"
                  id="formFile"
                  name="picture"
                  // className="custom-input"
                  accept="image/png,image/jpeg,image/jpg"
                  {...register("picture")}
                  file={images?.main}
                />
                {errors.picture?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.picture.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="carouselImage" className="d-block mb-1">
                  Image du carrousel <span className="text-md text-red">*</span>
                </label>
                {/* {mode === FormMode.UPDATE && getFormValue("carouselImage") ? (
                  <ImagePreview url={getFormValue("carouselImage")} />
                ) : null} */}
                <FileUploader
                  type="file"
                  id="carouselImage"
                  name="carouselImage"
                  // className="custom-input"
                  required={mode === FormMode.UPDATE ? false : true}
                  accept="image/png,image/jpeg,image/jpg"
                  {...register("carouselImage")}
                  file={images?.carousel}
                />
                {errors.carouselImage?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.carouselImage.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="text" className="d-block mb-1">
                  Description de l&apos;offre{" "}
                  <span className="text-md text-red">*</span>
                </label>

                <RichTextEditor
                  defaultValue={description}
                  onChange={data => {
                    setDescription(data);
                    setFormValue("text", data);
                  }}
                />
                {errors.text?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.text.message}
                  </div>
                )}
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </button>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
